import { Moment } from 'moment';

import { PageInfo, UploadedFile } from './common';
import { Country } from './country';
import { Discussion } from './discussion';
import { Region } from './region';
import { Topic } from './topic';
import { User } from './user';

export type PocKeyPerformanceIndicatorsInput = {
  name: string;
  qualitativeType: (string | number)[];
  qualitative: string | number;
};

export interface PocInput {
  id?: string;
  name?: string;
  description?: string;
  innovationCategory?: string;
  type?: string;
  originated?: string;
  pocKeyTags?: string[];
  pocRegion?: string[];
  pocImplementationLocation?: string[];
  pocTargetLocation?: string[];
  pocTopic?: string[];
  pocBusinessArea?: string[];
  pocBusinessAreaCustom?: string[];
  pocBusinessProduct?: string[];
  pocBusinessProductCustom?: string[];
  startDate?: string | Moment;
  endDate?: string | Moment;
  targetDate?: string | Moment;
  keyPerformanceIndicators?: PocKeyPerformanceIndicatorsInput[];
  targetBudget?: {
    amount?: number;
    currency?: string;
  };
  sponsorType?: PocContactType;
  sponsorUserId?: string;
  sponsorFirstName?: string;
  sponsorLastName?: string;
  sponsorEmail?: string;
  ownerType?: PocContactType;
  ownerUserId?: string;
  ownerFirstName?: string;
  ownerLastName?: string;
  ownerEmail?: string;
  leadType?: PocContactType;
  leadUserId?: string;
  leadFirstName?: string;
  leadLastName?: string;
  leadEmail?: string;
  challengeId?: string;
  problems?: string[];
  financials?: string[];
  nonFinancials?: string[];
  features?: string[];
  conclusion?: string;
  clarification?: string;
  productStatus?: string;
  productClarification?: string;
  status?: string;
  lastDescription?: string;
  textBoxes?: PocTextBoxes[];
  links?: PocLinks[];
  images?: UploadedFile[];
  videos?: UploadedFile[];
  documents?: UploadedFile[];
}

export enum PocStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ABANDONED = 'ABANDONED',
}

export enum ApprovalStatus {
  DRAFT = 'DRAFT',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export const PocStatusList = [
  { label: 'Abandoned', value: PocStatus.ABANDONED },
  { label: 'Completed', value: PocStatus.COMPLETED },
  { label: 'In progress', value: PocStatus.IN_PROGRESS },
  { label: 'Not started', value: PocStatus.NOT_STARTED },
];

export enum PocProductStatus {
  NOT_LAUNCHED = 'NOT_LAUNCHED',
  LAUNCHED = 'LAUNCHED',
}

export enum PocContactType {
  USER = 'USER',
  CUSTOM = 'CUSTOM',
}

export const PocProductStatusList = [
  { label: 'Launched', value: PocProductStatus.LAUNCHED },
  { label: 'Not launched', value: PocProductStatus.NOT_LAUNCHED },
];

export enum PocInnovationCategory {
  poc = 'Proof of concept',
  product = 'Product or Service',
}

export const PocInnovationCategoryList = [
  { label: 'Proof of concept', value: PocInnovationCategory.poc },
  { label: 'Product or Service', value: PocInnovationCategory.product },
];

export type PatchPocKpiIndicatorsInput = {
  pocId: string;
  name: string;
  type: number;
  criteria: string;
  currency: string;
  amount: number;
  currentValue: string;
};

export interface PocKeyTags {
  id: string;
  name: string;
}

export interface PocBusinessArea {
  id: string;
  name: string;
  parentName: string;
}

export interface PocBusinessProduct {
  id: string;
  name: string;
  type: 'DEFAULT' | 'CUSTOM';
}

export interface PocProblem {
  id: string;
  description: string;
}

export interface PocFinancial {
  id: string;
  description: string;
}

export interface PocFeature {
  id: string;
  description: string;
}

export interface PocVendorPartner {
  id: string;
  vendorPartnerType: string;
  name: string;
  contact: string;
  email: string;
}

export interface PocKpiIndicators {
  pocId: string;
  name: string;
  type: number;
  criteria: string;
  currency: string;
  amount: number;
  currentValue: string;
}

export interface PocKpiTarget {
  pocId: string;
  targetDate: string;
  currentTargetDate: string;
  targetCurrency: string;
  targetAmount: number;
  currentTargetAmount: number;
  indicators: PocKpiIndicators[];
  createdBy: number;
  updatedAt: string;
}

export interface PocContactsCustomUser {
  firstName: string;
  lastName: string;
  email: string;
}

export interface PocContacts {
  id: string;
  sponsor: User;
  sponsorType: PocContactType;
  sponsorCustom: PocContactsCustomUser;
  owner: User;
  ownerType: PocContactType;
  ownerCustom: PocContactsCustomUser;
  lead: User;
  leadType: PocContactType;
  leadCustom: PocContactsCustomUser;
}

export interface PocTextBoxes {
  value?: string;
}

export interface PocLinks {
  value?: string;
}

export interface Poc {
  id: string;
  name?: string;
  description?: string;
  innovationCategory?: string;
  type?: string;
  originated?: string;
  region?: Region[];
  implementationLocation?: Country[];
  targetLocation?: Country[];
  pocKeyTags?: PocKeyTags[];
  topic?: Topic[];
  businessArea?: PocBusinessArea[];
  businessProduct?: PocBusinessProduct[];
  timelineStartDate?: string;
  timelineEndDate?: string;
  problems?: PocProblem[];
  financials: PocFinancial[];
  nonFinancials?: PocFinancial[];
  features?: PocFeature[];
  pocVendorPartner?: PocVendorPartner[];
  kpiTarget?: PocKpiTarget;
  approvalStatus?: ApprovalStatus;
  status?: PocStatus;
  challenge?: {
    id: string;
    title: string;
  };
  contacts?: PocContacts;
  conclusion?: string;
  clarification?: string;
  productStatus?: PocProductStatus;
  productClarification?: string;
  lastDescription?: string;
  pocTextBoxes?: PocTextBoxes[];
  pocLinks?: PocLinks[];
  pocImages?: UploadedFile[];
  pocVideos?: UploadedFile[];
  pocDocuments?: UploadedFile[];
  scv: boolean;
  rejectDesc?: string;
  createdBy?: User;
  createdAt?: string;
  updatedAt?: string;
  isLiked: boolean;
  likes: number;
  comments: number;
  discussion: Discussion;
}
export interface PocKeyTagsResponse {
  pocKeyTags: PocKeyTags[];
}

export interface PocType {
  id: string;
  name: string;
  regions: Region[];
  topics: Topic[];
  likes: number;
  comments: number;
  businessArea: {
    name: string;
  }[];
  status: string;
}

export interface PocEdge {
  cursor: string;
  node: PocType;
}

export interface AggregationType {
  name: string;
  buckets: {
    key: string;
    count: number;
  }[];
}

export interface PocAggregations {
  businessArea: AggregationType;
  implLocations: AggregationType;
  innovationCategory: AggregationType;
  leadName: AggregationType;
  ownerName: AggregationType;
  productArea: AggregationType;
  productStatus: AggregationType;
  regions: AggregationType;
  sponsorName: AggregationType;
  status: AggregationType;
  tags: AggregationType;
  targetLocations: AggregationType;
  topics: AggregationType;
}

export interface PocSearchConnection {
  aggregations: PocAggregations;
  edges: PocEdge[];
  pageInfo: PageInfo;
  totalCount: number;
}

export interface LikePoc {
  likePocBasics: {
    pocId: string;
    userId: number;
  };
}

export interface UnlikePoc {
  unlikePocBasics: boolean;
}
