import { User } from '@frontend/data-access';
import { Col, Empty, Row } from 'antd';
import Link from 'next/link';

import CompanyAvatar from '../../../company-avatar/company-avatar';

function UserProfileAbout({ data }: { data: User }) {
  const companies =
    data?.companies?.filter(
      (company) => company.approvalStatus === 'approved'
    ) || [];

  return (
    <div className="space-y-6">
      <div className="space-y-3">
        <div className="text-gray-900 text-sm font-semibold">Biography</div>
        {data?.profile?.shortBio ? (
          <div>{data?.profile?.shortBio}</div>
        ) : (
          <div className="text-gray-700">No information</div>
        )}
      </div>
      <div className="space-y-3">
        <div className="text-gray-900 text-sm font-semibold">Skills</div>
        {data?.skills?.length ? (
          <Row className="gap-2 mt-3">
            {data.skills.map((skill, index) => {
              return (
                <Col
                  className="bg-gray-200 py-1 px-3 rounded-full text-xs"
                  key={index}
                >
                  {skill.name}
                </Col>
              );
            })}
          </Row>
        ) : (
          <div className="text-gray-700">No information</div>
        )}
      </div>
      <div className="space-y-3">
        <div className="text-gray-900 text-sm font-semibold">
          Interested topics
        </div>
        <Row className="gap-2 mt-3">
          {data?.topics?.length ? (
            data.topics.map((topic, index) => {
              return (
                <Col
                  className="bg-gray-200 py-1 px-3 rounded-full text-xs"
                  key={index}
                >
                  {topic.name}
                </Col>
              );
            })
          ) : (
            <div className="text-gray-700">No information</div>
          )}
        </Row>
      </div>
      <div className="space-y-3">
        <div className="text-gray-900 text-sm font-semibold">
          Interested region
        </div>
        <Row className="gap-2 mt-3">
          {data?.regions?.length ? (
            data.regions.map((region, index) => {
              return (
                <Col
                  className="bg-gray-200 py-1 px-3 rounded-full text-xs"
                  key={index}
                >
                  {region.name}
                </Col>
              );
            })
          ) : (
            <div className="text-gray-700">No information</div>
          )}
        </Row>
      </div>
      <div className="space-y-3">
        <div className="text-gray-900 text-sm font-semibold">Company</div>
        {companies.length ? (
          <div className="grid md:grid-cols-2 gap-4">
            {companies.map((company, index) => {
              return (
                <div key={index} className="bg-gray-200 p-4 rounded-xl">
                  <div className="flex gap-3">
                    <CompanyAvatar
                      size={64}
                      company={company}
                      showBadge={company.companyPortfolio?.isScvPortfolio}
                    />
                    <div className="w-full">
                      <div>
                        <Link
                          href={'/company/' + company.id}
                          className="text-base text-gray-900"
                        >
                          {company.name}
                        </Link>
                      </div>
                      <span className="line-clamp-2">
                        {company.description}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>
    </div>
  );
}

export default UserProfileAbout;
