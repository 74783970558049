import { CloseCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Company,
  ErrorResponse,
  GET_COMPANY,
  PATCH_COMPANY,
  UploadedFile,
} from '@frontend/data-access';
import { Button, Form, notification } from 'antd';
import { useState } from 'react';

import FileUpload, {
  supportedDocumentFormats,
} from '../../../file-upload/file-upload';

interface FormValues {
  deck: UploadedFile;
}

function EditInformationDeckForm({
  companyId,
  onFinish,
  onCancel,
}: {
  companyId?: number;
  onFinish: () => void;
  onCancel: () => void;
}) {
  const [isIncomplete, setIsIncomplete] = useState(false);
  const [form] = Form.useForm();
  const { data } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: companyId },
  });

  const handleCompleted = () => {
    notification.success({
      message: 'Successfully saved',
      placement: 'bottomLeft',
    });
    onFinish();
  };

  const handleError = (data: ErrorResponse) => {
    notification.error({
      message: 'Error',
      description: data.message,
      icon: (
        <span className="text-red-600">
          <CloseCircleOutlined />
        </span>
      ),
      placement: 'bottomLeft',
    });
  };

  const [patchCompany, { loading: loadingPatch }] = useMutation(PATCH_COMPANY, {
    onCompleted: handleCompleted,
    onError: handleError,
  });

  const handleOnFinish = async ({ deck }: FormValues) => {
    await patchCompany({
      variables: {
        input: {
          id: companyId,
          documents: {
            onePagers: [...(data?.company?.onePagers ?? []), deck],
          },
        },
      },
    });
  };

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsIncomplete(hasErrors);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleOnFinish}
      onFieldsChange={handleFormChange}
    >
      <div className="px-6 pt-6">
        <Form.Item
          name="deck"
          rules={[{ required: true, message: 'Please input information deck' }]}
        >
          <FileUpload
            description={
              <div className="mb-4">
                Click or drag file to this area to upload
              </div>
            }
            onUploadSuccess={(file) => form.setFieldValue('deck', file)}
            compact={false}
            supportedFileTypes={supportedDocumentFormats}
            maxFileSize={10000000} // 10MB
          />
        </Form.Item>
      </div>
      <div className="flex justify-end items-center px-4 py-3 border-t border-solid border-gray-400 gap-2">
        <Button disabled={loadingPatch} shape="round" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          loading={loadingPatch}
          shape="round"
        >
          Save
        </Button>
      </div>
    </Form>
  );
}

export default EditInformationDeckForm;
