import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { ApplicationDocument, UploadedFile } from '@frontend/data-access';
import { Empty } from 'antd';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';

export interface DocumentProps {
  isOwner?: boolean;
  documents: ApplicationDocument[];
  stageId: string | null | undefined;
  stageState: string;
  onDelete: (id: string) => void;
  noEmpty?: boolean;
}

export function Document({
  documents,
  stageId = null,
  stageState,
  onDelete,
  noEmpty = true,
  isOwner = false,
}: DocumentProps) {
  const [docs, setDocs] = useState<ApplicationDocument[]>([]);

  useEffect(() => {
    if (stageId) {
      setDocs(
        documents.filter((doc) =>
          stageId === null ? doc.stage === null : doc.stage?.id === stageId
        )
      );
    } else {
      const registrationDocs: ApplicationDocument[] = [];
      documents.forEach((d) => {
        if (!d.stage) {
          registrationDocs.push(d);
        }
      });
      setDocs(registrationDocs);
    }
  }, [documents, stageId]);

  const renderFile = (file: UploadedFile) => {
    switch (file.state) {
      case 'CLEAN':
        return (
          <div className="truncate">
            <PaperClipOutlined className="mr-2" />
            <Link href={file.url || '#'} className="text-primary-600">
              {file?.metadata?.filename}
            </Link>
          </div>
        );
      case 'INFECTED':
        return (
          <div className="text-red-500 truncate">
            <PaperClipOutlined className="mr-2" />
            {file?.metadata?.filename} (virus infected)
          </div>
        );
      case 'PROCESSING':
        return (
          <div className="truncate">
            <PaperClipOutlined className="mr-2" />
            {file?.metadata?.filename}
          </div>
        );
      case 'NOT_IN_MIME':
        return (
          <div className="truncate">
            <PaperClipOutlined className="mr-2" />
            {file?.metadata?.filename} (File type not supported)
          </div>
        );
      default:
        return (
          <div className="truncate">
            <PaperClipOutlined className="mr-2" />
            {file?.metadata?.filename}
          </div>
        );
    }
  };

  return (
    <div>
      {docs.length ? (
        <>
          <div className="text-gray-700 mb-2">Document</div>
          {docs.map((doc, index) => (
            <div key={index} className="p-2 flex justify-between">
              {renderFile(doc?.file)}
              {stageState === 'ACTIVE' && isOwner && (
                <DeleteOutlined
                  className="hover:!text-primary-700 hover:!cursor-pointer"
                  onClick={() => onDelete(doc.id)}
                />
              )}
            </div>
          ))}
        </>
      ) : noEmpty ? (
        <Empty description="No documents" />
      ) : null}
    </div>
  );
}
