import { gql } from '@apollo/client';

import { COMPANY_BASIC, COMPANY_INFO } from './company';
import { KEY_TAG } from './key-tag';
import { REGION } from './region';
import { TOPIC } from './topic';
import { FILE } from './upload';
import { USER_BASICS } from './user';

export const PITCH_LEAST = gql`
  fragment pitchLeast on Pitch {
    id
    status
    title
    createdAt
    createdBy {
      ...userBasics
    }
  }
`;

export const PITCH = gql`
  fragment pitch on Pitch {
    id
    status
    title
    body
    video {
      ...file
    }
    link
    document {
      ...file
    }
    discussion {
      id
    }
    company {
      ...basicInfo
    }
    topics {
      ...topic
    }
    regions {
      ...region
    }
    createdBy {
      ...userBasics
    }
    createdAt
    publishedAt
    approvedBy {
      ...userBasics
    }
    approvedAt
    likes
    isLiked
    comments
    rejectDesc
    keyTags {
      ...keyTag
    }
  }
`;

export const PITCH_PAGINATION_TOTAL = gql`
  fragment pitchPaginationTotal on PitchPaginationTotal {
    items {
      ...pitch
    }
    total
  }
`;

export const GET_PITCHES = gql`
  query Pitches(
    $searchBy: String
    $filterBy: PitchFilters
    $sort: PitchSortOption
    $page: Int
    $limit: Int
  ) {
    pitches(
      searchBy: $searchBy
      filterBy: $filterBy
      sort: $sort
      page: $page
      limit: $limit
    ) {
      ...pitchPaginationTotal
    }
  }
  ${PITCH_PAGINATION_TOTAL}
  ${PITCH}
  ${KEY_TAG}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${REGION}
  ${TOPIC}
  ${FILE}
`;

export const APPROVE_PITCH = gql`
  mutation ApprovePitch($id: UUID!) {
    approvePitch(id: $id) {
      ...pitchLeast
    }
  }
  ${PITCH_LEAST}
  ${USER_BASICS}
  ${FILE}
`;

export const REJECT_PITCH = gql`
  mutation RejectPitch($id: UUID!, $desc: String) {
    rejectPitch(id: $id, desc: $desc) {
      ...pitchLeast
    }
  }
  ${PITCH_LEAST}
  ${USER_BASICS}
  ${FILE}
`;

export const GET_PITCH_BY_ID = gql`
  query Pitch($id: UUID!) {
    pitch(id: $id) {
      ...pitch
    }
  }
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${REGION}
  ${TOPIC}
  ${FILE}
  ${PITCH}
  ${KEY_TAG}
`;

export const GET_PITCHED_USERS = gql`
  query PitchedUsers($createdBy: String) {
    pitchedUsers(createdBy: $createdBy) {
      userWithIdAndFullName {
        id
        name
      }
      count
    }
  }
`;

export const CREATE_PITCH = gql`
  mutation CreatePitch($input: CreatePitchInput!) {
    createPitch(input: $input) {
      ...pitchLeast
    }
  }
  ${PITCH_LEAST}
  ${USER_BASICS}
  ${FILE}
`;
export const PATCH_PITCH = gql`
  mutation PatchPitch($input: PatchPitchInput!) {
    patchPitch(input: $input) {
      ...pitch
    }
  }
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${REGION}
  ${TOPIC}
  ${FILE}
  ${PITCH}
  ${KEY_TAG}
`;
export const PUBLISH_PITCH = gql`
  mutation PublishPitch($input: PatchPitchInput!) {
    publishPitch(input: $input) {
      ...pitchLeast
    }
  }
  ${PITCH_LEAST}
  ${USER_BASICS}
  ${FILE}
`;

export const DELETE_PITCH = gql`
  mutation deletePitch($id: UUID!) {
    deletePitch(id: $id)
  }
`;

export const UNLIKE_PITCH = gql`
  mutation likePitch($id: UUID!) {
    unlikePitch(id: $id)
  }
`;

export const LIKE_PITCH = gql`
  mutation likePitch($id: UUID!) {
    likePitch(id: $id) {
      pitchId
      userId
    }
  }
`;

export const GET_MY_PITCH = gql`
  query pitches($page: Int, $limit: Int) {
    me {
      pitches(page: $page, limit: $limit) {
        ...pitchPaginationTotal
      }
    }
  }
  ${PITCH_PAGINATION_TOTAL}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${REGION}
  ${TOPIC}
  ${FILE}
  ${PITCH}
  ${KEY_TAG}
`;

export const GET_PITCH_SUGGESTION = gql`
  query pitchSuggestion($input: SuggestPitchInput!) {
    pitchSuggestion(input: $input) {
      id
      title
      body
      video {
        ...file
      }
      link
      createdAt
      comments
      likes
    }
  }
  ${FILE}
`;

export const GET_PITCH_FOR_USER = gql`
  query pitchForUser(
    $first: Int
    $after: String
    $isAll: Boolean!
    $filterBy: PitchTopicRegionFilters
  ) {
    pitchForUser(
      first: $first
      after: $after
      isAll: $isAll
      filterBy: $filterBy
    ) {
      edges {
        node {
          ...pitch
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${USER_BASICS}
  ${FILE}
  ${PITCH}
  ${KEY_TAG}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${TOPIC}
  ${REGION}
`;
export const GET_PITCH_STATISTICS = gql`
  query PitchStatusCount {
    pitchStatusCount {
      name
      count
    }
  }
`;
