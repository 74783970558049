import { CloseOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { GET_ME, Me } from '@frontend/data-access';
import { UserAvatar } from '@frontend/shared/ui';
import { Button, Comment as AntdComment, Input, InputRef } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import { Thread } from '../discussion-wall/discussion-wall';

const { TextArea } = Input;

/* eslint-disable-next-line */
export interface CommentAddProps {
  currentUser?: Me;
  currentThread?: Thread;
  isLoading: boolean;
  setCurrentThread: (thread: Thread | undefined) => void;
  onComment: (text: string) => void;
}

export function CommentAdd({
  currentUser,
  currentThread,
  isLoading,
  setCurrentThread,
  onComment,
}: CommentAddProps) {
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);
  const [text, setText] = useState<string>('');
  const inputRef = useRef<InputRef>(null);
  const router = useRouter();

  useEffect(() => {
    if (currentThread && !currentThread.isEdit && !currentThread.isDelete) {
      inputRef.current?.focus();
    }
  }, [currentThread]);

  const handleCommentAdd = () => {
    onComment(text);
    setText('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    if (value && value.trim().length === 0) {
      setText('');
      e.preventDefault();
    }
  };

  return (
    <AntdComment
      actions={[
        <div className="flex flex-wrap md:flex-nowrap gap-4 items-center">
          <Button
            key="add-comment"
            type="primary"
            shape="round"
            disabled={text === '' || isLoading || !meData}
            loading={isLoading}
            onClick={handleCommentAdd}
          >
            Add Comment
          </Button>
          {!meData && (
            <div>
              <span className="text-black">
                Become a FinTech Bridge member to like or comment.&nbsp;
                <Link
                  className="text-primary-600"
                  href={`/login?referrer=${process.env['NEXT_PUBLIC_PLATFORM_URL']}${process.env['NEXT_PUBLIC_BASE_PATH']}${router.asPath}`}
                >
                  Membership is free!
                </Link>
              </span>
            </div>
          )}
        </div>,
      ]}
      avatar={<UserAvatar user={currentUser as Me} />}
      content={
        <div>
          {currentThread && !currentThread?.isDelete && !currentThread?.isEdit && (
            <div className="flex justify-between mb-1 text-gray-700">
              <div>Replying to: {currentThread?.author?.fullName}</div>
              <CloseOutlined onClick={() => setCurrentThread(undefined)} />
            </div>
          )}
          <TextArea
            disabled={!meData}
            value={text}
            ref={inputRef}
            rows={3}
            placeholder="Add a comment..."
            onChange={(e) => setText(e.target.value)}
            onKeyUp={handleKeyDown}
          />
        </div>
      }
    />
  );
}

export default CommentAdd;
