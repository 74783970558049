import { gql } from '@apollo/client';

export const USER_STATISTICS = gql`
  fragment userStatistics on UserStatistics {
    date
    count
  }
`;

export const USER_ROLE_STATISTICS = gql`
  fragment userRoleStatistics on UserRoleStatistics {
    role
    count
  }
`;

export const USER_COUNTRY_STATISTICS = gql`
  fragment userCountryStatistics on UserCountryStatistics {
    country
    count
  }
`;

export const CHALLENGE_STATISTICS = gql`
  fragment challengeStatistics on ChallengeStatistics {
    type
    date
    count
  }
`;

export const CHALLENGE_APPLICATION_STATISTICS = gql`
  fragment applicationStatistics on ApplicationStatistics {
    type
    date
    count
  }
`;
export const POPULAR_TOPIC = gql`
  fragment popularTopic on PopularTopic {
    topic
    count
  }
`;

export const GET_USER_STATISTICS = gql`
  query UserStatistics($filterBy: UserRole!) {
    userStatistics(filterBy: $filterBy) {
      ...userStatistics
    }
  }
  ${USER_STATISTICS}
`;

export const GET_USER_ROLE_STATISTICS = gql`
  query UserRoleStatistics {
    userRoleStatistics {
      ...userRoleStatistics
    }
  }
  ${USER_ROLE_STATISTICS}
`;

export const GET_USER_COUNTRY_STATISTICS = gql`
  query UserCountryStatistics($filterBy: UserRole!) {
    userCountryStatistics(filterBy: $filterBy) {
      ...userCountryStatistics
    }
  }
  ${USER_COUNTRY_STATISTICS}
`;

export const GET_CHALLENGE_STATISTICS = gql`
  query ChallengeStatistics($filterBy: ChallengeType!) {
    challengeStatistics(filterBy: $filterBy) {
      ...challengeStatistics
    }
  }
  ${CHALLENGE_STATISTICS}
`;

export const GET_CHALLENGE_APPLICATION_STATISTICS = gql`
  query ApplicationStatistics($filterBy: ChallengeType!) {
    applicationStatistics(filterBy: $filterBy) {
      ...applicationStatistics
    }
  }
  ${CHALLENGE_APPLICATION_STATISTICS}
`;

export const GET_POPULAR_TOPICS = gql`
  query popularTopics($limit: Int) {
    popularTopics(limit: $limit) {
      ...popularTopic
    }
  }
  ${POPULAR_TOPIC}
`;
