import { DeleteFilled } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  COMPANY_CLAIM_REQUEST_SETTLE,
  COMPANY_PROFILE_OWNER_REMOVE,
  CompanyClaim,
  ErrorResponse,
  GET_COMPANY,
  GET_COMPANY_CLAIM_REQUESTS,
} from '@frontend/data-access';
import { UserAvatar } from '@frontend/shared/ui';
import { getLinkLabel } from '@frontend/shared/util';
import { Button, Collapse, Divider, notification, Spin } from 'antd';

function EditProfileManagerForm({
  companyId,
  onCancel,
}: {
  companyId: number;
  onCancel: () => void;
}) {
  const { data, loading } = useQuery<{ listCompanyClaims: CompanyClaim[] }>(
    GET_COMPANY_CLAIM_REQUESTS,
    {
      variables: { companyId: companyId },
    }
  );
  const handleCompleted = () => {
    notification.success({
      message: 'Claim request updated',
      placement: 'bottomLeft',
    });
  };
  const handleError = (error: ErrorResponse) => {
    notification.error({
      message: error.message,
      placement: 'bottomLeft',
    });
  };
  const [settle, { loading: updateLoading }] = useMutation(
    COMPANY_CLAIM_REQUEST_SETTLE,
    {
      onCompleted: handleCompleted,
      refetchQueries: [
        { query: GET_COMPANY_CLAIM_REQUESTS, variables: { companyId } },
        { query: GET_COMPANY, variables: { id: companyId } },
      ],
      onError: handleError,
    }
  );
  const handleRemoveCompleted = () => {
    notification.success({
      message: 'Profile manager removed',
      placement: 'bottomLeft',
    });
  };
  const [remove, { loading: deleteLoading }] = useMutation(
    COMPANY_PROFILE_OWNER_REMOVE,
    {
      onCompleted: handleRemoveCompleted,
      refetchQueries: [
        { query: GET_COMPANY_CLAIM_REQUESTS, variables: { companyId } },
        { query: GET_COMPANY, variables: { id: companyId } },
      ],
    }
  );

  const handleReject = (claimId: number) => {
    settle({
      variables: {
        input: {
          companyId: companyId,
          claimId: claimId,
          companyClaimStatus: 'REJECTED',
        },
      },
    });
  };

  const handleApprove = (claimId: number) => {
    settle({
      variables: {
        input: {
          companyId: companyId,
          claimId: claimId,
          companyClaimStatus: 'APPROVED',
        },
      },
    });
  };
  const handleRemove = (userId: number) => {
    remove({
      variables: {
        input: {
          companyId: companyId,
          userId: userId,
        },
      },
    });
  };

  const RequestItem = ({
    claim,
    index,
  }: {
    claim: CompanyClaim;
    index: number;
  }) => (
    <div key={index} className="flex items-center justify-between">
      <div className="flex flex-row gap-2 items-center">
        <UserAvatar size={34} user={claim.user} />
        <div className="grid">
          <span className="font-medium text-gray-800">
            {claim.user.fullName}
          </span>
          <span className="font-normal text-gray-700">
            {claim.user?.role
              ? getLinkLabel(claim.user.role.toLowerCase())
              : ''}
          </span>
        </div>
      </div>
      <div className="space-x-2">
        <Button
          loading={updateLoading}
          shape="round"
          onClick={() => handleApprove(claim?.id)}
        >
          Approve
        </Button>
        <Button
          loading={updateLoading}
          danger
          shape="round"
          onClick={() => handleReject(claim?.id)}
        >
          Reject
        </Button>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="text-center">
        <Spin />
      </div>
    );
  }

  return (
    <div className="p-6">
      <h5 className="font-medium text-gray-900 mb-4">
        Institution profile managers
      </h5>
      <div className="space-y-2">
        {data?.listCompanyClaims
          ?.filter((claim) => claim?.status === 'APPROVED')
          .map((claim, index) => (
            <div key={index} className="flex items-center justify-between">
              <div className="flex flex-row gap-2 items-center">
                <UserAvatar size={34} user={claim.user} />
                <div className="grid">
                  <span className="font-medium text-gray-800">
                    {claim.user.fullName}
                  </span>
                  <span className="font-normal text-gray-700">
                    {claim.user?.role
                      ? getLinkLabel(claim.user.role.toLowerCase())
                      : ''}
                  </span>
                </div>
              </div>
              <div className="space-x-2">
                <Button
                  loading={deleteLoading}
                  icon={<DeleteFilled />}
                  shape="circle"
                  onClick={() => handleRemove(claim?.user?.id)}
                />
              </div>
            </div>
          ))}
      </div>
      <Divider />
      <h5 className="font-medium text-gray-900 mb-4">Request(s)</h5>
      <p className="font-normal text-gray-700">
        The following are requests to join as Institution profile manager.
        Institution profile managers have the ability to edit Institution
        information.
      </p>
      <Collapse ghost>
        {data?.listCompanyClaims
          ?.filter((claim) => claim?.status === 'PENDING')
          .map((claim, index) => (
            <Collapse.Panel
              header={<RequestItem claim={claim} index={index} />}
              key={index}
            >
              <div className="space-y-3">
                <div>
                  <div>Institution name: {claim?.name}</div>
                  <div>Work email: {claim?.email}</div>
                  <div>Phone number: {claim?.phone}</div>
                </div>
                <div>
                  <div>Description:</div>
                  <div>{claim?.reason}</div>
                </div>
              </div>
            </Collapse.Panel>
          ))}
      </Collapse>
    </div>
  );
}

export default EditProfileManagerForm;
