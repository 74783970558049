import { gql } from '@apollo/client';

export const ANSWER = gql`
  fragment answer on Answer {
    id
    text
    questionText
    createdAt
    updatedAt
  }
`;
