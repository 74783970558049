import { useLazyQuery } from '@apollo/client';
import { GET_IDEAS_BY_USER_ID, Idea } from '@frontend/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { IdeaCardUser } from '@frontend/feature-idea';
import { Button, Empty, Spin } from 'antd';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

interface cursorBasedResponseType {
  edges: {
    cursor: string;
    node: Idea;
  }[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
}

function UserProfileIdeas() {
  const router = useRouter();
  const userId = router.query['id'] as string;
  const [autoFetch, setAutoFetch] = useState<boolean>(false);
  const [params, setParams] = useState<{
    userId: string;
    first: number;
    after?: string;
  }>({
    userId: userId,
    first: 4,
  });
  const [data, setData] = useState<cursorBasedResponseType>();

  const [getData, { loading }] = useLazyQuery<{
    userIdeas: cursorBasedResponseType;
  }>(GET_IDEAS_BY_USER_ID, {
    onCompleted: (res) => {
      const tempData = data?.edges || [];
      setData({
        edges: [...tempData, ...res.userIdeas.edges],
        pageInfo: res.userIdeas.pageInfo,
      });
    },
  });

  const handleLoadMore = () => {
    setParams({
      ...params,
      after: data?.edges[data.edges.length - 1].cursor,
    });
  };

  useEffect(() => {
    getData({
      variables: {
        ...params,
      },
    });
  }, [getData, params]);

  const Loader = () => {
    return (
      <div className="p-6 text-center">
        <Spin />
      </div>
    );
  };

  return (
    <div className="space-y-4">
      <div className="text-gray-900 text-sm font-semibold">Recent ideas</div>
      <InfiniteScroll
        dataLength={data?.edges.length || 0}
        next={handleLoadMore}
        hasMore={(autoFetch && data?.pageInfo.hasNextPage) || false}
        loader={<Loader />}
      >
        <div className="space-y-6">
          {data?.edges.map((data, index) => (
            <IdeaCardUser key={index} data={data.node} />
          ))}
        </div>
      </InfiniteScroll>
      {loading ? (
        <div className="flex items-center justify-center min-h-[400px]">
          <Loader />
        </div>
      ) : (
        data?.edges.length === 0 && (
          <div className="h-[200px] flex justify-center items-center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )
      )}
      {!autoFetch && data?.pageInfo.hasNextPage && (
        <div className="text-center">
          <Button
            shape="round"
            loading={loading}
            onClick={() => setAutoFetch(true)}
          >
            See more
          </Button>
        </div>
      )}
    </div>
  );
}

export default UserProfileIdeas;
