import { useQuery } from '@apollo/client';
import { GET_USER_BY_ID, User } from '@frontend/data-access';
import { Button, Empty, Spin } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';

import UserProfileHeader from './components/user-header';
import UserProfileTabs from './components/user-tabs';

/* eslint-disable-next-line */
export interface UserProfileProps {}

export function UserProfile(props: UserProfileProps) {
  const router = useRouter();
  const userId = router.query['id'];
  const { data, loading } = useQuery<{ findUser: User }>(GET_USER_BY_ID, {
    variables: { id: userId },
  });

  if (loading) {
    return (
      <div className="text-center">
        <Spin />
      </div>
    );
  }

  if (data?.findUser)
    return (
      <div className="bg-white p-5 rounded-lg">
        <NextSeo title={`${data?.findUser.fullName} - FintechBridge`} />
        <div className="space-y-4">
          <UserProfileHeader data={data?.findUser} />
          <div className="flex flex-col justify-center container">
            <UserProfileTabs data={data?.findUser} />
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="bg-white p-5 rounded-lg text-center space-y-4">
        <Empty description="User not found" />
        <div>
          <Link href="/bridge">
            <Button shape="round" type="primary">
              Back to Bridge
            </Button>
          </Link>
        </div>
      </div>
    );
}

export default UserProfile;
