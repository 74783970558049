import { generateCode } from '@frontend/data-access';
import { Alert, AlertMessage } from '@frontend/shared/ui';
import { Button, Form, Input, Space, Typography } from 'antd';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type FormValues = {
  email: string;
};

export function ForgotPassword() {
  const { t } = useTranslation('common');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [message] = useState<Alert>();
  const [alert, setAlert] = useState<Alert>();

  const handlePasswordReset = async (values: FormValues) => {
    setLoading(true);

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha('password_reset');

    const response = await generateCode({
      email: values.email,
      captcha: token,
    });

    if (!response.ok) {
      setAlert({
        messages: [response.statusText],
        type: 'error',
      });
    }

    if (response.status === 200) {
      setAlert({
        messages: [t('forgot_sent_mail')],
        type: 'success',
      });
    }

    setLoading(false);
  };

  return (
    <div className="w-[350px]">
      <Typography.Title className="!mb-2 text-center" level={2}>
        {t('forgot_title')}
      </Typography.Title>
      <div className="mb-6 text-center">
        <Typography.Text>
          {message?.type === 'success'
            ? t('forgot_sent_message')
            : t('forgot_desc')}
        </Typography.Text>
      </div>
      {alert && <AlertMessage className="my-6" alert={alert} />}
      {message?.type === 'success' ? (
        <Space
          className="flex flex-col items-center w-full"
          direction="vertical"
          size="middle"
        >
          <Typography.Text className="!font-medium">
            {form.getFieldValue('email')}
          </Typography.Text>
          <Button
            className="mb-2"
            onClick={() => handlePasswordReset(form.getFieldValue('email'))}
            type="link"
          >
            {t('forgot_resend_button')}
          </Button>
        </Space>
      ) : (
        <Form
          initialValues={{ email: '' }}
          layout="vertical"
          form={form}
          onFinish={handlePasswordReset}
        >
          <Form.Item
            label={t('login_input_email_required')}
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: t('login_input_password_required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              className="mt-2"
              loading={loading}
              block
              size="large"
              type="primary"
              shape="round"
              htmlType="submit"
            >
              {t('forgot_submit')}
            </Button>
          </Form.Item>
        </Form>
      )}

      <div className="flex justify-center">
        <Link href="/login" passHref>
          <Button htmlType="button" type="link">
            {t('forgot_back')}
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default ForgotPassword;
