import { FormRule } from 'antd';

export const required = (message: string) => {
  return { required: true, message: message };
};

export const name = {
  pattern: new RegExp(/^[a-zA-Z0-9]*$/),
  message: 'Invalid name',
};

export const url = {
  pattern: new RegExp(
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/
  ),
  message: 'Invalid url',
};

/* example of custom validator */
export const nameCustom = {
  message: 'Invalid name',
  validator: (_: FormRule, value: string) => {
    if (value) {
      if (/^(?!\d+$)\w+\S+/.test(value)) {
        return Promise.resolve();
      } else {
        return Promise.reject('');
      }
    }
    return Promise.resolve();
  },
};
