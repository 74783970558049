import { gql } from '@apollo/client';

export const TOPIC = gql`
  fragment topic on Topic {
    id
    name
  }
`;

export const GET_TOPICS = gql`
  query Topics {
    topics {
      ...topic
    }
  }
  ${TOPIC}
`;

export const CREATE_TOPIC = gql`
  mutation CreateTopic($name: String!) {
    createTopic(name: $name) {
      ...topic
    }
  }
  ${TOPIC}
`;

export const PATCH_TOPIC = gql`
  mutation PatchTopic($id: ID!, $name: String!) {
    patchTopic(id: $id, name: $name) {
      ...topic
    }
  }
  ${TOPIC}
`;
