import { Alert } from 'antd';
import { ReactElement } from 'react';

export type Alert = {
  messages: Array<string | ReactElement>;
  type: 'success' | 'info' | 'warning' | 'error';
};

export interface AlertMessageProps {
  alert: Alert;
  className?: string;
  onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export function AlertMessage({ alert, className, onClose }: AlertMessageProps) {
  return (
    <div className={className}>
      <Alert
        message={
          <ul className="!m-0">
            {alert.messages.map((message, index) => {
              return <li key={index}>{message}</li>;
            })}
          </ul>
        }
        type={alert.type}
        closable
        onClose={onClose}
      />
    </div>
  );
}

export default AlertMessage;
