import { useMutation } from '@apollo/client';
import {
  COMPANY_CLAIM_REQUEST,
  CompanyClaim,
  ErrorResponse,
  GET_COMPANY_CLAIM_REQUESTS,
} from '@frontend/data-access';
import { Form, Input, Modal, notification } from 'antd';

const formFieldRules = {
  name: [
    {
      required: true,
      validator: (_: unknown, value: string) =>
        value && value.trim().length > 2
          ? Promise.resolve()
          : Promise.reject('Please input your institution names!'),
    },
  ],
  email: [
    {
      required: true,
      validator: (_: unknown, value: string) =>
        value && value.trim().length > 2
          ? Promise.resolve()
          : Promise.reject('Please input your work email!'),
    },
  ],
  reason: [
    {
      required: true,
      validator: (_: unknown, value: string) =>
        value && value.trim().length > 2
          ? Promise.resolve()
          : Promise.reject('Please input your reason!'),
    },
  ],
};

type Props = {
  isOpenClaim: boolean;
  setIsOpenClaim: (value: boolean) => void;
  companyId: number;
};

const ClaimCompanyProfile = ({
  isOpenClaim,
  setIsOpenClaim,
  companyId,
}: Props) => {
  const [form] = Form.useForm();
  const handleOnCompleted = () => {
    form.resetFields();
    setIsOpenClaim(false);
    notification.success({
      message: 'Claim request sent',
      placement: 'bottomLeft',
    });
  };
  const handleError = (error: ErrorResponse) => {
    form.resetFields();
    setIsOpenClaim(false);
    notification.error({
      message: error.message,
      placement: 'bottomLeft',
    });
  };
  const [claimRequest, { loading }] = useMutation(COMPANY_CLAIM_REQUEST, {
    onCompleted: handleOnCompleted,
    onError: handleError,
    refetchQueries: [
      { query: GET_COMPANY_CLAIM_REQUESTS, variables: { companyId } },
    ],
  });
  const handleFinish = (values: CompanyClaim) => {
    claimRequest({ variables: { input: { ...values, companyId: companyId } } });
  };
  const handleSubmit = () => {
    form.submit();
  };
  return (
    <div>
      <Modal
        title="Claim this profile"
        open={isOpenClaim}
        onOk={handleSubmit}
        okButtonProps={{ shape: 'round', loading: loading }}
        cancelButtonProps={{
          shape: 'round',
        }}
        onCancel={() => setIsOpenClaim(false)}
      >
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Form.Item
            name="name"
            label="Institution name"
            rules={formFieldRules.name}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Work email"
            rules={formFieldRules.email}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label={
              <div>
                Phone number <span className="text-gray-700">(optional)</span>
              </div>
            }
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="reason"
            label="Please clarify why you want to become an owner of this Institution profile"
            rules={formFieldRules.reason}
            required
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ClaimCompanyProfile;
