import { LinkedinFilled } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import {
  Company,
  CompanyClaim,
  GET_COMPANY,
  GET_COMPANY_CLAIM_REQUESTS,
  GET_ME,
  GET_REGIONS,
  KeyPerson,
  LIST_KEY_PERSON,
  Me,
  Region,
} from '@frontend/data-access';
import { getLinkLabel } from '@frontend/shared/util';
import { Button, Col, Modal, Row, Typography } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';

import UserAvatar from '../../user-avatar/user-avatar';
import EditIndustriesForm from '../components/edit-actions/industries';
import EditInformationDeckForm from '../components/edit-actions/info-deck-form';
import EditKeyPersonForm from '../components/edit-actions/key-person';
import EditPitchForm from '../components/edit-actions/pitch';
import EditProfileManagerForm from '../components/edit-actions/profile-manager';
import EditRegionForm from '../components/edit-actions/region';
import EditShortDescForm from '../components/edit-actions/short-description';
import EditInformationCard, {
  EditInformationCardProps,
} from '../components/edit-info-card';
import InformationDeck from '../components/information-deck';
import PitchVideo from '../components/pitch-video';
import TagItems from '../components/tag-item';

type ActionTypes =
  | 'ABOUT'
  | 'INDUSTRIES'
  | 'REGION'
  | 'KEY_PERSON'
  | 'PROFILE_MANAGERS'
  | 'INFORMATION_DECK'
  | 'PITCH_VIDEO';

const { Text } = Typography;

const About = () => {
  const router = useRouter();
  const id = Number(router.query['companyId']);
  const [actionType, setActionType] = useState<ActionTypes>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: meData } = useQuery<{ me: Me }>(GET_ME);
  const { data } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: id },
  });

  const { data: dataRegions } = useQuery<{
    regions: Region[];
  }>(GET_REGIONS);

  const { data: dataKeyPerson } = useQuery<{
    listKeyPerson: KeyPerson[];
  }>(LIST_KEY_PERSON, {
    variables: {
      companyId: {
        companyId: id,
      },
    },
  });

  const { data: listClaimRequest } = useQuery<{
    listCompanyClaims: CompanyClaim[];
  }>(GET_COMPANY_CLAIM_REQUESTS, {
    variables: { companyId: id },
  });

  const renderTitle = () => {
    return 'Edit ' + getLinkLabel(actionType?.toLowerCase() as string);
  };

  const handleClose = () => {
    setActionType(undefined);
    setIsModalOpen(false);
  };

  const renderAction = () => {
    if (data)
      switch (actionType) {
        case 'ABOUT':
          return (
            <EditShortDescForm
              companyId={data?.company.id}
              onCancel={handleClose}
              onFinish={handleClose}
            />
          );
        case 'INDUSTRIES':
          return (
            <EditIndustriesForm
              companyId={data?.company.id}
              onCancel={handleClose}
              onFinish={handleClose}
            />
          );

        case 'REGION':
          return (
            <EditRegionForm
              companyId={data?.company.id}
              onCancel={handleClose}
              onFinish={handleClose}
            />
          );
        case 'KEY_PERSON':
          return (
            <EditKeyPersonForm
              companyId={data?.company.id}
              onCancel={handleClose}
              onFinish={handleClose}
            />
          );
        case 'PROFILE_MANAGERS':
          return (
            <EditProfileManagerForm
              companyId={data?.company.id}
              onCancel={handleClose}
            />
          );
        case 'INFORMATION_DECK':
          return (
            <EditInformationDeckForm
              companyId={data?.company.id}
              onCancel={handleClose}
              onFinish={handleClose}
            />
          );
        case 'PITCH_VIDEO':
          return (
            <EditPitchForm
              companyId={data?.company.id}
              onCancel={handleClose}
              onFinish={handleClose}
            />
          );

        default:
          break;
      }
    return <div />;
  };

  const handleModal = (type: ActionTypes) => {
    setActionType(type);
    setIsModalOpen(true);
  };

  const items: EditInformationCardProps[] = useMemo(
    () => [
      {
        title: 'About',
        content: <Text>{data?.company?.description}</Text>,
        extraAction: (
          <Button type="link" onClick={() => handleModal('ABOUT')}>
            Edit
          </Button>
        ),
      },
      {
        title: 'Industries',
        content:
          (data?.company.topics && data?.company.topics.length && (
            <TagItems data={data?.company.topics} test="selected-location" />
          )) ||
          null,
        extraAction: (
          <Button type="link" onClick={() => handleModal('INDUSTRIES')}>
            Edit
          </Button>
        ),
      },
      {
        title: 'Region',
        content:
          (data?.company.market?.regionIds?.length && (
            <TagItems
              data={data?.company.market?.regionIds.map((i) => ({
                name: dataRegions?.regions.find((r) => r.id === i)?.name ?? '',
              }))}
              test="selected-regions"
            />
          )) ||
          null,
        extraAction: (
          <Button type="link" onClick={() => handleModal('REGION')}>
            Edit
          </Button>
        ),
      },
      {
        title: 'KEY PERSONS',
        content:
          (dataKeyPerson?.listKeyPerson.length &&
            dataKeyPerson.listKeyPerson.length > 0 && (
              <Row className="gap-2 mt-1">
                {dataKeyPerson.listKeyPerson.map((item, index) => {
                  return (
                    <Col
                      className="flex flex-row gap-5 items-center bg-gray-200 py-3 pl-6 pr-3 rounded-full text-xs"
                      key={index}
                    >
                      <div>
                        <div className="text-gray-900 font-semibold">
                          {item.name}
                        </div>
                        <span className="text-gray-700 font-normal text-xs">
                          {item.role}
                        </span>
                      </div>
                      {item.linkedinUrl && (
                        <Link href={item.linkedinUrl} target="_blank">
                          <Button shape="circle" icon={<LinkedinFilled />} />
                        </Link>
                      )}
                    </Col>
                  );
                })}
              </Row>
            )) ||
          null,
        extraAction: (
          <Button type="link" onClick={() => handleModal('KEY_PERSON')}>
            Edit
          </Button>
        ),
      },
      {
        title: 'Institution profile managers',
        content:
          (data?.company.owners && data?.company.owners.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
              {data?.company?.owners.map((item, index) => (
                <div
                  className="flex items-center gap-4 px-4 py-[14px] bg-gray-200 rounded-full w-full"
                  key={index}
                >
                  <UserAvatar
                    size={40}
                    user={item.user}
                    showBadge={meData?.me.role !== 'EXTERNAL_USER'}
                  />
                  <div>
                    <div className="text-gray-900 font-medium text-sm">
                      {item.user.fullName}
                    </div>
                    <div className="text-gray-700 font-normal text-xs">
                      {item.user.role &&
                        getLinkLabel(item.user.role.toLowerCase())}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )) ||
          null,
        extraAction: (
          <>
            {listClaimRequest?.listCompanyClaims?.length !== undefined &&
              listClaimRequest?.listCompanyClaims?.length > 0 && (
                <span className="text-gray-700">{`(${
                  listClaimRequest?.listCompanyClaims?.filter(
                    (i) => i.status === 'PENDING'
                  ).length
                } pending) `}</span>
              )}
            <Button type="link" onClick={() => handleModal('PROFILE_MANAGERS')}>
              Edit
            </Button>
          </>
        ),
        hasAccess: meData?.me?.role === 'ADMIN',
      },
      {
        title: 'Information deck',
        content: (
          <InformationDeck
            data={data?.company?.onePagers}
            companyId={data?.company.id}
          />
        ),
        extraAction: data?.company?.onePagers &&
          data?.company?.onePagers?.length > 0 && (
            <Button type="link" onClick={() => handleModal('INFORMATION_DECK')}>
              Upload file
            </Button>
          ),
      },
      {
        title: 'Pitch video',
        content:
          data?.company?.pitchVideos || data?.company.isOwner ? (
            <PitchVideo
              data={data?.company?.pitchVideos ?? []}
              companyId={data?.company.id}
            />
          ) : null,
        extraAction: data?.company?.pitchVideos &&
          data?.company?.pitchVideos.length > 0 && (
            <Button type="link" onClick={() => handleModal('PITCH_VIDEO')}>
              Upload File
            </Button>
          ),
      },
    ],
    [
      data?.company?.description,
      data?.company.id,
      data?.company.isOwner,
      data?.company.market?.regionIds,
      data?.company?.onePagers,
      data?.company.owners,
      data?.company?.pitchVideos,
      data?.company.topics,
      dataKeyPerson?.listKeyPerson,
      dataRegions?.regions,
      listClaimRequest?.listCompanyClaims,
      meData?.me?.role,
    ]
  );

  return (
    <div>
      <Col className="space-y-6">
        {items.map((item, index) => (
          <EditInformationCard
            key={index}
            title={item.title}
            extraAction={
              data?.company.isOwner || item.hasAccess ? item.extraAction : null
            }
            content={item.content}
          />
        ))}
      </Col>
      <Modal
        title={renderTitle()}
        open={isModalOpen}
        onCancel={handleClose}
        width={800}
        bodyStyle={{
          padding: 0,
        }}
        footer={false}
      >
        {renderAction()}
      </Modal>
    </div>
  );
};

export default About;
