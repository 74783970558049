import { gql } from '@apollo/client';

export const CHALLENGE_ANNOUNCEMENT = gql`
  fragment announcement on ChallengeAnnouncement {
    challengeId
    title
    description
    createdAt
  }
`;

export const CREATE_CHALLENGE_ANNOUNCEMENT = gql`
  mutation CreateChallengeAnnouncement($input: CreateAnnouncementInput!) {
    createChallengeAnnouncement(input: $input) {
      challengeId
      title
      description
      createdAt
    }
  }
`;
