import {
  CalendarOutlined,
  CheckCircleOutlined,
  DollarOutlined,
  FireOutlined,
  FlagOutlined,
  TagsOutlined,
  TrophyOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Challenge, GET_ME } from '@frontend/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { IconCard, SocialShareButtonsGroup } from '@frontend/shared/ui';
import { capFirst, formatDateWithTimezone } from '@frontend/shared/util';
import { Col, Row, Space, Typography } from 'antd';
import { formatDistance, isBefore } from 'date-fns';
import useTranslation from 'next-translate/useTranslation';

export interface ChallengeBasicsProps {
  challenge: Challenge;
}

export const getCountdown = (
  registrationStartAt: string,
  registrationEndAt: string
) => {
  const isBeforeDeadline = isBefore(new Date(), new Date(registrationEndAt));

  if (isBeforeDeadline) {
    return (
      <>
        {' '}
        &#8212; {formatDistance(new Date(), new Date(registrationEndAt))} left
      </>
    );
  } else {
    return '';
  }
};

export function ChallengeBasics({ challenge }: ChallengeBasicsProps) {
  const { t } = useTranslation('admin');
  const { t: tcommon } = useTranslation('common');
  const { data } = useQuery(GET_ME);

  const renderPrizes = () => {
    return (
      <div className="flex flex-col">
        {Number(challenge.prizeCashAmount) ? (
          <span>
            {Number(challenge.prizeCashAmount).toLocaleString('en-US') + '$'}
          </span>
        ) : (
          ''
        )}
        {challenge.prizes
          ?.filter((prize) => !['CASH', 'CUSTOM'].includes(prize))
          .map((prize, index) => {
            return (
              <span key={index}>
                {prize === 'SCV_PARTNERSHIP'
                  ? 'Opportunity to partner with SC Ventures'
                  : prize}
              </span>
            );
          })}
      </div>
    );
  };

  return (
    <Space className="w-full" direction="vertical" size="middle">
      {Array.isArray(challenge?.applications) &&
        challenge.applications?.length > 0 && (
          <IconCard
            title={t('intra_challenge_basics_applications')}
            icon={<CheckCircleOutlined />}
          >
            <Typography.Text>{challenge?.applications?.length}</Typography.Text>
          </IconCard>
        )}
      {Array.isArray(challenge?.ideas) && challenge.ideas.length > 0 && (
        <IconCard
          title={t('intra_challenge_basics_ideas')}
          icon={<CheckCircleOutlined />}
        >
          <Typography.Text>{challenge?.ideas?.length}</Typography.Text>
        </IconCard>
      )}
      <IconCard
        title={
          challenge.registrationEndedAt
            ? t('intra_challenge_basics_registration_ended')
            : t('intra_challenge_basics_registration_deadline')
        }
        icon={<CalendarOutlined />}
      >
        {!challenge.registrationEndedAt && (
          <Typography.Text>
            {formatDateWithTimezone(
              challenge?.registrationEndAt,
              challenge?.timezone,
              'DD MMMM YYYY, HH:mm'
            )}
          </Typography.Text>
        )}
      </IconCard>
      {(data?.me?.role === 'ADMIN' ||
        data?.me?.role === 'CHALLENGE_MANAGER') && (
        <IconCard
          title={t('intra_challenge_basics_registration_created_by')}
          icon={<UserOutlined />}
        >
          <Typography.Text className="capitalize">
            {challenge?.createdBy?.fullName || '-'}
          </Typography.Text>
        </IconCard>
      )}
      {challenge.__typename === 'OpenChallenge' && (
        <IconCard
          title={t('intra_challenge_basics_registration_prize')}
          icon={<DollarOutlined />}
        >
          <Typography.Text>{renderPrizes()}</Typography.Text>
        </IconCard>
      )}
      {challenge.__typename === 'InternalChallenge' && (
        <IconCard
          title={t('intra_challenge_basics_registration_open_tp')}
          icon={<UserOutlined />}
        >
          <Typography.Text>
            {capFirst(challenge?.participationType)}
          </Typography.Text>
        </IconCard>
      )}
      <IconCard
        title={t('intra_challenge_basics_registration_sponsor')}
        icon={<TrophyOutlined />}
      >
        <Typography.Text>{challenge?.sponsor}</Typography.Text>
      </IconCard>
      {challenge?.innovationSpaceTag?.name && (
        <IconCard
          title={t('intra_challenge_basics_registration_innovation_space')}
          icon={<FireOutlined />}
        >
          <Typography.Text>
            {challenge?.innovationSpaceTag?.name}
          </Typography.Text>
        </IconCard>
      )}
      <IconCard title={tcommon('Regions')} icon={<FlagOutlined />}>
        <Row className="gap-2 mt-3">
          {challenge?.regions?.map((region, index) => {
            return (
              <Col
                className="bg-gray-200 py-1 px-3 rounded-full text-xs"
                key={index}
              >
                {region.name}
              </Col>
            );
          })}
        </Row>
      </IconCard>
      <IconCard title={tcommon('Topics')} icon={<TagsOutlined />}>
        <Row className="gap-2 mt-3">
          {challenge?.topics?.map((topic, index) => {
            return (
              <Col
                className="bg-zinc-50 py-1 px-3 rounded-2xl text-xs"
                key={index}
              >
                {topic.name}
              </Col>
            );
          })}
        </Row>
      </IconCard>
      {challenge.__typename === 'OpenChallenge' && <SocialShareButtonsGroup />}
    </Space>
  );
}

export default ChallengeBasics;
