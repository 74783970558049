import { CloseCircleOutlined } from '@ant-design/icons';
import { CodeNode } from '@lexical/code';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { TRANSFORMERS } from '@lexical/markdown';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { Divider, notification } from 'antd';
import { $getRoot } from 'lexical';
import { useState } from 'react';

import { ImageNode } from './nodes/ImageNode';
import { KeywordNode } from './nodes/KeywordNode';
import { MentionNode } from './nodes/MentionNode';
import { YouTubeNode } from './nodes/YouTubeNode';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import ImagePlugin from './plugins/ImagePlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import YouTubePlugin from './plugins/YouTubePlugin';
import ExampleTheme from './themes/ExampleTheme';

const editorConfig = {
  theme: ExampleTheme,
  onError(error: Error) {
    throw error;
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
    ImageNode,
    YouTubeNode,
    MentionNode,
    KeywordNode,
  ],
};
interface RichLexicalEditorProps {
  value?: string;
  placeholder?: string;
  setValue: (value: string) => void;
  className?: string;
}

function DefaultValuePlugin({ defaultValue }: { defaultValue: string }) {
  const [editor] = useLexicalComposerContext();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  if (defaultValue && !isInitialized) {
    editor.update(() => {
      if (editor?.getEditorState()?.isEmpty()) {
        try {
          const parser = new DOMParser();
          const dom = parser.parseFromString(defaultValue, 'text/html');
          const nodes = $generateNodesFromDOM(editor, dom);
          const root = $getRoot();
          root.clear();
          nodes.forEach((n) => root.append(n));
          setIsInitialized(true);
        } catch (error) {
          notification.error({
            message: 'Error',
            description: 'Invalid text format detected',
            icon: (
              <span className="text-red-600">
                <CloseCircleOutlined />
              </span>
            ),
            placement: 'bottomLeft',
          });
        }
      }
    });
  }

  return null;
}

export function RichLexicalEditor({
  value,
  placeholder,
  setValue,
  className,
}: RichLexicalEditorProps) {
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
    <LexicalComposer
      initialConfig={{
        ...editorConfig,
        editable: true,
        namespace: 'Editor',
        editorState: null,
      }}
    >
      <div className="editor-container">
        <ToolbarPlugin />
        {/* <Divider /> */}
        <div className={`editor-inner editor-shell ${className}`}>
          <RichTextPlugin
            contentEditable={
              <div className="editor-scroller">
                <div className="editor" ref={onRef}>
                  <ContentEditable className="editor-input" />
                </div>
              </div>
            }
            placeholder={
              <div className="editor-placeholder">
                {placeholder || 'Begin writing your text...'}
              </div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          {value && <DefaultValuePlugin defaultValue={value} />}
          <OnChangePlugin
            onChange={(editorState, editor) => {
              editor.update(() => {
                const html = $generateHtmlFromNodes(editor, null);
                setValue(html);
              });
            }}
          />
          <ImagePlugin captionsEnabled={false} />
          <HistoryPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <YouTubePlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          {floatingAnchorElem && (
            <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
          )}
        </div>
      </div>
    </LexicalComposer>
  );
}

export default RichLexicalEditor;
