export interface ChallengesTabsProps {
  onChange: (value: string | number) => void;
}

export function ChallengesTabs({ onChange }: ChallengesTabsProps) {
  return (
    <div className="w-full flex gap-4 items-center md:justify-end">
      {/* <div className="text-xl font-medium">Open challenges</div> */}
    </div>
  );
}

export default ChallengesTabs;
