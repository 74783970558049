import {
  FileTextOutlined,
  PaperClipOutlined,
  PlayCircleFilled,
  SafetyCertificateOutlined,
} from '@ant-design/icons';
import { getColorByKey } from '@frontend/shared/util';

export enum ContentTypes {
  VIDEO_EMPTY = 'VIDEO_EMPTY',
  VIDEO_PROCESSING = 'VIDEO_PROCESSING',
  VIDEO_NO_PERMISSION = 'VIDEO_NO_PERMISSION',
  POST_NO_BANNER = 'POST_NO_BANNER',
}

export interface ContentPlaceholderProps {
  type: ContentTypes;
  id: string;
  className?: string;
  kind?: string;
}

const contentMap = [
  {
    contentType: ContentTypes.VIDEO_EMPTY,
    contentImage: (
      <PaperClipOutlined
        style={{ fontSize: '35px', color: 'rgba(255, 255, 255, 0.75)' }}
      />
    ),
    customText:
      'As the owner of this pitch has not uploaded a video pitch, please refer to the attached file for further details.',
  },
  {
    contentType: ContentTypes.VIDEO_PROCESSING,
    contentImage: (
      <SafetyCertificateOutlined
        style={{ fontSize: '35px', color: 'rgba(255, 255, 255, 0.75)' }}
      />
    ),
    customText:
      'The video virus scanning is in progress. You can see the video once the scanning is completed.',
  },
  {
    contentType: ContentTypes.VIDEO_NO_PERMISSION,
    contentImage: (
      <PlayCircleFilled
        style={{ fontSize: '35px', color: 'rgba(255, 255, 255, 0.75)' }}
      />
    ),
    customText:
      'As this video is uploaded to YouTube, we are not able to play this video for you.',
  },
  {
    contentType: ContentTypes.POST_NO_BANNER,
    contentImage: (
      <FileTextOutlined
        style={{ fontSize: '35px', color: 'rgba(255, 255, 255, 0.75)' }}
      />
    ),
    customText: undefined,
  },
];

export function ContentPlaceholder({
  className = '',
  id,
  type,
  kind,
}: ContentPlaceholderProps) {
  const getImage = (type: ContentTypes) =>
    contentMap.find((map) => map.contentType === type)?.contentImage;
  const getText = (type: ContentTypes) =>
    contentMap.find((map) => map.contentType === type)?.customText;

  return (
    <div
      className={`${className} ${kind === 'banner' && 'h-[330.75px]'} ${
        kind === 'company' && 'h-48'
      } w-full flex flex-col items-center justify-center p-10 rounded-2xl group-hover:rounded-b-none ease-in duration-300`}
      style={{ backgroundColor: getColorByKey(id) }}
    >
      {getImage(type)}
      {getText(type) && (
        <div className="text-white px-10 text-center mt-6 drop-shadow-md">
          {getText(type)}
        </div>
      )}
    </div>
  );
}

export default ContentPlaceholder;
