import styles from './feature-content.module.less';

/* eslint-disable-next-line */
export interface FeatureContentProps {}

export function FeatureContent(props: FeatureContentProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to FeatureContent!</h1>
    </div>
  );
}

export default FeatureContent;
