import { useLazyQuery, useQuery } from '@apollo/client';
import {
  ChallengesCount,
  GET_INTERNAL_CHALLENGE_STATISTICS,
  GET_INTERNAL_CHALLENGE_STATISTICS_MINE,
  GET_ME,
  GET_OPEN_CHALLENGE_STATISTICS,
  Me,
} from '@frontend/data-access';
import { Col, Row, Statistic } from 'antd';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

interface ChallengeStatisticsProps {
  type: 'OPEN' | 'INTRAPRENEUR';
}

export function ChallengeStatistics({ type }: ChallengeStatisticsProps) {
  const { t } = useTranslation('admin');
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);
  const [challengesStats, setChallengesStats] = useState<
    {
      title: string;
      value: number;
    }[]
  >([]);
  const [getChallengeStatistics, { data, loading }] = useLazyQuery(
    type === 'OPEN'
      ? GET_OPEN_CHALLENGE_STATISTICS
      : meData?.me.role === 'CHALLENGE_MANAGER'
      ? GET_INTERNAL_CHALLENGE_STATISTICS_MINE
      : GET_INTERNAL_CHALLENGE_STATISTICS
  );

  const getTitleText = (key: string) => {
    switch (key) {
      case 'ended':
        return t('intra_challenge_closed');
      case 'active':
        return t('intra_challenge_open');
      case 'draft':
        return t('intra_challenge_draft');
      case 'upcoming':
        return t('intra_challenge_upcoming');
      default:
        return key[0].toUpperCase() + key.slice(1) + ' challenges';
    }
  };

  useEffect(() => {
    getChallengeStatistics();
  }, [getChallengeStatistics]);

  useEffect(() => {
    const mapToArr = (stats: ChallengesCount) => {
      return Object.keys(stats)
        .filter((key) => key !== '__typename')
        .map((key) => {
          return {
            title: getTitleText(key),
            value: stats[key as keyof ChallengesCount],
          };
        });
    };

    if (data?.openChallengesCount) {
      setChallengesStats(mapToArr(data.openChallengesCount));
    } else if (data?.internalChallengesCount) {
      setChallengesStats(mapToArr(data.internalChallengesCount));
    } else if (data?.internalChallengesCountMine) {
      setChallengesStats(mapToArr(data.internalChallengesCountMine));
    }
  }, [data]);

  return (
    <Row className="w-full border border-gray-400 rounded-2xl">
      {challengesStats.map((stat, index) => {
        return (
          <Col xs={24} sm={12} md={6} className="p-6 pr-0" key={index}>
            <Statistic
              className="h-full md:border-r border-gray-400"
              loading={loading}
              title={
                <div className="text-gray-900 !first-letter:uppercase">
                  {stat.title}
                </div>
              }
              value={stat.value}
            />
          </Col>
        );
      })}
    </Row>
  );
}

export default ChallengeStatistics;
