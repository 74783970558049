import { useQuery } from '@apollo/client';
import {
  Company,
  GET_COMPANY,
  GET_INVESTOR_INSIGHTS,
  InvestorInsights,
} from '@frontend/data-access';
import { getLinkLabel } from '@frontend/shared/util';
import { Col, Modal, Spin } from 'antd';
import { useRouter } from 'next/router';
import { useState } from 'react';

import EditInsightsCard, {
  InsightItemType,
} from '../components/edit-insights-card';

type ActionTypes =
  | 'PRODUCT_SERVICE_INFORMATION'
  | 'CUSTOMER_ACQUISITION'
  | 'FINANCIAL'
  | 'QUALITY_OF_REVENUES'
  | 'FINANCING_HISTORY'
  | 'FUNDRAISE'
  | 'OTHER';

const InvestorInsights = () => {
  const router = useRouter();
  const id = Number(router.query['companyId']);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [actionType, setActionType] = useState<ActionTypes>();

  const { data } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: id },
  });

  const { data: dataInsight, loading } = useQuery<{
    retrieveInvestorInsight?: InvestorInsights;
  }>(GET_INVESTOR_INSIGHTS, {
    variables: {
      companyId: id,
    },
  });

  const handleClose = () => {
    setActionType(undefined);
    setIsModalOpen(false);
  };

  const renderAction = () => {
    if (data)
      switch (actionType) {
        case 'PRODUCT_SERVICE_INFORMATION':
          break;
        case 'CUSTOMER_ACQUISITION':
          break;
        case 'FINANCIAL':
          break;
        case 'QUALITY_OF_REVENUES':
          break;
        case 'FINANCING_HISTORY':
          break;
        case 'FUNDRAISE':
          break;
        case 'OTHER':
          break;
        default:
          break;
      }
    return <div />;
  };

  const renderTitle = () => {
    return 'Edit ' + getLinkLabel(actionType?.toLowerCase() as string);
  };

  const items: InsightItemType[] = [
    {
      title: 'Product & SERVICE INFORMATION',
      items: ['information'],
    },
    {
      title: 'Customer Acquisition',
      items: ['partners'],
    },
    {
      title: 'Financials',
      items: [
        'customerAmount',
        'totalTeamSize',
        'techEmployees',
        'salesEmployees',
      ],
    },
    {
      title: 'Quality of revenues',
      items: ['revenuePlan'],
    },
    {
      title: 'Financing history',
      items: ['totalAmountRaised', 'currentInvestors'],
    },
    {
      title: 'Fundraise',
      items: [
        'targetFundRaisingAmount',
        'fundingType',
        'fundUsage',
        'closingDate',
      ],
    },
    {
      title: 'Other',
      items: ['approvals'],
    },
  ];

  if (loading)
    return (
      <div className="text-center p-5">
        <Spin />
      </div>
    );

  return (
    <div>
      Update the following investor fields to unlock investment and partnership
      opportunity.
      <br />
      These fields are visible only to your institution profile manager and
      Fintech Bridge admin.
      <div className="mt-8">
        <Col className="space-y-6">
          {items.map((item) => (
            <EditInsightsCard
              key={item.title}
              item={item}
              data={dataInsight?.retrieveInvestorInsight as InvestorInsights}
            />
          ))}
        </Col>
        <Modal
          title={renderTitle()}
          open={isModalOpen}
          onCancel={handleClose}
          width={800}
          bodyStyle={{
            padding: 0,
          }}
          footer={false}
        >
          {renderAction()}
        </Modal>
      </div>
    </div>
  );
};

export default InvestorInsights;
