/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  collection,
  getDocs,
  getFirestore,
  updateDoc,
  doc,
} from 'firebase/firestore';
import { FirebaseApp } from '@firebase/app';

export const updateField = async (
  uuid: string,
  docId: string,
  firebaseApp?: FirebaseApp
) => {
  try {
    if (firebaseApp) {
      const db = getFirestore(firebaseApp);
      const coll = collection(db, 'users', uuid, 'notifications');
      const querySnapshot = await getDocs(coll);
      if (!querySnapshot.empty) {
        const docRef = doc(db, 'users', uuid, 'notifications', docId);
        await updateDoc(docRef, {
          read: true,
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
};
