import {
  ApolloCache,
  NormalizedCacheObject,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  Company,
  Country,
  ErrorResponse,
  GET_COMPANY,
  GET_COUNTRIES,
  PATCH_COMPANY,
  UPSERT_FUNDING_STATUS,
  UpsertFundingStatus,
} from '@frontend/data-access';
import { bankEngagements, employeeNumberOptions } from '@frontend/shared/ui';
import {
  Button,
  DatePicker,
  Form,
  notification,
  Radio,
  Select,
  Space,
  Spin,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

interface FormValues {
  location?: string;
  foundedDate?: Date;
  employeeNumber?: string;
  bankEngagement?: string;
}

function EditBasicsForm({
  companyId,
  onFinish,
  onCancel,
}: {
  companyId: number;
  onFinish: () => void;
  onCancel: () => void;
}) {
  const { data, loading } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: companyId },
  });
  const { data: dataCountries, loading: loadingCountries } = useQuery<{
    countries: Country[];
  }>(GET_COUNTRIES);
  const [isIncomplete, setIsIncomplete] = useState(false);
  const [form] = Form.useForm();

  const handleCompleted = () => {
    notification.success({
      message: 'Successfully saved',
      placement: 'bottomLeft',
    });
    onFinish();
  };

  const handleError = (data: ErrorResponse) => {
    notification.error({
      message: 'Error',
      description: data.message,
      placement: 'bottomLeft',
    });
  };

  const [patchCompany, { loading: loadingPatch }] = useMutation(PATCH_COMPANY, {
    onError: handleError,
  });

  const handleUpdateCache = (
    cache: ApolloCache<NormalizedCacheObject>,
    { data }: { data: UpsertFundingStatus }
  ) => {
    if (data) {
      const { company }: { company: Company } = cache.readQuery({
        query: GET_COMPANY,
        variables: {
          id: companyId,
        },
      });
      cache.writeQuery({
        query: GET_COMPANY,
        data: {
          company: {
            ...company,
            progressDetails: {
              ...company.progressDetails,
              funding:
                !!data.upsertFundingStatus?.bankEngagement &&
                typeof data.upsertFundingStatus?.currentlyFundraising ===
                  'boolean',
            },
            fundingStatus: data.upsertFundingStatus,
          },
        },
      });
    }
  };

  const [upsertFundingStatus, { loading: loadingUpsertFundingStatus }] =
    useMutation(UPSERT_FUNDING_STATUS, {
      onError: handleError,
      update: handleUpdateCache,
    });

  const handleOnFinish = async (values: FormValues) => {
    const patchData = await patchCompany({
      variables: {
        input: {
          id: companyId,
          foundedDate: values.foundedDate
            ? new Date(values.foundedDate)
            : undefined,
          companyInfo: {
            location: values.location,
            employeeNumber: values.employeeNumber,
          },
        },
      },
    });
    const upsertData = await upsertFundingStatus({
      variables: {
        upsertFundingStatusInput: {
          companyId,
          bankEngagement: values.bankEngagement,
        },
      },
    });

    if (!patchData.errors && !upsertData.errors) {
      handleCompleted();
    }
  };

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsIncomplete(hasErrors);
  };

  useEffect(() => {
    if (data?.company) {
      form.setFieldsValue({
        location: data.company.companyInfo?.location,
        foundedDate: data.company.foundedDate
          ? moment(data.company.foundedDate)
          : null,
        employeeNumber: data.company.companyInfo?.employeeNumber,
        bankEngagement: data.company.fundingStatus?.bankEngagement,
      });
    }
  }, [data, form]);

  if (loading) {
    return (
      <div className="p-6 text-center">
        <Spin />
      </div>
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark="optional"
      onFinish={handleOnFinish}
      onFieldsChange={handleFormChange}
    >
      <div className="px-6 pt-6">
        <Form.Item name="location" label="HQ location">
          <Select
            placeholder="Enter country"
            loading={loadingCountries}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={dataCountries?.countries.map((country) => ({
              value: country.name,
              label: country.name,
            }))}
          />
        </Form.Item>
        <Form.Item name="foundedDate" label="Founded year">
          <DatePicker placeholder="Select date" />
        </Form.Item>
        <Form.Item label="Number of employees" name="employeeNumber">
          <Select
            placeholder="Select number of employees"
            options={employeeNumberOptions.map((option) => ({
              label: option,
              value: option,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Any engagement with the bank or SC Ventures"
          name="bankEngagement"
        >
          <Radio.Group>
            <Space direction="vertical">
              {bankEngagements.map((item) => {
                return (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
        </Form.Item>
      </div>
      <div className="flex justify-end items-center px-4 py-3 border-t border-solid border-gray-400 gap-2">
        <Button disabled={loadingPatch} shape="round" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          loading={loadingPatch || loadingUpsertFundingStatus}
          disabled={isIncomplete}
          shape="round"
        >
          Save
        </Button>
      </div>
    </Form>
  );
}

export default EditBasicsForm;
