import { useQuery } from '@apollo/client';
import { GET_IDEA_CRITERIA_RESULT, Idea } from '@frontend/data-access';

/* eslint-disable-next-line */
export interface IdeaEvaluationResultTagProps {
  ideaId: string;
}

export function IdeaEvaluationResultTag({
  ideaId,
}: IdeaEvaluationResultTagProps) {
  const { data } = useQuery<{ idea: Idea }>(GET_IDEA_CRITERIA_RESULT, {
    variables: { ideaId },
  });

  const results =
    data?.idea?.criteriaResults.filter(
      (result) =>
        result.criteria.type === 'CHALLENGE_EXPERT' && result.status === 'DONE'
    ) || [];
  const average =
    results.reduce((prev, curr) => prev + curr?.scale, 0) / results.length;

  const score = isNaN(average) ? '-' : average.toFixed(1);
  const expertCount = results.reduce(
    (prev, curr) => prev.add(curr?.createdBy?.id),
    new Set<number>([])
  );

  if (data) {
    return (
      <div className="space-x-2">
        <span className="bg-red-100 text-red-600 px-2 py-1 rounded-full w-6 h-6 text-center">
          {score}
        </span>
        {expertCount.size > 0 && <span>by {expertCount.size} experts</span>}
      </div>
    );
  } else return null;
}

export default IdeaEvaluationResultTag;
