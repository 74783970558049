import { Image, Layout } from 'antd';
import getConfig from 'next/config';
import Link from 'next/link';
import { ReactNode } from 'react';

export interface OnboardingSiderProps {
  children: ReactNode;
  onCollapse: (value: boolean) => void;
}

export function OnboardingSider({
  children,
  onCollapse,
}: OnboardingSiderProps) {
  const { publicRuntimeConfig } = getConfig();

  return (
    <Layout.Sider
      className="min-h-screen"
      breakpoint="md"
      collapsedWidth={0}
      trigger={null}
      width={256}
      onCollapse={onCollapse}
    >
      <Link href="/" passHref>
        <div className="px-6 py-3 h-[64px]">
          <Image
            src={`${publicRuntimeConfig.staticFolder}/logo-full.svg`}
            preview={false}
            height="40"
            alt="SCV logo"
          />
        </div>
      </Link>
      {children}
    </Layout.Sider>
  );
}

export default OnboardingSider;
