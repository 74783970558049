import { gql } from '@apollo/client';

import { COMMENT, REPLY } from './comment';

export const DISCUSSION = gql`
  fragment discussion on Discussion {
    id
    state
    comments {
      edges {
        cursor
        node {
          ...comment
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  ${COMMENT}
`;

export const GET_DISCUSSION = gql`
  query discussion($id: UUID!) {
    discussion(id: $id) {
      ...discussion
    }
  }
  ${DISCUSSION}
`;

export const CREATE_DISCUSSION_WALL_COMMENT = gql`
  mutation createDiscussionComment($input: CreateDiscussionCommentInput) {
    createDiscussionComment(input: $input) {
      ...comment
    }
  }
  ${COMMENT}
`;

export const REPLY_TO_DISCUSSION_COMMENT = gql`
  mutation ReplyToDiscussionComment($input: ReplyToDiscussionCommentInput) {
    replyToDiscussionComment(input: $input) {
      ...comment
    }
  }
  ${COMMENT}
`;

export const LIKE_DISCUSSION_WALL_COMMENT = gql`
  mutation LikeDiscussionComment($id: UUID) {
    likeDiscussionComment(id: $id) {
      ...comment
    }
  }
  ${COMMENT}
`;

export const LIKE_DISCUSSION_WALL_COMMENT_REPLY = gql`
  mutation LikeDiscussionCommentReply($id: UUID) {
    likeDiscussionCommentReply(id: $id) {
      ...reply
    }
  }
  ${REPLY}
`;

export const PATCH_DISCUSSION_WALL_COMMENT = gql`
  mutation PatchDiscussionComment($input: PatchDiscussionCommentInput) {
    patchDiscussionComment(input: $input) {
      ...comment
    }
  }
  ${COMMENT}
`;

export const PATCH_DISCUSSION_WALL_COMMENT_REPLY = gql`
  mutation PatchReplyToDiscussionComment(
    $input: PatchReplyToDiscussionCommentInput
  ) {
    patchReplyToDiscussionComment(input: $input) {
      ...comment
    }
  }
  ${COMMENT}
`;

export const DELETE_DISCUSSION_COMMENT = gql`
  mutation DeleteDiscussionComment($id: UUID!) {
    deleteDiscussionComment(id: $id)
  }
`;

export const DELETE_DISCUSSION_REPLY = gql`
  mutation DeleteDiscussionReply($id: UUID!) {
    deleteDiscussionReply(id: $id) {
      ...comment
    }
  }
  ${COMMENT}
`;
