import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  LikeFilled,
  LikeOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  ApolloCache,
  NormalizedCacheObject,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  CommentType,
  DELETE_DISCUSSION_COMMENT,
  DELETE_DISCUSSION_REPLY,
  Discussion,
  GET_DISCUSSION,
  GET_ME,
  LIKE_DISCUSSION_WALL_COMMENT,
  LIKE_DISCUSSION_WALL_COMMENT_REPLY,
  Me,
  PATCH_DISCUSSION_WALL_COMMENT,
  PATCH_DISCUSSION_WALL_COMMENT_REPLY,
  Reply,
} from '@frontend/data-access';
import { UserAvatar } from '@frontend/shared/ui';
import { Button, Comment as AntdComment, Input, Spin, Tooltip } from 'antd';
import { useEffect, useRef } from 'react';

import { Thread } from '../discussion-wall/discussion-wall';

const { TextArea } = Input;

/* eslint-disable-next-line */
export interface CommentProps {
  discussionId: string;
  comment: CommentType;
  currentUser?: Me;
  currentThread?: Thread;
  setCurrentThread: (thread: Thread | undefined) => void;
}

export function Comment({
  discussionId,
  comment,
  currentUser,
  currentThread,
  setCurrentThread,
}: CommentProps) {
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);
  const prevThread = useRef<Thread | undefined>();
  const [likeComment] = useMutation(LIKE_DISCUSSION_WALL_COMMENT);
  const [likeReply] = useMutation(LIKE_DISCUSSION_WALL_COMMENT_REPLY);
  const [patchComment, { loading: patchDiscussionWallCommentLoading }] =
    useMutation(PATCH_DISCUSSION_WALL_COMMENT, {
      onCompleted() {
        setCurrentThread(undefined);
      },
    });
  const [patchReply, { loading: patchDiscussionWallCommentReplyLoading }] =
    useMutation(PATCH_DISCUSSION_WALL_COMMENT_REPLY, {
      onCompleted() {
        setCurrentThread(undefined);
      },
    });
  const [deleteComment, { loading: deleteCommentLoading }] = useMutation(
    DELETE_DISCUSSION_COMMENT,
    {
      onCompleted() {
        setCurrentThread(undefined);
      },
      update: (cache: ApolloCache<NormalizedCacheObject>) => {
        const cacheData: {
          discussion: Discussion;
        } | null = cache.readQuery({
          query: GET_DISCUSSION,
          variables: {
            id: discussionId,
          },
        });
        if (cacheData) {
          const { discussion } = cacheData;

          if (discussion) {
            cache.writeQuery({
              query: GET_DISCUSSION,
              variables: {
                id: discussionId,
              },
              data: {
                discussion: {
                  ...discussion,
                  comments: {
                    ...discussion?.comments,
                    edges: discussion?.comments.edges?.filter(
                      (c) => c.node.id !== prevThread.current?.id
                    ),
                  },
                },
              },
            });
          }
        }
      },
    }
  );
  const [deleteReply, { loading: deleteReplyLoading }] = useMutation(
    DELETE_DISCUSSION_REPLY,
    {
      onCompleted() {
        setCurrentThread(undefined);
      },
    }
  );

  useEffect(() => {
    if (currentThread) {
      prevThread.current = currentThread;
    }
  }, [currentThread]);

  const renderAuthor = (comment: CommentType | Reply) => {
    return (
      <div className="w-full flex justify-between">
        <span>
          {comment.user?.fullName} {comment.updatedAt ? '(edited)' : ''}
        </span>
        {currentUser?.id === comment.user?.id && (
          <Tooltip key="edit-comment" title="Edit">
            <div
              className="ml-2 cursor-pointer"
              onClick={() => {
                setCurrentThread({
                  id: comment.id,
                  text: comment.text,
                  author: comment.user,
                  isEdit: true,
                });
              }}
            >
              <EditOutlined />
            </div>
          </Tooltip>
        )}
        {currentUser?.role === 'ADMIN' && (
          <Tooltip key="delete-reply" title="Delete">
            {(currentThread?.id === comment?.id && deleteReplyLoading) ||
            deleteCommentLoading ? (
              <Spin
                size="small"
                indicator={
                  <span className="text-gray-800 text-[8px]">
                    <LoadingOutlined />
                  </span>
                }
              />
            ) : (
              <div
                className="ml-2 cursor-pointer"
                onClick={() => {
                  setCurrentThread({
                    id: comment?.id,
                    text: comment?.text,
                    author: comment.user,
                    isDelete: true,
                  });
                  comment.__typename === 'Comment'
                    ? deleteComment({
                        variables: {
                          id: comment?.id,
                        },
                      })
                    : deleteReply({
                        variables: {
                          id: comment?.id,
                        },
                      });
                }}
              >
                <DeleteOutlined />
              </div>
            )}
          </Tooltip>
        )}
      </div>
    );
  };

  const renderContentComment = (comment: CommentType) => {
    return currentThread?.isEdit && currentThread.id === comment.id ? (
      <div className="space-y-2">
        <div className="flex justify-end">
          <CloseOutlined onClick={() => setCurrentThread(undefined)} />
        </div>
        <TextArea
          value={currentThread.text}
          autoFocus
          rows={3}
          placeholder="Add a comment..."
          onChange={(e) =>
            setCurrentThread({
              ...currentThread,
              text: e.target.value,
            })
          }
        />
        <Button
          type="primary"
          shape="round"
          loading={patchDiscussionWallCommentLoading}
          onClick={() =>
            patchComment({
              variables: {
                input: {
                  commentId: currentThread.id,
                  text: currentThread.text,
                },
              },
            })
          }
        >
          Submit
        </Button>
      </div>
    ) : (
      <p>{comment.text}</p>
    );
  };

  const renderContentReply = (reply: Reply) => {
    return currentThread?.isEdit && currentThread.id === reply.id ? (
      <div className="space-y-2">
        <div className="flex justify-end">
          <CloseOutlined onClick={() => setCurrentThread(undefined)} />
        </div>
        <TextArea
          value={currentThread.text}
          autoFocus
          rows={3}
          placeholder="Add a comment..."
          onChange={(e) =>
            setCurrentThread({
              ...currentThread,
              text: e.target.value,
            })
          }
        />

        <Button
          type="primary"
          shape="round"
          loading={patchDiscussionWallCommentReplyLoading}
          onClick={() =>
            patchReply({
              variables: {
                input: { replyId: currentThread.id, text: currentThread.text },
              },
            })
          }
        >
          Submit
        </Button>
      </div>
    ) : (
      <p>{reply.text}</p>
    );
  };

  const renderActionsComment = (comment: CommentType) => {
    return [
      <div
        className="cursor-pointer text-xs mr-2"
        onClick={() => {
          meData &&
            likeComment({
              variables: { id: comment.id },
            });
        }}
      >
        <Tooltip
          key="comment-basic-like"
          title={comment.isLiked ? 'Unlike' : 'Like'}
        >
          <span className={`${comment.isLiked && 'text-primary-600'}`}>
            {comment.isLiked ? <LikeFilled /> : <LikeOutlined />}
          </span>
          <span
            className={`comment-action ml-1 ${
              comment.isLiked && 'text-primary-600'
            }`}
          >
            {comment.likeCount}
          </span>
        </Tooltip>
      </div>,
      <span
        key="comment-list-reply-to-0"
        onClick={() => {
          meData &&
            setCurrentThread({
              id: comment.id,
              text: comment.text,
              author: comment.user,
            });
        }}
      >
        Reply
      </span>,
    ];
  };

  const renderActionsReply = (reply: Reply) => {
    return [
      <div
        className="cursor-pointer text-xs mr-2"
        onClick={() =>
          meData &&
          likeReply({
            variables: { id: reply.id },
          })
        }
      >
        <Tooltip
          key="comment-basic-like"
          title={reply.isLiked ? 'Unlike' : 'Like'}
        >
          <span className={`${reply.isLiked && 'text-primary-600'}`}>
            {reply.isLiked ? <LikeFilled /> : <LikeOutlined />}
          </span>
          <span
            className={`comment-action ml-1 ${
              reply.isLiked && 'text-primary-600'
            }`}
          >
            {reply.likeCount}
          </span>
        </Tooltip>
      </div>,
    ];
  };

  return (
    <AntdComment
      actions={renderActionsComment(comment)}
      author={renderAuthor(comment)}
      avatar={<UserAvatar user={comment.user} />}
      content={renderContentComment(comment)}
    >
      {comment.replies?.length &&
        comment.replies.map((reply) => {
          return (
            <AntdComment
              key={reply.id}
              actions={renderActionsReply(reply)}
              author={renderAuthor(reply)}
              avatar={<UserAvatar user={reply.user} />}
              content={renderContentReply(reply)}
            />
          );
        })}
    </AntdComment>
  );
}

export default Comment;
