import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_INTRAPRENEUR_CHALLENGES,
  GET_INTRAPRENEUR_CHALLENGES_AGGREGATE,
  GET_INTRAPRENEUR_CHALLENGES_AGGREGATE_MINE,
  GET_INTRAPRENEUR_CHALLENGES_MINE,
  GET_ME,
  GET_OPEN_CHALLENGES,
  GET_OPEN_CHALLENGES_AGGREGATE,
  IntrapreneurChallenge,
  Me,
  OpenChallenge,
} from '@frontend/data-access';
import { Col, Pagination, Row, Spin, Typography } from 'antd';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

import ChallengeCard from '../challenge-card/challenge-card';
import ChallengeFilter from '../challenge-filter/challenge-filter';
import ChallengeSort from '../challenge-sort/challenge-sort';

export type ChallengeStateType =
  | 'DRAFT'
  | 'ACTIVE'
  | 'ENDED'
  | 'UPCOMING'
  | 'ARCHIVED'
  | 'ALL';
export type ChallengeSortType =
  | 'CREATION_DATE'
  | 'START_DATE'
  | 'END_DATE'
  | 'ALPHABET';

interface ChallengeParams {
  page: number;
  limit: number;
  filterBy?: {
    states: ChallengeStateType[];
    spaceId?: string;
    userId?: number;
  };
  spaceId?: string;
  sort?: ChallengeSortType;
}

const DEFAULT_LIMIT = 9;

export interface ChallengeListProps {
  type: 'OPEN' | 'INTRAPRENEUR';
}

export function ChallengeList({ type }: ChallengeListProps) {
  const { t: tcommon } = useTranslation('common');
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);
  const { query } = useRouter();
  const { spaceId } = query;

  const [params, setParams] = useState<ChallengeParams>({
    page: 0,
    limit: DEFAULT_LIMIT,
    filterBy: {
      states: ['ACTIVE', 'DRAFT', 'ENDED', 'UPCOMING', 'ARCHIVED'],
    },
    sort: 'CREATION_DATE',
    spaceId: spaceId as string,
  });
  const [list, setList] = useState<OpenChallenge[] & IntrapreneurChallenge[]>(
    []
  );
  const [total, setTotal] = useState<number>();

  const sortOptions = [
    {
      value: 'CREATION_DATE',
      label: tcommon('Creation date'),
    },
    {
      value: 'START_DATE',
      label: tcommon('Start date'),
    },
    {
      value: 'END_DATE',
      label: tcommon('End date'),
    },
    {
      value: 'ALPHABET',
      label: tcommon('A-Z'),
    },
  ];

  const [getChallenge, { data: dataChallenges, loading }] = useLazyQuery(
    type === 'OPEN'
      ? GET_OPEN_CHALLENGES
      : meData?.me.role === 'CHALLENGE_MANAGER'
      ? GET_INTRAPRENEUR_CHALLENGES_MINE
      : GET_INTRAPRENEUR_CHALLENGES,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [getChallengeAggregate, { data: dataChallengesAggregate }] =
    useLazyQuery(
      type === 'OPEN'
        ? GET_OPEN_CHALLENGES_AGGREGATE
        : meData?.me.role === 'CHALLENGE_MANAGER'
        ? GET_INTRAPRENEUR_CHALLENGES_AGGREGATE_MINE
        : GET_INTRAPRENEUR_CHALLENGES_AGGREGATE,
      {
        fetchPolicy: 'network-only',
      }
    );

  useEffect(() => {
    getChallenge({ variables: params });
    getChallengeAggregate({ variables: { filterBy: params.filterBy } });
  }, [getChallenge, getChallengeAggregate, params]);

  useEffect(() => {
    if (dataChallenges?.openChallenges) {
      setList(dataChallenges?.openChallenges);
    } else if (dataChallenges?.internalChallenges) {
      setList(dataChallenges?.internalChallenges);
    } else if (dataChallenges?.internalChallengesMine) {
      setList(dataChallenges?.internalChallengesMine);
    }
  }, [dataChallenges]);

  useEffect(() => {
    if (dataChallengesAggregate?.openChallengesAggregate) {
      setTotal(dataChallengesAggregate?.openChallengesAggregate?.totalCount);
    } else if (dataChallengesAggregate?.internalChallengesAggregate) {
      setTotal(
        dataChallengesAggregate?.internalChallengesAggregate?.totalCount
      );
    } else if (dataChallengesAggregate?.internalChallengesAggregateMine) {
      setTotal(
        dataChallengesAggregate?.internalChallengesAggregateMine?.totalCount
      );
    }
  }, [dataChallengesAggregate]);

  const handleFilter = (filterBy: {
    states?: ChallengeStateType[];
    tag?: string;
    userId?: number;
  }) => {
    setParams({
      ...params,
      page: 0,
      filterBy: {
        states: filterBy.states ??
          params.filterBy?.states ?? [
            'ACTIVE',
            'DRAFT',
            'ENDED',
            'UPCOMING',
            'ARCHIVED',
          ],
        spaceId: filterBy.tag === 'ALL' ? undefined : filterBy.tag,
        userId: filterBy.userId,
      },
    });
  };

  const handleSort = (sort: ChallengeSortType) => {
    setParams({ ...params, page: 0, sort });
  };

  const handlePaginate = (page: number, pageSize: number) => {
    setParams({ ...params, page: page - 1 });
  };

  if (loading) {
    return (
      <div className="flex justify-center w-full">
        <Spin />
      </div>
    );
  }

  return (
    <>
      <Row justify="space-between">
        <Col>
          <ChallengeFilter
            type={type}
            states={
              params.filterBy?.states.length === 1
                ? params.filterBy?.states[0]
                : 'ALL'
            }
            tag={params.filterBy?.spaceId}
            userId={params.filterBy?.userId}
            onFilter={handleFilter}
          />
        </Col>
        <Col>
          <ChallengeSort
            value={params.sort}
            sortOptions={sortOptions}
            onSort={handleSort}
          />
        </Col>
      </Row>
      <Row className="py-2">
        <Typography.Text type="secondary">{total + ' Results'}</Typography.Text>
      </Row>
      <Row gutter={[16, 16]}>
        {list.map((challenge: OpenChallenge & IntrapreneurChallenge) => {
          return (
            <Col
              lg={{ span: 8 }}
              md={{ span: 12 }}
              span={24}
              key={challenge.id}
            >
              <ChallengeCard challenge={challenge} variables={params} />
            </Col>
          );
        })}
      </Row>
      <Row className="py-4">
        <Pagination
          defaultCurrent={1}
          current={params.page + 1}
          defaultPageSize={DEFAULT_LIMIT}
          total={total}
          onChange={handlePaginate}
        />
      </Row>
    </>
  );
}

export default ChallengeList;
