import { Company } from '@frontend/data-access';

import CompanyAvatar from '../company-avatar/company-avatar';

export interface CompanyCardProps {
  company: Company;
}

export function CompanyCard({ company }: CompanyCardProps) {
  return (
    <div className="flex space-x-3">
      <CompanyAvatar size={64} company={company} />
      <div className="flex flex-col">
        <div className="text-base text-gray-900 font-semibold">
          {company?.name}
        </div>
        <div className="text-sm text-gray-900 line-clamp-2">
          {company?.description}
        </div>
      </div>
    </div>
  );
}

export default CompanyCard;
