import {
  DoubleRightOutlined,
  LinkOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  GET_USER_BASICS_BY_ID,
  GET_USER_RANK,
  Me,
  User,
} from '@frontend/data-access';
import { Avatar, Button, Drawer, Grid, Modal, Skeleton, Spin } from 'antd';
import getConfig from 'next/config';
import Image from 'next/image';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

import ProtectedCard from '../protected-card/protected-card';
import UserInfo from '../user-info/user-info';

const { useBreakpoint } = Grid;

const covertName = (name: string) => {
  const splittedStr = name.split(/(\s+)/);
  let str = '';
  splittedStr.forEach((s) => {
    if (s.trim() !== '') {
      str = str.concat(s.trim().charAt(0).toUpperCase());
    }
  });
  return str;
};

export interface UserAvatarProps {
  user: User | Me;
  size?: number | 'default' | 'large' | 'small';
  shape?: 'square' | 'circle';
  showBadge?: boolean;
  badgeSize?: number;
  className?: string;
  isClickable?: boolean;
}

const levels = [
  {
    id: 1,
    description: 'Enthusiast - 0-1000',
  },
  {
    id: 2,
    description: 'Contributor - 1001-2000',
  },
  {
    id: 3,
    description: 'Influencer - 2001-3000',
  },
  {
    id: 4,
    description: 'Champion - 3001-5000',
  },
  {
    id: 5,
    description: 'Thought leader - 5001-7000',
  },
  {
    id: 6,
    description: 'Visionary - 7001-9999',
  },
  {
    id: 7,
    description: 'Legend - 9999+',
  },
];

export function UserAvatar({
  user,
  size = 'default',
  shape = 'circle',
  showBadge = false,
  badgeSize = 20,
  className,
  isClickable = true,
}: UserAvatarProps) {
  const { t: tcommon } = useTranslation('common');
  const screens = useBreakpoint();
  const [drawer, setDrawer] = useState<boolean>(false);
  const [helper, setHelper] = useState(false);
  const { publicRuntimeConfig } = getConfig();
  const [getRank, { data: rankData, loading: rankLoading }] = useLazyQuery<{
    findUser: User;
  }>(GET_USER_RANK);

  const [getUserInfo, { data: userData, loading }] = useLazyQuery<{
    findUser: User;
  }>(GET_USER_BASICS_BY_ID);

  const colors = [
    '!bg-[#68BAEA]',
    '!bg-[#68EA7D]',
    '!bg-[#1F3644]',
    '!bg-[#686DEA]',
    '!bg-[#EAC668]',
  ];

  const name = user?.fullName || user?.firstName + ' ' + user?.lastName;
  const src = user?.image?.url;

  const getColor = (name: string) => {
    const charCode = name.toLowerCase().charCodeAt(0);
    const index = (charCode - 97) % colors.length;
    return colors[index];
  };

  const color = name ? getColor(name) : '!bg-primary-1000';

  const getData = (id: string) => {
    getUserInfo({
      variables: {
        id: id,
      },
    });
  };

  const handleClick = () => {
    if (isClickable) {
      handleDrawer();
      getData(user?.id.toString());
      // router.push(`/user/${user?.id}`);
    }
  };

  const handleDrawer = () => {
    setDrawer(!drawer);
  };

  const handleHelper = () => {
    setHelper(!helper);
  };

  useEffect(() => {
    if (showBadge) {
      getRank({
        variables: {
          id: user?.id,
        },
      });
    }
  }, [getRank, showBadge, user?.id]);

  if (name === '' && src === null) {
    return <Avatar size={size} shape={shape} icon={<UserOutlined />} />;
  }

  return (
    <div className="relative w-fit">
      <Avatar
        size={size}
        shape={shape}
        src={src}
        className={`${className} ${color} ${isClickable && 'cursor-pointer'}`}
        onClick={handleClick}
      >
        <span data-test="profile-name">{name && covertName(name)}</span>
      </Avatar>
      {showBadge && !rankLoading && rankData?.findUser.rank?.badge.level && (
        <div
          className={`absolute bottom-[-5px] right-[-5px] cursor-pointer`}
          onClick={isClickable ? handleHelper : undefined}
        >
          <Image
            src={`${publicRuntimeConfig.staticFolder}/svg/user-badge/${rankData?.findUser.rank.badge.level}.svg`}
            height={badgeSize}
            width={badgeSize}
            alt="badge"
            className="drop-shadow-md"
          />
        </div>
      )}
      <Modal
        title="Level rule"
        open={helper}
        onCancel={handleHelper}
        footer={null}
      >
        <div className="mb-2">
          Intrapreneurs are ranked as follows based on their existing points in
          Fintech Bridge:
        </div>
        <ul className="list-disc space-y-2">
          {levels.map((level, index) => (
            <li key={index} className="flex items-center space-x-2">
              <Image
                src={`${publicRuntimeConfig.staticFolder}/svg/user-badge/${level.id}.svg`}
                height={20}
                width={20}
                alt="user-badge"
              />
              <span>{level.description}</span>
            </li>
          ))}
        </ul>
      </Modal>
      <Drawer
        title={
          <div className="flex justify-between">
            <Button
              ghost
              type="text"
              onClick={handleDrawer}
              icon={<DoubleRightOutlined />}
            />
            <Link href={`/user/${user?.id}`} target="_blank">
              <Button
                type="primary"
                shape="round"
                onClick={handleDrawer}
                icon={<LinkOutlined />}
              >
                {tcommon('user_profile_open_button')}
              </Button>
            </Link>
          </div>
        }
        placement="right"
        closable={false}
        onClose={handleDrawer}
        open={drawer}
        width={screens.md ? '500' : undefined}
      >
        <ProtectedCard type="MEMBER">
          {loading ? (
            <div className="text-center">
              <Spin />
            </div>
          ) : userData?.findUser ? (
            <UserInfo user={userData?.findUser} />
          ) : (
            <div className="space-y-4 text-center">
              <Avatar size={70} src="/data/avatar-1.jpeg" />
              <Skeleton />
              <Skeleton />
            </div>
          )}
        </ProtectedCard>
      </Drawer>
    </div>
  );
}

export default UserAvatar;
