import { Button, Input, notification } from 'antd';
import { useEffect, useState } from 'react';

interface ArrayInputProps {
  max?: number;
  value?: string[];
  onChange: (val: string[]) => void;
}
const ArrayInput = ({ value, max = 100, onChange }: ArrayInputProps) => {
  const [array, setArray] = useState<string[]>([]);

  useEffect(() => {
    if (value && Array.isArray(value)) {
      setArray(value);
    } else {
      setArray(['']);
    }
  }, [value]);

  return (
    <div className="space-y-5 w-full">
      {array.map((v, index) => {
        return (
          <Input
            value={v}
            prefix={`${index + 1}. `}
            placeholder={`Enter value ${index + 1}`}
            onChange={(e) => {
              const newArray = [...array].filter((a) => a !== '');
              newArray[index] = e.target.value;
              setArray(newArray);
              onChange(newArray);
            }}
          />
        );
      })}
      <Button
        block
        onClick={() => {
          if (array.length >= max) {
            notification.error({
              message: `You can add up to ${max} items`,
              placement: 'bottomLeft',
            });
            return;
          }
          setArray([...array, '']);
        }}
      >
        Add
      </Button>
    </div>
  );
};

export default ArrayInput;
