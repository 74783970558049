import {
  CloseCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Company,
  ErrorResponse,
  GET_COMPANY,
  PATCH_COMPANY,
  UploadedFile,
} from '@frontend/data-access';
import { handleOpenDocument } from '@frontend/shared/ui';
import { Button, Modal, notification } from 'antd';
import getConfig from 'next/config';
import Image from 'next/image';
import { useState } from 'react';

import EditInformationDeckForm from './edit-actions/info-deck-form';
import EmptyCard from './empty-card';

const InformationDeck = ({
  data,
  companyId,
}: {
  data?: UploadedFile[];
  companyId?: number;
}) => {
  const { publicRuntimeConfig } = getConfig();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState<UploadedFile | null>(null);

  const { data: dataCompany } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: companyId },
  });

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleCompleted = () => {
    notification.success({
      message: 'Successfully saved',
      placement: 'bottomLeft',
    });
  };

  const handleError = (data: ErrorResponse) => {
    notification.error({
      message: 'Error',
      description: data.message,
      icon: (
        <span className="text-red-600">
          <CloseCircleOutlined />
        </span>
      ),
      placement: 'bottomLeft',
    });
  };

  const [patchCompany, { loading: loadingPatch }] = useMutation(PATCH_COMPANY, {
    onCompleted: handleCompleted,
    onError: handleError,
    refetchQueries: [
      {
        query: GET_COMPANY,
        variables: { id: companyId },
      },
    ],
  });

  const onRemoveVideo = async (file: UploadedFile) => {
    try {
      setSelectedFile(file);
      await patchCompany({
        variables: {
          input: {
            id: companyId,
            documents: {
              onePagers: (data ?? []).filter((v) => v.id !== file.id),
            },
          },
        },
      });
    } catch (error) {
      // handleError(error);
      console.log('error', error);
    }
  };

  return (
    <div data-test="information-deck-content">
      {data && data.length > 0 ? (
        <div className="flex flex-col gap-4">
          {data.map((file, index) => (
            <div
              key={index}
              className="flex flex-row gap-2 items-center w-full"
            >
              <div className="grow bg-gray-200 rounded-full flex flex-row justify-between items-center p-2 flex-wrap md:flex-nowrap">
                <div className="flex gap-3">
                  <Image
                    src={`${publicRuntimeConfig.staticFolder}/svg/download-pdf-black.svg`}
                    height="24"
                    width="24"
                    alt="SCV logo"
                  />
                  <span className="truncate whitespace-nowrap max-w-[100px] md:max-w-sm">
                    {file.metadata?.filename ?? 'No file name'}
                  </span>
                </div>
                <div className="flex">
                  <Button
                    type="link"
                    icon={<EyeOutlined />}
                    onClick={() => handleOpenDocument(file)}
                  >
                    View file
                  </Button>
                </div>
              </div>
              {dataCompany?.company.isOwner && (
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  shape="circle"
                  onClick={() => onRemoveVideo(file)}
                  loading={loadingPatch && selectedFile?.id === file.id}
                />
              )}
            </div>
          ))}
        </div>
      ) : dataCompany?.company.isOwner ? (
        <div
          className="rounded-full border border-dashed bg-gray-200 hover:bg-white border-gray-500 hover:border-primary-400 cursor-pointer ease-in-out transition-all duration-200 py-2"
          onClick={toggleModal}
        >
          <div className="flex flex-row items-center justify-between mx-3">
            <div className="flex items-center gap-2">
              <div className="bg-gray-500 p-3 rounded-full">
                <Image
                  src={`${publicRuntimeConfig.staticFolder}/svg/download-pdf.svg`}
                  height="24"
                  width="24"
                  alt="SCV logo"
                />
              </div>
              <span className="text-gray-700 font-medium">
                Please upload your information deck file here
              </span>
            </div>
            {dataCompany?.company.isOwner && (
              <Button type="primary" shape="round" size="large">
                Upload file
              </Button>
            )}
          </div>
        </div>
      ) : (
        <EmptyCard title="No information" icon={<InfoCircleOutlined />} />
      )}
      <Modal
        title="Upload Information Deck"
        open={isModalVisible}
        onCancel={toggleModal}
        width={800}
        bodyStyle={{
          padding: 0,
        }}
        footer={false}
      >
        <EditInformationDeckForm
          companyId={companyId}
          onFinish={toggleModal}
          onCancel={toggleModal}
        />
      </Modal>
    </div>
  );
};

export default InformationDeck;
