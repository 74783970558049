import {
  BulbOutlined,
  DotChartOutlined,
  FileOutlined,
  FireOutlined,
  FlagOutlined,
  GlobalOutlined,
  LinkOutlined,
  TagsOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Idea } from '@frontend/data-access';
import { getLinkLabel } from '@frontend/shared/util';
import { Avatar, Col, Row, Space } from 'antd';
import Link from 'next/link';

import { handleOpenDocument } from '../documents/documents';
import IconCard from '../icon-card/icon-card';
import UserAvatarPdfTemplate from '../user-avatar-pdf-template/user-avatar-pdf-template';

/* eslint-disable-next-line */
export interface IdeaDetailBasicsPdfTemplateProps {
  data?: Idea;
}

interface IdeaBasicItemType {
  id: string;
  title: string;
  icon?: React.ReactNode;
  item: React.ReactNode;
}

export const getIdeaBasicItemsTemplate = (idea: Idea): IdeaBasicItemType[] => {
  return [
    {
      title: 'Applicants',
      icon: <TeamOutlined />,
      item: (
        <div className="flex gap-2 items-center justify-between">
          <Avatar.Group maxCount={5} className="!space-x-2">
            {idea.anonymous ? (
              <UserAvatarPdfTemplate size={40} name={''} src={null} />
            ) : (
              <UserAvatarPdfTemplate
                size={40}
                name={idea.createdBy.fullName}
                src={idea.createdBy.image?.url || null}
                level={idea.createdBy.rank?.badge.level}
              />
            )}
            {idea.applicants
              ?.filter(
                (i) =>
                  i.status === 'APPROVED' && idea.createdBy.id !== i.user.id
              )
              ?.map((applicant, index) => (
                <UserAvatarPdfTemplate
                  key={index}
                  size={40}
                  name={applicant.user.fullName}
                  src={applicant.user.image?.url || null}
                  level={applicant.user.rank?.badge.level}
                />
              ))}
          </Avatar.Group>
        </div>
      ),
    },
    idea.video || idea.documents?.length
      ? {
          title: 'Attachments',
          icon: <FileOutlined />,
          item: (
            <div className="flex justify-between">
              <div>
                {idea.video && (
                  <div>
                    <span className="text-sm text-gray-900">Video</span>
                    <div className="space-x-1">
                      <span className="text-primary-600">
                        <LinkOutlined />
                      </span>
                      <Link
                        className="text-primary-600 break-all"
                        href={idea.video}
                        target="_blank"
                      >
                        {idea.video}
                      </Link>
                    </div>
                  </div>
                )}
                {idea?.documents && idea?.documents?.length > 0 && (
                  <div>
                    <span className="text-sm text-gray-900">Documents</span>
                    {idea.documents?.map((file, index) => (
                      <div className="space-x-1" key={index}>
                        <span className="text-primary-600">
                          <FileOutlined />
                        </span>
                        <span
                          className="text-primary-600 cursor-pointer"
                          onClick={() => handleOpenDocument(file)}
                        >
                          {file.metadata?.filename}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ),
        }
      : null,
    idea.challenge
      ? {
          title: 'Challenge',
          icon: <BulbOutlined />,
          item: (
            <div>
              <Link
                className="text-primary-600"
                href={'/intrapreneurship/challenge/' + idea.challenge?.id || ''}
              >
                {idea.challenge?.title}
              </Link>
            </div>
          ),
        }
      : null,
    {
      title: 'Region',
      icon: <GlobalOutlined />,
      item: (
        <Row className="gap-2 mt-1">
          {idea.regions?.map((item, index) => {
            return (
              <Col
                className="bg-gray-200 py-1 px-3 rounded-full text-xs"
                key={index}
              >
                {item.name}
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      title: 'Target country',
      icon: <FlagOutlined />,
      item: (
        <Row className="gap-2 mt-1">
          {idea.countries?.map((item, index) => {
            return (
              <Col
                className="bg-gray-200 py-1 px-3 rounded-full text-xs"
                key={index}
              >
                {item.name}
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      title: 'Topics',
      icon: <TagsOutlined />,
      item: (
        <Row className="gap-2 mt-1">
          {idea.topics?.map((item, index) => {
            return (
              <Col
                className="bg-gray-200 py-1 px-3 rounded-full text-xs"
                key={index}
              >
                {item.name}
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      title: 'Business & Functional area',
      icon: <DotChartOutlined />,
      item: (
        <Row className="gap-2 mt-1">
          {idea.businessAreas?.map((item, index) => {
            return <Col key={index}>{item}</Col>;
          })}
        </Row>
      ),
    },
    {
      title: 'Idea status',
      icon: <FireOutlined />,
      item: <span>{idea.state && getLinkLabel(idea.state.toLowerCase())}</span>,
    },
  ].filter((a) => a != null) as IdeaBasicItemType[];
};

export function IdeaDetailBasicsPdfTemplate({
  data,
}: IdeaDetailBasicsPdfTemplateProps) {
  return (
    <Space className="w-full" direction="vertical" size="middle">
      {data &&
        getIdeaBasicItemsTemplate(data).map((item, index) => (
          <IconCard key={index} title={item.title} icon={item.icon}>
            <div>{item.item}</div>
          </IconCard>
        ))}
    </Space>
  );
}

export default IdeaDetailBasicsPdfTemplate;
