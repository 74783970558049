import styles from './feature-discussion.module.less';

/* eslint-disable-next-line */
export interface FeatureDiscussionProps {}

export function FeatureDiscussion(props: FeatureDiscussionProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to FeatureDiscussion!</h1>
    </div>
  );
}

export default FeatureDiscussion;
