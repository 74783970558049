import { useMutation } from '@apollo/client';
import {
  ChallengeApplication,
  CREATE_EVALUATION,
  GET_OPEN_CHALLENGE,
  PATCH_EVALUATION,
} from '@frontend/data-access';
import { notification, Select } from 'antd';
import useTranslation from 'next-translate/useTranslation';

/* eslint-disable-next-line */
export interface ApplicationManagerEvaluatuionScoreProps {
  application: ChallengeApplication;
}
const scores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export function ApplicationManagerEvaluatuionScore({
  application,
}: ApplicationManagerEvaluatuionScoreProps) {
  const { t: tcommon } = useTranslation('common');
  const criteria = application?.criteria.filter(
    (i) => i.type === 'CHALLENGE_MANAGER'
  )[0];
  const results = application?.criteriaResults?.filter(
    (r) => r?.status === 'DONE' && r?.criteria?.type === 'CHALLENGE_MANAGER'
  );

  const handleCompleted = () => {
    notification.success({
      message: 'Successfully saved',
      placement: 'bottomLeft',
    });
  };

  const [createEvaluation, { loading: createLoading }] = useMutation(
    CREATE_EVALUATION,
    {
      onCompleted: handleCompleted,
      refetchQueries: [
        {
          query: GET_OPEN_CHALLENGE,
          variables: { challengeId: application?.challenge?.id },
        },
      ],
    }
  );

  const [patchEvaluation, { loading: patchLoading }] = useMutation(
    PATCH_EVALUATION,
    {
      onCompleted: handleCompleted,
      refetchQueries: [
        {
          query: GET_OPEN_CHALLENGE,
          variables: { challengeId: application?.challenge?.id },
        },
      ],
    }
  );

  const handleSaveEvaluation = ({
    applicationId,
    criteriaId,
    point,
  }: {
    applicationId: string;
    criteriaId: string;
    point: number;
  }) => {
    if (results?.length > 0) {
      patchEvaluation({
        variables: {
          input: {
            id: results[0].id,
            criteriaId,
            scale: point,
            status: 'DONE',
          },
        },
      });
    } else {
      createEvaluation({
        variables: {
          input: {
            ideaId: applicationId,
            criteriaId,
            scale: point,
            status: 'DONE',
            evaluationType: 'APPLICATION',
          },
        },
      });
    }
  };

  return (
    <Select
      placeholder={tcommon('evaluation_score_placeholder')}
      className="!w-20 !text-center"
      defaultValue={results?.[0]?.scale}
      size="small"
      loading={createLoading || patchLoading}
      options={scores.map((item) => ({
        label: item,
        value: item,
      }))}
      disabled={application?.state !== 'SUBMITTED'}
      onChange={(e: number) => {
        if (e !== undefined) {
          handleSaveEvaluation({
            criteriaId: criteria?.id,
            applicationId: application?.id,
            point: e,
          });
        }
      }}
    />
  );
}

export default ApplicationManagerEvaluatuionScore;
