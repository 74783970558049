import { ApolloLink, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

export const createSplitLink = (httpLink: ApolloLink) => {
  if (process.env.NEXT_PUBLIC_WEBSOCKET_API_ENDPOINT === undefined) {
    throw new Error('NEXT_PUBLIC_WEBSOCKET_API_ENDPOINT env not defined');
  }

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.NEXT_PUBLIC_WEBSOCKET_API_ENDPOINT + '/graphql',
      connectionParams: {
        authToken: 'hi',
      },
    })
  );

  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );
};
