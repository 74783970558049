import { Col, Row } from 'antd';

const TagItems = ({
  data,
  test,
}: {
  data: { name: string }[];
  test: string;
}) => {
  return (
    <Row className="gap-2 mt-1">
      {data.map((item, index) => {
        return (
          <Col
            className="bg-gray-200 py-1 px-3 rounded-full text-xs"
            key={index}
          >
            <span
              data-test={`${test}-${item.name.replace(' ', '-').toLowerCase()}`}
            >
              {item.name}
            </span>
          </Col>
        );
      })}
    </Row>
  );
};

export default TagItems;
