import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export function H2Icon(props: Partial<CustomIconComponentProps>) {
  const H2Svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M4.5 16.45q-.2 0-.325-.125T4.05 16V8q0-.2.125-.338.125-.137.325-.137t.338.137q.137.138.137.338v3.525h5.075V8q0-.2.125-.338.125-.137.325-.137t.338.137q.137.138.137.338v8q0 .2-.137.325-.138.125-.338.125t-.325-.125Q10.05 16.2 10.05 16v-3.55H4.975V16q0 .2-.137.325-.138.125-.338.125Zm9.3 0q-.325 0-.537-.213-.213-.212-.213-.537v-2.625q0-.625.45-1.088.45-.462 1.075-.462h3.85q.275 0 .45-.175t.175-.425v-1.85q0-.25-.175-.438-.175-.187-.45-.187H13.5q-.2 0-.325-.125T13.05 8q0-.2.125-.338.125-.137.325-.137h4.925q.625 0 1.088.462.462.463.462 1.088v1.85q0 .625-.462 1.075-.463.45-1.088.45h-3.85q-.25 0-.425.187-.175.188-.175.438v2.45H19.5q.2 0 .338.137.137.138.137.338t-.137.325q-.138.125-.338.125Z" />
    </svg>
  );

  return <Icon height="18px" component={H2Svg} {...props} />;
}

export default H2Icon;
