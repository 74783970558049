import styles from './feature-intrapreneur-challenge.module.less';

/* eslint-disable-next-line */
export interface FeatureIntrapreneurChallengeProps {}

export function FeatureIntrapreneurChallenge(
  props: FeatureIntrapreneurChallengeProps
) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to FeatureIntrapreneurChallenge!</h1>
    </div>
  );
}

export default FeatureIntrapreneurChallenge;
