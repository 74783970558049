import { BellOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { FirebaseApp } from '@firebase/app';
import { GET_ME } from '@frontend/data-access';
import { Drawer, Grid } from 'antd';
import {
  collection,
  getFirestore,
  limit,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { Notification } from '../notification';
export interface NotificationBellProps {
  firebaseUser?: string;
  firebaseApp?: FirebaseApp;
  type?: 'text' | 'icon';
}

const { useBreakpoint } = Grid;

export function NotificationBell({
  firebaseUser,
  firebaseApp,
  type,
}: NotificationBellProps) {
  const screens = useBreakpoint();
  const [open, setOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [badge, setBadge] = useState<boolean>(false);

  const handleBellClick = () => {
    setOpen(true);
  };
  const handleCloseClick = () => {
    setOpen(false);
  };

  const BadgeCount = ({ count }: { count: string }) => (
    <span className="text-center block leading-6 w-5 h-5 bg-primary-600 !text-white rounded-full absolute top-0 right-0 text-xs">
      {count}
    </span>
  );

  const { data: meData } = useQuery<{
    me: {
      id: number;
      authToken: string;
    };
  }>(GET_ME);

  const fetchData = useCallback(async () => {
    try {
      if (firebaseApp) {
        const db = getFirestore(firebaseApp);
        const notifyCol = collection(
          db,
          'users',
          `${meData?.me.id}`,
          'notifications'
        );
        const query_ = query(notifyCol, where('read', '==', false), limit(10));
        const por = onSnapshot(query_, (snapshot) => {
          if (firebaseUser?.trim() === `${meData?.me.id}`) {
            const count = snapshot.size;
            if (count > 0) {
              setCount(count);
              setBadge(true);
            } else {
              setBadge(false);
            }
          } else {
            setBadge(false);
          }
        });
        return () => {
          por();
        };
      }

      return;
    } catch (error) {
      setBadge(false);
      return;
    }
  }, [firebaseUser, firebaseApp]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div
        onClick={handleBellClick}
        className="p-5 bg-gray-400 w-5 h-5 flex items-center justify-center hover:text-primary-600 hover:cursor-pointer rounded-full relative"
      >
        {badge && (
          <span className="text-center block leading-6 w-2.5 h-2.5 bg-primary-600 !text-white rounded-full absolute top-0 right-0 text-xs"></span>
        )}
        <span className="text-xl leading-none">
          <BellOutlined />
        </span>
      </div>
      <Drawer
        closable={false}
        placement="right"
        onClose={handleCloseClick}
        open={open}
        bodyStyle={{ padding: 0 }}
        width={screens.xs ? 356 : 378}
      >
        <Notification
          mode="COMPACT"
          firebaseUser={firebaseUser}
          firebaseApp={firebaseApp}
          setOpen={setOpen}
        />
      </Drawer>
    </>
  );
}

export default NotificationBell;
