import { Progress } from 'antd';
import { useMemo } from 'react';

/* eslint-disable-next-line */
export interface ResultIndicatorProps {
  score: number;
}

/**
 * Display a progress bar with the calculated overall score.
 * Display a label indicating the match quality based on the semiMatch condition.
 *
 * Determine the stroke color, background color, and label based on the overall score.
 * - 100%: Perfect match (Green)
 * - > 60%: Great match (Cyan)
 * - >= 40%: Semi match (Blue)
 * - < 40%: Low match (Gray)
 */
export function ResultIndicator({ score }: ResultIndicatorProps) {
  // Calculate the overall score as a percentage based on the input score.
  const overallScore = (score / 5) * 100;

  const data = useMemo(() => {
    let strokeColor, bgColor, label;

    if (overallScore === 100) {
      strokeColor = '#52C41A';
      bgColor = 'bg-green-600';
      label = 'Perfect match';
    } else if (overallScore > 60) {
      strokeColor = '#019AA4';
      bgColor = 'bg-cyan-600';
      label = 'Great match';
    } else if (overallScore >= 40) {
      strokeColor = '#4096FF';
      bgColor = 'bg-blue-400';
      label = 'Semi match';
    } else {
      strokeColor = '#8C8C8C';
      bgColor = 'bg-gray-700';
      label = 'Low match';
    }

    return { strokeColor, bgColor, label };
  }, [overallScore]);

  return (
    <div>
      <div className="flex w-full mb-4 items-center gap-3">
        {/* Display a progress bar with the calculated overall score. */}
        <Progress percent={overallScore} strokeColor={data.strokeColor} />
        {/* Display a label indicating the match quality based on the semiMatch condition. */}
        <div
          className={
            'text-xs text-white py-1 px-3 rounded-lg whitespace-nowrap ' +
            data.bgColor
          }
        >
          {data.label}
        </div>
      </div>
    </div>
  );
}

export default ResultIndicator;
