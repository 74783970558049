import { useQuery } from '@apollo/client';
import {
  GET_INNOVATIONS_FOR_ADMIN,
  GET_INTERNAL_CHALLENGE_CREATORS,
  Innovation,
  User,
} from '@frontend/data-access';
import { Select } from 'antd';
import useTranslation from 'next-translate/useTranslation';

import { ChallengeStateType } from '../challenge-list/challenge-list';

export interface ChallengeFilterProps {
  states?: ChallengeStateType;
  tag?: string;
  type: 'OPEN' | 'INTRAPRENEUR';
  onFilter: (filterBy: {
    states?: ChallengeStateType[];
    tag?: string;
    userId?: number;
  }) => void;
  userId?: number;
}

export function ChallengeFilter({
  states,
  onFilter,
  tag,
  type,
  userId,
}: ChallengeFilterProps) {
  const { t } = useTranslation('admin');
  const statusOptions = [
    {
      label: t('intra_challenge_all'),
      value: 'ALL',
    },
    {
      label: t('intra_challenge_open'),
      value: 'ACTIVE',
    },
    {
      label: t('intra_challenge_draft'),
      value: 'DRAFT',
    },
    {
      label: t('intra_challenge_closed'),
      value: 'ENDED',
    },
    {
      label: t('intra_challenge_upcoming'),
      value: 'UPCOMING',
    },
    {
      label: t('intra_challenge_archived'),
      value: 'ARCHIVED',
    },
  ];

  const { data: dataSpaceTag } = useQuery<{
    listInnovationSpace: {
      innovationSpaces: Innovation[];
      total: number;
    };
  }>(GET_INNOVATIONS_FOR_ADMIN, {
    variables: {
      page: 1,
      size: 1000,
    },
  });

  const { data: dataCreators } = useQuery<{
    internalChallengeCreators: User[];
  }>(GET_INTERNAL_CHALLENGE_CREATORS);

  const handleSpaceFilter = (tag: string) => {
    onFilter({ tag });
  };

  const handleStatesFilter = (value: ChallengeStateType | string) => {
    if (value === 'ALL') {
      onFilter({
        states: statusOptions
          .filter((option) => option.value !== 'ALL')
          .map((option) => option.value as ChallengeStateType),
      });
      return;
    }
    onFilter({ states: [value as ChallengeStateType] });
  };

  const handleCreatorFilter = (creatorId: number) => {
    onFilter({ userId: creatorId });
  };

  return (
    <div className="flex items-center">
      <div className="mr-2">{t('intra_challenge_filter')}:</div>
      <div className="!text-gray-700">{t('intra_challenge_status')}:</div>
      <Select
        className="min-w-[168px]"
        value={states}
        defaultValue="ALL"
        bordered={false}
        options={statusOptions}
        onChange={handleStatesFilter}
      />
      {type === 'INTRAPRENEUR' && (
        <>
          <div>{t('Innovation space')}:</div>
          <Select
            data-test="filter-select-tag"
            className="min-w-[150px]"
            defaultValue="ALL"
            bordered={false}
            value={tag}
            options={[
              { label: t('intra_challenge_all'), value: 'ALL' },
              ...(dataSpaceTag?.listInnovationSpace?.innovationSpaces.map(
                (space) => ({
                  key: space.id,
                  value: space.id,
                  label: space.name,
                })
              ) ?? []),
            ]}
            onChange={handleSpaceFilter}
          />
          <div>Creator:</div>
          <Select
            bordered={false}
            className="min-w-[150px]"
            placeholder="Search by creator"
            options={dataCreators?.internalChallengeCreators?.map((u) => ({
              label: u?.fullName,
              value: u?.id,
            }))}
            allowClear
            onChange={handleCreatorFilter}
            defaultValue={userId}
          />
        </>
      )}
    </div>
  );
}

export default ChallengeFilter;
