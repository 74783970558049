import { gql } from '@apollo/client';

// import { ANSWER } from './answer';
import { CHALLENGE_ANNOUNCEMENT } from './challenge-announcement';
import {
  CHALLENGE_APPLICATION,
  CHALLENGE_APPLICATION_BASIC,
  STAGE_APPLICATION,
  TEAM,
} from './challenge-application';
import { COMPANY_BASIC, COMPANY_INFO } from './company';
import { CRITERIA, CRITERIA_RESULT } from './criteria';
import { DISCUSSION } from './discussion';
import { IDEA_BASIC } from './idea';
import { INNOVATION } from './innovation';
import { QUESTION } from './question';
import { REGION } from './region';
import { STAGE, STAGE_BASICS } from './stage';
import { TOPIC } from './topic';
import { FILE } from './upload';
import { RANK, USER_BASICS } from './user';

export const INTRAPRENEUR_CHALLENGE = gql`
  fragment intrapreneurChallenge on InternalChallenge {
    id
    title
    state
    description
    longDescription
    sponsor
    participationType
    banner {
      ...file
    }
    regions {
      ...region
    }
    topics {
      ...topic
    }
    stages {
      ...stage
    }
    questions {
      ...question
    }
    applications {
      ...challengeApplication
    }
    announcements {
      ...announcement
    }
    discussion {
      id
    }
    viewerApplications {
      ...challengeApplication
    }
    criterias {
      ...criteria
    }
    ideas {
      id
    }
    innovationSpaceTag {
      ...innovation
    }
    panelMembers
    applicationId
    registrationStartAt
    registrationEndAt
    registrationEndedAt
    timezone
    discussionWall
    publishAt
    notifyAt
    createdAt
    updatedAt
    pinned
    createdBy {
      fullName
    }
    progress {
      basic
      media
      application
      questionnaire
      timeline
      discussionWall
    }
  }
`;

export const INTRAPRENEUR_CHALLENGE_DETAIL = gql`
  fragment intrapreneurChallengeDetail on InternalChallenge {
    id
    title
    state
    description
    longDescription
    sponsor
    participationType
    banner {
      ...file
    }
    regions {
      ...region
    }
    topics {
      ...topic
    }
    questions {
      ...question
    }
    ideas {
      id
    }
    panelMembers
    applicationId
    registrationStartAt
    registrationEndAt
    registrationEndedAt
    timezone
    discussionWall
    publishAt
    notifyAt
    createdAt
    updatedAt
    pinned
    createdBy {
      ...userBasics
    }
    progress {
      basic
      media
      application
      questionnaire
      timeline
      discussionWall
    }
    discussion {
      id
    }
    innovationSpaceTag {
      ...innovation
    }
  }
`;

export const INTRAPRENEUR_CHALLENGE_BASIC = gql`
  fragment intrapreneurChallengeBasic on InternalChallenge {
    id
    title
    state
    description
    longDescription
    participationType
    discussionWall
    sponsor
    banner {
      ...file
    }
    regions {
      ...region
    }
    topics {
      ...topic
    }
    panelMembers
    registrationStartAt
    registrationEndAt
    registrationEndedAt
    timezone
    createdAt
    updatedAt
    pinned
    createdBy {
      ...userBasics
    }
    innovationSpaceTag {
      ...innovation
    }
  }
`;

export const INTERNAL_CHALLENGE_STATISTICS = gql`
  fragment internalChallengesCount on ChallengesCount {
    active
    ended
    upcoming
    draft
  }
`;

export const GET_INTRAPRENEUR_CHALLENGES = gql`
  query IntrapreneurChallenges(
    $page: Int
    $limit: Int
    $sort: InternalChallengeSortOption
    $filterBy: InternalChallengesFilters
  ) {
    internalChallenges(
      page: $page
      limit: $limit
      sort: $sort
      filterBy: $filterBy
    ) {
      ...intrapreneurChallengeBasic
    }
  }
  ${INTRAPRENEUR_CHALLENGE_BASIC}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${INNOVATION}
  ${USER_BASICS}
`;

export const GET_INTRAPRENEUR_CHALLENGES_MINE = gql`
  query IntrapreneurChallengesMine(
    $page: Int
    $limit: Int
    $sort: InternalChallengeSortOption
    $filterBy: InternalChallengesFilters
  ) {
    internalChallengesMine(
      page: $page
      limit: $limit
      sort: $sort
      filterBy: $filterBy
    ) {
      ...intrapreneurChallengeBasic
    }
  }
  ${INTRAPRENEUR_CHALLENGE_BASIC}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${INNOVATION}
  ${USER_BASICS}
`;

export const GET_INTRAPRENEUR_CHALLENGES_FOR_USER = gql`
  query InternalChallengesForUser(
    $first: Int
    $after: String
    $filterBy: InternalChallengesForUserFilters
    $spaceId: [String]
    $forYou: Boolean!
  ) {
    internalChallengesForUser(
      first: $first
      after: $after
      filterBy: $filterBy
      spaceId: $spaceId
      forYou: $forYou
    ) {
      edges {
        cursor
        node {
          id
          title
          state
          description
          participationType
          sponsor
          banner {
            ...file
          }
          regions {
            ...region
          }
          topics {
            ...topic
          }
          timezone
          registrationStartAt
          registrationEndAt
          registrationEndedAt
          createdBy {
            ...userBasics
          }
          pinned
          innovationSpaceTag {
            ...innovation
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FILE}
  ${INNOVATION}
  ${REGION}
  ${TOPIC}
  ${USER_BASICS}
`;

export const GET_INTRAPRENEUR_CHALLENGES_AGGREGATE = gql`
  query IntrapreneurChallengesAggregate($filterBy: InternalChallengesFilters) {
    internalChallengesAggregate(filterBy: $filterBy) {
      totalCount
    }
  }
`;

export const GET_INTRAPRENEUR_CHALLENGES_AGGREGATE_MINE = gql`
  query IntrapreneurChallengesAggregateMine(
    $filterBy: InternalChallengesFilters
  ) {
    internalChallengesAggregateMine(filterBy: $filterBy) {
      totalCount
    }
  }
`;

export const GET_INTRAPRENEUR_CHALLENGE = gql`
  query IntrapreneurChallenge($challengeId: ID!) {
    internalChallenge(challengeId: $challengeId) {
      ...intrapreneurChallenge
    }
  }
  ${INTRAPRENEUR_CHALLENGE}
  ${CRITERIA}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${INNOVATION}
  ${QUESTION}
  ${CHALLENGE_APPLICATION}
  ${TEAM}
  ${USER_BASICS}
  ${CHALLENGE_ANNOUNCEMENT}
  ${STAGE}
  ${STAGE_APPLICATION}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${CRITERIA_RESULT}
  ${CRITERIA}
`;

export const GET_INTRAPRENEUR_CHALLENGE_CRITERIA = gql`
  query IntrapreneurChallenge($challengeId: ID!) {
    internalChallenge(challengeId: $challengeId) {
      id
      state
      innovationSpaceTag {
        id
        type
      }
      criterias {
        ...criteria
      }
    }
  }
  ${CRITERIA}
  ${USER_BASICS}
  ${FILE}
`;

export const GET_INTRAPRENEUR_CHALLENGE_WITH_IDEAS = gql`
  query IntrapreneurChallenge($challengeId: ID!) {
    internalChallenge(challengeId: $challengeId) {
      id
      title
      registrationStartAt
      registrationEndAt
      stages {
        ...stageBasics
        ideas {
          ...ideaBasic
          businessAreas
        }
      }
      criterias {
        ...criteria
      }
      experts {
        ...userBasics
      }
    }
  }
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${USER_BASICS}
  ${STAGE_BASICS}
  ${IDEA_BASIC}
  ${INNOVATION}
  ${RANK}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const GET_INTRAPRENEUR_CHALLENGE_DETAILS = gql`
  query IntrapreneurChallenge($challengeId: ID!) {
    internalChallenge(challengeId: $challengeId) {
      ...intrapreneurChallengeDetail
    }
  }
  ${INTRAPRENEUR_CHALLENGE_DETAIL}
  ${INNOVATION}
  ${REGION}
  ${TOPIC}
  ${QUESTION}
  ${FILE}
  ${USER_BASICS}
`;

export const GET_INTRAPRENEUR_CHALLENGE_STAGES = gql`
  query IntrapreneurChallenge($challengeId: ID!) {
    internalChallenge(challengeId: $challengeId) {
      id
      stages {
        ...stage
      }
    }
  }
  ${STAGE}
  ${STAGE_APPLICATION}
  ${CHALLENGE_APPLICATION}
  ${USER_BASICS}
  ${FILE}
  ${TEAM}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const GET_INTRAPRENEUR_CHALLENGE_DISCUSSION = gql`
  query IntrapreneurChallenge($challengeId: ID!) {
    internalChallenge(challengeId: $challengeId) {
      id
      discussion {
        ...discussion
      }
    }
  }
  ${DISCUSSION}
`;

export const GET_INTRAPRENEUR_CHALLENGE_ANNOUNCEMENT = gql`
  query IntrapreneurChallenge($challengeId: ID!) {
    internalChallenge(challengeId: $challengeId) {
      id
      announcements {
        ...announcement
      }
    }
  }
  ${CHALLENGE_ANNOUNCEMENT}
`;

export const GET_INTRAPRENEUR_CHALLENGE_APPLICATIONS = gql`
  query IntrapreneurChallenge($challengeId: ID!) {
    internalChallenge(challengeId: $challengeId) {
      applications {
        ...challengeApplication
      }
    }
  }
  ${CHALLENGE_APPLICATION}
  ${TEAM}
  ${FILE}
  ${USER_BASICS}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
`;

export const GET_INTRAPRENEUR_CHALLENGE_VIEWER_APPLICATIONS = gql`
  query IntrapreneurChallenge($challengeId: ID!) {
    internalChallenge(challengeId: $challengeId) {
      id
      title
      registrationStartAt
      registrationEndAt
      registrationEndedAt
      state
      stages {
        id
        name
        orderNumber
        state
      }
      viewerApplications {
        ...challengeApplicationBasic
      }
    }
  }
  ${CHALLENGE_APPLICATION_BASIC}
  ${TEAM}
  ${FILE}
  ${USER_BASICS}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const GET_INTRAPRENEUR_CHALLENGE_FOR_MANAGEMENT = gql`
  query IntrapreneurChallenge($challengeId: ID!) {
    internalChallenge(challengeId: $challengeId) {
      id
      title
      registrationStartAt
      registrationEndAt
      registrationEndedAt
      state
      applications {
        ...challengeApplication
      }
      viewerApplications {
        ...challengeApplication
      }
      stages {
        id
        name
        orderNumber
        state
      }
    }
  }
  ${CHALLENGE_APPLICATION}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
`;

export const GET_INTRAPRENEUR_CHALLENGE_BASICS = gql`
  query IntrapreneurChallenge($challengeId: ID!) {
    internalChallenge(challengeId: $challengeId) {
      id
      title
      state
      description
      longDescription
      participationType
      questions {
        ...question
      }
    }
  }
  ${QUESTION}
`;

export const GET_FEATURED_INTRAPRENEUR_CHALLENGE = gql`
  query GetFeaturedInternalChallenge($spaceTagId: UUID) {
    getFeaturedInternalChallenge(spaceTagId: $spaceTagId) {
      ...intrapreneurChallengeBasic
    }
  }
  ${INTRAPRENEUR_CHALLENGE_BASIC}
  ${FILE}
  ${REGION}
  ${INNOVATION}
  ${TOPIC}
  ${USER_BASICS}
`;

export const GET_INTERNAL_CHALLENGE_STATISTICS = gql`
  query InternalChallengesCount {
    internalChallengesCount {
      ...internalChallengesCount
    }
  }
  ${INTERNAL_CHALLENGE_STATISTICS}
`;

export const GET_INTERNAL_CHALLENGE_STATISTICS_MINE = gql`
  query InternalChallengesCountMine {
    internalChallengesCountMine {
      ...internalChallengesCount
    }
  }
  ${INTERNAL_CHALLENGE_STATISTICS}
`;

export const GET_CHALLENGES_SUGGESTION = gql`
  query InternalChallengeSuggestion {
    internalChallengeSuggestion {
      id
      title
      state
      description
      sponsor
      banner {
        ...file
      }
      participationType
      regions {
        ...region
      }
      topics {
        ...topic
      }
      registrationStartAt
      registrationEndAt
    }
  }
  ${FILE}
  ${REGION}
  ${TOPIC}
`;

export const GET_USER_APPLIED_CHALLENGES = gql`
  query UserAppliedChallenge($userId: Int!, $first: Int, $after: String) {
    userAppliedChallenge(userId: $userId, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          title
          state
          description
          participationType
          sponsor
          banner {
            ...file
          }
          regions {
            ...region
          }
          topics {
            ...topic
          }
          timezone
          registrationStartAt
          registrationEndAt
          registrationEndedAt
          createdBy {
            ...userBasics
          }
          pinned
          innovationSpaceTag {
            ...innovation
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FILE}
  ${INNOVATION}
  ${REGION}
  ${TOPIC}
  ${USER_BASICS}
`;

export const GET_INTRAPRENEUR_CHALLENGES_BASIC = gql`
  query IntrapreneurChallenges(
    $page: Int
    $limit: Int
    $sort: InternalChallengeSortOption
    $filterBy: InternalChallengesFilters
  ) {
    internalChallenges(
      page: $page
      limit: $limit
      sort: $sort
      filterBy: $filterBy
    ) {
      id
      title
    }
  }
`;

export const GET_ASSIGNED_EVALUATION_CHALLENGES = gql`
  query IntrapreneurChallenges($page: Int, $limit: Int) {
    assignedEvaluationChallenges(page: $page, limit: $limit) {
      items {
        id
        title
        ideas {
          id
          name
          problem
          video
          documents {
            id
          }
          createdAt
        }
      }
      total
    }
  }
`;

export const DOWNLOAD_CHALLENGE = gql`
  query DownloadChallengeList {
    downloadChallengeList
  }
`;

export const DOWNLOAD_OPEN_CHALLENGE = gql`
  query DownloadOpenChallengeList {
    downloadOpenChallengeList
  }
`;

export const GET_INTERNAL_CHALLENGE_CREATORS = gql`
  query InternalChallengeCreators {
    internalChallengeCreators {
      id
      fullName
    }
  }
`;

export const GET_FEATURED_CHALLENGES = gql`
  query ListFeaturedChallenges($input: ListFeaturedChallengesInput) {
    listFeaturedChallenges(input: $input) {
      id
      type
      challenge {
        id
        title
        sponsor
        banner {
          ...file
        }
        createdBy {
          ...userBasics
        }
        registrationEndAt
        timezone
        createdAt
      }
      createdAt
    }
  }
  ${FILE}
  ${USER_BASICS}
`;

export const FEATURE_SEARCH_CHALLENGE = gql`
  query SearchChallengeToFeature($input: ChallengeSearchInput) {
    searchChallengeToFeature(input: $input) {
      id
      title
      banner {
        ...file
      }
      createdBy {
        ...userBasics
      }
      createdAt
    }
  }
  ${FILE}
  ${USER_BASICS}
`;

export const CREATE_INTRAPRENEUR_CHALLENGE = gql`
  mutation CreateIntrapreneurChallenge($input: CreateInternalChallengeInput) {
    createInternalChallenge(input: $input) {
      ...intrapreneurChallengeBasic
    }
  }
  ${INTRAPRENEUR_CHALLENGE_BASIC}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${INNOVATION}
  ${USER_BASICS}
`;

export const PATCH_INTRAPRENEUR_CHALLENGE = gql`
  mutation PatchIntrapreneurChallenge($input: PatchInternalChallengeInput) {
    patchInternalChallenge(input: $input) {
      ...intrapreneurChallengeBasic
    }
  }
  ${INTRAPRENEUR_CHALLENGE_BASIC}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${INNOVATION}
  ${USER_BASICS}
`;

export const PUBLISH_CHALLENGE = gql`
  mutation PublishChallenge($input: PublishChallengeInput) {
    publishChallenge(input: $input) {
      id
      publishAt
    }
  }
`;

export const START_CHALLENGE_REGISTRATION = gql`
  mutation StartChallengeRegistration($challengeId: ID!) {
    startChallengeRegistration(challengeId: $challengeId) {
      id
      state
    }
  }
`;

export const END_CHALLENGE_REGISTRATION = gql`
  mutation EndChallengeRegistration($challengeId: ID!) {
    endChallengeRegistration(challengeId: $challengeId) {
      id
      state
    }
  }
`;

export const PIN_INTRAPRENEUR_CHALLENGE = gql`
  mutation pinInternalChallenge($challengeId: ID!) {
    pinInternalChallenge(challengeId: $challengeId) {
      challengeId
      featured
    }
  }
`;

export const UNPIN_INTRAPRENEUR_CHALLENGE = gql`
  mutation UnpinInternalChallenge($challengeId: ID!) {
    unpinInternalChallenge(challengeId: $challengeId)
  }
`;

export const ARCHIVE_CHALLENGE = gql`
  mutation ArchiveChallenge($challengeId: UUID!) {
    archiveChallenge(challengeId: $challengeId) {
      id
      title
      state
    }
  }
`;

export const RESTORE_CHALLENGE = gql`
  mutation RestoreChallenge($challengeId: UUID!) {
    restoreChallenge(challengeId: $challengeId) {
      id
      title
      state
    }
  }
`;

export const PATCH_CHALLENGE_CRITERIAS = gql`
  mutation PatchChallengeCriterias(
    $challengeId: UUID!
    $inputs: [CriteriaPatchInput]!
  ) {
    patchChallengeCriterias(challengeId: $challengeId, inputs: $inputs) {
      ...criteria
    }
  }
  ${CRITERIA}
  ${FILE}
  ${USER_BASICS}
`;

export const CREATE_FEATURED_CHALLENGES = gql`
  mutation CreateFeaturedChallenges($input: CreateFeaturedChallenge) {
    createFeaturedChallenges(input: $input) {
      id
      challenge {
        id
        title
        banner {
          ...file
        }
        createdBy {
          ...userBasics
        }
        createdAt
      }
      createdAt
    }
  }
  ${FILE}
  ${USER_BASICS}
`;
