import { gql } from '@apollo/client';

export const GET_AUDIT_LOGGED_USERS = gql`
  query AuditLoggedUsers {
    AuditLoggedUsers {
      userWithIdAndFullName {
        id
        name
      }
      count
    }
  }
`;

export const GET_AUDIT_LOG_LIST_SEARCH = gql`
  query AuditLogListSearch(
    $startDate: Date
    $endDate: Date
    $entityName: String
    $entityId: String
    $columnName: String
    $modifiedBy: Int
    $oldValue: String
    $newValue: String
    $ipAddress: String
    $name: String
    $page: Int
    $limit: Int
  ) {
    AuditLogListSearch(
      startDate: $startDate
      endDate: $endDate
      entityName: $entityName
      oldValue: $oldValue
      entityId: $entityId
      columnName: $columnName
      newValue: $newValue
      modifiedBy: $modifiedBy
      ipAddress: $ipAddress
      name: $name
      page: $page
      limit: $limit
    ) {
      items {
        id
        entityName
        entityId
        columnName
        oldValue
        newValue
        modifiedAt
        ipAddress
        name
        modifiedBy {
          id
          fullName
        }
        type
      }
      total
    }
  }
`;

export interface AuditLog {
  id: string;
  entityName: string;
  entityId: string;
  columnName: string;
  oldValue: string;
  newValue: string;
  modifiedAt: string;
  name: string;
  ipAddress: string;
  modifiedBy: {
    id: number;
    fullName: string;
  };
  type: string;
}
