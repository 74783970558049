import { useApolloClient, useQuery } from '@apollo/client';
import { GET_ME, Me, signOut } from '@frontend/data-access';
import { useRouter } from 'next/router';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

export function AuthHandler({ children }: { children: ReactNode }) {
  const client = useApolloClient();
  const router = useRouter();
  const [user, setUser] = useState<Me>();

  const { data } = useQuery<{
    me: Me;
  }>(GET_ME);

  useEffect(() => {
    setUser(data?.me);
  }, [data?.me]);

  const eventsListen = useMemo(
    () => [
      'click',
      'keydown',
      'scroll',
      'resize',
      'mousemove',
      'TabSelect',
      'TabHide',
    ],
    []
  );

  const logout = useCallback(async () => {
    try {
      const response = await signOut();
      if (response) {
        await client.clearStore();
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, router]);

  const getLastAction = () => {
    if (localStorage.getItem('lastAction') !== null) {
      return parseInt(localStorage.getItem('lastAction') || '0', 10);
    }

    return 0;
  };

  const setLastAction = useCallback((lastAction: number) => {
    localStorage.setItem('lastAction', lastAction.toString());
  }, []);

  const reset = useCallback(() => {
    setLastAction(Date.now());
  }, [setLastAction]);

  const check = useCallback(() => {
    const now = Date.now();
    const timeleft =
      getLastAction() +
      parseFloat(process.env.NEXT_PUBLIC_MINUTES_UNTIL_LOGOUT || '30') *
      60 *
      1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout && user) {
      logout();
    }
  }, [logout, user]);

  const initListener = useCallback(() => {
    reset();

    for (const type of eventsListen) {
      document.body.addEventListener(type, reset);
    }
  }, [reset, eventsListen]);

  useEffect(() => {
    initListener();

    let timer: NodeJS.Timeout;

    if (user) {
      timer = setInterval(() => {
        check();
      }, parseFloat(process.env.NX_SESSIONS_CHECK_INTERVAL || '15000'));
    }

    return () => {
      for (const type of eventsListen) {
        document.body.removeEventListener(type, reset);
      }

      clearTimeout(timer);
    };
  }, [check, initListener, eventsListen, reset, user]);

  useEffect(() => {
    if (router.pathname === '/sso-success') {
      try {
        const params = new URLSearchParams(router.asPath.split('?')[1]);

        window.opener.handleSsoSuccess({ referrer: params.get('referrer') });
        window.close();
      } catch (error) {
        router.push('/innovation');
      }
    }
  });

  return <div>{children}</div>;
}

export default AuthHandler;
