import { isJsonString } from '@frontend/shared/util';
import escapeHtml from 'escape-html';
import { Node, Text } from 'slate';

export interface RichEditorSerializedProps {
  value?: string;
}

const serializeOnlyText = (node: any): string => {
  if (Text.isText(node)) {
    const string = escapeHtml(node.text);
    return string;
  }
  const children: string = node.children
    .map((n: Node) => serializeOnlyText(n))
    .join('');
  return children;
};

export function IsEmptyRichText(value: string): boolean {
  if (value.trim() === '') return true;
  if (value && isJsonString(value)) {
    if (
      serializeOnlyText({ children: JSON.parse(value) as Node }).trim() === ''
    ) {
      return true;
    }
  }
  return false;
}

export function RichEditorSerialized({ value }: RichEditorSerializedProps) {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const serialize = (node: any): string => {
    if (Text.isText(node)) {
      let string = escapeHtml(node.text);
      if (node.bold) {
        string = `<strong>${string}</strong>`;
      }
      if (node.underline) {
        string = `<span style="text-decoration: underline"">${string}</span>`;
      }
      if (node.italic) {
        string = `<span style="font-style: italic">${string}</span>`;
      }
      return string;
    }

    const children: string = node.children
      .map((n: Node) => serialize(n))
      .join('');

    switch (node.type) {
      case 'block-quote':
        return `<blockquote style="text-align: ${node.align};"><p>${children}</p></blockquote>`;
      case 'paragraph':
        return `<p style="text-align: ${node.align};">${children}</p>`;
      case 'bulleted-list':
        return `<ul style={style} {...attributes}>
            ${children}
          </ul>`;
      case 'numbered-list':
        return `<ol>
            ${children}
          </ol>`;
      case 'heading-one':
        return `<h1 style="text-align: ${node.align};">
            ${children}
          </h1>`;
      case 'heading-two':
        return `<h2 style="text-align: ${node.align};">
            ${children}
          </h2>`;
      case 'link':
        return `<a style="text-align: ${node.align};" href="${escapeHtml(
          node.url
        )}">${children}</a>`;
      case 'list-item':
        return `<li style="text-align: ${node.align}">
            ${children}
          </li>`;
      default:
        return children;
    }
  };

  if (value && isJsonString(value)) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: serialize({
            children: JSON.parse(value) as Node,
          }),
        }}
      ></div>
    );
  }

  return <div>{value}</div>;
}

export default RichEditorSerialized;
