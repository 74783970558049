import { Challenge } from './challenge';
import { UploadedFile } from './common';
import { Country } from './country';
import { Criteria, CriteriaResult } from './criteria';
import { Discussion } from './discussion';
import { Innovation } from './innovation';
import { Region } from './region';
import { Stage } from './stage';
import { Topic } from './topic';
import { User } from './user';

export type IdeaGenerationState =
  | 'IDEATION_STAGE'
  | 'IN_BOOTCAMP'
  | 'PANEL_PITCH'
  | 'IDEA_VALIDATION'
  | 'MVP_DEVELOPMENT'
  | 'LIVE_PRODUCT';

export type IdeaStatus = 'PUBLISHED' | 'HIDDEN' | 'REPORTED';

export type IdeaGenerationType = 'AI' | 'SCRATCH';

export type IdeaCollaborationStatus = 'PENDING' | 'APPROVED' | 'REJECTED';

export type IdeaReportStatus =
  | 'NUDITY'
  | 'HATE_SPEECH'
  | 'HARASSMENT'
  | 'OTHER';

export enum IdeaFilterStatusType {
  RANDOM = 'RANDOM',
  TRENDING = 'TRENDING',
  LATEST = 'LATEST',
  PINNED = 'PINNED',
  REPORTED = 'REPORTED',
  COLLABORATIVE = 'COLLABORATIVE',
  CHALLENGE_APPLIED = 'CHALLENGE_APPLIED',
}

export interface IdeaContribute {
  id: string;
  ideaId: string;
  title: string;
  body: string;
  likes: number;
  isLiked: boolean;
  photo: UploadedFile;
  coverPhotoUrl: IdeaCoverPhotoUrl;
  createdBy: User;
  updatedAt: string;
  createdAt: string;
}

export interface IdeaReports {
  id: string;
  ideaId: string;
  description: string;
  reportStatus: IdeaReportStatus[];
  createdBy: User;
  updatedAt: Date;
  createdAt: Date;
}

export interface IdeaCoverPhotoUrl {
  author: string;
  profileUrl: string;
  imageUrl: string;
}

export interface IdeaCollaboration {
  id: string;
  ideaId: string;
  userId: number;
  user: User;
  status: IdeaCollaborationStatus;
  type: 'JOIN' | 'INVITE';
}

export interface Idea {
  id: string;
  name: string;
  problem: string;
  coverPhoto: UploadedFile;
  coverPhotoUrl: IdeaCoverPhotoUrl;
  state: IdeaGenerationState;
  status: IdeaStatus;
  ideaType: IdeaGenerationType;
  note: string;
  anonymous: boolean;
  reportedCount: number;
  regContribute: boolean;
  contributeCount: number;
  pinned: boolean;
  contributions: IdeaContribute[];
  ideaReports: IdeaReports[];
  regions: Region[];
  discussion: Discussion;
  internalDiscussion: Discussion;
  likes: number;
  isLiked: boolean;
  isReported: boolean;
  comments: number;
  internalComments: number;
  countries: Country[];
  createdBy: User;
  businessAreas: string[];
  createdAt: string;
  updatedAt: string;
  reason: string;
  topics: Topic[];
  video?: string;
  documents?: UploadedFile[];
  visible?: boolean;
  challenge?: Challenge;
  currentStage?: Stage;
  applicants: IdeaCollaboration[];
  collaborative: boolean;
  criteria: Criteria[];
  criteriaResults: CriteriaResult[];
  innovationSpaceTag?: Innovation;
}

export interface CreateIdea {
  createIdea: Idea;
}

export interface PatchIdea {
  patchIdea: Idea;
}

export interface IdeaBadgeWithRole {
  id: string;
  point: number;
  comment: string;
  interval: number;
  userType: string;
  goal: number;
  done: boolean;
}
