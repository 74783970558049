import {
  ArrowLeftOutlined,
  CaretUpOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import {
  ApolloCache,
  NormalizedCacheObject,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  GET_IDEA,
  GET_ME,
  Idea,
  IDEA_LIKE,
  IDEA_UNLIKE,
  Me,
  TEAM_INVITE,
} from '@frontend/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DiscussionWall } from '@frontend/feature-discussion';
import { formatDate } from '@frontend/shared/util';
import {
  Button,
  Col,
  Dropdown,
  Empty,
  Image,
  Modal,
  notification,
  Row,
  Spin,
  Tabs,
  Typography,
} from 'antd';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

import EvaluateScore from '../evaluate-score/evaluate-score';
import IdeaContribute from '../idea-contribute/idea-contribute';
import IdeaContributionList from '../idea-contribution-list/idea-contribution-list';
import IdeaDetailBasics from '../idea-detail-basics/idea-detail-basics';
import IdeaEvaluationResultTag from '../idea-evaluation-result-tag/idea-evaluation-result-tag';
import IdeaReport from '../idea-report/idea-report';
import UnsplashImage from '../unsplash-image/unsplash-image';
import IdeaDetailTeamInvite from './invite-alert';

type Props = {
  ideaId?: string;
  mode?: 'standart' | 'modal';
};

export function IdeaDetail({ ideaId, mode = 'standart' }: Props) {
  const { t: tcommon } = useTranslation('common');
  const router = useRouter();
  const id = ideaId ?? (router.query['id'] as string);
  const [contributeOpen, setContributeOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);
  const [getIdea, { data, loading }] = useLazyQuery<{ idea: Idea }>(GET_IDEA);
  const [inviteTeamMember, { loading: loadingInvite }] = useMutation(
    TEAM_INVITE,
    {
      onCompleted: () => {
        notification.success({
          message: 'Join request sent',
          placement: 'bottomLeft',
        });
      },
    }
  );

  const invites =
    data?.idea?.applicants.filter((a) => a.userId === meData?.me.id) || [];

  const handleUpdateCacheLike = (cache: ApolloCache<NormalizedCacheObject>) => {
    const cacheData: { idea: Idea } | null = cache.readQuery({
      query: GET_IDEA,
      variables: {
        ideaId: id,
      },
    });

    if (cacheData?.idea) {
      cache.writeQuery({
        query: GET_IDEA,
        variables: {
          ideaId: id,
        },
        data: {
          idea: {
            ...cacheData?.idea,
            isLiked: true,
            likes: cacheData?.idea.likes + 1,
          },
        },
      });
    }
  };
  const handleUpdateCacheUnLike = (
    cache: ApolloCache<NormalizedCacheObject>
  ) => {
    const cacheData: { idea: Idea } | null = cache.readQuery({
      query: GET_IDEA,
      variables: {
        ideaId: id,
      },
    });

    if (cacheData?.idea) {
      cache.writeQuery({
        query: GET_IDEA,
        variables: {
          ideaId: id,
        },
        data: {
          idea: {
            ...cacheData?.idea,
            isLiked: false,
            likes: cacheData?.idea.likes - 1,
          },
        },
      });
    }
  };

  const handleReport = () => {
    setReportOpen(!reportOpen);
  };

  const handleContribute = () => {
    setContributeOpen(!contributeOpen);
  };

  const [likeIdea] = useMutation(IDEA_LIKE, {
    update: handleUpdateCacheLike,
  });
  const [unlikeIdea] = useMutation(IDEA_UNLIKE, {
    update: handleUpdateCacheUnLike,
  });

  const onClickJoin = async () => {
    await inviteTeamMember({
      variables: {
        ideaId: data?.idea.id,
        userId: meData?.me.id,
        type: 'JOIN',
      },
    });
  };

  useEffect(() => {
    if (id) {
      getIdea({ variables: { ideaId: id } });
    }
  }, [getIdea, id]);

  if (loading)
    return (
      <div className="text-center">
        <Spin />
      </div>
    );

  const handleLike = () => {
    if (data?.idea.isLiked) {
      unlikeIdea({
        variables: {
          id: id,
        },
      });
    } else {
      likeIdea({
        variables: {
          id: id,
        },
      });
    }
  };

  const items = [];

  if (
    meData?.me?.role &&
    data?.idea?.challenge &&
    (['ADMIN', 'CHALLENGE_MANAGER'].includes(meData?.me?.role) ||
      data?.idea?.challenge?.experts?.filter((ex) => ex.id === meData?.me?.id)
        .length)
  ) {
    if (data?.idea?.internalDiscussion?.id) {
      items.push({
        key: '0',
        label:
          data?.idea?.internalComments === 0
            ? tcommon('idea_detail_evaluation_comments')
            : `${tcommon('idea_detail_evaluation_comments')} (${
                data?.idea.internalComments
              })`,
        children: (
          <>
            <div className="text-xs">
              {tcommon('idea_detail_evaluation_comment_description')}
            </div>
            <DiscussionWall id={data?.idea?.internalDiscussion?.id} title=" " />
          </>
        ),
      });
    }
  }
  if (data?.idea?.discussion?.id) {
    items.push({
      key: '1',
      label:
        data?.idea.comments === 0
          ? tcommon('idea_detail_comments')
          : `${tcommon('idea_detail_comments')} (${data?.idea.comments})`,
      children: <DiscussionWall id={data?.idea?.discussion?.id} title=" " />,
    });
  }
  if (data?.idea?.regContribute) {
    items.push({
      key: '2',
      label:
        data?.idea.contributeCount === 0
          ? tcommon('idea_detail_contributions')
          : `${tcommon('idea_detail_contributions')} (${
              data?.idea.contributeCount
            })`,
      children: (
        <IdeaContributionList
          title=" "
          ideaId={data?.idea.id}
          contributionCount={data?.idea.contributeCount}
        />
      ),
    });
  }

  if (!data?.idea) {
    return (
      <div className="text-center p-5">
        <Empty description="Idea not found" />
      </div>
    );
  }

  return (
    <div className="relative space-y-4">
      {mode === 'standart' && (
        <Row className="mb-2">
          <Button type="link" onClick={() => router.back()} className="!p-0">
            <ArrowLeftOutlined />
            {tcommon('back')}
          </Button>
        </Row>
      )}
      <IdeaDetailTeamInvite idea={data?.idea} />
      <Row gutter={[16, 16]}>
        <Col span={24} md={14}>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            <div>
              <div className="font-semibold">{tcommon('idea_detail_date')}</div>
              <div className="text-gray-700 text-sm">
                {formatDate(data?.idea?.createdAt, 'do LLL yyyy, HH:mm')}
              </div>
            </div>
            {meData?.me?.role !== undefined &&
            (['ADMIN', 'CHALLENGE_MANAGER'].includes(meData?.me?.role) ||
              meData?.me?.id === data?.idea?.createdBy?.id ||
              (data?.idea?.challenge !== undefined &&
                data?.idea?.challenge?.experts?.filter(
                  (ex) => ex.id === meData?.me?.id
                ).length)) &&
            data?.idea.criteriaResults.length > 0 ? (
              <div>
                <div className="font-semibold">
                  {tcommon('idea_detail_average_expert_score')}
                </div>
                <div className="text-gray-700 text-sm">
                  <IdeaEvaluationResultTag ideaId={id} />
                </div>
              </div>
            ) : null}
          </div>
        </Col>
        <Col span={24} md={10}>
          <div className="flex items-center md:justify-end gap-6">
            <div className="flex justify-between items-center gap-3 flex-wrap">
              {data?.idea.regContribute && (
                <Button
                  shape="round"
                  type="primary"
                  onClick={() => handleContribute()}
                >
                  {tcommon('idea_detail_contribute')}
                </Button>
              )}
              {invites?.length === 0 && data?.idea.collaborative && (
                <Button
                  shape="round"
                  onClick={onClickJoin}
                  loading={loadingInvite}
                >
                  {tcommon('idea_detail_join_team')}
                </Button>
              )}
              <div
                onClick={handleLike}
                className={`px-2 py-1 rounded-full border border-gray-400 flex flex-col gap-0 items-center justify-center select-none cursor-pointer ${
                  data?.idea.isLiked
                    ? 'bg-primary-600 text-white'
                    : 'hover:border-primary-600 hover:text-primary-600'
                }`}
              >
                <CaretUpOutlined />
                <span className="text-xs">{data?.idea.likes}</span>
              </div>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: '1',
                      disabled: data?.idea.isReported,
                      label: (
                        <span
                          onClick={() => {
                            !data?.idea.isReported && handleReport();
                          }}
                        >
                          {tcommon('idea_detail_report')}
                        </span>
                      ),
                    },
                  ],
                }}
                placement="bottomRight"
              >
                <Button shape="circle" icon={<EllipsisOutlined />} />
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24} md={14}>
          <div className="space-y-6">
            <div className="border p-3 rounded-xl border-gray-400 overflow-hidden">
              <div className="flex justify-center">
                {data?.idea?.coverPhoto && (
                  <div className="md:min-w-[218px] max-w-[218px] mr-3">
                    <div className="flex flex-col">
                      <Image
                        className="rounded-xl overflow-hidden"
                        alt={data?.idea.name}
                        width={218}
                        height={218}
                        preview={false}
                        src={data?.idea?.coverPhoto.url}
                      />
                    </div>
                  </div>
                )}
                {data?.idea?.coverPhotoUrl && (
                  <UnsplashImage
                    image={data?.idea.coverPhotoUrl}
                    name={data?.idea.name}
                  />
                )}
              </div>
              <div className="contents text-sm">
                <Typography.Title level={4} data-test="idea-title">
                  {data?.idea.name}
                </Typography.Title>
                <p
                  className="text-wrap break-words whitespace-break-spaces"
                  data-test="idea-problem"
                >
                  {data?.idea.problem}
                </p>
              </div>
            </div>
          </div>
          {mode === 'modal' && data && (
            <EvaluateScore type="IDEA" ideaId={id} />
          )}
          <Tabs items={items} />
        </Col>
        <Col span={24} md={10}>
          <IdeaDetailBasics data={data?.idea} />
        </Col>
      </Row>
      <Modal
        title={tcommon('idea_detail_contribute_title')}
        open={contributeOpen}
        bodyStyle={{ padding: 0 }}
        onCancel={() => handleContribute()}
        footer={null}
      >
        <IdeaContribute data={data?.idea} onCancel={() => handleContribute()} />
      </Modal>
      <Modal
        title={tcommon('idea_detail_report_title')}
        open={reportOpen}
        bodyStyle={{ padding: 0 }}
        onCancel={() => handleReport()}
        footer={null}
      >
        <IdeaReport data={data?.idea} onCancel={() => handleReport()} />
      </Modal>
    </div>
  );
}

export default IdeaDetail;
