import {
  DeleteFilled,
  LinkedinFilled,
  PlusOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Company,
  CREATE_KEY_PERSON,
  DELETE_KEY_PERSON,
  ErrorResponse,
  GET_COMPANY,
  KeyPerson,
  LIST_KEY_PERSON,
  UPDATE_KEY_PERSON,
} from '@frontend/data-access';
import { Button, Form, Input, Modal, notification, Spin } from 'antd';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { IsEmptyRichText } from '../../../rich-editor-serialized/rich-editor-serialized';

interface FormValues {
  description: string;
}

const formFieldRules = {
  name: [
    { required: true, message: '' },
    () => ({
      validator(_: unknown, value: string) {
        if (value) {
          if (!IsEmptyRichText(value)) return Promise.resolve();
        }
        return Promise.reject(new Error('Name is required'));
      },
    }),
  ],
  role: [
    { required: true, message: '' },
    () => ({
      validator(_: unknown, value: string) {
        if (value) {
          if (!IsEmptyRichText(value)) return Promise.resolve();
        }
        return Promise.reject(new Error('Role is required'));
      },
    }),
  ],
};

function EditKeyPersonForm({
  companyId,
}: {
  companyId: number;
  onFinish: () => void;
  onCancel: () => void;
}) {
  const { data, loading } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: companyId },
  });
  const [isIncomplete, setIsIncomplete] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [updatingKeyPerson, setUpdatingKeyPerson] = useState<KeyPerson | null>(
    null
  );
  const [form] = Form.useForm();

  const { data: dataKeyPerson } = useQuery<{
    listKeyPerson: KeyPerson[];
  }>(LIST_KEY_PERSON, {
    variables: {
      companyId: {
        companyId: companyId,
      },
    },
  });

  const handleCompleted = (message: string) => {
    notification.success({
      message: message,
      placement: 'bottomLeft',
    });
    setUpdatingKeyPerson(null);
    setAddModalVisible(false);
    form.resetFields();
  };

  const handleError = (data: ErrorResponse) => {
    notification.error({
      message: 'Error',
      description: data.message,
      placement: 'bottomLeft',
    });
  };

  const refreshQueries = [
    {
      query: LIST_KEY_PERSON,
      variables: { companyId: { companyId: companyId } },
    },
    { query: GET_COMPANY, variables: { id: companyId } },
  ];

  const [create, { loading: createLoading }] = useMutation(CREATE_KEY_PERSON, {
    onCompleted: () => handleCompleted('Successfully created'),
    refetchQueries: refreshQueries,
    onError: handleError,
  });

  const [update, { loading: updateLoading }] = useMutation(UPDATE_KEY_PERSON, {
    onCompleted: () => handleCompleted('Successfully updated'),
    refetchQueries: refreshQueries,
    onError: handleError,
  });

  const [deleteKeyPerson, { loading: deleteLoading }] = useMutation(
    DELETE_KEY_PERSON,
    {
      onCompleted: () => handleCompleted('Successfully deleted'),
      refetchQueries: refreshQueries,
      onError: handleError,
    }
  );

  const handleOnCreate = (values: FormValues) => {
    create({
      variables: {
        input: {
          companyId: companyId,
          ...values,
        },
      },
    });
  };

  const handleOnUpdate = (values: FormValues) => {
    update({
      variables: {
        input: {
          companyId: companyId,
          keyPersonId: updatingKeyPerson?.id,
          ...values,
        },
      },
    });
  };

  const handleOnDelete = (data: KeyPerson) => {
    setUpdatingKeyPerson(data);
    deleteKeyPerson({
      variables: {
        input: {
          companyId: companyId,
          keyPersonId: data.id,
        },
      },
    });
  };

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsIncomplete(hasErrors);
  };

  useEffect(() => {
    if (data?.company) {
      form.setFieldsValue({ topicIds: data?.company.topics?.map((i) => i.id) });
    }
  }, [data, form]);

  if (loading) {
    return (
      <div className="text-center p-6">
        <Spin />
      </div>
    );
  }

  return (
    <>
      {dataKeyPerson?.listKeyPerson.length ? (
        dataKeyPerson?.listKeyPerson.map((keyPerson) => (
          <div key={keyPerson.id} className="px-6 pt-6">
            <div className="flex items-center">
              <div className="grow">
                <div className="font-semibold">{keyPerson.name}</div>
                <div className="font-light text-gray-700">{keyPerson.role}</div>
              </div>
              <div>
                {keyPerson.linkedinUrl && (
                  <Link href={keyPerson.linkedinUrl} target="_blank">
                    <Button shape="circle" icon={<LinkedinFilled />} />
                  </Link>
                )}
              </div>
              <div>
                <Button
                  type="link"
                  shape="round"
                  onClick={() => {
                    setUpdatingKeyPerson(keyPerson);
                    form.setFieldsValue({
                      name: keyPerson.name,
                      role: keyPerson.role,
                      linkedinUrl: keyPerson.linkedinUrl,
                    });
                    setAddModalVisible(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  shape="circle"
                  loading={updatingKeyPerson === keyPerson && deleteLoading}
                  onClick={() => {
                    handleOnDelete(keyPerson);
                  }}
                  icon={<DeleteFilled />}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="px-6 pt-6">
          <div className="text-center">
            <TeamOutlined />
            <h2 className="font-medium">Key Persons</h2>
            <div className="font-light text-gray-700 mb-10">
              Your institution doesn’t have any key persons at the moment
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-end items-center px-4 py-3 border-t border-solid border-gray-400 gap-2">
        <Button
          className="w-full"
          type="link"
          shape="round"
          icon={<PlusOutlined />}
          onClick={() => {
            form.resetFields();
            setAddModalVisible(true);
          }}
        >
          Add person
        </Button>
      </div>
      <Modal
        footer={false}
        title={updatingKeyPerson ? 'Edit key person' : 'Add key person'}
        open={addModalVisible}
        onCancel={() => setAddModalVisible(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFieldsChange={handleFormChange}
          onFinish={updatingKeyPerson ? handleOnUpdate : handleOnCreate}
        >
          <div className="px-6 pt-6">
            <Form.Item
              name="name"
              label="Full name"
              rules={formFieldRules.name}
            >
              <Input placeholder="Full name" maxLength={100} />
            </Form.Item>
            <Form.Item name="role" label="Role" rules={formFieldRules.role}>
              <Input placeholder="Role in this institution" maxLength={100} />
            </Form.Item>
            <Form.Item
              name="linkedinUrl"
              label={
                <div>
                  LinkedIN <span className="text-gray-700">(optional)</span>
                </div>
              }
            >
              <Input
                placeholder="LinkedIN url address"
                type="link"
                maxLength={100}
              />
            </Form.Item>
          </div>
          <div className="flex justify-end items-center px-4 py-3 border-t border-solid border-gray-400 gap-2">
            <Button
              htmlType="submit"
              type="primary"
              loading={updatingKeyPerson ? updateLoading : createLoading}
              disabled={isIncomplete}
              shape="round"
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default EditKeyPersonForm;
