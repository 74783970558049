import { gql } from '@apollo/client';

import { REGION } from './region';
import { TOPIC } from './topic';

export const GET_INTERESTS = gql`
  query interests {
    regions {
      ...region
    }
    topics {
      ...topic
    }
  }
  ${REGION}
  ${TOPIC}
`;

export const UPDATE_INTEREST = gql`
  mutation updateInterests($input: UpdateInterestsInput) {
    updateInterests(input: $input) {
      topics {
        ...topic
      }
      regions {
        ...region
      }
    }
  }
  ${REGION}
  ${TOPIC}
`;

export const GET_USER_INTERESTS = gql`
  query interests {
    user {
      topics {
        ...topic
      }
      regions {
        ...region
      }
    }
  }
  ${REGION}
  ${TOPIC}
`;
