import entrepreneurshipReducer from '@admin/features/entrepreneurship/entrepreneurshipSlice';
import kanbanReducer from '@admin/features/intrapreneurship/challenge/components/Manage/kanban/kanbanSlice';
import intrapreneurshipReducer from '@admin/features/intrapreneurship/intrapreneurshipSlice';
import userReducer from '@admin/features/user/userSlice';
import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    intrapreneurship: intrapreneurshipReducer,
    entrepreneurship: entrepreneurshipReducer,
    user: userReducer,
    kanban: kanbanReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
