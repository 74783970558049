import { gql } from '@apollo/client';

export const KPI = gql`
  fragment kpi on Kpi {
    createdAt
    id
    name
    unit
  }
`;

export const KPI_POINT = gql`
  fragment kpiPoint on KpiPoint {
    id
    kpi {
      id
    }
    time
    value
  }
`;
