import { migrationGenerateCode } from '@frontend/data-access';
import { Alert, AlertMessage } from '@frontend/shared/ui';
import { Button, Form, Input, Typography } from 'antd';
import { useState } from 'react';

import { getAuthErrorMessages } from '../auth-helper';

type FormValues = {
  email: string;
  password: string;
};

export function AuthUserMigration() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<Alert>();

  const handleConfirm = async (values: FormValues) => {
    try {
      setAlert(undefined);
      setLoading(true);
      const response = await migrationGenerateCode({
        email: values.email,
      });
      if (response.status === 200) {
        setAlert({
          messages: ['Please check your email to receive a link'],
          type: 'success',
        });
      } else {
        setAlert({
          messages: getAuthErrorMessages(['error']),
          type: 'error',
        });
      }
    } catch (error) {
      let message = 'Unknown error';
      if (error instanceof Error) message = error.message;
      setAlert({ messages: [message], type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-[450px] flex flex-col items-center justify-center text-center">
      <Typography.Title className="!mb-2 text-center" level={2}>
        Welcome to FintechBridge 2.0
      </Typography.Title>
      <div className="mb-6 text-center">
        {alert ? (
          <Typography.Text>
            We sent a link to your email address.
          </Typography.Text>
        ) : (
          <Typography.Text>
            To create your new password, please insert your email address
            registered with FintechBridge account
          </Typography.Text>
        )}
      </div>
      {alert && <AlertMessage className="my-6" alert={alert} />}
      <div className="w-[350px]">
        <Form layout="vertical" form={form} onFinish={handleConfirm}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input your email',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              block
              size="large"
              type="primary"
              shape="round"
              htmlType="submit"
            >
              Create new password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default AuthUserMigration;
