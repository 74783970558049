module.exports = {
  locales: ['en', 'ca', 'mn'],
  defaultLocale: 'en',
  loader: false,
  pages: {
    '*': ['common', 'platform', 'admin'],
  },
  loadLocaleFrom: async (locale, ns) =>
    import(`./apps/locales/${locale}/${ns}.json`).then((r) => r),
};
