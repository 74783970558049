import { useLazyQuery } from '@apollo/client';
import { GET_IDEA_CONTRIBUTIONS, IdeaContribute } from '@frontend/data-access';
import { Button, Empty, Spin } from 'antd';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import IdeaContributionCard from '../idea-contribution-card/idea-contribution-card';
import styles from './idea-contribution-list.module.less';

interface ContributeEdge {
  cursor: string;
  node: IdeaContribute;
}

interface ContributionConnector {
  edges: ContributeEdge[];
  pageInfo: {
    hasNextPage: boolean;
  };
}

const DEFAULT_LIMIT = 3;

export function IdeaContributionList({
  ideaId,
  contributionCount,
  title,
}: {
  ideaId: string;
  contributionCount?: number;
  title?: string;
}) {
  const [data, setData] = useState<ContributeEdge[]>([]);
  const [autoFetch, setAutoFetch] = useState<boolean>(false);
  const [params, setParams] = useState<{
    ideaId: string;
    first: number;
    after?: string;
  }>({
    ideaId: ideaId,
    first: DEFAULT_LIMIT,
  });
  const [getData, { data: connector, loading, refetch }] = useLazyQuery<{
    ideaContribution: ContributionConnector;
  }>(GET_IDEA_CONTRIBUTIONS, {
    onCompleted({ ideaContribution }) {
      const tempEdges = params?.after ? data : [];
      tempEdges.push(...ideaContribution.edges);
      setData(tempEdges);
    },
  });

  const handleCardLike = (ideaId: string) => {
    const tempData = data?.map((item) => {
      if (item.node.id === ideaId) {
        return {
          ...item,
          node: {
            ...item.node,
            likes: item.node.isLiked
              ? item.node.likes - 1
              : item.node.likes + 1,
            isLiked: !item.node.isLiked,
          },
        };
      } else return item;
    });
    setData(tempData);
  };

  const handleLoadMore = () => {
    setParams({
      ...params,
      after:
        connector?.ideaContribution.edges[
          connector?.ideaContribution.edges.length - 1
        ].cursor,
    });
  };

  useEffect(() => {
    if (connector) {
      refetch();
    }
  }, [connector, contributionCount, refetch]);

  useEffect(() => {
    if (params.ideaId) {
      getData({ variables: params });
    }
  }, [getData, params]);

  const Loader = () => {
    return (
      <div className="text-center">
        <Spin />
      </div>
    );
  };

  return (
    <div className={styles['container']}>
      <div className="space-y-6">
        <div className="font-semibold mb-4">
          {title
            ? title
            : `Contributions ${contributionCount && `(${contributionCount})`}`}
        </div>
        {!data && loading && <Loader />}
        {data.length === 0 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No contributions"
          />
        ) : (
          <InfiniteScroll
            dataLength={data?.length || 0}
            next={handleLoadMore}
            hasMore={
              autoFetch &&
              (connector?.ideaContribution.pageInfo.hasNextPage || false)
            }
            loader={<Loader />}
            className="!overflow-visible space-y-6"
          >
            {data.map((item, index) => (
              <IdeaContributionCard
                key={index}
                data={item.node}
                onClickLike={handleCardLike}
              />
            ))}
          </InfiniteScroll>
        )}
        {!autoFetch && connector?.ideaContribution.pageInfo.hasNextPage && (
          <div className="text-center" onClick={() => setAutoFetch(true)}>
            <Button shape="round">Load more</Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default IdeaContributionList;
