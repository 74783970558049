import { Select } from 'antd';
import useTranslation from 'next-translate/useTranslation';

import { ChallengeSortType } from '../challenge-list/challenge-list';

export type SortOption = {
  value: string;
  label: string;
};
export interface ChallengeSortProps {
  value: ChallengeSortType | undefined;
  sortOptions: SortOption[];
  onSort: (sort: ChallengeSortType) => void;
}

export function ChallengeSort({
  value,
  sortOptions,
  onSort,
}: ChallengeSortProps) {
  const { t: tcommon } = useTranslation('common');

  return (
    <div className="flex items-center">
      <div className="text-gray-700">{tcommon('Sort by')}:</div>
      <Select
        value={value}
        defaultValue="CREATION_DATE"
        className="!w-36"
        bordered={false}
        options={sortOptions}
        onChange={onSort}
      />
    </div>
  );
}

export default ChallengeSort;
