import { gql } from '@apollo/client';

export const REGION = gql`
  fragment region on Region {
    id
    name
  }
`;

export const GET_REGIONS = gql`
  query Regions {
    regions {
      ...region
    }
  }
  ${REGION}
`;
