import { gql } from '@apollo/client';

import {
  CHALLENGE_APPLICATION,
  STAGE_APPLICATION,
  TEAM,
} from './challenge-application';
import { COMPANY_BASIC, COMPANY_INFO } from './company';
import { CRITERIA, CRITERIA_RESULT } from './criteria';
import { FILE } from './upload';
import { USER_BASICS } from './user';

export const STAGE = gql`
  fragment stage on Stage {
    id
    name
    description
    startAt
    endAt
    votingType
    scheduleHidden
    state
    createdAt
    updatedAt
    orderNumber
    applications {
      ...challengeApplication
    }
    viewerAssignedStageApplications {
      ...stageApplication
    }
  }
`;

export const STAGE_BASICS = gql`
  fragment stageBasics on Stage {
    id
    name
    description
    orderNumber
    state
  }
`;

export const REVIEWER_ASSIGNMENT = gql`
  fragment reviewerAssignment on ReviewerAssignment {
    id
    stageId
    applicationId
    reviewerId
  }
`;

export const GET_STAGE = gql`
  query Stage($stageId: ID!) {
    stage(stageId: $stageId) {
      id
      name
      state
      applications {
        ...stageApplication
      }
      nextStage {
        id
        applications {
          ...stageApplication
        }
      }
    }
  }
  ${STAGE_APPLICATION}
  ${CHALLENGE_APPLICATION}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
`;

export const GET_STAGE_APPLICATION = gql`
  query StageApplication($stageId: UUID!, $applicationId: UUID!) {
    stageApplication(stageId: $stageId, applicationId: $applicationId) {
      ...stageApplication
    }
  }
  ${STAGE_APPLICATION}
  ${CHALLENGE_APPLICATION}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
`;

export const GET_STAGE_FOR_USER = gql`
  query StageForUser($input: StageApplicationFindInput!) {
    stageForUser(input: $input) {
      id
      name
      state
      applications {
        ...stageApplication
      }
      viewerStageApplication {
        ...stageApplication
      }
      nextStage {
        id
        applications {
          ...stageApplication
        }
      }
    }
  }
  ${STAGE_APPLICATION}
  ${CHALLENGE_APPLICATION}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
`;

export const GET_STAGE_FOR_COACH = gql`
  query StageForCoach($stageId: ID!) {
    stageForCoach(stageId: $stageId) {
      id
      name
      state
      applications {
        ...stageApplication
      }
      viewerAssignedStageApplications {
        ...stageApplication
        viewerFeedbackCount
      }
      nextStage {
        id
        applications {
          ...stageApplication
        }
      }
    }
  }
  ${STAGE_APPLICATION}
  ${CHALLENGE_APPLICATION}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
`;

export const UPDATE_STAGES = gql`
  mutation UpdateStages($challengeId: ID!, $inputs: [UpdateStageInput]!) {
    updateStages(challengeId: $challengeId, inputs: $inputs) {
      ...stage
    }
  }
  ${STAGE}
  ${STAGE_APPLICATION}
  ${CHALLENGE_APPLICATION}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const START_STAGE = gql`
  mutation StartStage($stageId: ID!) {
    startStage(stageId: $stageId) {
      id
      state
    }
  }
`;

export const END_STAGE = gql`
  mutation EndStage($stageId: ID!) {
    endStage(stageId: $stageId) {
      id
      state
    }
  }
`;

export const ASSIGN_REVIEWERS_TO_STAGE_APPLICATION = gql`
  mutation AssignReviewersToStageApplication(
    $stageId: ID!
    $input: [AssignReviewerToStageApplicationInput!]!
  ) {
    assignReviewersToStageApplication(stageId: $stageId, input: $input) {
      ...reviewerAssignment
    }
  }
  ${REVIEWER_ASSIGNMENT}
`;

export const UNASSIGN_REVIEWERS_TO_STAGE_APPLICATION = gql`
  mutation UnassignReviewersToStageApplication(
    $input: UnassignReviewerToStageApplicationInput!
  ) {
    unassignReviewersToStageApplication(input: $input)
  }
  ${REVIEWER_ASSIGNMENT}
`;
