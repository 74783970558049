import { LikeOutlined } from '@ant-design/icons';
import { Post, User } from '@frontend/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  ContentPlaceholder,
  ContentTypes,
  UserAvatar,
} from '@frontend/shared/ui';
import { formatDate } from '@frontend/shared/util';
import { Image } from 'antd';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

/* eslint-disable-next-line */
export interface ContentCardUserProps {
  post: Post;
}

export function ContentCardUser({ post }: ContentCardUserProps) {
  const router = useRouter();
  const { publicRuntimeConfig } = getConfig();
  const url = '/community/' + post?.id;

  return (
    <div
      className={`group w-full flex flex-col ${post?.isPublic ? 'bg-gray-200' : 'bg-gray-1000'
        } cursor-pointer rounded-3xl hover:shadow-3xl transition duration-700 ease-in-out`}
      onClick={() => router.push(url)}
    >
      <div className="relative">
        {post?.banner?.url ? (
          <Image
            className="rounded-2xl group-hover:rounded-b-none ease-in duration-300 w-full aspect-video"
            width="100%"
            preview={false}
            src={post?.banner?.url || ''}
            alt={post.title}
            fallback="https://dummyimage.com/600x400/F5F5F5/fff.png&text=+"
          />
        ) : (
          <ContentPlaceholder
            type={ContentTypes.POST_NO_BANNER}
            className="rounded-3xl aspect-video"
            id={post?.id}
          />
        )}
        {post?.event === true && (
          <div className="bg-primary-600 text-gray-100 absolute top-5 left-4 text-sm font-semibold py-1 px-3 rounded-full">
            Event
          </div>
        )}
        <div className="absolute flex flex-wrap gap-2 bottom-6 left-4 right-4">
          {post?.isPublic === false && (
            <div className="bg-gray-1000 text-gray-100 text-sm font-semibold py-1 px-3 rounded-full whitespace-nowrap">
              Internal content
            </div>
          )}
          {post?.innovationSpaceTag && (
            <div className="bg-blue-600 text-gray-100 text-sm font-semibold py-1 px-3 rounded-full truncate">
              {post?.innovationSpaceTag.name}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2 justify-center w-full p-4">
        <p
          className={`flex space-x-1 text-xs font-normal ${post?.isPublic ? 'text-gray-800' : 'text-white'
            } mb-0`}
        >
          <span className="flex-shrink-0 text-sm font-light">
            {post?.publishedAt && formatDate(post?.publishedAt, 'do LLL yyyy')}
          </span>
          {post?.keyTags && post?.keyTags.length > 0 && (
            <span className="line-clamp-1">
              {`- ${post?.keyTags.map((tag) => tag.name).join(', ')}`}
            </span>
          )}
        </p>
        <div className="flex justify-between">
          <h4 className={`text-lg font-medium ${post?.isPublic ? 'text-gray-900' : 'text-white'} line-clamp-3 mb-8`}>
            {post?.title}
          </h4>
          <span className="text-2xl">
            <Image
              src={
                post?.isPublic
                  ? `${publicRuntimeConfig.staticFolder}/svg/arrow-up-right-blue.svg`
                  : `${publicRuntimeConfig.staticFolder}/svg/arrow-up-right.svg`
              }
              preview={false}
              width={24}
              height={24}
              alt={post.title}
            />
          </span>
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-2">
            <UserAvatar size={36} user={post.writtenBy as User} />
            <span
              className={`text-base font-semibold ${post?.isPublic ? 'text-gray-900' : 'text-white'
                }`}
            >
              {post?.writtenBy?.fullName}
            </span>
          </div>
          <div
            className={`mt-2 text-sm font-light ${post?.isPublic ? 'text-gray-900' : 'text-white'
              }`}
          >
            <LikeOutlined className="mr-1" /> {post?.likes}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentCardUser;
