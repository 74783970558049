import Link from 'next/link';
import { ReactElement } from 'react';

const errorMap = new Map<string, string | ReactElement>([
  [
    'auth.sign_up.linkedin_email_exists',
    'Please login using Linkedin account.',
  ],
  [
    'auth.sign_up.cognito_email_exists',
    'Please login using Standard Chartered Employee Option',
  ],
  ['auth.sign_up.google_email_exists', 'Please login using Google account.'],
  ['auth.sign_up.local_email_exists', 'Please login using email and password'],
  ['auth.sign_up.local.password_not_strong_enough', 'Password is not strong'],
  [
    'auth.sign_up.local_email_exists',
    'You have already registered by different method',
  ],
  // TODO: don't hard code Standard Chartered
  [
    'auth.sign_up.internal_email',
    'Please sign up via your Standard Chartered SSO account',
  ],
  ['auth.login.linkedin_email_exists', 'Please login using Linkedin account.'],
  [
    'auth.login.cognito_email_exists',
    'Please login using Standard Chartered Employee Option',
  ],
  ['auth.login.google_email_exists', 'Please login using Google account.'],
  ['auth.login.local_email_exists', 'Please login using email and password'],
  ['auth.login.wrong_credentials', 'Incorrect username or password'],
  ['auth.login.email_exists', 'Incorrect username or password'],
  ['auth.login.email_not_verified', 'Email is not verified'],
  [
    'auth.login.not_confirmed_migration',
    // TODO: FintechBridge 2.0
    <span>
      Welcome to FintechBridge 2.0. For your account safety, we have sent you an
      email with an account verification link. If you want to resend the email,
      please{' '}
      <Link className="text-primary-600" href="/migration">
        click here
      </Link>
    </span>,
  ],
  ['auth.confirm.utilized_token', 'Token already used'],
  ['auth.invalid_token', 'Token invalid'],
  [
    'auth.renew_password.password_not_strong_enough',
    'Password not strong enough',
  ],
  ['auth.renew_password.non_local_login_type', 'None local login type'],
  ['auth.renew_password.wrong_credentials', 'Wrong credentials'],
  ['auth.login.account_locked', 'Your account temporarily locked'],
  ['auth.captcha.verification_failed', 'Your request is invalid'],
]);

export function getAuthErrorMessages(
  errors: Array<string>
): Array<string | ReactElement> {
  const errorMessages: Array<string | ReactElement> = [];
  errors.map((error: string) => {
    return errorMessages.push(errorMap.get(error) ?? 'Unknown error');
  });
  return errorMessages;
}

export function usePasswordStrengthChecker(password: string) {
  const hasUpperCaseCharacter = /[A-Z]/.test(password);
  const hasEnoughCharacters = password.length >= 8;
  const hasNumber = /\d/.test(password);
  const hasSpecialCharacters = /[~!@#$%&]/.test(password);
  return {
    hasUpperCaseCharacter,
    hasEnoughCharacters,
    hasNumber,
    hasSpecialCharacters,
  };
}

export default { getAuthErrorMessages, usePasswordStrengthChecker };
