import { passwordConfirm } from '@frontend/data-access';
import { Alert, AlertMessage } from '@frontend/shared/ui';
import { Badge, Button, Col, Form, Input, Row, Typography } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';

import {
  getAuthErrorMessages,
  usePasswordStrengthChecker,
} from '../auth-helper';

/* eslint-disable-next-line */
export interface ResetPasswordProps {}

type FormValues = {
  password: string;
};

export function ResetPassword(props: ResetPasswordProps) {
  const { t } = useTranslation('common');
  const router = useRouter();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<Alert>();
  const password = Form.useWatch('password', form) || '';
  const {
    hasEnoughCharacters,
    hasNumber,
    hasSpecialCharacters,
    hasUpperCaseCharacter,
  } = usePasswordStrengthChecker(password);

  const handlePasswordReset = async (values: FormValues) => {
    if (router?.query['confirmation_token']) {
      try {
        setAlert(undefined);
        setLoading(true);

        const response = await passwordConfirm({
          newPassword: values.password,
          token: router?.query['confirmation_token']?.toString(),
        });
        if (response.status === 200) {
          router.push('/login');
        } else {
          setAlert({
            messages: getAuthErrorMessages(['auth.invalid_token']),
            type: 'error',
          });
        }
      } catch (error) {
        let message = 'Unknown error';
        if (error instanceof Error) message = error.message;
        setAlert({ messages: [message], type: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Col className="w-[350px]">
      <Col className="flex justify-center">
        <Typography.Title level={3}>
          {t('reset_password_title')}
        </Typography.Title>
      </Col>
      {alert && <AlertMessage className="my-6" alert={alert} />}
      <Form layout="vertical" form={form} onFinish={handlePasswordReset}>
        <Form.Item
          label={t('reset_input_password')}
          name="password"
          rules={[
            { required: true, message: t('reset_input_password_required') },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item label={t('join_input_password_contain')}>
          <Row>
            <Col span={8} style={{ display: 'flex' }}>
              <Badge
                status={hasUpperCaseCharacter ? 'success' : 'default'}
                text={t('join_input_password_uppercase')}
              />
            </Col>
            <Col span={16} style={{ display: 'flex' }}>
              <Badge
                status={hasEnoughCharacters ? 'success' : 'default'}
                text={t('join_input_password_length')}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8} style={{ display: 'flex' }}>
              <Badge
                status={hasNumber ? 'success' : 'default'}
                text={t('join_input_password_number')}
              />
            </Col>
            <Col span={16} style={{ display: 'flex' }}>
              <Badge
                status={hasSpecialCharacters ? 'success' : 'default'}
                text={t('join_input_password_character')}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label={t('reset_input_confirm_password')}
          name="password2"
          rules={[
            { required: true, message: t('reset_input_password_required') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t('reset_input_password_not_match'))
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Button
          loading={loading}
          htmlType="submit"
          block
          type="primary"
          shape="round"
        >
          {t('reset_button')}
        </Button>
        <Row className="py-4 flex justify-center">
          <Typography>
            <Link href="login">{t('reset_back')}</Link>
          </Typography>
        </Row>
      </Form>
    </Col>
  );
}

export default ResetPassword;
