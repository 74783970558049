import { LockOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { GET_ME, Me } from '@frontend/data-access';
import { Spin } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode } from 'react';

export type AccessType = 'INTERNAL' | 'MEMBER';

export function ProtectedCard({
  children,
  type,
  ignoreAccessControl = false,
}: {
  children: ReactNode;
  type: AccessType;
  ignoreAccessControl?: boolean;
}) {
  const { data, loading } = useQuery<{
    me: Me;
  }>(GET_ME);
  const { t } = useTranslation('common');
  const router = useRouter();
  const ACCESS_CONTROL = {
    INTERNAL: {
      title: t('protectedCard_internal_title'),
      linkLabel: t('protectedCard_internal_label'),
      link: `/login?type=internal&referrer=${process.env['NEXT_PUBLIC_PLATFORM_URL']}${process.env['NEXT_PUBLIC_BASE_PATH']}${router.asPath}`,
      roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
    },
    MEMBER: {
      title: t('protectedCard_internal_member_title'),
      linkLabel: t('protectedCard_internal_member_label'),
      link: `/login?referrer=${process.env['NEXT_PUBLIC_PLATFORM_URL']}${process.env['NEXT_PUBLIC_BASE_PATH']}${router.asPath}`,
      roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER', 'EXTERNAL_USER'],
    },
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-10">
        <Spin />
      </div>
    );
  }

  if (
    type === 'INTERNAL' &&
    data?.me?.role &&
    data?.me?.role === 'EXTERNAL_USER'
  ) {
    return null;
  }
  if (
    (data?.me?.role && ACCESS_CONTROL[type].roles.includes(data?.me?.role)) ||
    ignoreAccessControl
  ) {
    return <div>{children}</div>;
  }

  return (
    <div className="relative">
      <div className="absolute z-10 md:w-fit top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-[330px]">
        <div className="border border-gray-400 rounded-full bg-white shadow-xl py-3 px-4 flex gap-3 items-center">
          <Avatar
            className="!bg-primary-100 !min-w-[36px] !min-h-[36px]"
            icon={
              <span className="text-primary-600">
                <LockOutlined />
              </span>
            }
            size={36}
          ></Avatar>
          <div className="grid px-1.5">
            <span className="text-left font-semibold">
              {ACCESS_CONTROL[type].title}
            </span>
            <Link href={ACCESS_CONTROL[type].link}>
              <span className="text-left text-primary-600 text-sm">
                {ACCESS_CONTROL[type].linkLabel}
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="blur-sm select-none pointer-events-none">{children}</div>
    </div>
  );
}

export default ProtectedCard;
