import {
  EnvironmentOutlined,
  FacebookFilled,
  FireOutlined,
  InstagramFilled,
  LinkedinFilled,
  MailOutlined,
  MediumCircleFilled,
  ReadOutlined,
  RocketOutlined,
  TwitterOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { GET_ME, Me, User } from '@frontend/data-access';
import { Button, Col, Row, Tabs } from 'antd';
import Link from 'next/link';
import { useMemo } from 'react';

import UserProfileAbout from './tab-items/about';
import UserProfileChallenges from './tab-items/challenges';
import UserProfileIdeas from './tab-items/ideas';
import UserProfilePosts from './tab-items/posts';

const UserProfileTabs = ({ data }: { data: User }) => {
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);
  const showInternal =
    data.role !== 'EXTERNAL_USER' && meData?.me?.role !== 'EXTERNAL_USER';

  const items = useMemo(() => {
    // External user can't see or have the "ideas" tab
    return [
      {
        visible: true,
        icon: <UserOutlined />,
        label: 'About',
        key: 'about',
        children: <UserProfileAbout data={data} />,
      },
      {
        visible: showInternal,
        icon: <FireOutlined />,
        label: 'Ideas',
        key: 'ideas',
        children: <UserProfileIdeas />,
      },
      {
        visible: true,
        icon: <ReadOutlined />,
        label: 'Posts',
        key: 'posts',
        children: <UserProfilePosts />,
      },
      {
        visible: showInternal,
        icon: <RocketOutlined />,
        label: 'Challenges',
        key: 'challenges',
        children: <UserProfileChallenges />,
      },
    ];
  }, [data, showInternal]);

  return (
    <Row gutter={[16, 24]}>
      <Col span={24} md={24}>
        <div className="space-y-3">
          <Tabs
            items={items
              .filter((a) => a.visible)
              .map((item) => ({
                ...item,
                label: (
                  <span>
                    {item.icon} {item.label}
                  </span>
                ),
              }))}
          />
          {data.profile?.location && (
            <div className="bg-gray-200 rounded-3xl p-5">
              <div className="flex items-center gap-2 text-gray-900 text-sm font-semibold">
                <EnvironmentOutlined />
                <span>{data.profile?.location}</span>
              </div>
            </div>
          )}
          {(!data.profile?.socialHidden || !data.emailHidden) && (
            <div className="bg-gray-200 rounded-3xl p-5 space-y-2">
              <div className="text-gray-900 text-sm font-semibold">Connect</div>
              <div className="space-y-2">
                {meData?.me.role !== 'EXTERNAL_USER' && !data.emailHidden && (
                  <div>
                    <Link
                      className="space-x-2 text-primary-600"
                      href={`mailto:${data.email}`}
                    >
                      <Button shape="circle">
                        <MailOutlined />
                      </Button>
                      <span>{data.email}</span>
                    </Link>
                  </div>
                )}
                {!data.profile?.socialHidden && (
                  <>
                    {data.profile?.facebook && (
                      <div>
                        <Link
                          className="space-x-2 text-primary-600 items-center flex flex-nowrap"
                          href={data.profile?.facebook}
                          target="_blank"
                        >
                          <Button shape="circle" target="_blank">
                            <FacebookFilled />
                          </Button>
                          <span className="line-clamp-1">
                            {data.profile?.facebook}
                          </span>
                        </Link>
                      </div>
                    )}
                    {data.profile?.twitter && (
                      <div>
                        <Link
                          className="space-x-2 text-primary-600 items-center flex flex-nowrap"
                          href={data.profile?.twitter}
                          target="_blank"
                        >
                          <Button shape="circle">
                            <TwitterOutlined />
                          </Button>
                          <span className="line-clamp-1">
                            {data.profile?.twitter}
                          </span>
                        </Link>
                      </div>
                    )}
                    {data.profile?.linkedin && (
                      <div>
                        <Link
                          className="space-x-2 text-primary-600 items-center flex flex-nowrap"
                          href={data.profile?.linkedin}
                          target="_blank"
                        >
                          <Button
                            shape="circle"
                            href={data.profile?.linkedin}
                            target="_blank"
                          >
                            <LinkedinFilled />
                          </Button>
                          <span className="line-clamp-1">
                            {data.profile?.linkedin}
                          </span>
                        </Link>
                      </div>
                    )}
                    {data.profile?.instagram && (
                      <div>
                        <Link
                          className="space-x-2 text-primary-600 items-center flex flex-nowrap"
                          href={data.profile?.instagram}
                          target="_blank"
                        >
                          <Button
                            shape="circle"
                            href={data.profile?.instagram}
                            target="_blank"
                          >
                            <InstagramFilled />
                          </Button>
                          <span className="line-clamp-1">
                            {data.profile?.instagram}
                          </span>
                        </Link>
                      </div>
                    )}
                    {data.profile?.medium && (
                      <div>
                        <Link
                          className="space-x-2 text-primary-600 items-center flex flex-nowrap"
                          href={data.profile?.medium}
                          target="_blank"
                        >
                          <Button
                            shape="circle"
                            href={data.profile?.medium}
                            target="_blank"
                          >
                            <MediumCircleFilled />
                          </Button>
                          <span className="line-clamp-1">
                            {data.profile?.medium}
                          </span>
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default UserProfileTabs;
