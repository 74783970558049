import '@admin/styles/globals.less';
import '@admin/styles/lexical/ContentEditable.css';
import '@admin/styles/lexical/ImageNode.css';
import '@admin/styles/lexical/index.css';
import '@admin/styles/lexical/Placeholder.css';

import { FirebaseAppProvider } from '@admin/common/components/contexts/firebaseApp';
import { store } from '@admin/settings/store';
import { ApolloProvider } from '@apollo/client';
import { AuthChecker, AuthHandler } from '@frontend/feature-auth';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import appWithI18n from 'next-translate/appWithI18n';
import NextNProgress from 'nextjs-progressbar';
import { ReactElement, ReactNode } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';

import i18nConfig from '../../../../i18n';
import { useApollo } from '../lib/apolloClient';

export type NextPageWithLayout<P = Record<string, never>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  const apolloClient = useApollo(pageProps);

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <NextNProgress color="#0473EA" />
        <AuthChecker type="ADMIN">
          <AuthHandler>
            <GoogleReCaptchaProvider
              container={{
                parameters: {
                  badge: 'bottomleft',
                },
              }}
              reCaptchaKey={process.env['NEXT_PUBLIC_RECAPTCHA_SITE_KEY']}
            >
              <FirebaseAppProvider>
                {getLayout(<Component {...pageProps} />)}
              </FirebaseAppProvider>
            </GoogleReCaptchaProvider>
          </AuthHandler>
        </AuthChecker>
      </ApolloProvider>
    </Provider>
  );
}

export default appWithI18n(MyApp, {
  ...i18nConfig,
});
