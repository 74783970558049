import { gql } from '@apollo/client';

import { COMPANY_MEMBER_INVITE } from './company';
import { FILE } from './upload';

export const GET_INVITES = gql`
  query Invites {
    invites {
      ...memberInvite
    }
  }
  ${COMPANY_MEMBER_INVITE}
  ${FILE}
`;
