import {
  CloseCircleOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  PlayCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Company,
  ErrorResponse,
  GET_COMPANY,
  PATCH_COMPANY,
  UploadedFile,
} from '@frontend/data-access';
import { Button, Modal, notification } from 'antd';
import { useState } from 'react';

import EditPitchForm from './edit-actions/pitch';
import EmptyCard from './empty-card';

const PitchVideo = ({
  data,
  companyId,
}: {
  data: UploadedFile[];
  companyId?: number;
}) => {
  const [selectedFile, setSelectedFile] = useState<UploadedFile | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: dataCompany } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: companyId },
  });

  const handleCompleted = () => {
    notification.success({
      message: 'Successfully saved',
      placement: 'bottomLeft',
    });
    // onFinish();
  };

  const handleError = (data: ErrorResponse) => {
    notification.error({
      message: 'Error',
      description: data.message,
      icon: (
        <span className="text-red-600">
          <CloseCircleOutlined />
        </span>
      ),
      placement: 'bottomLeft',
    });
  };

  const [patchCompany, { loading: loadingPatch }] = useMutation(PATCH_COMPANY, {
    onCompleted: handleCompleted,
    onError: handleError,
    refetchQueries: [
      {
        query: GET_COMPANY,
        variables: { id: companyId },
      },
    ],
  });

  const onRemoveVideo = async (file: UploadedFile) => {
    try {
      setSelectedFile(file);
      await patchCompany({
        variables: {
          input: {
            id: companyId,
            pitchVideos: (dataCompany?.company?.pitchVideos ?? []).filter(
              (v) => v.id !== file.id
            ),
          },
        },
      });
    } catch (error) {
      // handleError(error);
      console.log('error', error);
    }
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <>
      <div data-test="information-deck-content">
        {data && data.length > 0 ? (
          <div className="flex flex-col gap-4">
            {data.map((file, index) =>
              file.url ? (
                <div key={`${file.url}-${index}`} className="relative">
                  {/* <VideoPlayer key={file.url} url={file.url ?? ''} /> */}
                  <video
                    className="rounded-3xl object-contain h-full w-full"
                    src={file.url}
                    controls
                  />
                  {dataCompany?.company.isOwner && (
                    <div className="absolute top-4 right-4">
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        shape="circle"
                        loading={loadingPatch && selectedFile?.id === file.id}
                        className="absolute top-0 right-0"
                        onClick={() => onRemoveVideo(file)}
                      />
                    </div>
                  )}
                </div>
              ) : (
                dataCompany?.company.isOwner && (
                  <div key={index} className="flex flex-row gap-2 items-center">
                    <div className="grow bg-gray-200 rounded-full flex flex-row justify-between items-center p-2 flex-wrap md:flex-nowrap">
                      <div className="flex gap-3 items-center">
                        <PlayCircleOutlined />
                        <span className="truncate whitespace-nowrap max-w-[200px] md:max-w-sm">
                          Processing:{' '}
                          {file.metadata?.filename ?? 'No file name'}
                        </span>
                      </div>
                    </div>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      shape="circle"
                      onClick={() => onRemoveVideo(file)}
                      loading={loadingPatch && selectedFile?.id === file.id}
                    />
                  </div>
                )
              )
            )}
          </div>
        ) : dataCompany?.company.isOwner ? (
          <div
            className="flex flex-col items-center rounded-3xl border border-dashed bg-gray-200 hover:bg-white border-gray-500 hover:border-primary-400 cursor-pointer ease-in-out transition-all duration-200 py-16"
            onClick={() => toggleModal()}
          >
            <div className="p-2 bg-gray-500 rounded-full w-10 h-10 text-white text-lg text-center">
              <UploadOutlined />
            </div>
            <p className="ant-upload-text">Upload your pitch video</p>
            <p className="ant-upload-hint">
              <Button type="primary" shape="round">
                Upload video
              </Button>
            </p>
          </div>
        ) : (
          <EmptyCard title="No information" icon={<InfoCircleOutlined />} />
        )}
      </div>

      <Modal
        title="Upload Pitch video"
        open={isModalVisible}
        onCancel={toggleModal}
        width={900}
        bodyStyle={{
          padding: 0,
        }}
        footer={false}
      >
        <EditPitchForm
          companyId={companyId}
          onFinish={toggleModal}
          onCancel={toggleModal}
        />
      </Modal>
    </>
  );
};

export default PitchVideo;
