import { UploadedFile } from './common';
import { Company } from './company';
import { Discussion } from './discussion';
import { Innovation } from './innovation';
import { KeyTag } from './key-tag';
import { Region } from './region';
import { Topic } from './topic';
import { User } from './user';

export enum PostStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface Post {
  id: string;
  status: PostStatus;
  title: string;
  body: string;
  banner?: UploadedFile;
  company?: Company;
  topics?: [Topic];
  regions?: [Region];
  excerpt?: string;
  createdBy?: User;
  createdAt?: string;
  publishedAt?: string;
  approvedBy?: User;
  approvedAt?: string;
  likes?: number;
  comments?: number;
  isLiked?: boolean;
  discussion?: Discussion;
  rejectDesc?: string;
  keyTags: KeyTag[];
  writtenBy?: User;
  isPublic: boolean;
  event: boolean;
  eventTimezone: string;
  eventStartDate: string;
  eventEndDate: string;
  eventRegistrationDate: string;
  eventLocation: string;
  rsvp: boolean;
  rsvpList: User[];
  innovationSpaceTag?: Innovation;
}

export interface PostBasic {
  id: string;
  title: string;
  body: string;
  banner?: UploadedFile;
  excerpt?: string;
  createdBy?: User;
  publishedAt?: string;
  likes?: number;
  comments?: number;
  status: PostStatus;
  isLiked?: boolean;
  keyTags: KeyTag[];
  writtenBy?: User;
  isPublic: boolean;
  event: boolean;
  eventTimezone: string;
  eventStartDate: string;
  eventEndDate: string;
  eventRegistrationDate: string;
  eventLocation: string;
  rsvp: boolean;
  rsvpList: User[];
}

export interface LikePost {
  likePost: {
    postId: string;
    userId: number;
  };
}

export type FeaturedContentType = 'POST' | 'EVENT';
export type FeaturingType = 'INTERNAL' | 'EXTERNAL';

export interface FeaturedContent {
  id: string;
  content: Post;
  rank: number;
  type: FeaturedContentType;
  featureFor: FeaturingType;
  createdAt: string;
  updatedAt: string;
}
