import { emailConfirm, emailGenerateCode } from '@frontend/data-access';
import { Alert, AlertMessage } from '@frontend/shared/ui';
import { Button, Col, Form, Input, Row, Spin, Typography } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { getAuthErrorMessages } from '../auth-helper';

/* eslint-disable-next-line */
export interface ConfirmProps {}

export function Confirm(props: ConfirmProps) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [resendClicked, setResendClicked] = useState(false);
  const [alert, setAlert] = useState<Alert>();
  const router = useRouter();
  const token = router.query['confirmation_token'];

  const handleConfirm = useCallback(async () => {
    if (router?.query['confirmation_token']) {
      try {
        setAlert(undefined);
        setLoading(true);
        const response = await emailConfirm({
          token: router?.query['confirmation_token']?.toString(),
        });
        if (response.status === 200) {
          setAlert({
            messages: ['Your profile successfully verified.'],
            type: 'success',
          });
        } else {
          setAlert({
            messages: getAuthErrorMessages(['auth.invalid_token']),
            type: 'error',
          });
        }
      } catch (error) {
        let message = 'Unknown error';
        if (error instanceof Error) message = error.message;
        setAlert({ messages: [message], type: 'error' });
      } finally {
        setLoading(false);
      }
    }
  }, [router]);

  useEffect(() => {
    if (token) {
      handleConfirm();
    }
  }, [token, handleConfirm]);

  const handleResend = async (values: { email: string }) => {
    try {
      setAlert(undefined);
      setLoading(true);

      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
      const token = await executeRecaptcha('email_verification');

      const { status } = await emailGenerateCode({
        email: values.email,
        captcha: token
      });

      if (status === 200) {
        setAlert({
          messages: [
            'Email successfully sent. Please check your email and confirm your email address.',
          ],
          type: 'success',
        });
      } else {
        setAlert({ messages: ['Token has expired'], type: 'error' });
      }
    } catch (error) {
      let message = 'Email verification error';
      if (error instanceof Error) message = error.message;
      setAlert({ messages: [message], type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Col className="w-96">
      <Col className="flex justify-center">
        <Typography.Title level={3}>Join FinTech Bridge</Typography.Title>
      </Col>
      {alert && <AlertMessage className="my-6" alert={alert} />}
      {alert?.type === 'error' && (
        <Row className="py-4 flex justify-center">
          {resendClicked ? (
            <Form
              layout="vertical"
              form={form}
              onFinish={handleResend}
              className="w-full"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please input your email',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  block
                  size="large"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Resend
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Typography>
              <Button
                loading={loading}
                onClick={() => setResendClicked(true)}
                type="link"
              >
                Resend activation link
              </Button>
            </Typography>
          )}
        </Row>
      )}
      {loading && (
        <Col className="flex justify-center">
          <Spin />
        </Col>
      )}
      <Row className="py-4 flex justify-center">
        <Typography>
          <Link href="login">Back to Login</Link>
        </Typography>
      </Row>
    </Col>
  );
}

export default Confirm;
