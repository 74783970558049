const UNSPLASH_API_URL = 'https://api.unsplash.com';
const ACCESS_KEY = process.env.NEXT_PUBLIC_UNSPLASH_ACCESS_KEY;

export const getPhoto = ({
  perPage,
  page,
  query,
  orientation,
}: {
  perPage: number;
  page: number;
  query: string;
  orientation: 'landscape' | 'portrait' | 'squarish';
}) => {
  return fetch(
    `${UNSPLASH_API_URL}/search/photos?client_id=${ACCESS_KEY}&page=${page}&per_page=${perPage}&query=${encodeURIComponent(
      query
    )}&orientation=${orientation}`
  );
};
