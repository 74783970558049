import { SearchOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Company,
  ErrorResponse,
  GET_COMPANY,
  GET_TOPICS,
  PATCH_COMPANY,
  Topic,
} from '@frontend/data-access';
import { TagPicker } from '@frontend/shared/ui';
import { Button, Form, Input, notification, Spin } from 'antd';
import { useEffect, useState } from 'react';

interface FormValues {
  description: string;
}

function EditIndustriesForm({
  companyId,
  onFinish,
  onCancel,
}: {
  companyId: number;
  onFinish: () => void;
  onCancel: () => void;
}) {
  const { data, loading } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: companyId },
  });
  const [isIncomplete, setIsIncomplete] = useState(false);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [form] = Form.useForm();
  const { data: dataTopics } = useQuery<{
    topics: Topic[];
  }>(GET_TOPICS);

  const handleCompleted = () => {
    notification.success({
      message: 'Successfully saved',
      placement: 'bottomLeft',
    });
    onFinish();
  };

  const handleError = (data: ErrorResponse) => {
    notification.error({
      message: 'Error',
      description: data.message,
      placement: 'bottomLeft',
    });
  };

  const [patchCompany, { loading: loadingPatch }] = useMutation(PATCH_COMPANY, {
    onCompleted: handleCompleted,
    onError: handleError,
  });

  const handleOnFinish = (values: FormValues) => {
    patchCompany({
      variables: {
        input: {
          id: companyId,
          ...values,
        },
      },
    });
  };

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsIncomplete(hasErrors);
  };

  const filterByName = (tagName: string) => {
    setTopics(
      dataTopics?.topics.filter((tag) => {
        return tag.name.toLowerCase().indexOf(tagName.toLowerCase()) === 0;
      }) || []
    );
  };

  useEffect(() => {
    if (dataTopics?.topics.length) {
      setTopics(dataTopics.topics);
    }
  }, [dataTopics]);

  if (loading) {
    return (
      <div className="text-center p-6">
        <Spin />
      </div>
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFieldsChange={handleFormChange}
      onFinish={handleOnFinish}
    >
      <div className="px-6 pt-6">
        <div className="text-center">
          <h2 className="text-3xl font-medium">Institution Industry</h2>
        </div>
        <div className="flex justify-center mb-4">
          <Input
            data-test="interested-search"
            className="max-w-[463px]"
            prefix={<SearchOutlined />}
            placeholder="Search"
            onChange={(e) => filterByName(e.target.value)}
          />
        </div>
        <Form.Item
          name="topicIds"
          label="Industry"
          rules={[{ required: true, message: 'Please select industries' }]}
        >
          <TagPicker
            defaultValues={data?.company.topics?.map((i) => i.id)}
            options={topics.map((i) => ({
              value: i.id,
              label: i.name,
            }))}
            onChange={(value: any[]) => form.setFieldValue('topicIds', value)}
          />
        </Form.Item>
      </div>
      <div className="flex justify-end items-center px-4 py-3 border-t border-solid border-gray-400 gap-2">
        <Button disabled={loadingPatch} shape="round" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          loading={loadingPatch}
          disabled={isIncomplete}
          shape="round"
        >
          Save
        </Button>
      </div>
    </Form>
  );
}

export default EditIndustriesForm;
