import { useQuery } from '@apollo/client';
import { GET_ME, Me, User } from '@frontend/data-access';
import { formatNumber, getLinkLabel } from '@frontend/shared/util';
import { Progress, Typography } from 'antd';

import UserAvatar from '../../user-avatar/user-avatar';

function UserProfileHeader({ data }: { data: User }) {
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);
  const isShowUserBadge =
    data.role !== 'EXTERNAL_USER' && meData?.me?.role !== 'EXTERNAL_USER';

  return (
    <div className="w-full flex justify-center">
      <div className="space-y-6 lg:w-1/2 w-full">
        <div className="flex flex-wrap lg:flex-nowrap gap-4 lg:gap-9 items-center justify-center">
          <UserAvatar
            size={100}
            badgeSize={40}
            user={data}
            showBadge={isShowUserBadge}
            isClickable={false}
          />
          <div className="flex flex-col gap-1">
            <div className="flex flex-row items-center gap-2">
              <Typography.Text className="flex-1 text-gray-900 text-3xl font-medium">
                {data?.fullName}
              </Typography.Text>
            </div>
            <div data-test="profile-role">
              {data?.role && getLinkLabel(data.role.toLowerCase())}
            </div>
            {data?.profile?.title && (
              <div data-test="profile-title">Title: {data?.profile?.title}</div>
            )}
            {isShowUserBadge && (
              <div>
                <div className="flex justify-between gap-2">
                  <span className="text-primary-600 text-sm font-medium">
                    Level {data?.rank?.badge.level} - {data?.rank?.badge.name}
                  </span>
                  <span className="text-gray-600 text-sm font-medium">
                    {`${formatNumber(data?.rank?.rank)} / ${formatNumber(
                      data?.rank?.badge.endPoint
                    )}`}
                  </span>
                </div>
                {data?.rank && (
                  <Progress
                    percent={
                      (100 * (data.rank.rank - data.rank.badge.startPoint)) /
                      (data.rank.badge.endPoint - data.rank.badge.startPoint)
                    }
                    showInfo={false}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfileHeader;
