import { gql } from '@apollo/client';

export const FUNDER = gql`
  fragment funder on Funder {
    id
    name
  }
`;

export const BUSINESS_STAGE = gql`
  fragment businessStage on BusinessStage {
    id
    name
  }
`;

export const FUNDING_STAGE = gql`
  fragment fundingStage on FundingStage {
    id
    name
  }
`;

export const GET_FUNDERS = gql`
  query Funders {
    funders {
      ...funder
    }
  }
  ${FUNDER}
`;

export const GET_BUSINESS_STAGES = gql`
  query BusinessStages {
    businessStages {
      ...businessStage
    }
  }
  ${BUSINESS_STAGE}
`;

export const GET_FUNDING_STAGES = gql`
  query FundingStages {
    fundingStages {
      ...fundingStage
    }
  }
  ${FUNDING_STAGE}
`;
