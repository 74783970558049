export const colors = [
  '#002766',
  '#0050B3',
  '#096DD9',
  '#40A9FF',
  '#092B00',
  '#237804',
  '#52C41A',
  '#95DE64',
];

export const getRandomColor = () =>
  colors[Math.floor(Math.random() * colors.length)];

export const getColorByKey = (key: string) => {
  const index = key.charCodeAt(0) % colors.length;
  return colors[index];
};
