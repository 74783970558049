import {
  BankOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
  FacebookFilled,
  LinkedinFilled,
  LinkOutlined,
  TeamOutlined,
  TwitterOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Company, GET_COMPANY } from '@frontend/data-access';
import { bankEngagements } from '@frontend/shared/ui';
import { formatDate, getLinkLabel } from '@frontend/shared/util';
import { Button, Divider, Modal, Spin } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

import EditBasicsForm from '../edit-actions/basics';
import EditSocialAccountsForm from '../edit-actions/social-accounts';
import CompanyProfileComplete from './profile-complete';

type actionTypes = 'BASIC_INFO' | 'SOCIAL_ACCOUNTS';

function CompanySideBar() {
  const router = useRouter();
  const id = Number(router.query['companyId']);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [actionType, setActionType] = useState<actionTypes>();
  const { data, loading } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: id },
  });

  const itemsBasics = [
    {
      title: 'Location',
      icon: <EnvironmentOutlined />,
      content: data?.company.companyInfo?.location
        ? 'HQ: ' + data?.company.companyInfo?.location
        : null,
    },
    {
      title: 'Founded date',
      icon: <CalendarOutlined />,
      content: data?.company.foundedDate
        ? 'Founded ' + formatDate(data?.company.foundedDate, 'LLL yyyy')
        : null,
    },
    {
      title: 'Team',
      icon: <TeamOutlined />,
      content: data?.company.companyInfo?.employeeNumber ?? null,
    },
    {
      title: 'Engagement',
      icon: <BankOutlined />,
      content: data?.company.fundingStatus?.bankEngagement?.length
        ? bankEngagements.find(
          (item) => item.value === data?.company.fundingStatus?.bankEngagement
        )?.label
        : null,
    },
  ];

  const itemsSocials = [
    {
      title: 'Website',
      icon: <LinkOutlined />,
      content: data?.company.companyInfo?.website ?? null,
    },
    {
      title: 'Linkedin',
      icon: <LinkedinFilled />,
      content: data?.company.companyInfo?.linkedin ?? null,
    },
    {
      title: 'Facebook',
      icon: <FacebookFilled />,
      content: data?.company.companyInfo?.facebook ?? null,
    },
    {
      title: 'Twitter',
      icon: <TwitterOutlined />,
      content: data?.company.companyInfo?.twitter ?? null,
    },
  ];

  const handleModal = (type: actionTypes) => {
    setActionType(type);
    setIsOpenModal(true);
  };

  const handleClose = () => {
    setActionType(undefined);
    setIsOpenModal(false);
  };

  const renderTitle = () => {
    return 'Add ' + getLinkLabel(actionType?.toLowerCase() as string);
  };

  const renderAction = () => {
    if (data)
      switch (actionType) {
        case 'BASIC_INFO':
          return (
            <EditBasicsForm
              companyId={data?.company.id}
              onCancel={handleClose}
              onFinish={handleClose}
            />
          );
        case 'SOCIAL_ACCOUNTS':
          return (
            <EditSocialAccountsForm
              companyId={data?.company.id}
              onCancel={handleClose}
              onFinish={handleClose}
            />
          );

        default:
          break;
      }
    return <div />;
  };

  if (loading) {
    return (
      <div className="p-6 text-center">
        <Spin />
      </div>
    );
  }

  return (
    <div className="space-y-3">
      <CompanyProfileComplete />
      <div className="rounded-3xl p-5 bg-gray-200 space-y-3">
        {data?.company.isOwner && (
          <div className="flex justify-end">
            <Button type="link" onClick={() => handleModal('BASIC_INFO')}>
              Edit
            </Button>
          </div>
        )}
        {itemsBasics.map((item, index) => (
          <div
            key={index}
            className="flex justify-between items-center truncate"
          >
            <div className="flex gap-3 items-center">
              <span className="w-4 text-center">{item.icon}</span>
              {item.content ? (
                <div className="font-medium text-sm text-gray-900">
                  {item.content}
                </div>
              ) : (
                <div className="font-medium text-sm text-gray-700">
                  No information
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="rounded-3xl p-5 bg-gray-200 space-y-3">
        <div className="flex justify-between items-center">
          <div className="text-gray-900 text-sm font-semibold">Connect</div>
          {data?.company.isOwner && (
            <Button type="link" onClick={() => handleModal('SOCIAL_ACCOUNTS')}>
              Edit
            </Button>
          )}
        </div>
        {itemsSocials
          .filter((i) => i.content)
          .map((item, index) => (
            <div
              key={index}
              className="flex justify-between items-center truncate"
            >
              <div className="flex gap-3 items-center">
                <Button shape="circle">{item.icon}</Button>
                <Link
                  href={item.content as string}
                  className="font-medium text-sm text-primary-600 line-clamp-1"
                >
                  {item.content}
                </Link>
              </div>
            </div>
          ))}
        {itemsSocials.filter((i) => i.title !== 'Website' && i.content)
          .length === 0 && data?.company.isOwner && (
            <>
              <Divider />
              <div className="text-center space-y-3 p-4">
                <div className="bg-white py-2 px-2 rounded-lg h-6 w-6 flex justify-center items-center m-auto">
                  <LinkOutlined />
                </div>
                <div className="space-y-1">
                  <div className="text-gray-900 text-sm">
                    You haven't entered any social accounts
                  </div>
                  <p className="text-gray-700 text-sm">
                    Insert your social page links for interested parties to
                    explore more about you
                  </p>
                </div>
              </div>
            </>
          )}
      </div>

      <Modal
        title={renderTitle()}
        open={isOpenModal}
        onCancel={() => handleClose()}
        width={800}
        bodyStyle={{
          padding: 0,
        }}
        footer={false}
      >
        {renderAction()}
      </Modal>
    </div>
  );
}

export default CompanySideBar;
