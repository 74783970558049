import { BellOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Typography, Row, Empty } from 'antd';
import Link from 'next/link';
import React, { useState, useEffect } from 'react';
import NotificationItem from './notification-item/notification-item';
import { useQuery } from '@apollo/client';
import { GET_ME } from '@frontend/data-access';
import { updateField } from './firebase-query/query';
import {
  onSnapshot,
  collection,
  getFirestore,
  query,
  orderBy,
  startAfter,
  limit,
  getDocs,
} from 'firebase/firestore';
import { useRouter } from 'next/router';
import { FirebaseApp } from '@firebase/app';
import useTranslation from 'next-translate/useTranslation';

const { Title } = Typography;
/* eslint-disable-next-line */

export type NotificationMode = 'FULL' | 'COMPACT';

export interface NotificationProps {
  mode?: NotificationMode;
  firebaseUser?: string;
  firebaseApp?: FirebaseApp;
  setOpen?(data: boolean): void;
}

export type NotificationData = {
  content?: string;
  type?: string;
  read?: boolean;
  createdAt?: { seconds: number; nanoseconds: number };
  id: string;
  link?: string | undefined;
};

export function Notification({
  mode = 'FULL',
  firebaseUser,
  firebaseApp,
  setOpen,
}: NotificationProps) {
  const { t } = useTranslation('common');
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const [lastDoc, setLastDoc] = useState<NotificationData | null>();
  const router = useRouter();

  const { data: meData } = useQuery<{
    me: {
      id: number;
      authToken: string;
    };
  }>(GET_ME);

  const handleClick = async (
    index: string,
    link: string | undefined,
    firebaseApp?: FirebaseApp
  ) => {
    const notificationIndex = notifications.findIndex(
      (notification) => notification.id === index
    );

    if (notificationIndex !== -1) {
      const updatedNotifications = [...notifications];
      updatedNotifications[notificationIndex] = {
        ...updatedNotifications[notificationIndex],
        read: true,
      };
      setNotifications(updatedNotifications);
    }

    if (firebaseApp) {
      updateField(`${meData?.me.id}`, index, firebaseApp);
    }

    if (link) {
      router.push(link);
    }
    if (setOpen) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (firebaseUser && meData?.me) {
      if (firebaseApp) {
        const db = getFirestore(firebaseApp);
        const notifyCol = collection(
          db,
          'users',
          `${meData.me.id}`,
          'notifications'
        );
        const query_ = query(
          notifyCol,
          orderBy('createdAt', 'desc'),
          limit(20)
        );

        return onSnapshot(query_, (snapshot) => {
          const data: NotificationData[] = [];
          snapshot.docs.forEach((doc) => {
            data.push({
              ...doc.data(),
              id: doc.id,
            });
          });

          if (firebaseUser?.trim() === `${meData?.me.id}`) {
            setNotifications((prevNotifications) => {
              if (!snapshot.empty) {
                if (snapshot.size >= 20) {
                  setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
                }
                return [...data];
              } else {
                setLastDoc(null);
                return prevNotifications;
              }
            });
          } else {
            setNotifications([]);
          }
        });
      }
    }
  }, [firebaseUser, meData]);

  const handleLoadMore = async () => {
    if (lastDoc) {
      const db = getFirestore();
      const notifyCol = collection(
        db,
        'users',
        `${meData?.me.id}`,
        'notifications'
      );

      const query_ = query(
        notifyCol,
        orderBy('createdAt', 'desc'),
        startAfter(lastDoc),
        limit(20)
      );
      const querySnapshot = await getDocs(query_);
      const newNotifications = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      if (querySnapshot.empty) {
        setLastDoc(null);
      } else {
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...newNotifications,
      ]);
    }
  };
  const closeDrawer = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <>
      <Title
        level={4}
        className={`flex justify-between text-gray-1000 p-4 ${
          mode === 'COMPACT' ? 'border-b border-gray-300' : ''
        }`}
      >
        <div>
          <BellOutlined className="mr-3" />
          <span className="text-gray-900 !text-base">
            {t('notification_title')}
          </span>
        </div>
        {mode === 'COMPACT' && (
          <Link href="/notification">
            <Button type="link" onClick={() => closeDrawer()}>
              {t('notification_see_all_button')}
            </Button>
          </Link>
        )}
      </Title>
      {notifications?.length ? (
        <div className="p-3">
          {notifications.map((item, index) => (
            <NotificationItem
              onClick={() => handleClick(item.id, item.link, firebaseApp)}
              key={index}
              data={item}
            />
          ))}
          {lastDoc && (
            <Row justify="center" className="py-4">
              <Button
                className="mt-2"
                type="primary"
                shape="round"
                icon={<DownOutlined />}
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            </Row>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('notification_empty')}
          />
        </div>
      )}
    </>
  );
}

export default Notification;
