import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';

export interface OnboardingHeaderProps {
  title: React.ReactNode;
  isSiderHidden: boolean;
  showMobileMenu: (value: boolean) => void;
  onSaveAndExit: () => void;
  handleExitClick: () => void;
}

export function OnboardingHeader({
  title,
  isSiderHidden,
  showMobileMenu,
  onSaveAndExit,
  handleExitClick,
}: OnboardingHeaderProps) {
  return (
    <Layout.Header className="flex items-center justify-between !leading-normal">
      <div>
        {isSiderHidden && (
          <Button
            className="mr-2"
            type="text"
            size="small"
            onClick={() => showMobileMenu(isSiderHidden)}
          >
            Menu
          </Button>
        )}
        {!isSiderHidden && <div className="text-gray-700">{title}</div>}
      </div>
      <div className="space-x-2">
        <Button
          type="link"
          size="large"
          icon={<SaveOutlined className="text-sm" />}
          onClick={onSaveAndExit}
        >
          Update
        </Button>
        <Button
          shape="circle"
          icon={<CloseOutlined />}
          onClick={handleExitClick}
        ></Button>
      </div>
    </Layout.Header>
  );
}

export default OnboardingHeader;
