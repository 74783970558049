import { CaretUpOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  IdeaContribute,
  LIKE_IDEA_CONTRIBUTION,
  UNLIKE_IDEA_CONTRIBUTION,
} from '@frontend/data-access';
import { Image, Typography } from 'antd';
import { formatDistance } from 'date-fns';
import { useState } from 'react';

import UnsplashImage from '../unsplash-image/unsplash-image';
import UserAvatar from '../user-avatar/user-avatar';

export interface IdeaContributionCardProps {
  data: IdeaContribute;
  onClickLike(ideaId: string): void;
}

export function IdeaContributionCard({
  data,
  onClickLike,
}: IdeaContributionCardProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [likeContribution] = useMutation(LIKE_IDEA_CONTRIBUTION, {
    onCompleted() {
      onClickLike(data?.id);
    },
  });
  const [unlikeContribution] = useMutation(UNLIKE_IDEA_CONTRIBUTION, {
    onCompleted() {
      onClickLike(data?.id);
    },
  });

  const handleLike = () => {
    likeContribution({
      variables: {
        id: data?.id,
      },
    });
  };

  const handleUnlike = () => {
    unlikeContribution({
      variables: {
        id: data?.id,
      },
    });
  };

  function countWords(str: string) {
    if (str) return str.trim().split(/\s+/).length;
    else return 0;
  }

  const renderAvatar = () => {
    return (
      <div className="flex gap-3 items-center">
        <UserAvatar size={40} user={data.createdBy} showBadge />
        <div className="grid">
          <span className="text-gray-900 text-base">
            {data.createdBy.fullName}
          </span>
          <span className="text-gray-700 text-sm">
            {formatDistance(new Date(data.createdAt), new Date(), {
              addSuffix: true,
            })}
          </span>
        </div>
      </div>
    );
  };

  const renderActions = () => {
    return (
      <div className="flex gap-4">
        <div
          onClick={() => (data?.isLiked ? handleUnlike() : handleLike())}
          className={`px-2 py-1 rounded-full border border-gray-400 flex flex-col gap-0 items-center justify-center select-none cursor-pointer ${
            data?.isLiked
              ? 'bg-primary-600 text-white'
              : 'hover:border-primary-600 hover:text-primary-600'
          }`}
        >
          <CaretUpOutlined />
          <span className="text-xs">{data?.likes}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-3xl border border-gray-400 p-6 space-y-6">
      <div className="flex justify-between items-center">
        <div>{renderAvatar()}</div>
        <div>{renderActions()}</div>
      </div>
      <div className="min-h-[218px]">
        {data?.coverPhotoUrl && (
          <div className="md:min-w-[218px] max-w-[218px] mr-5">
            <div className="flex flex-col">
              <UnsplashImage image={data?.coverPhotoUrl} name={data?.title} />
            </div>
          </div>
        )}
        {data?.photo && (
          <div className="md:min-w-[218px] max-w-[218px] mr-5">
            <div className="flex flex-col">
              <Image
                className="rounded-xl overflow-hidden"
                alt={data?.title}
                width={218}
                height={218}
                src={data?.photo?.url}
                preview={false}
              />
            </div>
          </div>
        )}
        <div className="contents text-sm">
          <Typography.Title level={5}>{data?.title}</Typography.Title>
          <Typography.Paragraph>
            <span className={`${isCollapsed ? '' : 'line-clamp-[8]'}`}>
              {data.body}
            </span>
            {countWords(data?.body) > 70 && (
              <span
                className="text-primary-600 cursor-pointer"
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                {isCollapsed ? 'Hide' : 'See more'}
              </span>
            )}
          </Typography.Paragraph>
        </div>
      </div>
    </div>
  );
}

export default IdeaContributionCard;
