import { Col, Row } from 'antd';
import { ReactNode } from 'react';

interface IconCardProps {
  title: string;
  icon: ReactNode;
  children: ReactNode;
}

export function IconCard({ title, icon, children }: IconCardProps) {
  return (
    <Row className="border border-gray-300 rounded-xl p-4 gap-4 shadow-sm">
      <Col>
        <div className="bg-gray-200  py-2 px-2 rounded-lg h-6 w-6 flex justify-center items-center">
          {icon}
        </div>
      </Col>
      <Col span={20}>
        <div className="font-medium mb-1">{title}</div>
        {children}
      </Col>
    </Row>
  );
}

export default IconCard;
