import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { GET_ME } from '@frontend/data-access';
import { Button } from 'antd';
import { useEffect, useState } from 'react';

import ContentPlaceholder, {
  ContentTypes,
} from '../content-placeholder/content-placeholder';

export interface YoutubePlayerProps {
  url: string;
  id: string;
  kind?: string;
}

export function YoutubePlayer({ id, url, kind }: YoutubePlayerProps) {
  const [hasCopied, setHasCopied] = useState(false);

  const youtubeId = (url: string) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url?.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hasCopied) {
        setHasCopied(false);
      }
    }, 2500);
    return () => clearTimeout(timer);
  }, [hasCopied]);

  const { data: meData } = useQuery<{
    me: {
      id: number;
      authToken: string;
      role: string;
    };
  }>(GET_ME);

  const ytId = youtubeId(url);

  const handleCopy = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(url);
      setHasCopied(true);
    }
  };

  if (meData?.me.role !== 'EXTERNAL_USER') {
    return (
      <div className="flex flex-col gap-2">
        <ContentPlaceholder
          className="rounded-2xl h-full"
          type={ContentTypes.VIDEO_NO_PERMISSION}
          kind={kind}
          key={url}
          id={id}
        />
        {url && (
          <div className="ml-auto">
            <Button
              shape="round"
              icon={hasCopied ? <CheckOutlined /> : <CopyOutlined />}
              onClick={handleCopy}
            >
              {hasCopied ? 'Copied' : 'Copy youtube link'}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="relative h-0 overflow-hidden pb-[56.25%]">
        <iframe
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            borderRadius: '0.375rem',
          }}
          width="100%"
          height="480"
          src={`https://www.youtube.com/embed/${ytId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  );
}

export default YoutubePlayer;
