import getConfig from 'next/config';
import Image from 'next/image';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

export function SocialShareButtons() {
  const { publicRuntimeConfig } = getConfig();
  const url = typeof window === 'undefined' ? '/' : window?.location?.href;

  return (
    <div className="w-full flex flex-wrap gap-2">
      <TwitterShareButton url={url}>
        <div className="bg-[#55ACEE] border-[#55ACEE] rounded-3xl h-6 px-3 text-white flex justify-center items-center gap-2 text-xs">
          <Image
            src={`${publicRuntimeConfig.staticFolder}/svg/twitter.svg`}
            alt="twitter"
            width={14}
            height={14}
          />
          Twitter
        </div>
      </TwitterShareButton>
      <FacebookShareButton url={url}>
        <div className="bg-[#1877F2] border-[#1877F2] rounded-3xl h-6 px-3 text-white flex justify-center items-center gap-2 text-xs">
          <Image
            src={`${publicRuntimeConfig.staticFolder}/svg/facebook.svg`}
            alt="facebook"
            width={14}
            height={14}
          />
          Facebook
        </div>
      </FacebookShareButton>
      <LinkedinShareButton url={url}>
        <div className="bg-[#0073B1] border-[#0073B1] rounded-3xl h-6 px-3 text-white flex justify-center items-center gap-2 text-xs">
          <Image
            src={`${publicRuntimeConfig.staticFolder}/svg/linkedin.svg`}
            alt="linkedin"
            width={14}
            height={14}
          />
          LinkedIn
        </div>
      </LinkedinShareButton>
    </div>
  );
}

export default SocialShareButtons;
