import {
  CheckCircleFilled,
  CheckCircleOutlined,
  LockFilled,
  UnlockFilled,
} from '@ant-design/icons';
import {
  ApolloCache,
  NormalizedCacheObject,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  ChallengeApplication,
  ChallengeApplicationForUser,
  DELETE_APPLICATION_DOCUMENT,
  DeleteApplicationDocument,
  GET_INTRAPRENEUR_CHALLENGE_STAGES,
  GET_INTRAPRENEUR_CHALLENGES_APPLICATION_FOR_USER,
  GET_ME,
  GET_OPEN_CHALLENGE_STAGES,
  Me,
  Stage,
  UPLOAD_APPLICATION_DOCUMENT,
  UploadApplicationDocument,
  UploadedFile,
} from '@frontend/data-access';
import { Collapse, CollapsePanelProps, notification, UploadFile } from 'antd';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import FileUpload, {
  supportedDocumentFormats,
} from '../file-upload/file-upload';
import { Document } from './document/document';

const { Panel } = Collapse;

/* eslint-disable-next-line */
export interface ApplicationDocumentProps {
  application: ChallengeApplication;
}

export function ApplicationDocument({ application }: ApplicationDocumentProps) {
  const router = useRouter();
  const { applicationId, id } = router.query;

  const [viewerId, setViewerId] = useState<number>();
  const [allStages, setAllStages] = useState<Stage[]>([]);
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);
  const [getData, { data: challengeStages }] = useLazyQuery<{
    internalChallenge: {
      stages: Stage[];
    };
    openChallenge: {
      stages: Stage[];
    };
  }>(
    application?.challenge?.__typename === 'InternalChallenge'
      ? GET_INTRAPRENEUR_CHALLENGE_STAGES
      : GET_OPEN_CHALLENGE_STAGES
  );

  useEffect(() => {
    if (application) {
      getData({
        variables: {
          challengeId: id,
        },
      });
    }
  }, [application, getData, id]);

  const handleUploadCacheUpdate = (
    cache: ApolloCache<NormalizedCacheObject>,
    { data }: { data: UploadApplicationDocument }
  ) => {
    const application: ChallengeApplicationForUser | null = cache.readQuery({
      query: GET_INTRAPRENEUR_CHALLENGES_APPLICATION_FOR_USER,
      variables: { id: applicationId },
    });

    if (application) {
      const { challengeApplicationForUser } = application;
      cache.writeQuery({
        query: GET_INTRAPRENEUR_CHALLENGES_APPLICATION_FOR_USER,
        variables: { id: applicationId },
        data: {
          challengeApplicationForUser: {
            ...challengeApplicationForUser,
            documents: [
              ...challengeApplicationForUser.documents,
              data.uploadApplicationDocument,
            ],
          },
        },
      });
    }
  };

  const handleDeleteCacheUpdate = (
    cache: ApolloCache<NormalizedCacheObject>,
    { data }: { data: DeleteApplicationDocument }
  ) => {
    const application: ChallengeApplicationForUser | null = cache.readQuery({
      query: GET_INTRAPRENEUR_CHALLENGES_APPLICATION_FOR_USER,
      variables: { id: applicationId },
    });

    if (application) {
      const { challengeApplicationForUser } = application;
      cache.writeQuery({
        query: GET_INTRAPRENEUR_CHALLENGES_APPLICATION_FOR_USER,
        variables: { id: applicationId },
        data: {
          challengeApplicationForUser: {
            ...challengeApplicationForUser,
            documents: [
              ...challengeApplicationForUser.documents.filter(
                (item) => item.id !== data.deleteApplicationDocument
              ),
            ],
          },
        },
      });
    }
  };

  const handleUploadCompleted = () => {
    notification.open({
      message: 'Document uploaded',
      icon: (
        <span className="text-green-600">
          <CheckCircleOutlined />
        </span>
      ),
      placement: 'bottomLeft',
    });
  };
  const handleDeleteCompleted = () => {
    notification.open({
      message: 'Document removed',
      icon: (
        <span className="text-green-600">
          <CheckCircleOutlined />
        </span>
      ),
      placement: 'bottomLeft',
    });
  };

  const [uploadApplicationDocument] = useMutation<UploadApplicationDocument>(
    UPLOAD_APPLICATION_DOCUMENT,
    { onCompleted: handleUploadCompleted, update: handleUploadCacheUpdate }
  );
  const [deleteApplicationDocument] = useMutation<DeleteApplicationDocument>(
    DELETE_APPLICATION_DOCUMENT,
    { onCompleted: handleDeleteCompleted, update: handleDeleteCacheUpdate }
  );

  const genExtra = (state: string) => {
    switch (state) {
      case 'UPCOMING':
        return <LockFilled className="!text-gray-500" />;
        break;
      case 'ENDED':
        return <CheckCircleFilled className="!text-green-800" />;
        break;
      case 'ACTIVE':
        return <UnlockFilled className="!text-gray-900" />;
        break;

      default:
        return null;
        break;
    }
  };

  const renderTitle = (title: string) => {
    return (
      <div>
        {title} <span className="ml-1 text-gray-700">(optional)</span>
      </div>
    );
  };

  const handleUploadSuccess = async (
    file: UploadedFile,
    stageId: string | null | undefined,
    setFileList: (param: UploadFile[]) => void
  ) => {
    await uploadApplicationDocument({
      variables: {
        input: { applicationId: applicationId, stageId: stageId, file: file },
      },
    });
    setFileList([]);
  };

  const handleRemoveSuccess = (file: UploadFile) => {
    //
  };

  const handleDeleteDocument = async (id: string) => {
    await deleteApplicationDocument({
      variables: {
        documentId: id,
      },
    });
  };

  useEffect(() => {
    const challenge =
      application?.challenge?.__typename === 'InternalChallenge'
        ? challengeStages?.internalChallenge
        : challengeStages?.openChallenge;
    if (challenge) {
      setAllStages([
        {
          id: '',
          name: 'Registration',
          state: 'ENDED',
          orderNumber: 0,
          startAt: '',
          endAt: '',
        },
        ...(challenge?.stages || []),
      ]);
    }
  }, [id, challengeStages]);

  useEffect(() => {
    if (meData) {
      setViewerId(meData?.me?.id);
    }
  }, [meData]);

  const checkIsOwner = () => {
    switch (application?.__typename) {
      case 'CompanyChallengeApplication':
        return application?.company?.isOwner;

      default:
        return application?.user?.id === viewerId;
    }
  };

  return (
    <Collapse className="max-w-[457px]" expandIconPosition="end">
      {allStages &&
        allStages
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .map((stage, index) => {
            const panelProps: CollapsePanelProps = {
              header: renderTitle(stage?.name),
              key: index,
              className: '!bg-white !rounded-t-md',
              extra: genExtra(stage.state),
            };
            if (stage.state === 'UPCOMING') {
              panelProps.collapsible = 'disabled';
            }
            return (
              <Panel {...panelProps}>
                {stage.state === 'ACTIVE' && checkIsOwner() && (
                  <FileUpload
                    className="mb-3"
                    description={<div>Please upload your document here</div>}
                    onUploadSuccess={(file, setFileList) =>
                      handleUploadSuccess(file, stage.id, setFileList)
                    }
                    onRemove={handleRemoveSuccess}
                    maxFiles={10}
                    maxFileSize={10485760}
                    supportedFileTypes={supportedDocumentFormats}
                  />
                )}
                <Document
                  isOwner={checkIsOwner()}
                  documents={application.documents}
                  stageId={stage.id}
                  stageState={stage.state}
                  onDelete={handleDeleteDocument}
                />
              </Panel>
            );
          })}
    </Collapse>
  );
}

export default ApplicationDocument;
