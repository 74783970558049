import { Image, Layout } from 'antd';
import getConfig from 'next/config';
import Link from 'next/link';
import { ReactNode } from 'react';

export interface BasicHeaderProps {
  title: string;
  suffix?: ReactNode;
}

export function BasicHeader({ title, suffix }: BasicHeaderProps) {
  const { publicRuntimeConfig } = getConfig();

  return (
    <Layout.Header className="flex items-center !bg-white justify-between">
      <div className="flex items-center">
        <Link href="/" passHref>
          <div className="px-6 py-3 h-[64px]">
            <Image
              src={`${publicRuntimeConfig.staticFolder}/logo-full.svg`}
              preview={false}
              height="40"
              alt="SCV logo"
            />
          </div>
        </Link>
        <div className="hidden md:block text-gray-700 ml-16">{title}</div>
      </div>
      <div>{suffix}</div>
    </Layout.Header>
  );
}

export default BasicHeader;
