import styles from './feature-auth.module.less';

/* eslint-disable-next-line */
export interface FeatureAuthProps {}

export function FeatureAuth(props: FeatureAuthProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to FeatureAuth!</h1>
    </div>
  );
}

export default FeatureAuth;
