import { useMutation, useQuery } from '@apollo/client';
import { GET_ME, Idea, Me, RESPOND_TEAM_INVITE } from '@frontend/data-access';
import { Alert, Button } from 'antd';

export function IdeaDetailTeamInvite({ idea }: { idea: Idea }) {
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);
  const invites = idea?.applicants.filter(
    (a) =>
      a.status === 'PENDING' &&
      a.userId === meData?.me.id &&
      a.type === 'INVITE'
  );

  const [respond, { loading }] = useMutation(RESPOND_TEAM_INVITE);

  const onClickRespond = (status: string) => {
    respond({ variables: { id: invites[0].id, status: status } });
  };

  if (invites.length) {
    return (
      <div>
        <Alert
          message="Invited to the team"
          description={`You have been invited to the team of ${
            idea.anonymous ? 'Anonymous member' : idea.createdBy?.fullName
          } for this idea in Fintech Bridge. `}
          type="info"
          showIcon
          action={
            <div className="space-x-2">
              <Button
                size="small"
                type="primary"
                shape="round"
                loading={loading}
                onClick={() => onClickRespond('APPROVED')}
              >
                Accept
              </Button>
              <Button
                size="small"
                danger
                ghost
                shape="round"
                loading={loading}
                onClick={() => onClickRespond('REJECTED')}
              >
                Reject
              </Button>
            </div>
          }
        />
      </div>
    );
  } else {
    return null;
  }
}

export default IdeaDetailTeamInvite;
