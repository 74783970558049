import { useLazyQuery } from '@apollo/client';
import {
  Announcement,
  Challenge,
  GET_INTRAPRENEUR_CHALLENGE_ANNOUNCEMENT,
  GET_OPEN_CHALLENGE_ANNOUNCEMENT,
} from '@frontend/data-access';
import { RichEditorSerialized } from '@frontend/shared/ui';
import { formatDate } from '@frontend/shared/util';
import { Divider, Empty, Spin, Typography } from 'antd';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

export interface ChallengeAnnouncementProps {
  challenge: Challenge & { __typename?: string };
}

export function ChallengeAnnouncement({
  challenge,
}: ChallengeAnnouncementProps) {
  const { t } = useTranslation('admin');
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [getOpenChallengeAnnouncement, resultOpenChallenge] = useLazyQuery<{
    openChallenge: {
      announcements: Announcement[];
    };
  }>(GET_OPEN_CHALLENGE_ANNOUNCEMENT);
  const [getIntraChallengeAnnouncement, resultIntraChallenge] = useLazyQuery<{
    internalChallenge: {
      announcements: Announcement[];
    };
  }>(GET_INTRAPRENEUR_CHALLENGE_ANNOUNCEMENT);

  useEffect(() => {
    if (challenge) {
      switch (challenge.__typename) {
        case 'OpenChallenge':
          getOpenChallengeAnnouncement({
            variables: {
              challengeId: challenge.id,
            },
          });
          break;
        case 'InternalChallenge':
          getIntraChallengeAnnouncement({
            variables: {
              challengeId: challenge.id,
            },
          });
          break;
        default:
          break;
      }
    }
  }, [challenge, getOpenChallengeAnnouncement, getIntraChallengeAnnouncement]);

  useEffect(() => {
    if (resultOpenChallenge?.data?.openChallenge?.announcements) {
      setAnnouncements(resultOpenChallenge.data.openChallenge.announcements);
    }

    if (resultIntraChallenge?.data?.internalChallenge?.announcements) {
      setAnnouncements(
        resultIntraChallenge.data.internalChallenge.announcements
      );
    }
  }, [resultOpenChallenge, resultIntraChallenge]);

  if (resultOpenChallenge?.loading || resultIntraChallenge?.loading) {
    return (
      <div className="flex justify-center p-5">
        <Spin />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="font-semibold mb-4">
        {t('intra_challenge_announcements')}
      </div>
      <div>
        {announcements.length ? (
          announcements.map((item, index) => {
            return (
              <div key={index}>
                <div className="flex flex-col">
                  <Typography.Text className="text-sm text-gray-700 mb-1">
                    {formatDate(item.createdAt, 'do LLL yyyy, HH:mm')}
                  </Typography.Text>
                  <Typography.Text className="text-base text-gray-900 font-medium mb-1">
                    {item.title}
                  </Typography.Text>
                  <Typography.Paragraph className="text-sm text-gray-900 font-normal !m-0">
                    <RichEditorSerialized value={item.description} />
                  </Typography.Paragraph>
                </div>
                {announcements.length !== ++index && <Divider />}
              </div>
            );
          })
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('intra_challenge_announcements_empty')}
          />
        )}
      </div>
    </div>
  );
}

export default ChallengeAnnouncement;
