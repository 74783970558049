interface EmptyCardType {
  title: string;
  description?: string;
  icon: React.ReactNode;
}

const EmptyCard = ({ title, icon, description }: EmptyCardType) => {
  return (
    <div className="w-full flex flex-col items-center gap-1 text-center">
      <div className="bg-white  py-2 px-2 rounded-lg h-6 w-6 flex justify-center items-center">
        {icon}
      </div>
      <h3 className="text-gray-900 font-medium text-sm">{title}</h3>
      <p className="text-gray-700 text-sm font-normal">{description}</p>
    </div>
  );
};

export default EmptyCard;
