import { BellOutlined } from '@ant-design/icons';
import { formatDistance } from 'date-fns';
import { NotificationData } from '../notification';
import { Typography } from 'antd';

const { Text } = Typography;

/* eslint-disable-next-line */
export interface NotificationItemProps {
  onClick: (index: string, link: string) => void;
  data: NotificationData;
}

export function NotificationItem(props: NotificationItemProps) {
  const createdAt = props.data.createdAt || { seconds: 0, nanoseconds: 0 };
  const formattedDate = new Date(createdAt?.seconds * 1000);

  const handleClick = () => {
    const { onClick, data } = props;
    onClick(data.id, data.link ?? '');
  };
  return (
    <div
      className="flex p-4 relative hover:bg-white hover:border-gray-300 hover:rounded-3xl hover:shadow-[4px_12px_40px_16px_rgba(0,0,0,0.03)] hover:cursor-pointer"
      onClick={handleClick}
    >
      <div
        className={`flex flex-col flex-shrink-0 justify-center text-center rounded-full mr-4 w-8 h-8 ${
          props.data.read
            ? 'bg-gray-300 text-gray-700 hover:cursor-pointer'
            : 'bg-primary-100 text-primary-600 after:w-2 after:h-2 after:rounded-full after:bg-primary-600 after:absolute after:right-0 after:top-1/2 hover:cursor-pointer'
        }`}
      >
        <BellOutlined />
      </div>
      <div
        className={`flex-grow ${
          props.data.read ? 'text-gray-700' : 'text-gray-1000'
        }`}
      >
        <Text>{props.data.content}</Text>
        <p className="text-gray-700 text-xs mt-1">
          {`${formatDistance(new Date(formattedDate), new Date())} ago`}
        </p>
      </div>
    </div>
  );
}

export default NotificationItem;
