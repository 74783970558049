import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export function H1Icon(props: Partial<CustomIconComponentProps>) {
  const H1Svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M6.5 16.45q-.2 0-.325-.125T6.05 16V8q0-.2.125-.338.125-.137.325-.137t.338.137q.137.138.137.338v3.525h5.075V8q0-.2.125-.338.125-.137.325-.137t.338.137q.137.138.137.338v8q0 .2-.137.325-.138.125-.338.125t-.325-.125Q12.05 16.2 12.05 16v-3.55H6.975V16q0 .2-.137.325-.138.125-.338.125Zm11 0q-.2 0-.325-.125T17.05 16V8.45H15.5q-.2 0-.325-.125T15.05 8q0-.2.125-.338.125-.137.325-.137h1.675q.35 0 .575.225.225.225.225.55V16q0 .2-.138.325-.137.125-.337.125Z" />
    </svg>
  );

  return <Icon height="18px" component={H1Svg} {...props} />;
}

export default H1Icon;
