export const getLinkLabel = (value: string): string => {
  return value
    ? (value.charAt(0).toUpperCase() + value.slice(1)).replace(/-|_/g, ' ')
    : '';
};

export const capFirst = (value: string): string => {
  return value
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

export const formatNumber = (value?: string | number) => {
  return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
};
