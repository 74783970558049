import { useEffect, useState } from 'react';

import styles from './tag-picker.module.less';

export type ItemInterface = {
  label: string;
  value: string | number;
};

/* eslint-disable-next-line */
export interface TagPickerProps {
  maxCount?: number;
  options: ItemInterface[];
  onChange: (value: any[]) => void;
  defaultValues?: any[];
}

export function TagPicker({
  options,
  onChange,
  defaultValues = [],
  maxCount,
}: TagPickerProps) {
  const [selectedTags, setSelectedTags] = useState<ItemInterface[]>([]);

  const renderClassName = (isChecked: boolean, disabled: boolean) => {
    return `py-1 px-4 rounded-full cursor-pointer select-none border border-gray-500 ${
      disabled && 'cursor-not-allowed	text-gray-700 bg-gray-300'
    } ${
      isChecked
        ? 'bg-primary-600 text-white border-transparent'
        : 'text-gray-900'
    }`;
  };

  const selectTag = (tag: ItemInterface, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t.value !== tag.value);
    setSelectedTags(nextSelectedTags);
    onChange(nextSelectedTags.map((item) => item.value));
  };

  const Tag = ({
    tag,
    isChecked,
    disabled,
  }: {
    tag: ItemInterface;
    isChecked: boolean;
    disabled: boolean;
  }) => (
    <div
      className={renderClassName(isChecked, disabled)}
      onClick={() => !disabled && selectTag(tag, !isChecked)}
    >
      {tag.label}
    </div>
  );

  useEffect(() => {
    if (defaultValues.length) {
      setSelectedTags(
        options.filter((item) => defaultValues.includes(item.value))
      );
    }
  }, [defaultValues]);

  return (
    <div className="flex flex-wrap gap-2">
      {options.map((item) => (
        <Tag
          key={item.value}
          tag={item}
          isChecked={selectedTags.some((sub) => sub.value === item.value)}
          disabled={
            (!selectedTags.some((sub) => sub.value === item.value) &&
              maxCount &&
              selectedTags.length >= maxCount) ||
            false
          }
        />
      ))}
    </div>
  );
}

export default TagPicker;
