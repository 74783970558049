import { gql } from '@apollo/client';

import { FILE } from './upload';

export const SHOWCASE = gql`
  fragment showcase on Showcase {
    id
    title
    description
    label
    type
    banner {
      ...file
    }
    link
    createdBy {
      id
      role
      fullName
      image {
        url
      }
    }
    buttonName
  }
`;

export const CREATE_SHOWCASE = gql`
  mutation CreateShowcase($input: CreateShowcaseInput!) {
    createShowcase(input: $input) {
      ...showcase
    }
  }
  ${SHOWCASE}
  ${FILE}
`;

export const PATCH_SHOWCASE = gql`
  mutation PatchShowcase($input: PatchShowcaseInput!) {
    patchShowcase(input: $input) {
      ...showcase
    }
  }
  ${SHOWCASE}
  ${FILE}
`;

export const REMOVE_SHOWCASE = gql`
  mutation RemoveShowcase($id: UUID!) {
    removeShowcase(id: $id)
  }
`;

export const GET_SHOWCASE_BY_ID = gql`
  query Showcase($id: UUID!) {
    showcase(id: $id) {
      ...showcase
    }
  }
  ${SHOWCASE}
  ${FILE}
`;

export const GET_SHOWCASES = gql`
  query Showcases($types: [ShowcaseType]!) {
    showcases(types: $types) {
      ...showcase
    }
  }
  ${SHOWCASE}
  ${FILE}
`;
