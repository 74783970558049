import { SearchOutlined } from '@ant-design/icons';
import {
  ApolloCache,
  NormalizedCacheObject,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  Company,
  ErrorResponse,
  GET_COMPANY,
  GET_REGIONS,
  Market,
  Region,
  UPSERT_MARKET,
} from '@frontend/data-access';
import { Button, Form, Input, notification, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import TagPicker from '../../../tag-picker/tag-picker';

function EditRegionForm({
  companyId,
  onFinish,
  onCancel,
}: {
  companyId: number;
  onFinish: () => void;
  onCancel: () => void;
}) {
  const { data, loading } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: companyId },
  });
  const [isIncomplete, setIsIncomplete] = useState(false);
  const [regions, setRegions] = useState<Region[]>([]);
  const [form] = Form.useForm();
  const { data: dataRegions } = useQuery<{
    regions: Region[];
  }>(GET_REGIONS);

  const regionIds = Form.useWatch('regionIds', form);

  const handleCompleted = () => {
    notification.success({
      message: 'Successfully saved',
      placement: 'bottomLeft',
    });
    onFinish();
  };

  const handleError = (data: ErrorResponse) => {
    notification.error({
      message: 'Error',
      description: data.message,
      placement: 'bottomLeft',
    });
  };

  const handleUpdateCache = (
    cache: ApolloCache<NormalizedCacheObject>,
    { data: dataMarket }: { data?: any }
  ) => {
    if (dataMarket) {
      const companyData: { company: Company } | null = cache.readQuery({
        query: GET_COMPANY,
        variables: {
          id: companyId,
        },
      });

      if (companyData?.company) {
        cache.writeQuery({
          query: GET_COMPANY,
          variables: {
            id: companyId,
          },
          data: {
            company: {
              ...companyData.company,
              market: {
                ...dataMarket.upsertMarket,
              },
            },
          },
        });
      }
    }
  };

  const [market, { loading: loadingUpsertMarket }] = useMutation(
    UPSERT_MARKET,
    {
      onCompleted: handleCompleted,
      onError: handleError,
      update: handleUpdateCache,
    }
  );

  const handleOnFinish = useCallback(
    async (values: Market) => {
      try {
        await form.validateFields();
        const params = {
          ...values,
          companyId: companyId,
        };

        await market({
          variables: { upsertMarketInput: params },
        });
      } catch (error) {
        //
      }
    },
    [companyId, form, market]
  );

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsIncomplete(hasErrors);
  };

  const filterByName = (tagName: string) => {
    setRegions(
      dataRegions?.regions.filter((tag) => {
        return tag.name.toLowerCase().indexOf(tagName.toLowerCase()) === 0;
      }) || []
    );
  };

  useEffect(() => {
    if (dataRegions?.regions.length) {
      setRegions(dataRegions.regions);
    }
  }, [dataRegions]);

  useEffect(() => {
    if (data?.company) {
      form.setFieldsValue({ regionIds: data.company?.market?.regionIds });
    }
  }, [data, form]);

  if (loading) {
    return (
      <div className="text-center p-6">
        <Spin />
      </div>
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFieldsChange={handleFormChange}
      onFinish={handleOnFinish}
    >
      <div className="px-6 pt-6">
        <div className="text-center">
          <h2 className="text-3xl font-medium">Interested Regions</h2>
          <div className="!text-center mb-10">
            Interested Regions: Select the regions that interests you for us to
            suggest personalized contents for you.
          </div>
        </div>
        <div className="flex justify-center mb-10">
          <Input
            data-test="interested-search"
            className="max-w-[463px]"
            prefix={<SearchOutlined />}
            placeholder="Search"
            onChange={(e) => filterByName(e.target.value)}
          />
        </div>
        <Form.Item
          name="regionIds"
          rules={[{ required: true, message: 'Please select industries' }]}
        >
          <TagPicker
            defaultValues={regionIds}
            options={regions.map((i) => ({
              value: i.id,
              label: i.name,
            }))}
            onChange={(value: any[]) => form.setFieldValue('regionIds', value)}
          />
        </Form.Item>
      </div>
      <div className="flex justify-end items-center px-4 py-3 border-t border-solid border-gray-400 gap-2">
        <Button disabled={loadingUpsertMarket} shape="round" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          loading={loadingUpsertMarket}
          disabled={isIncomplete}
          shape="round"
        >
          Save
        </Button>
      </div>
    </Form>
  );
}

export default EditRegionForm;
