import { gql } from '@apollo/client';

export const SKILL = gql`
  fragment skill on Skill {
    id
    name
    rootId
  }
`;

export const SEARCH_SKILLS = gql`
  query Skills($keyword: String!, $limit: Int!) {
    skills(keyword: $keyword, limit: $limit) {
      ...skill
    }
  }
  ${SKILL}
`;

export const SKILL_CATEGORY = gql`
  fragment skillCategory on Skill {
    id
    name
  }
`;
