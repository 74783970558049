import {
  ArrowRightOutlined,
  CheckCircleFilled,
  CloseOutlined,
  ContactsOutlined,
  ReadOutlined,
  RocketOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { GET_ME, GET_ME_INDICATORS, Me } from '@frontend/data-access';
import { Progress } from 'antd';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useMemo, useState } from 'react';

export function ProfileIndicatorCard({
  type,
}: {
  type: 'horizontal' | 'vertical';
}) {
  const { t } = useTranslation('common');
  const { data: meData } = useQuery<{
    me: Me;
  }>(GET_ME);
  const { data } = useQuery<{
    me: Me & {
      country: string;
      posts: {
        items: {
          id: string;
        }[];
      };
      pitches: {
        items: {
          id: string;
        }[];
      };
    };
    myCompanies: {
      id: string;
    }[];
  }>(GET_ME_INDICATORS);
  const [isHidden, setIsHidden] = useState(false);

  const title = t('profileIndicatorCard_title');
  const description = t('profileIndicatorCard_description');

  const profileChecklistComplete = useMemo(() => {
    return [
      meData?.me?.skills && meData.me.skills.length > 0,
      // meData?.me?.profile?.title && meData.me.profile.title.trim() !== '',
      data?.myCompanies && data.myCompanies.length > 0,
      meData?.me?.topics && meData.me.topics.length > 0,
      meData?.me?.profile?.location && meData.me.profile.location.trim() !== '',
      [
        meData?.me?.profile?.linkedin,
        meData?.me?.profile?.twitter,
        meData?.me?.profile?.facebook,
        meData?.me?.profile?.instagram,
        meData?.me?.profile?.medium,
      ].some((a) => !!a),
    ].every((a) => a);
  }, [
    data?.myCompanies,
    meData?.me.profile?.facebook,
    meData?.me.profile?.instagram,
    meData?.me.profile?.linkedin,
    meData?.me.profile?.location,
    meData?.me.profile?.medium,
    // meData?.me.profile?.title,
    meData?.me.profile?.twitter,
    meData?.me.skills,
    meData?.me.topics,
  ]);

  const defaultItems = useMemo(
    () => [
      {
        key: 'profile',
        icon: <SolutionOutlined />,
        title: t('profileIndicatorCard_profile_title'),
        description: t('profileIndicatorCard_profile_description'),
        linkText: t('profileIndicatorCard_update'),
        link: '/my-profile/about',
        isCompleted: profileChecklistComplete,
      },
      {
        key: 'company',
        icon: <ContactsOutlined />,
        title: t('profileIndicatorCard_company_title'),
        description: t('profileIndicatorCard_company_description'),
        linkText: t('profileIndicatorCard_create'),
        link: '/company/onboarding/basics',
        isCompleted: !!data?.myCompanies?.length,
      },
      {
        key: 'pitch',
        icon: <RocketOutlined />,
        title: t('profileIndicatorCard_pitch_title'),
        description: t('profileIndicatorCard_pitch_description'),
        linkText: t('profileIndicatorCard_started'),
        link: '/pitch-arena/create/basics',
        isCompleted: !!data?.me?.pitches?.items?.length,
      },
      {
        key: 'post',
        icon: <ReadOutlined />,
        title: t('profileIndicatorCard_post_title'),
        description: t('profileIndicatorCard_post_description'),
        linkText: t('profileIndicatorCard_post'),
        link: '/bridge',
        isCompleted: !!data?.me?.posts?.items?.length,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data?.me?.pitches?.items?.length,
      data?.me?.posts?.items?.length,
      data?.myCompanies?.length,
      profileChecklistComplete,
    ]
  );

  const filteredItems = defaultItems.filter(
    (i) => i.key !== 'company' && i.key !== 'pitch'
  );

  const items =
    meData?.me.role === 'ADMIN' ||
    meData?.me.role === 'INTRAPRENEUR' ||
    meData?.me.role === 'CHALLENGE_MANAGER'
      ? filteredItems
      : defaultItems;

  if (!meData || isHidden || items.filter((i) => !i.isCompleted).length === 0)
    return <div></div>;

  if (type === 'horizontal') {
    return (
      <div className="flex flex-col gap-6 rounded-3xl border border-gray-300 bg-white shadow-[0_4px_30px_rgba(0,0,0,0.03)] px-10 py-8">
        <div>
          <div className="flex justify-between">
            <h4 className="text-gray-900 font-semibold text-xl">{title}</h4>
            <div
              className="cursor-pointer select-none"
              onClick={() => setIsHidden(true)}
            >
              <CloseOutlined />
            </div>
          </div>
          <span className="text-gray-900 text-base font-normal">
            {description}
          </span>
        </div>
        <div
          className={`grid lg:grid-cols-${items.length} sm:grid-cols-2 gap-6`}
        >
          {items.map((item, index) => (
            <div
              key={index}
              className="p-5 border border-gray-300 rounded-3xl space-y-4"
            >
              <div className="flex justify-between items-center">
                <div
                  className={`text-3xl ${
                    item.isCompleted ? 'text-gray-600' : 'text-primary-600'
                  }`}
                >
                  {item.icon}
                </div>
                {item.isCompleted && (
                  <div className="text-xl text-primary-600">
                    <CheckCircleFilled />
                  </div>
                )}
              </div>
              <div>
                <h4
                  className={`${
                    item.isCompleted ? 'text-gray-600' : 'text-black'
                  } font-medium text-base`}
                >
                  {item.title}
                </h4>
                <span
                  className={`${
                    item.isCompleted ? 'text-gray-600' : 'text-gray-900'
                  } text-sm`}
                >
                  {item.description}
                </span>
              </div>
              <div>
                <Link
                  className={`${
                    item.isCompleted ? 'text-gray-600' : 'text-primary-600'
                  }`}
                  href={item.link}
                >
                  {item.linkText}&nbsp;
                  <ArrowRightOutlined />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col gap-4 rounded-xl border border-gray-300 bg-white shadow-sm p-6 z-10 relative">
        <div>
          <div className="flex justify-between">
            <h4 className="text-gray-900 font-semibold text-xl">{title}</h4>
            <div
              className="cursor-pointer select-none"
              onClick={() => setIsHidden(true)}
            >
              <CloseOutlined />
            </div>
          </div>
          <span className="text-gray-900 text-base font-normal">
            {description}
          </span>
        </div>
        <div>
          <span className="text-xs font-normal text-gray-700">
            {t('profileIndicatorCard_completed_description', {
              count: items.filter((i) => i.isCompleted).length,
              total: items.length,
            })}
          </span>
          <Progress
            percent={Math.floor(
              (items.filter((i) => i.isCompleted).length / items.length) * 100
            )}
            showInfo={false}
          />
        </div>
        <div>
          {items.map((item, index) => (
            <div key={index} className="p-[10px] space-y-4">
              <div className="flex gap-[10px]">
                <div className={`text-2xl text-primary-600`}>
                  {item?.isCompleted ? <CheckCircleFilled /> : item.icon}
                </div>
                <div className="w-full">
                  <h4 className={`text-gray-900 font-medium text-sm !mb-0`}>
                    {item.title}
                  </h4>
                  {item.isCompleted ? (
                    <span className="text-xs text-gray-700">
                      {t('profileIndicatorCard_completed')}
                    </span>
                  ) : (
                    <div>
                      <span className={`text-gray-800 text-xs`}>
                        {item.description}
                      </span>
                      <div>
                        <Link
                          className={`${
                            item.isCompleted
                              ? 'text-gray-600'
                              : 'text-primary-600'
                          }`}
                          href={item.link}
                        >
                          {item.linkText}&nbsp;
                          <ArrowRightOutlined />
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ProfileIndicatorCard;
