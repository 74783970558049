import styles from './feature-matchmaking.module.less';

/* eslint-disable-next-line */
export interface FeatureMatchmakingProps {}

export function FeatureMatchmaking(props: FeatureMatchmakingProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to FeatureMatchmaking!</h1>
    </div>
  );
}

export default FeatureMatchmaking;
