import { gql } from '@apollo/client';

export const FAQ = gql`
  fragment faq on Faq {
    id
    title
    body
    type
    status
    role
    user {
      id
      role
      fullName
      image {
        url
      }
    }
    createdAt
  }
`;

export const CREATE_FAQ = gql`
  mutation CreateFaq($input: CreateFaqInput!) {
    createFaq(input: $input) {
      ...faq
    }
  }
  ${FAQ}
`;

export const PATCH_FAQ = gql`
  mutation PatchFaq($input: PatchFaqInput!) {
    patchFaq(input: $input) {
      ...faq
    }
  }
  ${FAQ}
`;

export const REMOVE_FAQ = gql`
  mutation DeleteFaq($id: UUID!) {
    deleteFaq(id: $id)
  }
`;

export const GET_FAQ_BY_ID = gql`
  query Faq($id: UUID!) {
    faq(id: $id) {
      ...faq
    }
  }
  ${FAQ}
`;

export const GET_FAQS = gql`
  query Faqs($types: [FaqType]!, $statuses: [FaqStatus]!) {
    faqs(types: $types, statuses: $statuses) {
      ...faq
    }
  }
  ${FAQ}
`;

export const GET_FAQS_INTERNAL = gql`
  query FaqsInternal($types: [FaqType]!, $statuses: [FaqStatus]!) {
    faqsInternal(types: $types, statuses: $statuses) {
      ...faq
    }
  }
  ${FAQ}
`;
