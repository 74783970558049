import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  MailOutlined,
  MediumCircleFilled,
  TwitterOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { GET_ME, GET_USER_BY_ID, Me, User } from '@frontend/data-access';
import { Button, Col, Empty, Row, Spin, Typography } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';

import CompanyAvatar from '../company-avatar/company-avatar';
import UserHeaderSection from '../user-header-section/user-header-section';

const { Text } = Typography;

export function UserInfo({ user }: { user: User }) {
  const { t: tcommon } = useTranslation('common');
  const router = useRouter();
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);
  const { data, loading } = useQuery<{
    findUser: Me;
  }>(GET_USER_BY_ID, {
    variables: {
      id: user?.id,
    },
  });
  const getCompanyLink = useCallback(
    (id: number) => {
      if (router.basePath.includes('/adminftb')) {
        // Admin
        return '/company-management/' + id;
      }
      return '/company/' + id;
    },
    [router.basePath]
  );

  if (loading) {
    return (
      <div className="text-center">
        <Spin />
      </div>
    );
  }

  const companies = data?.findUser?.companies.filter(
    (company) => company.approvalStatus === 'approved'
  );

  const items = [
    {
      title: tcommon('user_profile_biography'),
      content: data?.findUser.profile?.shortBio ? (
        <span className="text-gray-900 font-normal">
          {data.findUser.profile.shortBio}
        </span>
      ) : (
        <div className="text-gray-700">{tcommon('no_information')}</div>
      ),
    },
    {
      title: tcommon('user_profile_skills'),
      content: (
        <Row className="gap-2 mt-3">
          {data?.findUser?.skills.length ? (
            data.findUser.skills.map((skill, index) => {
              return (
                <Col
                  className="bg-gray-200 py-1 px-3 rounded-full text-xs"
                  key={index}
                >
                  {skill.name}
                </Col>
              );
            })
          ) : (
            <div className="text-gray-700">{tcommon('no_information')}</div>
          )}
        </Row>
      ),
    },
    {
      title: tcommon('user_profile_interested_topics'),
      content: (
        <Row className="gap-2 mt-3">
          {data?.findUser?.topics.length ? (
            data.findUser.topics.map((topic, index) => {
              return (
                <Col
                  className="bg-gray-200 py-1 px-3 rounded-full text-xs"
                  key={index}
                >
                  {topic.name}
                </Col>
              );
            })
          ) : (
            <div className="text-gray-700">{tcommon('no_information')}</div>
          )}
        </Row>
      ),
    },
    {
      title: tcommon('user_profile_interested_region'),
      content: (
        <Row className="gap-2 mt-3">
          {data?.findUser?.regions.length ? (
            data.findUser.regions.map((region, index) => {
              return (
                <Col
                  className="bg-gray-200 py-1 px-3 rounded-full text-xs"
                  key={index}
                >
                  {region.name}
                </Col>
              );
            })
          ) : (
            <div className="text-gray-700">{tcommon('no_information')}</div>
          )}
        </Row>
      ),
    },
    {
      title: tcommon('user_profile_interested_company'),
      content: (
        <div className="space-y-4">
          {companies?.length ? (
            companies.map((company, index) => {
              return (
                <div key={index} className="bg-gray-200 p-4 rounded-xl">
                  <div className="flex gap-3">
                    <CompanyAvatar
                      size={64}
                      company={company}
                      showBadge={company.companyPortfolio?.isScvPortfolio}
                    />
                    <div className="w-full">
                      <Link
                        href={getCompanyLink(company.id)}
                        className="text-base text-gray-900"
                      >
                        <Text>{company.name}</Text>
                      </Link>
                      <span className="line-clamp-2">
                        {company.description}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="col-span-12 flex flex-col justify-center items-center gap-4">
      {data?.findUser && <UserHeaderSection data={data?.findUser} />}
      <div className="w-full">
        <div className="space-y-2">
          <div className="flex gap-2">
            {meData?.me.role !== 'EXTERNAL_USER' &&
              !data?.findUser.emailHidden && (
                <Button shape="circle" href={`mailto:${data?.findUser.email}`}>
                  <MailOutlined />
                </Button>
              )}
            {!data?.findUser.profile?.socialHidden && (
              <>
                {data?.findUser.profile?.facebook && (
                  <Button
                    shape="circle"
                    href={data?.findUser.profile?.facebook}
                    target="_blank"
                  >
                    <FacebookFilled />
                  </Button>
                )}
                {data?.findUser.profile?.twitter && (
                  <Button
                    shape="circle"
                    href={data?.findUser.profile?.twitter}
                    target="_blank"
                  >
                    <TwitterOutlined />
                  </Button>
                )}
                {data?.findUser.profile?.linkedin && (
                  <Button
                    shape="circle"
                    href={data?.findUser.profile?.linkedin}
                    target="_blank"
                  >
                    <LinkedinFilled />
                  </Button>
                )}
                {data?.findUser.profile?.instagram && (
                  <Button
                    shape="circle"
                    href={data?.findUser.profile?.instagram}
                    target="_blank"
                  >
                    <InstagramFilled />
                  </Button>
                )}
                {data?.findUser.profile?.medium && (
                  <Button
                    shape="circle"
                    href={data?.findUser.profile?.medium}
                    target="_blank"
                  >
                    <MediumCircleFilled />
                  </Button>
                )}
              </>
            )}
          </div>
          {items.map((item, index) => (
            <div key={index} className="space-y-2">
              <div className="text-gray-900 font-semibold">{item.title}</div>
              <div>{item.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserInfo;
