import { gql } from '@apollo/client';

import { COMPANY_BASIC, COMPANY_INFO } from './company';
import { KEY_TAG } from './key-tag';
import { REGION } from './region';
import { TOPIC } from './topic';
import { FILE } from './upload';
import { USER_BASICS } from './user';

export const POST = gql`
  fragment post on Post {
    id
    status
    title
    body
    banner {
      ...file
    }
    regions {
      ...region
    }
    topics {
      ...topic
    }
    excerpt
    createdBy {
      ...userBasics
    }
    createdAt
    publishedAt
    approvedBy {
      ...userBasics
    }
    company {
      ...basicInfo
    }
    approvedAt
    likes
    isLiked
    comments
    discussion {
      id
    }
    rejectDesc
    keyTags {
      ...keyTag
    }
    writtenBy {
      ...userBasics
    }
    isPublic
    event
    eventTimezone
    eventStartDate
    eventEndDate
    eventRegistrationDate
    eventLocation
    rsvp
    innovationSpaceTag {
      id
      name
    }
  }
`;

export const POST_PAGINATION_TOTAL = gql`
  fragment postPaginationTotal on PostPaginationTotal {
    items {
      ...post
    }
    total
  }
`;

export const FEATURED_CONTENT = gql`
  fragment featuredContent on FeaturedContent {
    id
    content {
      ...post
    }
    rank
    type
    featureFor
    createdAt
  }
`;

export const GET_POST_STATISTICS = gql`
  query postStatusCount {
    postStatusCount {
      name
      count
    }
  }
`;

export const GET_POSTS = gql`
  query Posts(
    $searchBy: String
    $filterBy: PostFilters
    $sort: PostSortOption
    $page: Int
    $limit: Int
  ) {
    posts(
      searchBy: $searchBy
      filterBy: $filterBy
      sort: $sort
      page: $page
      limit: $limit
    ) {
      ...postPaginationTotal
    }
  }
  ${POST_PAGINATION_TOTAL}
  ${POST}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${REGION}
  ${TOPIC}
  ${FILE}
  ${KEY_TAG}
`;

export const GET_POST_BY_ID = gql`
  query Post($id: UUID!) {
    post(id: $id) {
      ...post
    }
  }
  ${POST}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${REGION}
  ${TOPIC}
  ${FILE}
  ${KEY_TAG}
`;
export const GET_POST_BY_ID_ADMIN = gql`
  query Post($id: UUID!) {
    post(id: $id) {
      ...post
      rsvpList {
        id
        fullName
        email
        companies {
          name
          approvalStatus
        }
        profile {
          title
        }
      }
    }
  }
  ${POST}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${REGION}
  ${TOPIC}
  ${FILE}
  ${KEY_TAG}
`;

export const GET_POSTED_USERS = gql`
  query PostedUsers($createdBy: String) {
    postedUsers(createdBy: $createdBy) {
      userWithIdAndFullName {
        id
        name
      }
      count
    }
  }
`;

export const CREATE_POST = gql`
  mutation createPost($input: CreatePostInput!) {
    createPost(input: $input) {
      ...post
    }
  }
  ${POST}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${REGION}
  ${TOPIC}
  ${FILE}
  ${KEY_TAG}
`;

export const GET_MY_POSTS = gql`
  query MyPosts(
    $filterBy: PostFilters
    $page: Int
    $limit: Int
    $sort: PostSortOption
  ) {
    me {
      posts(filterBy: $filterBy, page: $page, limit: $limit, sort: $sort) {
        items {
          id
          status
          title
          excerpt
          banner {
            ...file
          }
          status
          createdAt
          rejectDesc
        }
        total
      }
    }
  }
  ${FILE}
`;

export const GET_POST_SUGGESTION = gql`
  query PostSuggestion($input: SuggestPostInput!) {
    postSuggestion(input: $input) {
      id
      title
      excerpt
      publishedAt
      likes
      isLiked
      comments
      banner {
        ...file
      }
      createdBy {
        ...userBasics
      }
      writtenBy {
        ...userBasics
      }
      event
      isPublic
    }
  }
  ${USER_BASICS}
  ${FILE}
`;

export const GET_RELATED_POST = gql`
  query RelatedPosts($spaceId: UUID) {
    relatedPosts(spaceId: $spaceId) {
      id
      title
      excerpt
      publishedAt
      likes
      isLiked
      comments
      banner {
        ...file
      }
      createdBy {
        ...userBasics
      }
      keyTags {
        id
        name
      }
      writtenBy {
        ...userBasics
      }
    }
  }
  ${USER_BASICS}
  ${FILE}
`;

export const GET_POSTS_FOR_USER = gql`
  query postsForUser(
    $first: Int
    $after: String
    $isAll: Boolean!
    $filterBy: PostTopicRegionFilters
  ) {
    postsForUser(
      first: $first
      after: $after
      isAll: $isAll
      filterBy: $filterBy
    ) {
      edges {
        node {
          id
          title
          excerpt
          publishedAt
          likes
          comments
          isLiked
          banner {
            ...file
          }
          createdBy {
            ...userBasics
          }
          keyTags {
            id
            name
          }
          writtenBy {
            ...userBasics
          }
          isPublic
          event
          innovationSpaceTag {
            id
            name
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${USER_BASICS}
  ${FILE}
`;

export const GET_POSTS_BY_USER_ID = gql`
  query UserPosts($userId: Int!, $first: Int, $after: String) {
    userPosts(userId: $userId, first: $first, after: $after) {
      edges {
        node {
          id
          title
          excerpt
          publishedAt
          likes
          comments
          isLiked
          banner {
            ...file
          }
          createdBy {
            ...userBasics
          }
          keyTags {
            id
            name
          }
          writtenBy {
            ...userBasics
          }
          isPublic
          event
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${USER_BASICS}
  ${FILE}
`;

export const GET_FEATURED_CONTENTS = gql`
  query ListFeaturedContents($input: ListFeaturedContentsInput) {
    listFeaturedContents(input: $input) {
      ...featuredContent
    }
  }
  ${FEATURED_CONTENT}
  ${POST}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${REGION}
  ${TOPIC}
  ${FILE}
  ${KEY_TAG}
`;

export const FEATURE_SEARCH_CONTENT = gql`
  query SearchContentToFeature($input: PostSearchInput) {
    searchContentToFeature(input: $input) {
      id
      title
      banner {
        ...file
      }
    }
  }
  ${FILE}
`;

export const CHECK_POST_PERMISSION = gql`
  query CheckPostPermission($id: UUID) {
    checkPostPermission(id: $id)
  }
`;

export const PATCH_POST = gql`
  mutation patchPost($input: PatchPostInput!) {
    patchPost(input: $input) {
      ...post
    }
  }
  ${POST}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${REGION}
  ${TOPIC}
  ${FILE}
  ${KEY_TAG}
`;

export const PUBLISH_POST = gql`
  mutation publishPost($input: PatchPostInput!) {
    publishPost(input: $input) {
      ...post
    }
  }
  ${POST}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${REGION}
  ${TOPIC}
  ${FILE}
  ${KEY_TAG}
`;

export const DELETE_POST_BY_ID = gql`
  mutation deletePost($id: UUID!) {
    deletePost(id: $id)
  }
`;

export const APPROVE_POST = gql`
  mutation ApprovePost($id: UUID!) {
    approvePost(id: $id) {
      ...post
    }
  }
  ${POST}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${REGION}
  ${TOPIC}
  ${FILE}
  ${KEY_TAG}
`;

export const REJECT_POST = gql`
  mutation RejectPost($id: UUID!, $desc: String) {
    rejectPost(id: $id, desc: $desc) {
      ...post
    }
  }
  ${POST}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${REGION}
  ${TOPIC}
  ${FILE}
  ${KEY_TAG}
`;

export const UNLIKE_POST = gql`
  mutation likePost($id: UUID!) {
    unlikePost(id: $id)
  }
`;

export const LIKE_POST = gql`
  mutation likePost($id: UUID!) {
    likePost(id: $id) {
      postId
      userId
    }
  }
`;
export const RSVP_POST = gql`
  mutation rsvp($id: UUID!) {
    rsvp(id: $id) {
      id
      rsvp
    }
  }
`;

export const CREATE_FEATURED_CONTENTS = gql`
  mutation CreateFeaturedContents($input: CreateFeaturedContent) {
    createFeaturedContents(input: $input) {
      id
      content {
        id
        title
        banner {
          ...file
        }
      }
      createdAt
    }
  }
  ${FILE}
`;
