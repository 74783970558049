import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export function FormatAlignJustifyIcon(
  props: Partial<CustomIconComponentProps>
) {
  const FormatAlignJustifySvg = () => (
    <svg width="14" height="14" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M3,3H21V5H3V3M3,7H21V9H3V7M3,11H21V13H3V11M3,15H21V17H3V15M3,19H21V21H3V19Z"
      />
    </svg>
  );

  return <Icon height="18px" component={FormatAlignJustifySvg} {...props} />;
}

export default FormatAlignJustifyIcon;
