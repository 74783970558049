import { ShareAltOutlined } from '@ant-design/icons';
import { IconCard } from '@frontend/shared/ui';

import SocialShareButtons from '../social-share-buttons/social-share-buttons';

export function SocialShareButtonsGroup() {
  return (
    <IconCard title="Share" icon={<ShareAltOutlined />}>
      <div className="w-full flex flex-wrap gap-1 mt-3">
        <SocialShareButtons />
      </div>
    </IconCard>
  );
}

export default SocialShareButtonsGroup;
