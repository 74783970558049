import { useMutation, useQuery } from '@apollo/client';
import {
  Company,
  ErrorResponse,
  GET_COMPANY,
  PATCH_COMPANY,
} from '@frontend/data-access';
import { Button, Form, Input, notification, Spin } from 'antd';
import { useEffect, useState } from 'react';

interface FormValues {
  description: string;
}

function EditShortDescForm({
  companyId,
  onFinish,
  onCancel,
}: {
  companyId: number;
  onFinish: () => void;
  onCancel: () => void;
}) {
  const { data, loading } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: companyId },
  });
  const [isIncomplete, setIsIncomplete] = useState(false);
  const [form] = Form.useForm();

  const handleCompleted = () => {
    notification.success({
      message: 'Successfully saved',
      placement: 'bottomLeft',
    });
    onFinish();
  };

  const handleError = (data: ErrorResponse) => {
    notification.error({
      message: 'Error',
      description: data.message,
      placement: 'bottomLeft',
    });
  };

  const [patchCompany, { loading: loadingPatch }] = useMutation(PATCH_COMPANY, {
    onCompleted: handleCompleted,
    onError: handleError,
  });

  const handleOnFinish = (values: FormValues) => {
    patchCompany({
      variables: {
        input: {
          id: companyId,
          ...values,
        },
      },
    });
  };

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsIncomplete(hasErrors);
  };

  useEffect(() => {
    if (data?.company) {
      form.setFieldsValue({ description: data?.company.description });
    }
  }, [data, form]);

  if (loading) {
    return (
      <div className="p-6 text-center">
        <Spin />
      </div>
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleOnFinish}
      onFieldsChange={handleFormChange}
    >
      <div className="px-6 pt-6">
        <Form.Item
          name="description"
          label="Short description"
          rules={[{ required: true, message: 'Please input description' }]}
        >
          <Input.TextArea
            rows={6}
            maxLength={1000}
            showCount
            placeholder="Begin writing about your Institution..."
          />
        </Form.Item>
      </div>
      <div className="flex justify-end items-center px-4 py-3 border-t border-solid border-gray-400 gap-2">
        <Button disabled={loadingPatch} shape="round" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          loading={loadingPatch}
          disabled={isIncomplete}
          shape="round"
        >
          Save
        </Button>
      </div>
    </Form>
  );
}

export default EditShortDescForm;
