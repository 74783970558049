import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  firebaseUUID: string | undefined;
}

export const initialState: UserState = {
  firebaseUUID: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setFirebaseUUID: (state, action: PayloadAction<string>) => {
      state.firebaseUUID = action.payload;
    },
  },
});

export const { setFirebaseUUID } = userSlice.actions;

export default userSlice.reducer;
