import { gql } from '@apollo/client';

export const CONTRIBUTION = gql`
  fragment contribution on Contribution {
    id
    title
    description
    value
    proposalCount
    createdAt
    public
    company {
      ...basicInfo
    }
    skills {
      ...skill
    }
    accountType
    locked
    status
    userId
  }
`;

export const CONTRIBUTION_ONLY = gql`
  fragment contributionOnly on Contribution {
    id
    title
    description
    value
    proposalCount
    createdAt
    public
    skills {
      ...skill
    }
    accountType
    locked
    status
    userId
  }
`;
