import { Challenge } from '@frontend/data-access';
import { RichEditorSerialized } from '@frontend/shared/ui';
import { Carousel, Image, Typography } from 'antd';
import useTranslation from 'next-translate/useTranslation';
// import { isAfter, isBefore } from 'date-fns';

export interface ChallengeOverviewProps {
  challenge: Challenge;
  hasApply?: boolean;
}

export function ChallengeOverview({
  challenge,
  hasApply,
}: ChallengeOverviewProps) {
  const { t } = useTranslation('admin');

  // extra validation if required
  // isBefore(new Date(), new Date(challenge.registrationEndAt)) &&
  // isAfter(new Date(), new Date(challenge.registrationStartAt));
  return (
    <div className="mb-6">
      <div className="font-semibold mb-4">
        {t('intra_challenge_overview_details')}
      </div>
      <Carousel autoplay>
        <Image
          src={challenge?.banner?.url || ''}
          alt={challenge?.banner?.metadata?.filename || ''}
          preview={false}
          className="rounded-md w-full"
        />
        {/* {challenge?.images?.map((img, index) => {
          return (
            <div key={index} className="rounded-sm">
              <Image
                src={img.url || ''}
                alt={img.metadata?.filename || ''}
                className="rounded-md w-full"
                width={300}
                height={150}
              />
            </div>
          );
        })} */}
      </Carousel>
      <div className="font-semibold mb-2 mt-6">
        {t('intra_challenge_overview_description')}
      </div>
      <Typography.Text>
        <RichEditorSerialized value={challenge?.longDescription} />
      </Typography.Text>
      <Carousel autoplay className="mb-5">
        {challenge?.images?.map((img, index) => {
          return (
            <div key={index} className="rounded-sm">
              <Image
                src={img.url || ''}
                alt={img.metadata?.filename || ''}
                className="rounded-md w-full"
                width={300}
                height={150}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}

export default ChallengeOverview;
