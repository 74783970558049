import {
  CalendarOutlined,
  CheckCircleFilled,
  DownloadOutlined,
  EnvironmentOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_ME,
  GET_POST_BY_ID,
  Post,
  RSVP_POST,
  User,
} from '@frontend/data-access';
import {
  formatDateWithoutTimezone,
  timezoneStringToGmtOffsetString,
} from '@frontend/shared/util';
import { Alert, Button, Divider, Input, Modal, Table } from 'antd';
import moment from 'moment';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export interface EventCardProps {
  post: Post;
  type: 'ADMIN' | 'USER';
  customStyle?: string;
}

export function EventCard({
  post,
  type = 'USER',
  customStyle,
}: EventCardProps) {
  const { t: tcommon } = useTranslation('common');
  const router = useRouter();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showMemberListModal, setShowMemberListModal] =
    useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [memberData, setMemberData] = useState<User[]>([]);
  const [reserveEvent, { loading }] = useMutation(RSVP_POST, {
    refetchQueries: [{ query: GET_POST_BY_ID, variables: { id: post.id } }],
  });
  const handleReserveEvent = () => {
    reserveEvent({ variables: { id: post.id } });
  };
  const { data: me } = useQuery(GET_ME, {
    onCompleted(data) {
      setIsLoggedIn(true);
    },
    onError(error) {
      setIsLoggedIn(false);
    },
  });

  const renderJoinButton = (data: Post) => {
    if (type === 'ADMIN') {
      return (
        <div className="flex space-x-4">
          <Button
            shape="round"
            className="w-full"
            type="primary"
            onClick={() => setShowMemberListModal(true)}
          >
            {tcommon('event_see_member_list')}
          </Button>
          <Button
            shape="round"
            className="w-full"
            onClick={() => handleDownload()}
          >
            <DownloadOutlined /> {tcommon('event_download_email_list')}
          </Button>
        </div>
      );
    } else if (type === 'USER') {
      if (data.rsvp === true) {
        return (
          <Button shape="round" className="w-full" disabled>
            {tcommon('event_already_joined')}
          </Button>
        );
      } else if (moment().diff(data.eventRegistrationDate, 'minutes') > 0) {
        return (
          <Button shape="round" className="w-full" disabled>
            {tcommon('event_registration_closed')}
          </Button>
        );
      } else {
        return (
          <Button
            type="primary"
            shape="round"
            className="w-full"
            onClick={() => {
              if (isLoggedIn) {
                setShowModal(true);
              } else {
                router.push(
                  `/login?&referrer=${process.env['NEXT_PUBLIC_PLATFORM_URL']}${process.env['NEXT_PUBLIC_BASE_PATH']}${router.asPath}`
                );
              }
            }}
          >
            {tcommon('event_register')}
          </Button>
        );
      }
    }
    return null;
  };

  const EventInfo = ({ title, data }: { title: string; data: Post }) => (
    <div className="space-y-2">
      <h4 className="text-base font-semibold">{title}</h4>
      <div className="flex items-center">
        <CalendarOutlined className="mr-4" />
        <div className="font-semibold">
          {formatDateWithoutTimezone(data.eventStartDate, 'Do MMM YY, HH:mm')}{' '}
          {timezoneStringToGmtOffsetString(data?.eventTimezone)}
        </div>
      </div>
      <div className="flex items-center font-semibold">
        <EnvironmentOutlined className="mr-4" />
        <div>{data.eventLocation}</div>
      </div>
      {type === 'ADMIN' && (
        <div className="flex items-center font-semibold">
          <UserOutlined className="mr-4" />
          <div>
            {tcommon('event_members_joined', {
              count: data?.rsvpList ? data?.rsvpList.length : 0,
            })}
          </div>
        </div>
      )}
      <div className="text-gray-700 font-normal">
        {tcommon('event_register_by') + ' '}
        {formatDateWithoutTimezone(
          data.eventRegistrationDate,
          'Do MMM YY, HH:mm'
        )}{' '}
        {timezoneStringToGmtOffsetString(data?.eventTimezone)}
      </div>
    </div>
  );

  const downloadMemberData = (data: string) => {
    const date = new Date();
    const filename = `user_data_${date.toISOString().slice(0, 10)}.csv`;
    // const decodedString = Buffer.from(data, 'base64').toString('utf8');
    const blob = new Blob([data], {
      type: 'text/csv',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload = () => {
    const csvRows = [];
    csvRows.push(`Name, Company, Email, Title`);
    const str = memberData.map(
      (item) =>
        `${item.fullName}, ${item.companies
          ?.filter((c) => c.approvalStatus === 'approved')
          ?.map((item) => item.name)
          .join('; ')}, ${item.email}, ${item?.profile?.title}`
    );
    csvRows.push(...str);

    downloadMemberData(csvRows.join('\r\n'));
  };

  useEffect(() => {
    if (post?.rsvpList && post?.rsvpList.length > 0) {
      setMemberData(post?.rsvpList);
    }
  }, [post?.rsvpList]);

  if (post.event === false) return null;

  return (
    <>
      <div>
        <Divider />
        <div className={`bg-white rounded-xl space-y-4 ${customStyle}`}>
          <EventInfo
            title={type === 'ADMIN' ? 'Event RSVP' : 'Event date'}
            data={post}
          />
          {renderJoinButton(post)}
        </div>
      </div>
      {type === 'USER' && (
        <Modal
          title={
            post.rsvp
              ? tcommon('event_register_done')
              : tcommon('event_register_confirmation')
          }
          open={showModal}
          onCancel={() => setShowModal(false)}
          footer={
            post.rsvp === false
              ? [
                  <Button
                    shape="round"
                    key="cancel"
                    onClick={() => setShowModal(false)}
                  >
                    {tcommon('cancel')}
                  </Button>,
                  <Button
                    shape="round"
                    key="submit"
                    type="primary"
                    onClick={() => handleReserveEvent()}
                    loading={loading}
                  >
                    {tcommon('event_register')}
                  </Button>,
                ]
              : [
                  <Button
                    shape="round"
                    key="close"
                    onClick={() => setShowModal(false)}
                  >
                    {tcommon('close')}
                  </Button>,
                ]
          }
        >
          <div className="space-y-4">
            {post.rsvp !== true ? (
              <>
                <EventInfo title={post.title} data={post} />
                <Alert
                  message={tcommon('event_register_alert_message')}
                  type="info"
                />
              </>
            ) : (
              <div className="text-center space-y-4">
                <div className="text-green-600">
                  <CheckCircleFilled className="mr-4  text-7xl" />
                </div>
                <h3 className="text-2xl font-semibold">
                  {tcommon('event_register_success_title')}
                </h3>
                <div className="text-base">
                  {tcommon('event_register_success_message')}
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
      {type === 'ADMIN' && (
        <Modal
          title="Member list"
          open={showMemberListModal}
          onCancel={() => setShowMemberListModal(false)}
          footer={false}
          className="!w-1/2"
        >
          <div className="space-y-4">
            <div className="flex items-center justify-end space-x-4">
              <Button shape="round" onClick={() => handleDownload()}>
                <DownloadOutlined /> {tcommon('event_download_email_list')}
              </Button>
              <Input.Search
                placeholder={tcommon('event_register_search_name')}
                onSearch={(value) => {
                  const result = post.rsvpList.filter((item) =>
                    item.fullName.toLowerCase().includes(value.toLowerCase())
                  );
                  setMemberData(result);
                }}
                onChange={(e) => {
                  const result = post.rsvpList.filter((item) =>
                    item.fullName
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  );
                  setMemberData(result);
                }}
                style={{ width: 300 }}
                size="middle"
                enterButton
                allowClear
              />
            </div>
            <Table
              columns={[
                {
                  title: tcommon('event_register_name') as string,
                  dataIndex: 'fullName',
                  key: 'fullName',
                  className: 'whitespace-nowrap',
                },
                {
                  title: tcommon('event_register_company') as string,
                  dataIndex: 'company',
                  key: 'company',
                  render: (_, record) => {
                    const companies = record.companies
                      ?.filter((c) => c.approvalStatus === 'approved')
                      .map((c) => c.name);
                    return companies?.join(', ');
                  },
                },
                {
                  title: tcommon('event_register_email') as string,
                  dataIndex: 'email',
                  key: 'email',
                },
                {
                  title: 'Title',
                  dataIndex: 'title',
                  key: 'title',
                  render: (_, record) => record?.profile?.title,
                },
              ]}
              dataSource={memberData}
            />
          </div>
        </Modal>
      )}
    </>
  );
}

export default EventCard;
