import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { UploadedFile } from '@frontend/data-access';
import { notification } from 'antd';

import styles from './documents.module.less';

/* eslint-disable-next-line */
export interface DocumentsProps {}

export function handleOpenDocument(document: UploadedFile) {
  switch (document?.state) {
    case 'INFECTED':
      notification.open({
        message: 'Virus has been detected',
        icon: (
          <span className="text-red-600">
            <CloseCircleOutlined />
          </span>
        ),
        placement: 'bottomLeft',
      });
      break;
    case 'PROCESSING':
      notification.open({
        message: 'File virus scanning is in progress',
        icon: (
          <span className="text-primary-600">
            <InfoCircleOutlined />
          </span>
        ),
        placement: 'bottomLeft',
      });
      break;
    case 'NOT_IN_MIME':
      notification.open({
        message: 'File type not supported',
        icon: (
          <span className="text-primary-600">
            <InfoCircleOutlined />
          </span>
        ),
        placement: 'bottomLeft',
      });
      break;
    default:
      window.open(document?.url);
      break;
  }
}

export function Documents(props: DocumentsProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to Documents!</h1>
    </div>
  );
}
