import { CriteriaPatchInput } from '@frontend/data-access';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StageInput } from '.';

export interface EntrepreneurshipState {
  currentOpenChallengeId: string;
  stagesData: StageInput[];
  criteriasData: CriteriaPatchInput[];
  saveAndExit: string;
}

export const initialState: EntrepreneurshipState = {
  currentOpenChallengeId: '',
  stagesData: [],
  criteriasData: [],
  saveAndExit: '',
};

const entrepreneurshipSlice = createSlice({
  name: 'entrepreneurship',
  initialState,
  reducers: {
    setCurrentOpenChallengeId: (state, action: PayloadAction<string>) => {
      state.currentOpenChallengeId = action.payload;
    },
    setAllCriteriasData: (
      state,
      action: PayloadAction<{ criterias: CriteriaPatchInput[] }>
    ) => {
      const { criterias } = action.payload;
      state.criteriasData = criterias;
    },
    setCriteriasData: (
      state,
      action: PayloadAction<{ criteria: CriteriaPatchInput; index: number }>
    ) => {
      const { criteria, index } = action.payload;
      state.criteriasData[index] = criteria;
    },
    removeCriteriaData: (state, action: PayloadAction<number>) => {
      state.criteriasData.splice(action.payload, 1);
    },
    resetCriteriasData: (state) => {
      state.criteriasData = [];
    },
    setAllStageData: (
      state,
      action: PayloadAction<{ stages: StageInput[] }>
    ) => {
      const { stages } = action.payload;
      state.stagesData = stages;
    },
    setStagesData: (
      state,
      action: PayloadAction<{ stage: StageInput; index: number }>
    ) => {
      const { stage, index } = action.payload;
      state.stagesData[index] = stage;
    },
    removeStageData: (state, action: PayloadAction<number>) => {
      state.stagesData.splice(action.payload, 1);
    },
    resetStagesData: (state) => {
      state.stagesData = [];
    },
    setSaveAndExit: (state, action: PayloadAction<string>) => {
      state.saveAndExit = action.payload;
    },
  },
});

export const {
  setCurrentOpenChallengeId,
  setAllCriteriasData,
  setCriteriasData,
  removeCriteriaData,
  resetCriteriasData,
  setAllStageData,
  setStagesData,
  removeStageData,
  resetStagesData,
  setSaveAndExit,
} = entrepreneurshipSlice.actions;

export default entrepreneurshipSlice.reducer;
