import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Company,
  GET_COMPANY,
  GET_MATCHMAKING_RESULT_BY_ID,
  GET_ME,
  MatchmakingResult,
  Me,
} from '@frontend/data-access';
import { KeywordTag, ResultIndicator } from '@frontend/feature-matchmaking';
import { getLinkLabel } from '@frontend/shared/util';
import { Collapse, Divider, Segmented, Spin } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { useEffect, useState } from 'react';

import ProfileCover from './components/cover';
import EditInformationCard from './components/edit-info-card';
import CompanySideBar from './components/sidebar';
import About from './tab-items/about';
import InvestorInsights from './tab-items/investor-insights';

export const companyType = [
  {
    label: 'Accelerator / Incubator',
    value: 'ACCELERATOR',
  },
  {
    label: 'Consultancy firm',
    value: 'CONSULTANCY',
  },
  {
    label: 'Corporation',
    value: 'CORPORATE',
  },
  {
    label: 'Government institution',
    value: 'GOVERNMENT',
  },
  {
    label: 'Startup / SME',
    value: 'STARTUP',
  },
  {
    label: 'University / Research institution',
    value: 'RESEARCH',
  },
  {
    label: 'Venture capital / CVC',
    value: 'INVESTMENT',
  },
];

export const bankEngagements = [
  {
    value: '1',
    label: 'No engagement at all',
  },
  {
    value: '2',
    label: 'In discussion with business units, PoC/Pilot',
  },
  {
    value: '3',
    label: 'In PoC/ Pilot phase',
  },
  {
    value: '4',
    label: 'Already in a production',
  },
  {
    value: '5',
    label: 'Invested by the bank',
  },
];

export const employeeNumberOptions = [
  '1 - 10',
  '11 - 50',
  '51 - 100',
  '101 - 250',
  '251 - 500',
  '500 +',
];

export function CompanyProfile() {
  const router = useRouter();
  const id = Number(router.query['companyId']);
  const matchResultId = String(router.query['matchResultId']);
  const [tab, setTab] = useState<SegmentedValue>(0);
  const { data, loading } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: id },
  });
  const { data: dataMe } = useQuery<{ me: Me }>(GET_ME);

  const onChangeTab = (value: SegmentedValue) => {
    setTab(value);
  };

  const [getMatchmakingResult, { data: matchmakingResult }] = useLazyQuery<{
    matchmakingResultById: MatchmakingResult;
  }>(GET_MATCHMAKING_RESULT_BY_ID);

  const MatchResult = () => {
    if (matchmakingResult)
      return (
        <div className="p-4 bg-gray-200 rounded-xl mb-5">
          <EditInformationCard
            title="Matchmaking result"
            content={
              <div className="">
                <div className="w-96">
                  <ResultIndicator
                    score={
                      matchmakingResult.matchmakingResultById.details.find(
                        (v) => v.type === 'OVERALL'
                      )?.score ?? 0
                    }
                  />
                </div>
                <div className="flex items-center space-x-5 font-medium">
                  <div className="flex flex-wrap mb-4 gap-2">
                    {matchmakingResult.matchmakingResultById.keywords?.map(
                      (item) => (
                        <KeywordTag key={item.id} tag={item} />
                      )
                    )}
                  </div>
                </div>
                <Collapse expandIconPosition="end" defaultActiveKey={[1]}>
                  <Collapse.Panel
                    header={
                      <span className="text-primary-600 font-semibold">
                        VIEW REASONING
                      </span>
                    }
                    key="1"
                    style={{
                      padding: 0,
                    }}
                    className="!p-0"
                  >
                    <div className="space-y-3">
                      <div>
                        The overall synergy score reflects a good geographic fit
                        and some technological overlap, but limited industry
                        alignment.
                      </div>
                      <div className="space-y-3">
                        {matchmakingResult.matchmakingResultById.details.map(
                          (item) => (
                            <div key={item.id} className="grid grid-cols-3">
                              <div className="col-span-1 text-primary-600 font-semibold">
                                &#x2022; {getLinkLabel(item.type)}
                              </div>
                              <div className="col-span-2">{item.reasoning}</div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </Collapse.Panel>
                </Collapse>
              </div>
            }
          />
        </div>
      );
    return <></>;
  };

  useEffect(() => {
    if (matchResultId) {
      getMatchmakingResult({ variables: { id: matchResultId } });
    }
  }, [getMatchmakingResult, matchResultId]);

  if (loading)
    return (
      <div className="flex justify-center min-h-screen">
        <Spin />
      </div>
    );

  return (
    <div>
      {data && (
        <div>
          <NextSeo
            title={data?.company.name}
            description={data?.company.description}
            openGraph={{
              title: data?.company.name,
              description: data?.company.description,
              images: [
                {
                  url:
                    data?.company?.cover?.url || data?.company.logo?.url || '',
                  secureUrl:
                    data?.company?.cover?.url || data?.company.logo?.url || '',
                },
              ],
            }}
            twitter={{
              handle: '@handle',
              site: '@site',
              cardType: 'summary_large_image',
            }}
          />
          <ProfileCover data={data?.company} />
          <Divider />
          <MatchResult />
          <div className="flex flex-col justify-center container">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex-1 col-span-2 space-y-7">
                {(dataMe?.me.role === 'ADMIN' || data?.company.isOwner) &&
                  data?.company?.companyType === 'STARTUP' && (
                    <Segmented
                      value={tab}
                      className="w-full"
                      options={[
                        { label: 'About', value: 0 },
                        {
                          label: 'Investor insights',
                          icon: <UnlockOutlined />,
                          value: 1,
                        },
                      ]}
                      block
                      onChange={onChangeTab}
                      onResize={undefined}
                      onResizeCapture={undefined}
                    />
                  )}
                {tab === 0 && <About />}
                {(dataMe?.me.role === 'ADMIN' || data?.company.isOwner) &&
                  tab === 1 && <InvestorInsights />}
              </div>
              <div className="col-span">
                <CompanySideBar />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CompanyProfile;
