import { gql } from '@apollo/client';

import { FILE } from './upload';
import { USER_BASICS } from './user';

export const INNOVATION_TARGET = gql`
  fragment innovationTarget on InnovationTarget {
    id
    title
    type
    value
    results {
      ...innovationTargetResult
    }
  }
`;

export const INNOVATION = gql`
  fragment innovation on InnovationSpace {
    id
    name
    description
    coverPhoto {
      ...file
    }
    type
    createdAt
    updatedAt
    deletedAt
  }
`;

export const INNOVATION_SPACE_ACCESS = gql`
  fragment innovationSpaceAccess on InnovationSpaceAccess {
    id
    innovationSpace {
      id
      name
    }
    email
    innovationSpaceAccessType
    status
    settledUser {
      ...userBasics
    }
    mailResponse
    invitationMailStatus
    createdAt
    updatedAt
  }
`;

export const GET_INNOVATIONS_FOR_ADMIN = gql`
  query InnovationsForAdmin(
    $size: Int
    $page: Int
    $filter: InnovationSpaceFilter
  ) {
    listInnovationSpace(size: $size, page: $page, filter: $filter) {
      innovationSpaces {
        ...innovation
      }
      total
    }
  }
  ${INNOVATION}
  ${FILE}
`;

export const GET_INNOVATIONS = gql`
  query ListUserInnovationSpace {
    listUserInnovationSpace {
      ...innovation
    }
  }
  ${INNOVATION}
  ${FILE}
`;

export const GET_INNOVATION_BY_ID = gql`
  query FindInnovationSpaceById($id: ID) {
    findInnovationSpaceById(id: $id) {
      innovationSpace {
        ...innovation
      }
      status
    }
  }
  ${INNOVATION}
  ${FILE}
`;

export const GET_INNOVATIONS_WITH_STATUS = gql`
  query ListInnovationSpacesWithStatus(
    $first: Int
    $after: String
    $joined: Boolean
  ) {
    listInnovationSpacesWithStatus(
      first: $first
      after: $after
      joined: $joined
    ) {
      edges {
        node {
          innovationSpace {
            ...innovation
          }
          status
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${INNOVATION}
  ${FILE}
`;

export const GET_INNOVATIONS_STATISTICS = gql`
  query InnovationStatistics {
    innovationStatistics {
      status
      count
    }
  }
`;

export const CREATE_INNOVATION = gql`
  mutation CreateInnovation($input: CreateInnovationSpace!) {
    createInnovationSpace(input: $input) {
      ...innovation
    }
  }
  ${INNOVATION}
  ${FILE}
`;

export const UPDATE_INNOVATION = gql`
  mutation UpdateInnovationSpace($input: UpdateInnovationSpace!) {
    updateInnovationSpace(input: $input) {
      ...innovation
    }
  }
  ${INNOVATION}
  ${FILE}
`;

export const DELETE_INNOVATION = gql`
  mutation DeleteInnovation($input: DeleteInnovationSpace!) {
    deleteInnovationSpace(input: $input)
  }
`;

export const RESTORE_INNOVATION_SPACE = gql`
  mutation RestoreInnovationSpace($input: RestoreInnovationSpace) {
    restoreInnovationSpace(input: $input) {
      ...innovation
    }
  }
  ${INNOVATION}
  ${FILE}
`;

export const REQUEST_INNOVATION_ACCESS = gql`
  mutation RequestInnovationSpaceAccess($input: RequestInnovationSpaceAccess!) {
    requestInnovationSpaceAccess(input: $input) {
      ...innovationSpaceAccess
    }
  }
  ${INNOVATION_SPACE_ACCESS}
  ${FILE}
  ${USER_BASICS}
`;
export const GET_INNOVATION_SPACE_MEMBERS = gql`
  query ListInnovationSpaceMembers($input: ListInnovationSpaceMembers) {
    listInnovationSpaceMembers(input: $input) {
      innovationSpaceMembers {
        id
        user {
          ...userBasics
        }
        innovationSpace {
          ...innovation
        }
      }
      total
    }
  }
  ${USER_BASICS}
  ${INNOVATION}
  ${FILE}
`;

export const GET_INNOVATION_SPACE_ACCESS = gql`
  query ListInnovationSpaceAccess($input: ListInnovationSpaceAccess) {
    listInnovationSpaceAccess(input: $input) {
      ...innovationSpaceAccess
    }
  }
  ${INNOVATION_SPACE_ACCESS}
  ${FILE}
  ${USER_BASICS}
`;

export const GET_PENDING_INVITES = gql`
  query ListPendingSpaceAccesses($input: ListInnovationSpaceAccesses) {
    listPendingSpaceAccesses(input: $input) {
      ...innovationSpaceAccess
    }
  }
  ${INNOVATION_SPACE_ACCESS}
  ${FILE}
  ${USER_BASICS}
`;

export const SETTLE_INNOVATION_SPACE_ACCESS_REQUEST = gql`
  mutation SettleSpaceAccessRequest($input: SettleSpaceAccessRequest!) {
    settleSpaceAccessRequest(input: $input) {
      ...innovationSpaceAccess
    }
  }
  ${INNOVATION_SPACE_ACCESS}
  ${FILE}
  ${USER_BASICS}
`;

export const REMOVE_INNOVATION_SPACE_ACCESS = gql`
  mutation RemoveSpaceAccess($input: SpaceAccessRemoval) {
    removeSpaceAccess(input: $input) {
      ...innovationSpaceAccess
    }
  }
  ${INNOVATION_SPACE_ACCESS}
  ${FILE}
  ${USER_BASICS}
`;

export const RESEND_INVITATION_MAIL = gql`
  mutation ResendInvitationEmail($input: ResendSpaceAccessInvitation!) {
    resendInvitationEmail(input: $input) {
      ...innovationSpaceAccess
    }
  }
  ${INNOVATION_SPACE_ACCESS}
  ${FILE}
  ${USER_BASICS}
`;

export const VALIDATE_INVITE_EMAILS = gql`
  mutation ValidateInviteEmails($input: InnovationSpaceInvitationValidation) {
    validateInviteEmails(input: $input)
  }
`;

export const INVITE_USERS_TO_INNOVATION = gql`
  mutation InviteUsersToInnovationSpace($input: InviteUsersToInnovationSpace!) {
    inviteUsersToInnovationSpace(input: $input) {
      ...innovationSpaceAccess
    }
  }
  ${INNOVATION_SPACE_ACCESS}
  ${FILE}
  ${USER_BASICS}
`;

export const ACCEPT_INNOVATION_SPACE_INVITATION = gql`
  mutation AcceptInnovationSpaceInvitation(
    $input: AcceptInnovationSpaceInvitation!
  ) {
    acceptInnovationSpaceInvitation(input: $input) {
      id
      user {
        ...userBasics
      }
      innovationSpace {
        id
      }
    }
  }
  ${USER_BASICS}
  ${FILE}
`;

export const COUNT_INNOVATION_SPACE_PENDING_REQUESTS = gql`
  query CountPendingSpaceAccesses($spaceId: UUID!) {
    countPendingSpaceAccesses(spaceId: $spaceId)
  }
`;
