import { gql } from '@apollo/client';

import { DISCUSSION } from './discussion';
import { REGION } from './region';
import { TOPIC } from './topic';
import { FILE } from './upload';
import { USER_BASICS } from './user';

export const POC_BUSINESS_AREA = gql`
  fragment businessArea on BusinessArea {
    id
    name
    parentName
  }
`;

export const POC_BUSINESS_PRODUCT = gql`
  fragment businessProduct on BusinessProduct {
    id
    name
    type
  }
`;

export const POC_BASICS_KEY_TAG = gql`
  fragment pocKeyTags on PocKeyTags {
    id
    name
  }
`;

export const POC_CONTACTS = gql`
  fragment pocContacts on PocContactList {
    id
    sponsor {
      ...userBasics
    }
    sponsorType
    sponsorCustom {
      firstName
      lastName
      email
    }
    owner {
      ...userBasics
    }
    ownerType
    ownerCustom {
      firstName
      lastName
      email
    }
    lead {
      ...userBasics
    }
    leadType
    leadCustom {
      firstName
      lastName
      email
    }
  }
`;

export const POC = gql`
  fragment poc on PocBasics {
    id
    name
    description
    innovationCategory
    type
    originated
    implementationLocation {
      id
      name
    }
    targetLocation {
      id
      name
    }
    pocKeyTags {
      ...pocKeyTags
    }
    topic {
      ...topic
    }
    region {
      ...region
    }
    businessArea {
      ...businessArea
    }
    businessProduct {
      ...businessProduct
    }
    createdBy {
      id
    }
    timelineStartDate
    timelineEndDate
    problems {
      id
      description
    }
    financials {
      id
      description
    }
    nonFinancials {
      id
      description
    }
    features {
      id
      description
    }
    pocVendorPartner {
      id
      vendorPartnerType
      name
      contact
      email
    }
    kpiTarget {
      pocId
      targetDate
      currentTargetDate
      targetCurrency
      targetAmount
      currentTargetAmount
      updatedAt
      indicators {
        pocId
        name
        type
        criteria
        currency
        amount
        currentValue
      }
      createdBy
    }
    challenge {
      id
      title
    }
    status
    approvalStatus
    contacts {
      ...pocContacts
    }
    conclusion
    clarification
    productClarification
    status
    productStatus
    lastDescription
    pocTextBoxes {
      value
    }
    pocLinks {
      value
    }
    pocImages {
      ...file
    }
    pocVideos {
      ...file
    }
    pocDocuments {
      ...file
    }
    createdAt
    updatedAt
    likes
    isLiked
    comments
    discussion {
      ...discussion
    }
  }
`;

export const GET_POC_BY_ID = gql`
  query Poc($id: UUID!) {
    poc(id: $id) {
      ...poc
    }
  }
  ${POC}
  ${POC_BASICS_KEY_TAG}
  ${USER_BASICS}
  ${DISCUSSION}
  ${POC_CONTACTS}
  ${FILE}
  ${POC_BUSINESS_AREA}
  ${POC_BUSINESS_PRODUCT}
  ${REGION}
  ${TOPIC}
`;

export const GET_POC_LIST = gql`
  query PocListByAdmin(
    $filterBy: PocApprovalStatusFilters
    $SCV: Boolean
    $search: String
    $page: Int
    $limit: Int
  ) {
    pocListByAdmin(
      filterBy: $filterBy
      SCV: $SCV
      search: $search
      page: $page
      limit: $limit
    ) {
      items {
        id
        name
        description
        type
        createdAt
        status
        approvalStatus
        scv
        kpiTarget {
          createdBy
        }
        rejectDesc
      }
      total
    }
  }
`;

export const GET_MY_POC = gql`
  query PocList($page: Int, $limit: Int) {
    me {
      pocList(page: $page, limit: $limit) {
        items {
          id
          name
          description
          type
          createdAt
          status
          approvalStatus
          rejectDesc
        }
        total
      }
    }
  }
`;

export const GET_POC_KEY_TAGS = gql`
  query PocKeyTags {
    pocKeyTags {
      ...pocKeyTags
    }
  }
  ${POC_BASICS_KEY_TAG}
`;

export const GET_POC_TOTAL_COUNT = gql`
  query PocFindTotal {
    pocFindTotal
  }
`;

export const GET_POC_PENDING_COUNT = gql`
  query PocFindTotalPending {
    pocFindTotalPending
  }
`;

export const CREATE_POC_BASICS = gql`
  mutation CreatePocBasics($input: CreatePocBasicsInput!) {
    createPocBasics(input: $input) {
      ...poc
    }
  }
  ${POC}
  ${POC_BASICS_KEY_TAG}
  ${USER_BASICS}
  ${DISCUSSION}
  ${POC_CONTACTS}
  ${FILE}
  ${POC_BUSINESS_AREA}
  ${POC_BUSINESS_PRODUCT}
  ${REGION}
  ${TOPIC}
`;

export const PATCH_POC_BASICS = gql`
  mutation PatchPocBasics($input: PatchPocBasicsInput!) {
    patchPocBasics(input: $input) {
      ...poc
    }
  }
  ${POC}
  ${POC_BASICS_KEY_TAG}
  ${USER_BASICS}
  ${DISCUSSION}
  ${POC_CONTACTS}
  ${FILE}
  ${POC_BUSINESS_AREA}
  ${POC_BUSINESS_PRODUCT}
  ${REGION}
  ${TOPIC}
`;

export const PATCH_POC_GEOGRAPHICAL = gql`
  mutation PatchPocGeographical($input: PatchPocGeographicalInput!) {
    patchPocGeographical(input: $input) {
      ...poc
    }
  }
  ${POC}
  ${POC_BASICS_KEY_TAG}
  ${USER_BASICS}
  ${DISCUSSION}
  ${POC_CONTACTS}
  ${FILE}
  ${POC_BUSINESS_AREA}
  ${POC_BUSINESS_PRODUCT}
  ${REGION}
  ${TOPIC}
`;

export const PATCH_POC_BUSINESS = gql`
  mutation PatchPocBusiness($input: PatchPocBusinessInput!) {
    patchPocBusiness(input: $input) {
      ...poc
    }
  }
  ${POC}
  ${POC_BASICS_KEY_TAG}
  ${USER_BASICS}
  ${DISCUSSION}
  ${POC_CONTACTS}
  ${FILE}
  ${POC_BUSINESS_AREA}
  ${POC_BUSINESS_PRODUCT}
  ${REGION}
  ${TOPIC}
`;

export const PATCH_POC_TIMELINE = gql`
  mutation PatchPocTimeline($input: PatchPocTimelineInput!) {
    patchPocTimeline(input: $input) {
      ...poc
    }
  }
  ${POC}
  ${POC_BASICS_KEY_TAG}
  ${USER_BASICS}
  ${DISCUSSION}
  ${POC_CONTACTS}
  ${FILE}
  ${POC_BUSINESS_AREA}
  ${POC_BUSINESS_PRODUCT}
  ${REGION}
  ${TOPIC}
`;

export const PATCH_POC_KPI_TARGET = gql`
  mutation PatchPocKpiTarget($input: PatchPocKpiTargetInput!) {
    patchPocKpiTarget(input: $input) {
      ...poc
    }
  }
  ${POC}
  ${POC_BASICS_KEY_TAG}
  ${USER_BASICS}
  ${DISCUSSION}
  ${POC_CONTACTS}
  ${FILE}
  ${POC_BUSINESS_AREA}
  ${POC_BUSINESS_PRODUCT}
  ${REGION}
  ${TOPIC}
`;

export const PATCH_POC_VENDOR = gql`
  mutation PatchPocVendor($input: PatchPocVendorInput!) {
    patchPocVendor(input: $input) {
      ...poc
    }
  }
  ${POC}
  ${POC_BASICS_KEY_TAG}
  ${USER_BASICS}
  ${DISCUSSION}
  ${POC_CONTACTS}
  ${FILE}
  ${POC_BUSINESS_AREA}
  ${POC_BUSINESS_PRODUCT}
  ${REGION}
  ${TOPIC}
`;

export const PATCH_POC_CONTACTS = gql`
  mutation PatchPocContacts($input: PatchPocContactsInput!) {
    patchPocContacts(input: $input) {
      ...poc
    }
  }
  ${POC}
  ${POC_BASICS_KEY_TAG}
  ${USER_BASICS}
  ${DISCUSSION}
  ${POC_CONTACTS}
  ${FILE}
  ${POC_BUSINESS_AREA}
  ${POC_BUSINESS_PRODUCT}
  ${REGION}
  ${TOPIC}
`;

export const PATCH_POC_ASPECTS = gql`
  mutation PatchPocAspects($input: PatchPocAspectsInput!) {
    patchPocAspects(input: $input) {
      ...poc
    }
  }
  ${POC}
  ${POC_BASICS_KEY_TAG}
  ${USER_BASICS}
  ${DISCUSSION}
  ${POC_CONTACTS}
  ${FILE}
  ${POC_BUSINESS_AREA}
  ${POC_BUSINESS_PRODUCT}
  ${REGION}
  ${TOPIC}
`;

export const PATCH_POC_LAST_STAGE = gql`
  mutation PatchPocBasicsLastStage($input: PatchPocBasicsLastStageInput!) {
    patchPocBasicsLastStage(input: $input) {
      ...poc
    }
  }
  ${POC}
  ${POC_BASICS_KEY_TAG}
  ${USER_BASICS}
  ${DISCUSSION}
  ${POC_CONTACTS}
  ${FILE}
  ${POC_BUSINESS_AREA}
  ${POC_BUSINESS_PRODUCT}
  ${REGION}
  ${TOPIC}
`;

export const CREATE_POC_BUSINESS_AREA_TAG = gql`
  mutation CreatePocBusinessAreaTag($input: CreatePocBusinessAreaInput!) {
    createPocBusinessAreaTag(input: $input) {
      ...businessArea
    }
  }
  ${POC_BUSINESS_AREA}
`;

export const CREATE_POC_BUSINESS_PRODUCT_TAG = gql`
  mutation CreatePocBusinessProductTag($input: CreatePocBusinessProductInput!) {
    createPocBusinessProductTag(input: $input) {
      ...businessProduct
    }
  }
  ${POC_BUSINESS_PRODUCT}
`;

export const CREATE_POC_BASICS_KEY_TAGS = gql`
  mutation CreatePocBasicsKeyTags($input: CreatePocBasicsKeyTagsInput!) {
    createPocBasicsKeyTags(input: $input) {
      ...pocKeyTags
    }
  }
  ${POC_BASICS_KEY_TAG}
`;

export const POC_PUBLISH = gql`
  mutation pocPublish($id: UUID!) {
    pocPublish(id: $id) {
      ...poc
    }
  }
  ${POC}
  ${POC_BASICS_KEY_TAG}
  ${USER_BASICS}
  ${DISCUSSION}
  ${POC_CONTACTS}
  ${FILE}
  ${POC_BUSINESS_AREA}
  ${POC_BUSINESS_PRODUCT}
  ${REGION}
  ${TOPIC}
`;

export const UPDATE_POC_SCV_INVESTMENT = gql`
  mutation PocSCV($id: UUID!, $bool: Boolean) {
    pocSCV(id: $id, bool: $bool) {
      id
      scv
    }
  }
`;

export const APPROVE_OR_DECLINE_POC = gql`
  mutation PocApprove($id: UUID!, $approved: Boolean, $desc: String) {
    pocApprove(id: $id, approved: $approved, desc: $desc) {
      id
      approvalStatus
      rejectDesc
    }
  }
`;

export const DELETE_POC = gql`
  mutation DeletePoc($id: UUID!) {
    deletePoc(id: $id)
  }
`;

export const GET_BUSINESS_AREA_TAGS = gql`
  query PocBusinessAreaTags {
    pocBusinessAreaTags {
      ...businessArea
    }
  }
  ${POC_BUSINESS_AREA}
`;

export const GET_BUSINESS_PRODUCT_TAGS = gql`
  query PocBusinessProductTags {
    pocBusinessProductTags {
      ...businessProduct
    }
  }
  ${POC_BUSINESS_PRODUCT}
`;

export const DISCOVER_POC = gql`
  query DiscoverPoc($after: String, $first: Int, $filterBy: DiscoverPocFilter) {
    discoverPoc(after: $after, first: $first, filterBy: $filterBy) {
      edges {
        node {
          id
          name
          regions {
            name
          }
          topics {
            name
          }
          likes
          comments
          businessArea {
            name
          }
          status
        }
        cursor
      }
      aggregations {
        innovationCategory {
          buckets {
            key
            count
          }
        }
        tags {
          buckets {
            key
            count
          }
        }
        regions {
          buckets {
            key
            count
          }
        }
        implLocations {
          buckets {
            key
            count
          }
        }
        targetLocations {
          buckets {
            key
            count
          }
        }
        topics {
          buckets {
            key
            count
          }
        }
        businessArea {
          buckets {
            key
            count
          }
        }
        productArea {
          buckets {
            key
            count
          }
        }
        sponsorName {
          buckets {
            key
            count
          }
        }
        ownerName {
          buckets {
            key
            count
          }
        }
        leadName {
          buckets {
            key
            count
          }
        }
        status {
          buckets {
            key
            count
          }
        }
        productStatus {
          buckets {
            key
            count
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const LIKE_POC = gql`
  mutation LikePocBasics($id: UUID!) {
    likePocBasics(id: $id) {
      pocId
      userId
    }
  }
`;

export const UNLIKE_POC = gql`
  mutation UnlikePocBasics($id: UUID!) {
    unlikePocBasics(id: $id)
  }
`;

export const PATCH_BASICS_POC_KEY_TAG = gql`
  mutation PatchPocBasicsKeyTag($id: ID!, $name: String!) {
    patchPocBasicsKeyTag(id: $id, name: $name) {
      ...pocKeyTags
    }
  }
  ${POC_BASICS_KEY_TAG}
`;

export const DELETE_BASICS_POC_KEY_TAG = gql`
  mutation DeletePocBasicsKeyTag($id: ID!) {
    deletePocBasicsKeyTag(id: $id)
  }
`;
