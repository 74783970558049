import { CheckCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Company,
  GET_COMPANY,
  GET_INVESTOR_INSIGHTS,
  InvestorInsights,
  UPDATE_INVESTOR_INSIGHTS,
} from '@frontend/data-access';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
  Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import ArrayInput from './edit-actions/array-input';

const { Text } = Typography;

enum MyTypes {
  string,
  int,
  array,
  date,
  funding,
}

const variableTypes: { [key: string]: MyTypes } = {
  information: MyTypes.string,
  partners: MyTypes.array,
  customerAmount: MyTypes.int,
  totalTeamSize: MyTypes.int,
  techEmployees: MyTypes.int,
  salesEmployees: MyTypes.int,
  revenuePlan: MyTypes.string,
  totalAmountRaised: MyTypes.int,
  currentInvestors: MyTypes.array,
  targetFundRaisingAmount: MyTypes.string,
  fundingType: MyTypes.funding, // SAFE CONVERTIBLE EQUITY
  fundUsage: MyTypes.string,
  closingDate: MyTypes.date,
  approvals: MyTypes.string,
};

const keyToLabel: { [key: string]: string } = {
  information: 'What differentiates your solution from others in the market?',
  partners: 'Any strategic distribution partner',
  customerAmount: 'How many customers acquired to date',
  totalTeamSize: 'Total team size',
  techEmployees: 'Number of tech employees',
  salesEmployees: 'Number of sales employees',
  revenuePlan:
    'How do you plan to grow revenue & revenue per customer to achieve your revenue target',
  totalAmountRaised: 'How much have you raised in total',
  currentInvestors: 'Who are your current investors',
  targetFundRaisingAmount: 'How much capital do you intend to raise',
  fundingType: 'Preferred funding type',
  fundUsage: 'Use of Fund',
  closingDate: 'Planned close date',
  approvals:
    'Regulatory approvals/ certification required for your current solution / future product features',
};

export type InsightItemType = {
  title: string;
  items: string[];
};

interface InsightCardProps {
  item: InsightItemType;
  data: InvestorInsights;
}

const EditInsightsCard = ({ item, data }: InsightCardProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const router = useRouter();
  const [form] = useForm();
  const id = Number(router.query['companyId']);
  const [customValues, setCustomValues] = useState({}); // Array values

  const { data: dataCompany } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: id },
  });

  const [update, { loading }] = useMutation(UPDATE_INVESTOR_INSIGHTS);

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      closingDate: data?.closingDate ? moment(data?.closingDate) : null,
    });
  }, [data, form]);

  const onSave = (values: InvestorInsights) => {
    const input: InvestorInsights = {
      ...values,
      ...customValues,
      companyId: id,
    };
    if (values.closingDate) {
      input.closingDate = moment(values.closingDate).format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      );
    }
    update({
      variables: {
        input: input,
      },
      refetchQueries: [
        { query: GET_INVESTOR_INSIGHTS, variables: { companyId: id } },
      ],
      onCompleted() {
        setIsEditing(false);
        notification.open({
          message: 'Successfully saved',
          icon: (
            <span className="text-green-600">
              <CheckCircleOutlined />
            </span>
          ),
          placement: 'bottomLeft',
        });
      },
    });
  };

  const renderValue = (key: string) => {
    const type = variableTypes[key];
    const value = (data as any)?.[key];
    if (!value) {
      return <div />;
    }
    let element = null;
    switch (type) {
      case MyTypes.array:
        element = (
          <div className="flex flex-col">
            {value.map((v: string, index: number) => {
              return (
                <Text>
                  {index + 1}. {v}
                </Text>
              );
            })}
          </div>
        );
        break;
      case MyTypes.date:
        element = <Text>{moment(value).format('YYYY.MM.DD')}</Text>;
        break;
      case MyTypes.funding:
      case MyTypes.string:
      case MyTypes.int:
      default:
        element = <Text>{value}</Text>;
        break;
    }
    return <div className="py-5 flex items-center h-full">{element}</div>;
  };

  const renderInput = (key: string) => {
    const type = variableTypes[key];
    let element = null;
    switch (type) {
      case MyTypes.array:
        element = (
          <ArrayInput
            value={(data as any)?.[key]}
            onChange={(val) => {
              setCustomValues({
                ...customValues,
                [key]: val,
              });
            }}
          />
        );
        break;
      case MyTypes.date:
        element = (
          <Form.Item name={key} noStyle>
            <DatePicker />
          </Form.Item>
        );
        break;
      case MyTypes.funding:
        element = (
          <Form.Item name={key} noStyle>
            <Select
              className="w-full"
              options={[
                { value: 'SAFE', label: 'Safe' },
                { value: 'CONVERTIBLE', label: 'Convertible' },
                { value: 'EQUITY', label: 'Equity' },
              ]}
            />
          </Form.Item>
        );
        break;
      case MyTypes.string:
        element = (
          <Form.Item name={key} noStyle>
            <Input max={500} />
          </Form.Item>
        );
        break;
      case MyTypes.int:
        element = (
          <Form.Item name={key} noStyle>
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              precision={0}
              min={1}
              maxLength={9}
            />
          </Form.Item>
        );
        break;
      default:
        element = <Text>No input</Text>;
        break;
    }
    return (
      <div className="py-5 pr-5 flex items-center h-full w-full">{element}</div>
    );
  };

  return (
    <div className="flex justify-between">
      <div className="flex-1 grid">
        <div className="flex gap-2 justify-between">
          <span className="text-primary-600 text-sm font-semibold mb-2">
            {item.title.toUpperCase()}
          </span>
          {dataCompany?.company.isOwner && (
            <div className="flex">
              {isEditing ? (
                <div>
                  <Button
                    danger
                    type="link"
                    disabled={loading}
                    onClick={() => {
                      setIsEditing(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="link"
                    loading={loading}
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <Button
                  type="link"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  Edit
                </Button>
              )}
            </div>
          )}
        </div>
        <Form form={form} onFinish={onSave}>
          <div className="relative overflow-x-auto sm:rounded-lg">
            <div id="detailed-pricing" className="w-full overflow-x-auto">
              <div className="overflow-hidden">
                {item.items.map((key) => {
                  return (
                    <div className="grid grid-cols-2 text-sm text-gray-700 border-b border-gray-400 gap-x-16">
                      <div className="text-gray-900 p-5 bg-gray-200">
                        {keyToLabel[key]}
                      </div>
                      {isEditing ? (
                        <div>{renderInput(key)}</div>
                      ) : (
                        <span className="text-gray-900">
                          {renderValue(key)}
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EditInsightsCard;
