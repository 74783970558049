import { gql } from '@apollo/client';

import { FILE } from './upload';
import { USER_BASICS } from './user';

export const CRITERIA = gql`
  fragment criteria on Criteria {
    id
    name
    description
    scale
    type
    createdBy {
      ...userBasics
    }
    evaluators {
      ...userBasics
    }
    createdAt
    updatedAt
  }
`;
export const CRITERIA_RESULT = gql`
  fragment result on Result {
    id
    scale
    criteria {
      ...criteria
    }
    createdBy {
      ...userBasics
    }
    status
    createdAt
    updatedAt
  }
`;

export const CREATE_EVALUATION = gql`
  mutation CreateEvaluation($input: ResultCreateInput!) {
    createEvaluation(input: $input) {
      id
      criteria {
        ...criteria
      }
      scale
      createdBy {
        ...userBasics
      }
      status
      createdAt
    }
  }
  ${CRITERIA}
  ${FILE}
  ${USER_BASICS}
`;

export const PATCH_EVALUATION = gql`
  mutation PatchEvaluation($input: ResultPatchInput!) {
    patchEvaluation(input: $input) {
      id
      criteria {
        ...criteria
      }
      scale
      createdBy {
        ...userBasics
      }
      status
      createdAt
    }
  }
  ${CRITERIA}
  ${FILE}
  ${USER_BASICS}
`;

export const CREATE_CRITERIA = gql`
  mutation CreateCriteria($input: CriteriaCreateInput!) {
    createCriteria(input: $input) {
      ...criteria
    }
  }
  ${CRITERIA}
  ${FILE}
  ${USER_BASICS}
`;

export const PATCH_CRITERIA = gql`
  mutation PatchCriteria($input: CriteriaPatchInput!) {
    patchCriteria(input: $input) {
      ...criteria
    }
  }
  ${CRITERIA}
  ${FILE}
  ${USER_BASICS}
`;

export const ASSIGN_EVALUATORS = gql`
  mutation AssignEvaluators($input: CriteriaEvaluatorInput!) {
    assignEvaluators(input: $input) {
      criteria {
        ...criteria
      }
      user {
        ...userBasics
      }
    }
  }
  ${CRITERIA}
  ${FILE}
  ${USER_BASICS}
`;

export const UNASSIGN_EVALUATORS = gql`
  mutation UnAssignEvaluators($input: CriteriaEvaluatorInput!) {
    unAssignEvaluators(input: $input)
  }
`;

export const DELETE_TEAM_MEMBER = gql`
  mutation DeleteTeamMember($id: UUID!) {
    deleteTeamMember(id: $id)
  }
`;
