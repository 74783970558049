/* eslint-disable-next-line */
export interface VideoPlayerProps {
  url: string;
}

export function VideoPlayer({ url }: VideoPlayerProps) {
  return (
    <div>
      <video className="rounded-lg w-full" src={url} controls />
    </div>
  );
}

export default VideoPlayer;
