import {
  DatabaseOutlined,
  LoadingOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useApolloClient, useQuery } from '@apollo/client';
import { FirebaseApp } from '@firebase/app';
import { GET_ME_WITH_RANK, Me, signOut } from '@frontend/data-access';
import { NotificationBell } from '@frontend/notification';
import { Button, Divider, Dropdown, Grid, Space, Spin } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import UserHeaderSection from '../user-header-section/user-header-section';

export interface HeaderProfileSectionProps {
  firebaseUser?: string;
  firebaseApp?: FirebaseApp;
}

const { useBreakpoint } = Grid;

export function HeaderProfileSection({
  firebaseUser,
  firebaseApp,
}: HeaderProfileSectionProps) {
  const { data: meData, loading: meLoading } = useQuery<{ me: Me }>(
    GET_ME_WITH_RANK
  );

  const client = useApolloClient();
  const router = useRouter();
  const screens = useBreakpoint();

  const items = [
    {
      key: 'my-profile',
      label: (
        <Link
          href="/my-profile/about"
          className="px-2 py-1 flex items-center gap-2 text-sm"
        >
          <UserOutlined style={{ fontSize: 20 }} /> My profile
        </Link>
      ),
    },
    {
      key: 'logout',
      label: (
        <div
          className="px-2 py-1 flex items-center gap-2 text-sm !text-red-500"
          onClick={() => logout()}
        >
          <LogoutOutlined style={{ fontSize: 20 }} /> Logout
        </div>
      ),
    },
  ];

  if (screens.xs) {
    items.unshift({
      key: 'my-space',
      label: (
        <Link
          href={`/my-profile/space`}
          className="px-2 py-1 flex items-center gap-2 text-sm"
        >
          <DatabaseOutlined style={{ fontSize: 20 }} />
          My space
        </Link>
      ),
    });
  }

  const logout = async () => {
    try {
      const response = await signOut();
      if (response) {
        await client.clearStore();
        window.location.reload();
      }
    } catch (error) {
      console.log('logout error: ', error);
    }
  };

  const contentStyle: React.CSSProperties = {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow:
      '0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)',
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  };

  const isShowUserBadge = meData?.me.role !== 'EXTERNAL_USER';

  const renderSmallMenu = () => {
    if (meData) {
      return (
        <>
          <Link className="hidden sm:block" href={`/my-profile/space`}>
            <span
              data-test="my-space-btn"
              className={`${
                router.pathname.includes('/my-profile/space') ? '' : ''
              } font-light text-xl`}
            >
              My space
            </span>
          </Link>
          <NotificationBell
            firebaseUser={firebaseUser}
            firebaseApp={firebaseApp}
          />
          <Dropdown
            menu={{ items }}
            placement="topLeft"
            trigger={['click']}
            dropdownRender={(menu) => (
              <div style={contentStyle}>
                {isShowUserBadge && (
                  <div>
                    <Space style={{ padding: 8 }}>
                      <div className="bg-white px-3.5 pb-1.5 py-3 w-72">
                        <UserHeaderSection data={meData?.me} size="small" />
                      </div>
                    </Space>
                    <Divider style={{ margin: 0 }} />
                  </div>
                )}
                {React.cloneElement(menu as React.ReactElement, {
                  style: menuStyle,
                })}
              </div>
            )}
          >
            <div
              data-test="profile-dropdown"
              className="p-2 flex items-center justify-center hover:text-primary-600 rounded-full cursor-pointer"
            >
              <span className="text-xl">
                <UserOutlined />
              </span>
            </div>
          </Dropdown>
        </>
      );
    } else {
      if (screens.xs) {
        return (
          <Link href="/login">
            <div className="p-2 flex items-center justify-center hover:text-primary-600 rounded-full cursor-pointer">
              <UserOutlined className="text-xl" />
            </div>
          </Link>
        );
      }
      return (
        <Link href="/login" data-test="login-btn">
          <Button shape="round" size="large" type="primary">
            Join our community
          </Button>
        </Link>
      );
    }
  };

  return (
    <div className="flex gap-5 items-center">
      {meLoading ? <Spin indicator={<LoadingOutlined />} /> : renderSmallMenu()}
    </div>
  );
}

export default HeaderProfileSection;
