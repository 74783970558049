import { gql } from '@apollo/client';

import { REGION } from './region';
import { TOPIC } from './topic';

export const GET_DISCOVER_COMPANIES = gql`
  query DiscoverCompanies(
    $first: Int
    $after: String
    $filterBy: DiscoverCompaniesFilter
  ) {
    discoverCompanies(first: $first, after: $after, filterBy: $filterBy) {
      aggregations {
        location {
          name
          buckets {
            key
            count
          }
        }
        regions {
          name
          buckets {
            key
            count
          }
        }
        topics {
          name
          buckets {
            key
            count
          }
        }
        employeeNumber {
          name
          buckets {
            key
            count
          }
        }
      }
      edges {
        cursor
        node {
          id
          name
          logo
          description
          employeeNumber
          approvalStatus
          isScvPortfolio
          location
          topics {
            ...topic
          }
          regions {
            ...region
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
  ${TOPIC}
  ${REGION}
`;
export const GET_DISCOVER_USERS = gql`
  query DiscoverUsers(
    $first: Int
    $after: String
    $filterBy: DiscoverUsersFilter
  ) {
    discoverUsers(first: $first, after: $after, filterBy: $filterBy) {
      aggregations {
        location {
          name
          buckets {
            key
            count
          }
        }
      }
      edges {
        cursor
        node {
          id
          firstName
          lastName
          title
          image
          location
          verified
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;
