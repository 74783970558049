import { gql } from '@apollo/client';

import { TOPIC } from './topic';
import { FILE } from './upload';

export const COMPANY_BASIC = gql`
  fragment basicInfo on Company {
    id
    name
    companyType
    companyInfo {
      employeeNumber
      location
      ...link
    }
    description
    foundedDate
    progress
    approvalStatus
    registeredBy {
      id
      fullName
    }
    logo {
      ...file
    }
    cover {
      ...file
    }
    isOwner
    createdAt
  }
`;

export const COMPANY = gql`
  fragment company on Company {
    ...basicInfo
    topics {
      ...topic
    }
    progressDetails {
      ...progressDetails
    }
    impact {
      ...impact
    }
    market {
      ...market
    }
    fundingStatus {
      ...fundingStatus
    }
    members {
      ...member
    }
    strategicEntities {
      ...strategicEntity
    }
    noteworthyEvents {
      ...noteworthyEvent
    }
    onePagers {
      ...file
    }
    pitchDecks {
      ...file
    }
    memberInvites {
      email
      status
    }
    isOwner
    isMember
    companyPortfolio {
      isScvPortfolio
    }
    applications {
      rejectDesc
    }
    keyPerson {
      ...keyPerson
    }
    pitchVideos {
      ...file
    }
    __typename
  }
`;

export const COMPANY_INFO = gql`
  fragment link on CompanyInfo {
    website
    facebook
    twitter
    linkedin
  }
`;

export const COMPANY_PROGRESS_DETAILS = gql`
  fragment progressDetails on StartupProgressDetails {
    basic
    links
    impact
    topic
    market
    team
    documents
    funding
    strategic
    noteworthyEvent
  }
`;

export const COMPANY_IMPACT = gql`
  fragment impact on Impact {
    companyId
    mission
    uniqueness
    impactability
    measuringImpactMetrics
    sdgs
    __typename
  }
`;

export const COMPANY_MARKET = gql`
  fragment market on Market {
    companyId
    focusMarkets
    marketModel
    totalAddressableMarket
    serviceableAddressableMarket
    serviceableObtainableMarket
    regionIds
    __typename
  }
`;

export const COMPANY_FUNDING_STATUS = gql`
  fragment fundingStatus on FundingStatus {
    companyId
    businessStageId
    fundingStageId
    currentlyFundraising
    bankEngagement
    participatingInvestors
    totalFunding
    targetFundraise
    preMoneyValuation
    minimumTicketSize
    maximumTicketSize
    totalEquityOffered
    preferredFundingTypes
    preferredFunders
    estimatedClosingDate
    __typename
  }
`;

export const COMPANY_MEMBER = gql`
  fragment member on CompanyMember {
    user {
      id
      fullName
      email
      role
      image {
        id
        url
      }
    }
    position
    role
    __typename
  }
`;

export const COMPANY_FUNDING = gql`
  fragment funding on Funding {
    id
    totalRaised
    numberOfRounds
    __typename
  }
`;

export const COMPANY_FUNDING_REQUEST = gql`
  fragment fundingRequest on FundingRequest {
    id
    amount
    targetAmount
    comment
    estimatedClosing
    status
    __typename
  }
`;

export const COMPANY_FOLLOWER_COUNT = gql`
  fragment followerCount on ViewsCount {
    thisWeek
    lastWeek
  }
`;

export const COMPANY_PAGE_VIEW = gql`
  fragment pageView on ViewsCount {
    thisWeek
    lastWeek
  }
`;

export const COMPANY_PITCHDECK_VIEW = gql`
  fragment pitchDeckView on ViewsCount {
    thisWeek
    lastWeek
  }
`;

export const COMPANY_STRATEGIC_ENTITY = gql`
  fragment strategicEntity on StrategicEntity {
    createdAt
    deletedAt
    description
    id
    imageData
    name
    type
    updatedAt
  }
`;

export const COMPANY_PILOT_MARKET = gql`
  fragment pilotMarket on PilotMarket {
    id
    projectedIncome
    projectedIncomeDate
    countries {
      alpha2
      countryCode
      id
      name
    }
  }
`;

export const COMPANY_TARGET_MARKET = gql`
  fragment targetMarket on TargetMarket {
    id
    entryDate
    marketSize
    projectedIncome
    projectedIncomeDate
    barriers
    countries {
      alpha2
      countryCode
      id
      name
    }
  }
`;

export const COMPANY_COMPETITOR = gql`
  fragment competitor on Competitor {
    id
    differentiators
    names
  }
`;

export const COMPANY_EXIT_PLAN = gql`
  fragment exitPlan on ExitPlan {
    details
    estimatedDate
    exitType
    jurisdiction
    potentialAcquirers
  }
`;

export const COMPANY_NOTEWORTHY_EVENT = gql`
  fragment noteworthyEvent on Noteworthy {
    id
    date
    description
    image {
      url
    }
    link
    title
  }
`;

export const COMPANY_MEMBER_INVITE = gql`
  fragment memberInvite on CompanyMemberInvite {
    company {
      id
      name
      logo {
        ...file
      }
    }
    email
    message
    status
    userId
    verification
  }
`;

export const COMPANY_APPLICATION = gql`
  fragment application on CompanyApplication {
    id
    company {
      id
      name
      logo {
        ...file
      }
    }
    status
    type
    deniedAt
    approvedAt
    createdAt
  }
`;

export const COMPANY_SHOWCASE_INFO = gql`
  fragment companyShowcaseInfo on Company {
    id
    name
    logo {
      ...file
    }
    description
    companyInfo {
      location
    }
    topics {
      ...topic
    }
    companyPortfolio {
      isScvPortfolio
      isShowcase
      isFeatured
      description
      banner {
        ...file
      }
    }
  }
`;

export const KEY_PERSON = gql`
  fragment keyPerson on KeyPerson {
    id
    name
    role
    linkedinUrl
    __typename
  }
`;

export const GET_COMPANIES = gql`
  query Companies(
    $searchBy: String
    $filterBy: CompanyFilters
    $sort: CompanySortOption
    $page: Int
    $limit: Int
  ) {
    companies(
      searchBy: $searchBy
      filterBy: $filterBy
      sort: $sort
      page: $page
      limit: $limit
    ) {
      id
      name
      foundedDate
      progress
      approvalStatus
      registeredBy {
        fullName
      }
      logo {
        ...file
      }
      createdAt
      companyPortfolio {
        isScvPortfolio
      }
      applications {
        rejectDesc
      }
      companyType
    }
  }
  ${FILE}
`;

export const GET_COMPANY_APPROVAL_STATUS = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      name
      approvalStatus
    }
  }
`;

export const GET_PORTFOLIO_COMPANIES = gql`
  query PortfolioCompanies {
    portfolioCompanies {
      id
      name
      logo {
        ...file
      }
    }
  }
  ${FILE}
`;

export const GET_COMPANIES_BY_FILTER_COUNT = gql`
  query companiesAggregate($searchBy: String, $filterBy: CompanyFilters) {
    companiesAggregate(searchBy: $searchBy, filterBy: $filterBy) {
      totalCount
    }
  }
`;

export const GET_COMPANY_BASICS = gql`
  query Company($id: ID!) {
    company(id: $id) {
      ...basicInfo
      topics {
        ...topic
      }
    }
  }
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${FILE}
  ${TOPIC}
`;

export const GET_COMPANY = gql`
  query Company($id: ID!) {
    company(id: $id) {
      ...company
      owners {
        user {
          id
          role
          fullName
          image {
            url
          }
        }
      }
      __typename
    }
  }
  ${COMPANY}
  ${COMPANY_BASIC}
  ${COMPANY_FUNDING_STATUS}
  ${COMPANY_IMPACT}
  ${COMPANY_INFO}
  ${COMPANY_MARKET}
  ${COMPANY_MEMBER}
  ${COMPANY_NOTEWORTHY_EVENT}
  ${COMPANY_PROGRESS_DETAILS}
  ${COMPANY_STRATEGIC_ENTITY}
  ${FILE}
  ${TOPIC}
  ${KEY_PERSON}
`;

export const GET_COMPANIES_FOR_USER = gql`
  query CompaniesForUser($page: Int, $limit: Int) {
    companiesForUser(page: $page, limit: $limit) {
      ...basicInfo
      topics {
        ...topic
      }
      __typename
    }
  }
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${FILE}
  ${TOPIC}
`;

export const GET_MY_COMPANIES = gql`
  query MyCompanies {
    myCompanies {
      ...basicInfo
      topics {
        ...topic
      }
    }
  }
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${FILE}
  ${TOPIC}
`;

export const GET_COMPANY_IMPACT = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      impact {
        ...impact
      }
    }
  }
  ${COMPANY_IMPACT}
`;

export const GET_ALL_COMPANY = gql`
  query AllCompanies {
    allCompanies {
      id
      name
      description
      employeeNumber
      location
      logo {
        id
        url
      }
    }
  }
`;

export const GET_COMPANY_MARKET = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      market {
        ...market
      }
    }
  }
  ${COMPANY_MARKET}
`;

export const GET_COMPANY_FUNDING_STATUS = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      fundingStatus {
        ...fundingStatus
      }
    }
  }
  ${COMPANY_FUNDING_STATUS}
`;

export const GET_COMPANIES_COUNT = gql`
  query CompaniesCount {
    companiesCount {
      status
      count
    }
  }
`;

export const GET_SHOWCASE_COMPANY_FEATURED = gql`
  query ShowcaseCompanyFeatured {
    showcaseCompanyFeatured {
      ...companyShowcaseInfo
    }
  }
  ${COMPANY_SHOWCASE_INFO}
  ${FILE}
  ${TOPIC}
`;

export const GET_SHOWCASE_COMPANY = gql`
  query ShowcaseCompany($filterBy: ShowcaseFilters, $sort: ShowCaseSortOption) {
    showcaseCompany(filterBy: $filterBy, sort: $sort) {
      ...companyShowcaseInfo
    }
  }
  ${COMPANY_SHOWCASE_INFO}
  ${FILE}
  ${TOPIC}
`;

export const VERIFY_COMPANY_MEMBER = gql`
  mutation verifyCompanyMember($verificationCode: String!) {
    verifyCompanyMember(verificationCode: $verificationCode)
  }
`;

export const REJECT_COMPANY_MEMBER = gql`
  mutation rejectCompanyMember($id: ID) {
    rejectCompanyMember(id: $id) {
      id
    }
  }
`;

export const GET_COMPANY_INTRODUCTION = gql`
  query ListCompanyIntroduction($id: ID!) {
    listCompanyIntroduction(companyId: $id) {
      id
      fileData {
        ...file
      }
      companyId
    }
  }
  ${FILE}
`;

export const DELETE_COMPANY_INTRODUCTION_INPUT = gql`
  mutation DeleteCompanyIntroductionInput(
    $input: DeleteCompanyIntroductionInput!
  ) {
    deleteCompanyIntroduction(input: $input) {
      id
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      ...company
      __typename
    }
  }
  ${COMPANY}
  ${COMPANY_BASIC}
  ${COMPANY_FUNDING_STATUS}
  ${COMPANY_IMPACT}
  ${COMPANY_INFO}
  ${COMPANY_MARKET}
  ${COMPANY_MEMBER}
  ${COMPANY_NOTEWORTHY_EVENT}
  ${COMPANY_PROGRESS_DETAILS}
  ${COMPANY_STRATEGIC_ENTITY}
  ${FILE}
  ${TOPIC}
  ${KEY_PERSON}
`;

export const PATCH_COMPANY = gql`
  mutation PatchCompany($input: PatchCompanyInput!) {
    patchCompany(input: $input) {
      ...company
      __typename
    }
  }
  ${COMPANY}
  ${COMPANY_BASIC}
  ${COMPANY_FUNDING_STATUS}
  ${COMPANY_IMPACT}
  ${COMPANY_INFO}
  ${COMPANY_MARKET}
  ${COMPANY_MEMBER}
  ${COMPANY_NOTEWORTHY_EVENT}
  ${COMPANY_PROGRESS_DETAILS}
  ${COMPANY_STRATEGIC_ENTITY}
  ${FILE}
  ${TOPIC}
  ${KEY_PERSON}
`;

export const CREATE_COMPANY_INTRODUCTION_INPUT = gql`
  mutation CreateCompanyIntroductionInput(
    $input: CreateCompanyIntroductionInput!
  ) {
    createCompanyIntroduction(input: $input) {
      id
      companyId
      fileData {
        ...file
      }
    }
  }
  ${FILE}
`;

export const LIST_KEY_PERSON = gql`
  query ListKeyPerson($companyId: CompanyId!) {
    listKeyPerson(input: $companyId) {
      ...keyPerson
    }
  }
  ${KEY_PERSON}
`;

export const CREATE_KEY_PERSON = gql`
  mutation CreateKeyPersonInput($input: createKeyPersonInput!) {
    createKeyPerson(input: $input) {
      ...keyPerson
    }
  }
  ${KEY_PERSON}
`;

export const UPDATE_KEY_PERSON = gql`
  mutation UpdateKeyPersonInput($input: updateKeyPersonInput!) {
    updateKeyPerson(input: $input) {
      ...keyPerson
    }
  }
  ${KEY_PERSON}
`;

export const DELETE_KEY_PERSON = gql`
  mutation DeleteKeyPersonInput($input: deleteKeyPersonInput!) {
    deleteKeyPerson(input: $input) {
      ...keyPerson
    }
  }
  ${KEY_PERSON}
`;

export const UPSERT_IMPACT = gql`
  mutation UpsertImpact($upsertImpactInput: UpsertImpactInput!) {
    upsertImpact(upsertImpactInput: $upsertImpactInput) {
      ...impact
    }
  }
  ${COMPANY_IMPACT}
`;

export const UPSERT_MARKET = gql`
  mutation UpsertMarket($upsertMarketInput: UpsertMarketInput!) {
    upsertMarket(upsertMarketInput: $upsertMarketInput) {
      ...market
    }
  }
  ${COMPANY_MARKET}
`;

export const UPSERT_FUNDING_STATUS = gql`
  mutation UpsertFundingStatus(
    $upsertFundingStatusInput: UpsertFundingStatusInput!
  ) {
    upsertFundingStatus(upsertFundingStatusInput: $upsertFundingStatusInput) {
      ...fundingStatus
    }
  }
  ${COMPANY_FUNDING_STATUS}
`;

export const UPSERT_STRATEGIC_ENTITY = gql`
  mutation UpsertStrategicEntities($input: StrategicEntityInput!) {
    upsertStrategicEntities(input: $input) {
      ...strategicEntity
    }
  }
  ${COMPANY_STRATEGIC_ENTITY}
`;

export const UPSERT_NOTEWORTHY_EVENT = gql`
  mutation UpsertNoteworthyEvent($input: NoteworthyEventInput!) {
    upsertNoteworthyEvent(input: $input) {
      ...noteworthyEvent
    }
  }
  ${COMPANY_NOTEWORTHY_EVENT}
`;

export const INVITE_COMPANY_MEMBERS = gql`
  mutation CompanyMembersInvite($input: MembersInviteInput!) {
    companyMembersInvite(input: $input) {
      ...memberInvite
    }
  }
  ${COMPANY_MEMBER_INVITE}
  ${FILE}
`;

export const APPROVE_COMPANY_APPLICATION = gql`
  mutation ApproveCompanyApplication($companyId: ID) {
    approveCompanyApplication(companyId: $companyId) {
      company {
        ...basicInfo
      }
    }
  }
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${FILE}
`;

export const DENY_COMPANY_APPLICATION = gql`
  mutation DenyCompanyApplication($companyId: ID, $desc: String) {
    denyCompanyApplication(companyId: $companyId, desc: $desc) {
      company {
        ...basicInfo
      }
    }
  }
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${FILE}
`;

export const UPDATE_SCV_PORTFOLIO = gql`
  mutation updateScvPortfolio($companyIds: [ID]!) {
    updateScvPortfolio(companyIds: $companyIds) {
      companyId
      isScvPortfolio
      isShowcase
      description
    }
  }
`;

export const DELETE_SHOWCASE = gql`
  mutation deleteShowcase($id: ID!) {
    deleteShowcase(id: $id) {
      companyId
      isShowcase
    }
  }
`;

export const UPSERT_SHOWCASE = gql`
  mutation upsertShowcase($input: CompanyShowcaseInput!) {
    upsertShowcase(input: $input) {
      ...companyShowcaseInfo
    }
  }
  ${COMPANY_SHOWCASE_INFO}
  ${FILE}
  ${TOPIC}
`;

export const APPLY_FOR_LISTING_COMPANY = gql`
  mutation applyForListing($companyId: ID) {
    applyForListing(companyId: $companyId) {
      id
      companyId
      status
    }
  }
`;

export const COMPANY_CHECK_WEBSITE = gql`
  query checkUniqueWebsite($companyId: ID, $url: String!) {
    checkUniqueWebsite(companyId: $companyId, url: $url)
  }
`;

export const ADD_COMPANY_MEMBER = gql`
  mutation addCompanyMember($companyIds: [ID]!) {
    addCompanyMember(companyIds: $companyIds) {
      ...member
    }
  }
  ${COMPANY_MEMBER}
`;

export const GET_COMPANY_CLAIM_REQUESTS = gql`
  query listCompanyClaims($companyId: ID) {
    listCompanyClaims(companyId: $companyId) {
      id
      status
      phone
      email
      reason
      name
      user {
        id
        fullName
        role
      }
    }
  }
`;
export const COMPANY_CLAIM_REQUEST = gql`
  mutation requestCompanyClaim($input: CreateCompanyClaimInput!) {
    requestCompanyClaim(input: $input) {
      id
    }
  }
`;

export const COMPANY_CLAIM_REQUEST_SETTLE = gql`
  mutation settleCompanyClaim($input: SettleCompanyClaimRequestInput!) {
    settleCompanyClaim(input: $input) {
      id
      status
    }
  }
`;

export const COMPANY_PROFILE_OWNER_REMOVE = gql`
  mutation removeCompanyProfileOwner($input: removeCompanyProfileOwnerInput) {
    removeCompanyProfileOwner(input: $input)
  }
`;

export const INVESTOR_INSIGHT = gql`
  fragment investorInsight on InvestorInsight {
    id
    information
    companyId
    partners
    customerAmount
    totalTeamSize
    techEmployees
    salesEmployees
    revenuePlan
    totalAmountRaised
    currentInvestors
    targetFundRaisingAmount
    fundingType
    fundUsage
    closingDate
    approvals
    createdAt
    updatedAt
    deletedAt
  }
`;

export const GET_INVESTOR_INSIGHTS = gql`
  query RetrieveInvestorInsight($companyId: ID) {
    retrieveInvestorInsight(companyId: $companyId) {
      ...investorInsight
    }
  }
  ${INVESTOR_INSIGHT}
`;

export const UPDATE_INVESTOR_INSIGHTS = gql`
  mutation InsertBusinessInformation($input: InsertBusinessInformation) {
    insertBusinessInformation(input: $input) {
      ...investorInsight
    }
  }
  ${INVESTOR_INSIGHT}
`;

export const LIST_COMPANY_AND_PORTFOLIO = gql`
  query ListCompanyAndPortfolios(
    $type: CompanyAndPortfolioType
    $page: Int
    $limit: Int
  ) {
    listCompanyAndPortfolios(type: $type, page: $page, limit: $limit) {
      companyAndPortfolios {
        company {
          id
          name
          logo {
            url
          }
          companyPortfolio {
            isScvPortfolio
          }
          companyType
        }
        type
      }
    }
  }
`;

export const UPDATE_COMPANY_AND_PORTFOLIO = gql`
  mutation CreateCompanyAndPortfolio($input: CreateCompanyAndPortfolio) {
    createCompanyAndPortfolio(input: $input) {
      id
      company {
        id
      }
    }
  }
`;
