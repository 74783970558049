import { gql } from '@apollo/client';

export const FILE = gql`
  fragment file on UploadedFile {
    id
    storage
    url
    state
    status
    public
    metadata {
      filename
      size
      mime
    }
  }
`;
