/* eslint-disable-next-line */
export interface ComingSoonProps {}

export function ComingSoon(props: ComingSoonProps) {
  return (
    <div {...props} className="h-full flex items-center justify-center">
      <div className="text-gray-700">Coming soon</div>
    </div>
  );
}

export default ComingSoon;
