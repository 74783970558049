import { Image, Layout } from 'antd';
import getConfig from 'next/config';
import Link from 'next/link';
import { ReactElement } from 'react';

/* eslint-disable-next-line */
export interface UserHeaderNosiderProps {
  title: string;
  headerSuffix?: ReactElement;
}

export function UserHeaderNosider({
  title,
  headerSuffix,
}: UserHeaderNosiderProps) {
  const { publicRuntimeConfig } = getConfig();

  return (
    <Layout.Header className="flex items-center justify-between">
      <Link href="/" passHref>
        <div className="px-6 py-3 h-[64px]">
          <Image
            src={`${publicRuntimeConfig.staticFolder}/logo-full.svg`}
            preview={false}
            height="40"
            alt="SCV logo"
          />
        </div>
      </Link>
      <div className="flex flex-grow justify-between ml-16">
        <span className="text-gray-700">{title}</span>
        {headerSuffix}
      </div>
    </Layout.Header>
  );
}

export default UserHeaderNosider;
