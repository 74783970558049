import { CheckCircleFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  getPhoto,
  Idea,
  IdeaCoverPhotoUrl,
  REGISTER_IDEA_CONTRIBUTE,
  UploadedFile,
} from '@frontend/data-access';
import { Button, Divider, Empty, Form, Image, Input, notification } from 'antd';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useState } from 'react';

import ImageUpload from '../image-upload/image-upload';
import styles from './idea-contribute.module.less';

type ImageSelectionType = 'UPLOAD' | 'API';
/* eslint-disable-next-line */
export interface IdeaContributeProps {
  data: Idea;
  onCancel(): void;
}

interface IdeaContribute {
  title: string;
  body: string;
  photo: UploadedFile;
}

export function IdeaContribute({ data, onCancel }: IdeaContributeProps) {
  const { t: tcommon } = useTranslation('common');
  const [form] = Form.useForm();
  const [isIncomplete, setIsIncomplete] = useState(false);

  const [defaultFileList, setDefaultFileList] = useState<
    {
      uid: string;
      name: string;
      url: string;
    }[]
  >([]);
  const [imageSelectionType, setImageSelectionType] =
    useState<ImageSelectionType>('UPLOAD');
  const photo: IdeaCoverPhotoUrl = Form.useWatch('photo', form);
  const [images, setImages] = useState<IdeaCoverPhotoUrl[]>([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const handleCompleted = () => {
    form.resetFields();
    onCancel();
    notification.success({
      message: 'Contribution successfully submitted',
      placement: 'bottomLeft',
    });
  };

  const [submitContribution, { loading }] = useMutation(
    REGISTER_IDEA_CONTRIBUTE,
    {
      onCompleted: handleCompleted,
    }
  );

  const handleUploadSuccess = (file: UploadedFile) => {
    form.setFieldValue('photo', file);
    handleFormChange();
  };

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsIncomplete(hasErrors);
  };

  const handleOnFinish = useCallback(
    async (values: IdeaContribute) => {
      try {
        await form.validateFields();
        submitContribution({
          variables: {
            input: {
              ...values,
              ideaId: data?.id,
              photo: imageSelectionType === 'API' ? undefined : photo,
              coverPhotoUrl:
                imageSelectionType === 'UPLOAD' ? undefined : photo,
            },
          },
        });
      } catch (error) {
        //
      }
    },
    [data?.id, form, imageSelectionType, photo, submitContribution]
  );

  const changeImageSelectionType = (type: ImageSelectionType) => {
    form.setFieldValue('photo', null);
    setDefaultFileList([]);
    setImageSelectionType(type);
  };

  const getImage = async () => {
    changeImageSelectionType('API');
    setLoadingImage(true);
    const response = await getPhoto({
      orientation: 'squarish',
      page: 0,
      perPage: 3,
      query:
        data?.topics.map((t) => t.name).toString() +
        ',' +
        (form.getFieldValue('title') || data?.name),
    });
    const imageData = await response.json();
    if (response.status === 200) {
      setImages(
        imageData?.results.map((i: any) => ({
          author: i.user.name,
          profileUrl: i.user.links.html,
          imageUrl: i.urls.small,
        }))
      );
    }
    setLoadingImage(false);
  };

  const renderUnsplashImages = () => {
    if (!loadingImage && images?.length === 0) {
      return (
        <div className="text-center">
          <Empty
            description="No images found"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </div>
      );
    }
    return (
      <div className="grid grid-cols-3 gap-6">
        {images?.map((image, key) => (
          <div
            key={key}
            className="relative cursor-pointer"
            onClick={() => form.setFieldValue('photo', image)}
          >
            <Image
              src={image.imageUrl}
              alt=""
              className="rounded-lg"
              preview={false}
            />
            {photo?.imageUrl === image.imageUrl && (
              <div className="text-2xl absolute top-3 right-3 text-white">
                <CheckCircleFilled />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles['container']}>
      <Form
        form={form}
        layout="vertical"
        requiredMark={'optional'}
        onFieldsChange={handleFormChange}
        onFinish={handleOnFinish}
      >
        <div className="p-6">
          <p>
            <span className="font-semibold">
              {tcommon('idea_detail_contribute_note')}:{' '}
            </span>
            {data?.note ||
              tcommon('idea_detail_contribute_note_default_message')}
          </p>
          <Form.Item
            name="title"
            label={tcommon('idea_detail_contribute_input_title')}
            rules={[
              { required: true, message: tcommon('input_required_message') },
            ]}
          >
            <Input
              placeholder={tcommon(
                'idea_detail_contribute_input_title_placeholder'
              )}
              maxLength={100}
              showCount
            />
          </Form.Item>
          <Form.Item
            name="body"
            label={tcommon('idea_detail_contribute_body')}
            rules={[
              { required: true, message: tcommon('input_required_message') },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder={tcommon('idea_detail_contribute_body_placeholder')}
              maxLength={5000}
              showCount
            />
          </Form.Item>
          <Form.Item
            name="photo"
            rules={[
              { required: true, message: tcommon('upload_image_required') },
            ]}
          >
            <div className="space-y-4">
              {(!imageSelectionType || imageSelectionType === 'UPLOAD') && (
                <ImageUpload
                  compact={false}
                  onUploadSuccess={handleUploadSuccess}
                  defaultImages={defaultFileList}
                />
              )}
              {imageSelectionType === 'API' && (
                <Button
                  shape="round"
                  type="default"
                  className="w-full"
                  onClick={() => changeImageSelectionType('UPLOAD')}
                >
                  {tcommon('idea_detail_contribute_photo_button')}
                </Button>
              )}
              <Divider plain>or</Divider>
              {(!imageSelectionType ||
                imageSelectionType === 'UPLOAD' ||
                images?.length === 0) && (
                <Button
                  shape="round"
                  type="primary"
                  className="w-full"
                  loading={loadingImage}
                  onClick={getImage}
                >
                  {tcommon('idea_detail_contribute_generate_button')}
                </Button>
              )}
              {imageSelectionType === 'API' && renderUnsplashImages()}
            </div>
          </Form.Item>
        </div>
        <div className="flex justify-end items-center px-4 py-3 border-t border-solid border-gray-400 gap-2">
          <Button key="back" shape="round" onClick={() => onCancel()}>
            {tcommon('cancel')}
          </Button>
          <Button
            type="primary"
            shape="round"
            loading={loading}
            htmlType="submit"
          >
            {tcommon('idea_detail_contribute_submit_button')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default IdeaContribute;
