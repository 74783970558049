import {
  CloseOutlined,
  DownloadOutlined,
  FileOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import {
  ChallengeApplication,
  CompanyClaim,
  GET_COMPANY_CLAIM_REQUESTS,
  GET_ME,
  Me,
} from '@frontend/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  ApplicationEvaluationResultTag,
  CompanyAvatar,
  RichEditorSerialized,
  UserAvatarPdfTemplate,
} from '@frontend/shared/ui';
import { formatDate } from '@frontend/shared/util';
import { Button, Col, Modal, Row, Typography } from 'antd';
import getConfig from 'next/config';
import NextImage from 'next/image';
import Link from 'next/link';
import { ReactElement, useState } from 'react';
import { usePDF } from 'react-to-pdf';

interface ApplicationPdfTemplateProps {
  application: ChallengeApplication;
}
export function ApplicationPdfTemplate({
  application,
}: ApplicationPdfTemplateProps) {
  const { publicRuntimeConfig } = getConfig();
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

  const { data: listClaimRequest } = useQuery<{
    listCompanyClaims: CompanyClaim[];
  }>(GET_COMPANY_CLAIM_REQUESTS, {
    variables: { companyId: application.company.id },
  });

  const calculateManagerScore = () => {
    const score = application?.criteriaResults?.filter(
      (r) => r?.status === 'DONE' && r?.criteria?.type === 'CHALLENGE_MANAGER'
    )[0]?.scale;
    return score;
  };

  const BasicCard = ({
    title,
    children,
  }: {
    title: string;
    children: ReactElement;
  }) => {
    return (
      <div>
        <h3 className="font-semibold">{title}</h3>
        <div>{children}</div>
      </div>
    );
  };

  return (
    <>
      <Button
        className="space-x-2"
        shape="round"
        block
        onClick={() => setIsModalOpen(true)}
      >
        <DownloadOutlined />
        Download as PDF
      </Button>
      <Modal
        closeIcon={<CloseOutlined />}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title="PDF Preview"
        width={1106}
        footer={false}
      >
        <div ref={targetRef} aria-readonly>
          <div className="mx-4 pt-4">
            <NextImage
              src={`${publicRuntimeConfig.staticFolder}/logo-full-transparent.svg`}
              height="48"
              width="200"
              priority
              alt="SCV logo"
            />
            <Row gutter={[16, 16]} className="p-5">
              <Col span={24}>
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <div className="font-semibold">Date</div>
                    <div className="text-gray-700 text-sm">
                      {formatDate(application?.createdAt, 'do LLL yyyy, HH:mm')}
                    </div>
                  </div>
                  {meData?.me.role === 'ADMIN' && (
                    <div>
                      <div className="font-semibold">
                        Challenge manager score
                      </div>
                      <div className="text-gray-700 text-sm">
                        {calculateManagerScore()}
                      </div>
                    </div>
                  )}
                  <div>
                    <div className="font-semibold">Average expert score</div>
                    <div className="flex  gap-2 text-gray-700 text-sm mt-3">
                      <ApplicationEvaluationResultTag
                        criteriaResults={application?.criteriaResults}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={24} md={14}>
                <div className="flex flex-col space-y-3">
                  <Typography.Text className="text-sm !text-gray-700">
                    Solution name
                  </Typography.Text>
                  <Typography.Text className="text-base !text-gray-900 mt-1 font-semibold">
                    {application?.title}
                  </Typography.Text>
                  <Typography.Text className="text-sm !text-gray-700">
                    Solution description
                  </Typography.Text>
                  <div>{application?.description}</div>
                  {application?.answers && application?.answers?.length > 0 && (
                    <div className="space-y-2">
                      <Typography.Text className="text-base !text-gray-900 mt-1 font-semibold">
                        Questionnaire
                      </Typography.Text>
                      {application?.answers.map((item, index) => (
                        <div key={index} className="space-y-1">
                          <div className="font-semibold">
                            {item.questionText}
                          </div>
                          <RichEditorSerialized value={item.text} />
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="space-y-4">
                    <BasicCard
                      title="Applicant"
                      children={
                        <div className="space-y-3">
                          <div className="flex space-x-3">
                            <CompanyAvatar
                              className="mt-2"
                              size={64}
                              company={application.company}
                            />
                            <div className="flex flex-col">
                              <div className="text-base text-gray-900 font-semibold">
                                {application.company?.name}
                              </div>
                              <div className="text-sm text-gray-900">
                                {application.company?.description}
                              </div>
                            </div>
                          </div>
                          <div className="space-y-2">
                            {listClaimRequest?.listCompanyClaims
                              .filter((i) => i.status === 'APPROVED')
                              .map((item, index) => (
                                <div className="flex" key={index}>
                                  <UserAvatarPdfTemplate
                                    size="large"
                                    src={item.user.image?.url || null}
                                    name={item.user.fullName}
                                    className="!mr-2"
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      }
                    />

                    <BasicCard
                      title="Attachments"
                      children={
                        <div className="flex flex-col space-y-2">
                          {application?.video && (
                            <>
                              <Typography.Text className="text-xs !text-gray-700">
                                Video
                              </Typography.Text>
                              <Link
                                className="text-primary-600 space-x-2"
                                href={application?.video}
                                target="_blank"
                              >
                                <LinkOutlined />
                                <span>{application?.video}</span>
                              </Link>
                            </>
                          )}
                          {application?.documents?.length > 0 && (
                            <>
                              <Typography.Text className="text-xs !text-gray-700">
                                Documents
                              </Typography.Text>
                              {application?.documents?.map((file, index) => (
                                <Link
                                  target="_blank"
                                  key={index}
                                  className="text-primary-600 space-x-2"
                                  href={file?.file?.url || '#'}
                                >
                                  <FileOutlined />
                                  <span>{file?.file?.metadata?.filename}</span>
                                </Link>
                              ))}
                            </>
                          )}
                        </div>
                      }
                    />

                    <BasicCard
                      title="Challenge"
                      children={<div>{application?.challenge?.title}</div>}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <Button
          className="space-x-2"
          shape="round"
          block
          onClick={() => toPDF()}
        >
          <DownloadOutlined />
          Save PDF
        </Button>
      </Modal>
    </>
  );
}
