import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export function BookmarkIcon(props: Partial<CustomIconComponentProps>) {
  const BookmarkSvg = () => (
    <svg
      width="16"
      height={props.height || '21'}
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 19.8569L8 14.8569L1 19.8569V3.85693C1 3.3265 1.21071 2.81779 1.58579 2.44272C1.96086 2.06765 2.46957 1.85693 3 1.85693H13C13.5304 1.85693 14.0391 2.06765 14.4142 2.44272C14.7893 2.81779 15 3.3265 15 3.85693V19.8569Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return <Icon height="18px" component={BookmarkSvg} {...props} />;
}

export default BookmarkIcon;
