import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { SchemaLink } from '@apollo/client/link/schema';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';

import { createSplitLink } from './apolloLink';
import { removeTypenameFromMutationLink } from './middlewares';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

type SchemaContext =
  | SchemaLink.ResolverContext
  | SchemaLink.ResolverContextFunction;

/* eslint-disable */
interface InitApollo {
  initialState?: any;
  ctx?: SchemaContext;
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      // if (extensions?.code === 100 && window.location.pathname != '/login') {
      //   window.location.href = '/login';
      // }
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

function createIsomorphicLink(ctx?: SchemaContext) {
  // if (typeof window === 'undefined') {
  //   const { schema } = require('../modules/graphql/schema');

  //   return new SchemaLink({ schema, context: ctx });
  // }

  let GRAPHQL_URI = `${process.env.API_URL}/graphql`;
  if (typeof window !== 'undefined') {
    //this should be build an absolute url on client side....
    GRAPHQL_URI = `${process.env.NEXT_PUBLIC_BASE_URL}/graphql`;
  }

  const httpLink = new HttpLink({
    uri: GRAPHQL_URI,
    credentials: 'include', // Additional fetch() options like `credentials` or `headers`
  });
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 2,
      retryIf: (error, _operation) => !!error,
    },
  });

  return from([retryLink, errorLink, httpLink]);
}

function createApolloClient(ctx?: SchemaContext) {
  const ssrMode = typeof window === 'undefined';

  return new ApolloClient({
    ssrMode,
    link: ssrMode
      ? createIsomorphicLink(ctx || undefined)
      : from([
          removeTypenameFromMutationLink,
          createSplitLink(createIsomorphicLink(ctx || undefined)),
        ]),
    cache: new InMemoryCache({
      possibleTypes: {
        ChallengeApplication: [
          'IndividualChallengeApplication',
          'TeamChallengeApplication',
          'CompanyChallengeApplication',
        ],
      },
      typePolicies: {
        InternalChallenge: {
          fields: {
            topics: {
              merge: (existing, incoming) => {
                return !!incoming ? incoming : existing;
              },
            },
            regions: {
              merge: (existing, incoming) => {
                return !!incoming ? incoming : existing;
              },
            },
            questions: {
              merge: (existing, incoming) => {
                return !!incoming ? incoming : existing;
              },
            },
          },
        },
        OpenChallenge: {
          fields: {
            topics: {
              merge: (existing, incoming) => {
                return !!incoming ? incoming : existing;
              },
            },
            regions: {
              merge: (existing, incoming) => {
                return !!incoming ? incoming : existing;
              },
            },
            questions: {
              merge: (existing, incoming) => {
                return !!incoming ? incoming : existing;
              },
            },
          },
        },
        Company: {
          fields: {
            companyPortfolio: {
              merge(existing, incoming) {
                return !!incoming ? incoming : existing;
              },
            },
          },
        },
        Query: {
          fields: {
            me: {
              merge: true,
            },
            challengeIdeaQuestions: {
              merge: (existing, incoming) => {
                return !!incoming ? incoming : existing;
              },
            },
          },
        },
      },
    }),
  });
}

export function initializeApollo({ ctx, initialState }: InitApollo) {
  const _apolloClient = apolloClient ?? createApolloClient(ctx || undefined);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: { props: any }
) {
  pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();

  return pageProps;
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(
    () => initializeApollo({ initialState: state }),
    [state]
  );
  return store;
}
