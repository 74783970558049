import { Typography } from 'antd';

export interface PageTitleProps {
  title: string;
  description?: string;
}

const { Title } = Typography;

export function PageTitle({ title, description }: PageTitleProps) {
  return (
    <div>
      <Title className="!font-medium !mb-0" level={3}>
        {title}
      </Title>
      {description && <p className="text-gray-700 mb-2 mt-1">{description}</p>}
    </div>
  );
}

export default PageTitle;
