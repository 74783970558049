import {
  BulbOutlined,
  CloseOutlined,
  FileOutlined,
  LinkOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import {
  Answer,
  ChallengeApplication,
  CompanyClaim,
  Criteria,
  CriteriaResult,
  GET_COMPANY_CLAIM_REQUESTS,
  GET_ME,
  Me,
  User,
} from '@frontend/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DiscussionWall } from '@frontend/feature-discussion';
import { formatDate, getLinkLabel } from '@frontend/shared/util';
import { Col, Collapse, Modal, Progress, Row, Typography } from 'antd';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';

import ApplicationEvaluationResultTag from '../application-evaluation-result-tag/application-evaluation-result-tag';
import ApplicationManagerEvaluatuionScore from '../application-manager-evaluatuion-score/application-manager-evaluatuion-score';
import CompanyCard from '../company-card/company-card';
import EvaluateScore from '../evaluate-score/evaluate-score';
import IconCard from '../icon-card/icon-card';
import RichEditorSerialized from '../rich-editor-serialized/rich-editor-serialized';
import UserAvatar from '../user-avatar/user-avatar';
import { ApplicationPdfTemplate } from './application-pdf-template';

export interface ApplicationDetialProps {
  application: ChallengeApplication;
}

export function ApplicationDetail({ application }: ApplicationDetialProps) {
  const { t: tcommon } = useTranslation('common');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);

  const results =
    application?.criteriaResults.filter(
      (r) => r?.criteria?.type === 'CHALLENGE_EXPERT' && r?.status === 'DONE'
    ) || [];

  const isExpert =
    application.challenge?.experts?.some(
      (expert: User) => expert.id === meData?.me.id
    ) || false;

  const { data: listClaimRequest } = useQuery<{
    listCompanyClaims: CompanyClaim[];
  }>(GET_COMPANY_CLAIM_REQUESTS, {
    variables: { companyId: application.company.id },
  });

  const Questionnaire = ({ data }: { data?: Answer[] }) => {
    if (data !== undefined && data?.length) {
      return (
        <Collapse accordion>
          {data.map((item, index) => (
            <Collapse.Panel header={item.questionText} key={index}>
              <RichEditorSerialized value={item.text} />
            </Collapse.Panel>
          ))}
        </Collapse>
      );
    } else {
      return null;
    }
  };

  const calculateAverageScore = (data: CriteriaResult[]) => {
    const average =
      data.reduce((prev, curr) => prev + curr?.scale, 0) / data.length;
    return isNaN(average) ? undefined : average.toFixed(1);
  };

  const CriteriaProgress = ({
    data,
    result,
  }: {
    data: Criteria;
    result: CriteriaResult[];
  }) => {
    const finalResult = result.filter(
      (r) => r?.criteria?.id === data?.id && r?.status === 'DONE'
    );
    const percent =
      (finalResult.reduce((prev, result) => prev + result?.scale, 0) *
        data?.scale) /
      finalResult.length;

    return (
      <div className="flex justify-end">
        <div className="text-center space-y-2 font-medium">
          <Progress
            type="circle"
            width={80}
            strokeColor="#595959"
            percent={percent}
            format={(value) => (value ? (value / 10).toFixed(1) : '-')}
          />
          <div className="text-gray-700 font-normal text-xs">{data?.name}</div>
        </div>
      </div>
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} md={14}>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <div className="font-semibold">{tcommon('idea_detail_date')}</div>
            <div className="text-gray-700 text-sm">
              {formatDate(application?.createdAt, 'do LLL yyyy, HH:mm')}
            </div>
          </div>
          {meData?.me.role === 'ADMIN' && (
            <div>
              <div className="font-semibold">
                {tcommon('idea_detail_challenge_manager_score')}
              </div>
              <div className="text-gray-700 text-sm">
                <ApplicationManagerEvaluatuionScore application={application} />
              </div>
            </div>
          )}
          <div>
            <div className="font-semibold">
              {tcommon('idea_detail_average_expert_score')}
            </div>
            <div className="flex  gap-2 text-gray-700 text-sm">
              <div>
                <ApplicationEvaluationResultTag
                  criteriaResults={application?.criteriaResults}
                />
              </div>
              <div>
                {application?.criteriaResults?.length > 0 && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    className="text-primary-600 cursor-pointer"
                    onClick={() => setIsModalOpen(true)}
                  >
                    {tcommon('idea_detail_see_detail')}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col span={24} md={14}>
        <div className="flex flex-col space-y-3">
          <Typography.Text className="text-sm !text-gray-700">
            {tcommon('application_name')}
          </Typography.Text>
          <Typography.Text className="text-base !text-gray-900 mt-1 font-semibold">
            {application?.title}
          </Typography.Text>
          <Typography.Text className="text-sm !text-gray-700">
            {tcommon('application_description')}
          </Typography.Text>
          <div>{application?.description}</div>
          {application?.answers && application?.answers?.length > 0 && (
            <>
              <Typography.Text className="text-base !text-gray-900 mt-1 font-semibold">
                {tcommon('application_questionnaire')}
              </Typography.Text>
              <Questionnaire data={application?.answers} />
            </>
          )}

          {application && (
            <EvaluateScore type="APPLICATION" ideaId={application.id} />
          )}
          {(meData?.me.role === 'ADMIN' || isExpert) &&
            application.internalDiscussion?.id && (
              <DiscussionWall
                title={tcommon('application_evaluation_comment')}
                id={application.internalDiscussion.id}
              />
            )}
        </div>
      </Col>
      <Col span={24} md={10} className="!space-y-4">
        <IconCard title="Applicant" icon={<TeamOutlined />}>
          <div className="space-y-3">
            <CompanyCard company={application.company} />
            <div className="space-y-2">
              {listClaimRequest?.listCompanyClaims
                .filter((i) => i.status === 'APPROVED')
                .map((item, index) => (
                  <div className="flex" key={index}>
                    <UserAvatar
                      size="large"
                      user={item.user}
                      className="!mr-2"
                    />
                    <div className="flex flex-col">
                      <Typography.Text className="text-sm font-semibold !text-gray-900">
                        {item?.user?.fullName}
                      </Typography.Text>
                      <Typography.Text className="text-xs !text-gray-700">
                        {item?.user?.role &&
                          getLinkLabel(item?.user?.role.toLowerCase())}
                      </Typography.Text>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </IconCard>
        <IconCard title="Attachments" icon={<FileOutlined />}>
          <div className="flex flex-col space-y-2">
            {application?.video && (
              <>
                <Typography.Text className="text-xs !text-gray-700">
                  {tcommon('application_video')}
                </Typography.Text>
                <Link
                  data-test="application-challenge-video"
                  className="text-primary-600 space-x-2 truncate"
                  href={application?.video}
                  target="_blank"
                >
                  <LinkOutlined />
                  <span>{application?.video}</span>
                </Link>
              </>
            )}
            {application?.documents?.length > 0 && (
              <>
                <Typography.Text className="text-xs !text-gray-700">
                  {tcommon('application_documents')}
                </Typography.Text>
                {application?.documents?.map((file, index) => (
                  <Link
                    target="_blank"
                    key={index}
                    data-test="application-challenge-video"
                    className="text-primary-600 space-x-2 truncate"
                    href={file?.file?.url || '#'}
                  >
                    <FileOutlined />
                    <span>{file?.file?.metadata?.filename}</span>
                  </Link>
                ))}
              </>
            )}
          </div>
        </IconCard>
        <IconCard title="Challenge" icon={<BulbOutlined />}>
          <Link
            data-test="application-challenge-name"
            className="text-primary-600"
            href={
              '/entrepreneurship/challenge/' + application.challenge?.id || ''
            }
          >
            {application?.challenge?.title}
          </Link>
        </IconCard>
        {meData?.me.role === 'ADMIN' && (
          <ApplicationPdfTemplate application={application} />
        )}
      </Col>
      <Modal
        closeIcon={<CloseOutlined />}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title={tcommon('idea_detail_expert_score_title')}
        width={1106}
        footer={false}
      >
        <div className="overflow-x-scroll">
          <table className="w-full">
            <thead>
              <tr>
                <th className="text-left">
                  <div className="px-4">
                    <div className="text-gray-700 font-medium">
                      {tcommon('idea_detail_expert_score_overall')}
                    </div>
                    <div className="text-gray-900 font-medium text-4xl">
                      {calculateAverageScore(results) || '-'}
                    </div>
                  </div>
                </th>
                {application?.criteria
                  ?.filter((c) => c?.type !== 'CHALLENGE_MANAGER')
                  ?.map((criteria, index) => (
                    <th key={index} className="text-right">
                      <CriteriaProgress data={criteria} result={results} />
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {application?.challenge?.experts?.map((expert, index) => (
                <tr key={index}>
                  <td className="text-left border-b border-gray-400">
                    <div className="flex space-x-2 p-4">
                      <div className="flex items-center">
                        <UserAvatar user={expert} />
                      </div>
                      <div>
                        <div className="font-semibold">{expert?.fullName}</div>
                        <div className="text-gray-700">
                          {tcommon('idea_detail_expert_score_expert')}
                        </div>
                      </div>
                    </div>
                  </td>
                  {application?.criteria
                    ?.filter((c) => c?.type !== 'CHALLENGE_MANAGER')
                    ?.map((criteria, index) => {
                      const resultData = results.find(
                        (r) =>
                          r?.criteria?.id === criteria?.id &&
                          r?.createdBy?.id === expert?.id
                      );

                      return (
                        <td
                          key={index}
                          className="text-right border-b border-gray-400"
                        >
                          <div className="px-10">
                            {resultData ? resultData?.scale : '-'}
                          </div>
                        </td>
                      );
                    })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </Row>
  );
}

export default ApplicationDetail;
