import { gql } from '@apollo/client';

export const TAG = gql`
  fragment tag on Tag {
    categoryId
    createdAt
    id
    name
    updatedAt
  }
`;
