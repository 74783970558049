import { Progress } from 'antd';

export interface StepProgressProps {
  progress?: number;
  steps?: number;
  strokeColor?: string;
}

export function StepProgress({
  progress = 0,
  steps = 10,
  strokeColor = 'primary',
}: StepProgressProps) {
  return (
    <div className="mb-4">
      <div className="text-xs text-gray-700">
        Completed {progress} out of {steps}
      </div>
      <Progress
        percent={(100 * progress) / steps}
        showInfo={false}
        strokeColor={strokeColor}
      />
    </div>
  );
}

export default StepProgress;
