import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import getConfig from 'next/config';
import Image from 'next/image';

const covertName = (name: string) => {
  const splittedStr = name.split(/(\s+)/);
  let str = '';
  splittedStr.forEach((s) => {
    if (s.trim() !== '') {
      str = str.concat(s.trim().charAt(0).toUpperCase());
    }
  });
  return str;
};

export interface UserAvatarPdfTemplateProps {
  name: string;
  src: string | null;
  size?: number | 'default' | 'large' | 'small';
  shape?: 'square' | 'circle';
  level?: number;
  className?: string;
}

export function UserAvatarPdfTemplate({
  name,
  src,
  size = 'default',
  shape = 'circle',
  level,
  className,
}: UserAvatarPdfTemplateProps) {
  const { publicRuntimeConfig } = getConfig();

  const colors = [
    '!bg-primary-1000',
    '!bg-primary-800',
    '!bg-primary-700',
    '!bg-primary-500',
    '!bg-green-1000',
    '!bg-green-900',
    '!bg-green-600',
    '!bg-green-400',
    '!bg-primary-1000',
    '!bg-primary-800',
    '!bg-primary-700',
    '!bg-primary-500',
    '!bg-green-1000',
    '!bg-green-900',
    '!bg-green-600',
    '!bg-green-400',
    '!bg-primary-1000',
    '!bg-primary-800',
    '!bg-primary-700',
    '!bg-primary-500',
    '!bg-green-1000',
    '!bg-green-900',
    '!bg-green-600',
    '!bg-green-400',
    '!bg-primary-1000',
    '!bg-primary-800',
    '!bg-primary-700',
    '!bg-primary-500',
    '!bg-green-1000',
    '!bg-green-900',
    '!bg-green-600',
    '!bg-green-400',
  ];

  const color = name
    ? `${colors[name.toLowerCase().charCodeAt(0) - 97]}`
    : '!bg-primary-1000';

  if (name === '' && src === null) {
    return <Avatar size={size} shape={shape} icon={<UserOutlined />} />;
  }

  return (
    <div className="flex gap-2">
      <div className="relative">
        <Avatar
          size={size}
          shape={shape}
          src={src}
          className={`${className} ${color}`}
        >
          <span>{name && covertName(name)}</span>
        </Avatar>
        {level && (
          <div className="absolute bottom-[-5px] right-[-5px] hover:cursor-pointer">
            <Image
              src={`${publicRuntimeConfig.staticFolder}/svg/user-badge/${level}.svg`}
              height={20}
              width={20}
              alt="badge"
              className="drop-shadow-md"
            />
          </div>
        )}
      </div>
      {name}
    </div>
  );
}
export default UserAvatarPdfTemplate;
