import { InfoCircleOutlined } from '@ant-design/icons';

import EmptyCard from './empty-card';

export interface EditInformationCardProps {
  title: string;
  hasAccess?: boolean;
  uppercase?: boolean;
  content: React.ReactNode;
  extraAction?: React.ReactNode;
}

const EditInformationCard = ({
  title,
  content,
  extraAction,
  uppercase = true,
}: EditInformationCardProps) => {
  const dataTest = title.toLowerCase().replace(' ', '-');
  return (
    <div className="flex justify-between">
      <div className="flex-1 grid">
        <div className="flex gap-2 justify-between">
          <span className="text-primary-600 text-sm font-semibold mb-2">
            {uppercase ? title.toUpperCase() : title}
          </span>
          <div>{extraAction}</div>
        </div>
        <div data-test={dataTest + '-content'} className="overflow-auto">
          {content || (
            <EmptyCard title="No information" icon={<InfoCircleOutlined />} />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditInformationCard;
