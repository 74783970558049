import { gql } from '@apollo/client';

/* 
  use relative import within data-access; 
  absolute imports are causing undefined fragment error
*/
export const CHALLENGE_IDEA = gql`
  fragment challengeIdea on ChallengeIdea {
    id
    name
    createdUser {
      email
      fullName
      image {
        url
      }
    }
    state
    type
    assignmentLimit
    problemDescription
    assignments {
      id
      email
      fullName
      image {
        url
      }
    }
    topics {
      id
      name
    }
    answers {
      id
      text
      questionText
    }
    feedback {
      id
      comments {
        edges {
          cursor
          node {
            id
            user {
              id
              fullName
              image {
                url
              }
            }
            text
            createdAt
            updatedAt
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
    createdAt
    updatedAt
  }
`;

export const SOLUTION_IDEA = gql`
  fragment solutionIdea on SolutionIdea {
    id
    name
    createdUser {
      email
      fullName
      image {
        url
      }
    }
    state
    type
    assignmentLimit
    problemDescription
    solutionDescription
    document {
      id
      state
      metadata {
        extension
        filename
        size
      }
      objectKey
      public
      status
      storage
      url
    }
    link
    assignments {
      id
      email
      fullName
      image {
        url
      }
    }
    topics {
      id
      name
    }
    answers {
      id
      text
      questionText
    }
    feedback {
      id
      comments {
        edges {
          cursor
          node {
            id
            user {
              id
              fullName
              image {
                url
              }
            }
            text
            createdAt
            updatedAt
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
    createdAt
    updatedAt
  }
`;

export const CHALLENGE_IDEA_BASIC = gql`
  fragment challengeIdeaBasic on ChallengeIdeaPaginationTotal {
    items {
      id
      name
      createdUser {
        email
        fullName
        image {
          url
        }
      }
      state
      type
      assignments {
        id
        email
        fullName
        image {
          url
        }
      }
      createdAt
      updatedAt
    }
    total
  }
`;

export const SOLUTION_IDEA_BASIC = gql`
  fragment solutionIdeaBasic on SolutionIdeaPaginationTotal {
    items {
      id
      name
      createdUser {
        email
        fullName
        image {
          url
        }
      }
      state
      type
      assignments {
        id
        email
        fullName
        image {
          url
        }
      }
      createdAt
      updatedAt
    }
    total
  }
`;

export const MY_CHALLENGE_IDEAS = gql`
  fragment challengeIdeaPaginationTotal on ChallengeIdeaPaginationTotal {
    items {
      id
      name
      state
      createdAt
    }
    total
  }
`;

export const MY_SOLUTION_IDEAS = gql`
  fragment solutionIdeaPaginationTotal on SolutionIdeaPaginationTotal {
    items {
      id
      name
      state
      createdAt
    }
    total
  }
`;

export const CHALLENGE_IDEA_STATISTICS = gql`
  fragment challengeIdeaCount on IdeaCountAggregate {
    state
    count
  }
`;

export const SOLUTION_IDEA_STATISTICS = gql`
  fragment solutionIdeaCount on IdeaCountAggregate {
    state
    count
  }
`;

export const IDEA_QUESTION = gql`
  fragment ideaQuestion on IdeaQuestion {
    id
    text
    type
    createdAt
    updatedAt
  }
`;

export const GET_INTRAPRENEUR_CHALLENGE_IDEA = gql`
  query ChallengeIdea($ideaId: UUID!) {
    challengeIdea(ideaId: $ideaId) {
      ...challengeIdea
    }
  }
  ${CHALLENGE_IDEA}
`;

export const GET_INTRAPRENEUR_CHALLENGE_IDEA_FOR_USER = gql`
  query ChallengeIdeaForUser($ideaId: UUID!) {
    challengeIdeaForUser(ideaId: $ideaId) {
      ...challengeIdea
    }
  }
  ${CHALLENGE_IDEA}
`;

export const GET_INTRAPRENEUR_CHALLENGE_IDEA_FOR_COACH = gql`
  query ChallengeIdeaForUser($ideaId: UUID!) {
    challengeIdeaForCoach(ideaId: $ideaId) {
      ...challengeIdea
    }
  }
  ${CHALLENGE_IDEA}
`;

export const GET_INTRAPRENEUR_SOLUTION_IDEA = gql`
  query SolutionIdea($ideaId: UUID!) {
    solutionIdea(ideaId: $ideaId) {
      ...solutionIdea
    }
  }
  ${SOLUTION_IDEA}
`;

export const GET_INTRAPRENEUR_SOLUTION_IDEA_FOR_COACH = gql`
  query SolutionIdeaForUser($ideaId: UUID!) {
    solutionIdeaForCoach(ideaId: $ideaId) {
      ...solutionIdea
    }
  }
  ${SOLUTION_IDEA}
`;

export const GET_INTRAPRENEUR_SOLUTION_IDEA_FOR_USER = gql`
  query SolutionIdeaForUser($ideaId: UUID!) {
    solutionIdeaForUser(ideaId: $ideaId) {
      ...solutionIdea
    }
  }
  ${SOLUTION_IDEA}
`;

export const GET_INTRAPRENEUR_CHALLENGE_IDEAS = gql`
  query ChallengeIdeas(
    $filterBy: ChallengeIdeasFilters
    $limit: Int
    $page: Int
  ) {
    challengeIdeas(filterBy: $filterBy, limit: $limit, page: $page) {
      ...challengeIdeaBasic
    }
  }
  ${CHALLENGE_IDEA_BASIC}
`;

export const GET_INTRAPRENEUR_SOLUTION_IDEAS = gql`
  query SolutionIdeas(
    $filterBy: SolutionIdeasFilters
    $limit: Int
    $page: Int
  ) {
    solutionIdeas(filterBy: $filterBy, limit: $limit, page: $page) {
      ...solutionIdeaBasic
    }
  }
  ${SOLUTION_IDEA_BASIC}
`;

export const GET_CHALLENGE_IDEA_STATISTICS = gql`
  query ChallengeIdeaCount {
    challengeIdeaCount {
      ...challengeIdeaCount
    }
  }
  ${CHALLENGE_IDEA_STATISTICS}
`;

export const GET_SOLUTION_IDEA_STATISTICS = gql`
  query SolutionIdeaCount {
    solutionIdeaCount {
      ...solutionIdeaCount
    }
  }
  ${SOLUTION_IDEA_STATISTICS}
`;

export const GET_CHALLENGE_IDEA_QUESTIONS = gql`
  query challengeIdeaQuestions {
    challengeIdeaQuestions {
      ...ideaQuestion
    }
  }
  ${IDEA_QUESTION}
`;

export const GET_SOLUTION_IDEA_QUESTIONS = gql`
  query solutionIdeaQuestions {
    solutionIdeaQuestions {
      ...ideaQuestion
    }
  }
  ${IDEA_QUESTION}
`;

export const GET_MY_CHALLENGE_IDEAS = gql`
  query myChallengeIdeas($page: Int, $limit: Int) {
    me {
      challengeIdeas(page: $page, limit: $limit) {
        ...challengeIdeaPaginationTotal
      }
    }
  }
  ${MY_CHALLENGE_IDEAS}
`;

export const GET_MY_SOLUTION_IDEAS = gql`
  query mySolutionIdeas($page: Int, $limit: Int) {
    me {
      solutionIdeas(page: $page, limit: $limit) {
        ...solutionIdeaPaginationTotal
      }
    }
  }
  ${MY_SOLUTION_IDEAS}
`;

export const CREATE_CHALLENGE_IDEA = gql`
  mutation createChallengeIdea($input: CreateChallengeIdeaInput) {
    createChallengeIdea(input: $input) {
      ...challengeIdea
    }
  }
  ${CHALLENGE_IDEA}
`;

export const CREATE_SOLUTION_IDEA = gql`
  mutation createSolutionIdea($input: CreateSolutionIdeaInput) {
    createSolutionIdea(input: $input) {
      ...solutionIdea
    }
  }
  ${SOLUTION_IDEA}
`;

export const UPDATE_CHALLENGE_IDEA_STATE = gql`
  mutation UpdateChallengeIdeaState($input: UpdateChallengeIdeaInput) {
    updateChallengeIdeaState(input: $input) {
      ...challengeIdea
    }
  }
  ${CHALLENGE_IDEA}
`;

export const UPDATE_SOLUTION_IDEA_STATE = gql`
  mutation UpdateSolutionIdeaState($input: UpdateSolutionIdeaInput) {
    updateSolutionIdeaState(input: $input) {
      ...solutionIdea
    }
  }
  ${SOLUTION_IDEA}
`;

export const CREATE_CHALLENGE_IDEA_QUESTION = gql`
  mutation createChallengeIdeaQuestion($input: [String]) {
    createChallengeIdeaQuestion(input: $input) {
      ...ideaQuestion
    }
  }
  ${IDEA_QUESTION}
`;

export const CREATE_SOLUTION_IDEA_QUESTION = gql`
  mutation createSolutionIdeaQuestion($input: [String]) {
    createSolutionIdeaQuestion(input: $input) {
      ...ideaQuestion
    }
  }
  ${IDEA_QUESTION}
`;

export const ASSIGN_CHALLENGE_IDEA = gql`
  mutation assignChallengeIdea($input: AssignIdeaInput) {
    assignChallengeIdea(input: $input) {
      ...challengeIdea
    }
  }
  ${CHALLENGE_IDEA}
`;

export const ASSIGN_SOLUTION_IDEA = gql`
  mutation assignSolutionIdea($input: AssignIdeaInput) {
    assignSolutionIdea(input: $input) {
      ...solutionIdea
    }
  }
  ${SOLUTION_IDEA}
`;

export const GET_COACH_ASSIGNED_SOLUTION_IDEAS = gql`
  query assignedSolutionIdeas {
    me {
      assignedSolutionIdeas {
        id
        name
        state
        feedback {
          comments {
            edges {
              cursor
              node {
                likeCount
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_COACH_ASSIGNED_CHALLENGE_IDEAS = gql`
  query assignedChallengeIdeas {
    me {
      assignedChallengeIdeas {
        id
        name
        state
        feedback {
          comments {
            edges {
              cursor
              node {
                likeCount
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_COACH_ASSIGNED_CHALLENGES = gql`
  query AssignedChallenges {
    me {
      assignedChallenges {
        stage {
          id
          name
          viewerAssignedStageApplications {
            application {
              ... on IndividualChallengeApplication {
                challenge {
                  id
                  title
                }
              }
              ... on TeamChallengeApplication {
                challenge {
                  id
                  title
                }
              }
              ... on CompanyChallengeApplication {
                challenge {
                  id
                  title
                }
              }
            }
          }
        }
        count
      }
    }
  }
`;
