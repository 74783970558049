import { gql } from '@apollo/client';

import { COMPANY_BASIC, COMPANY_INFO } from './company';
import { CRITERIA, CRITERIA_RESULT } from './criteria';
import { FILE } from './upload';
import { USER_BASICS } from './user';

// import { STAGE_APPLICATION } from './stage';
export const STAGE_APPLICATION = gql`
  fragment stageApplication on StageApplication {
    stageId
    userId
    applicationId
    createdAt
    stage {
      id
    }
    user {
      ...userBasics
    }
    application {
      ...challengeApplication
    }
    reviewers {
      ...userBasics
    }
    feedbackDiscussionId
    internalDiscussionId
  }
`;

export const INDIVIDUAL_CHALLENGE_APPLICATION = gql`
  fragment individualChallengeApplication on IndividualChallengeApplication {
    id
    title
    video
    description
    user {
      ...userBasics
    }
    challenge {
      id
    }
    answers {
      id
      questionId
      text
      questionText
    }
    currentStage {
      id
      name
    }
    state
    submittedAt
    createdAt
    updatedAt
  }
`;

export const COMPANY_CHALLENGE_APPLICATION = gql`
  fragment companyChallengeApplication on CompanyChallengeApplication {
    id
    title
    video
    description
    answers {
      id
      text
      questionId
      questionText
    }
    state
    company {
      ...basicInfo
    }
    documents {
      id
      file {
        ...file
      }
      createdAt
      updatedAt
      stage {
        id
        name
        state
        orderNumber
      }
    }
    submittedAt
    createdAt
    updatedAt
  }
`;

export const CHALLENGE_APPLICATION = gql`
  fragment challengeApplication on ChallengeApplication {
    ... on IndividualChallengeApplication {
      id
      title
      description
      video
      state
      currentStage {
        id
        name
      }
      answers {
        id
        text
        questionId
        questionText
      }
      user {
        ...userBasics
      }
      documents {
        id
        file {
          ...file
        }
        createdAt
        updatedAt
        stage {
          id
          name
          state
          orderNumber
        }
      }
      challenge {
        id
        title
        state
        registrationEndAt
        stages {
          id
          name
          description
          orderNumber
          state
        }
      }
      submittedAt
      createdAt
      __typename
    }
    ... on TeamChallengeApplication {
      id
      title
      description
      video
      state
      currentStage {
        id
        name
      }
      answers {
        id
        text
        questionId
        questionText
      }
      user {
        ...userBasics
      }
      team {
        ...team
      }
      documents {
        id
        file {
          ...file
        }
        createdAt
        updatedAt
        stage {
          id
          name
          state
          orderNumber
        }
      }
      challenge {
        id
        title
        state
        registrationEndAt
        stages {
          id
          name
          description
          orderNumber
          state
        }
      }
      submittedAt
      createdAt
      __typename
    }
    ... on CompanyChallengeApplication {
      id
      title
      description
      video
      state
      answers {
        id
        text
        questionId
        questionText
      }
      company {
        ...basicInfo
      }
      documents {
        id
        file {
          ...file
        }
        createdAt
        updatedAt
        stage {
          id
          name
          state
          orderNumber
        }
      }
      internalDiscussion {
        id
      }
      submittedAt
      user {
        ...userBasics
      }
      createdAt
      criteriaResults {
        ...result
      }
      criteria {
        ...criteria
      }
      challenge {
        id
        title
        experts {
          ...userBasics
        }
      }
      __typename
    }
  }
`;

export const CHALLENGE_APPLICATION_BASIC = gql`
  fragment challengeApplicationBasic on ChallengeApplication {
    ... on IndividualChallengeApplication {
      id
      title
      description
      video
      state
      currentStage {
        id
        name
      }
      answers {
        id
        text
        questionId
        questionText
      }
      user {
        ...userBasics
      }
      documents {
        id
        file {
          ...file
        }
        createdAt
        updatedAt
        stage {
          id
          name
          state
          orderNumber
        }
      }
      challenge {
        id
        title
      }
      submittedAt
      createdAt
      __typename
    }
    ... on TeamChallengeApplication {
      id
      title
      description
      video
      state
      currentStage {
        id
        name
      }
      answers {
        id
        text
        questionId
        questionText
      }
      user {
        ...userBasics
      }
      team {
        ...team
      }
      documents {
        id
        file {
          ...file
        }
        createdAt
        updatedAt
        stage {
          id
          name
          state
          orderNumber
        }
      }
      challenge {
        id
        title
      }
      submittedAt
      createdAt
      __typename
    }
    ... on CompanyChallengeApplication {
      id
      title
      video
      description
      state
      answers {
        id
        text
        questionId
        questionText
      }
      company {
        ...basicInfo
      }
      # user {
      #   ...userBasics
      # }
      challenge {
        id
        title
        state
        registrationEndAt
        criterias {
          ...criteria
        }
        experts {
          ...userBasics
        }
      }
      criteriaResults {
        ...result
      }
      internalDiscussion {
        id
      }
      criteria {
        ...criteria
      }
      documents {
        id
        file {
          ...file
        }
        createdAt
        updatedAt
        stage {
          id
          name
          state
          orderNumber
        }
      }
      updatedAt
      submittedAt
      createdAt
      __typename
    }
  }
`;

export const TEAM_CHALLENGE_APPLICATION = gql`
  fragment teamChallengeApplication on TeamChallengeApplication {
    id
    title
    description
    video
    user {
      ...userBasics
    }
    team {
      ...team
    }
    challenge {
      id
    }
    answers {
      id
      questionId
      text
      questionText
    }
    currentStage {
      id
      name
    }
    documents {
      id
      file {
        ...file
      }
      createdAt
      updatedAt
    }
    state
    submittedAt
    createdAt
    updatedAt
  }
`;

export const MY_CHALLENGE_APPLICATIONS = gql`
  fragment challengeApplicationPaginationTotal on ChallengeApplicationPaginationTotal {
    items {
      ... on IndividualChallengeApplication {
        id
        title
        description
        state
        currentStage {
          id
          name
        }
        user {
          ...userBasics
        }
        challenge {
          id
          title
          state
          registrationEndAt
        }
        submittedAt
        createdAt
        __typename
      }
      ... on TeamChallengeApplication {
        id
        title
        description
        state
        currentStage {
          id
          name
        }
        user {
          ...userBasics
        }
        team {
          ...team
        }
        challenge {
          id
          title
          state
          registrationEndAt
        }
        submittedAt
        createdAt
        __typename
      }
      ... on CompanyChallengeApplication {
        id
        title
        description
        state
        answers {
          id
          text
          questionId
          questionText
        }
        company {
          ...basicInfo
        }
        challenge {
          id
          title
          state
          registrationEndAt
        }
        submittedAt
        createdAt
        __typename
      }
    }
    total
  }
`;

export const MY_OPEN_CHALLENGE_APPLICATIONS = gql`
  fragment openChallengeApplicationPaginationTotal on OpenChallengeApplicationPaginationTotal {
    items {
      ... on IndividualChallengeApplication {
        id
        title
        description
        state
        currentStage {
          id
          name
        }
        user {
          ...userBasics
        }
        challenge {
          id
          title
          state
          registrationEndAt
        }
        submittedAt
        createdAt
        __typename
      }
      ... on TeamChallengeApplication {
        id
        title
        description
        state
        currentStage {
          id
          name
        }
        user {
          ...userBasics
        }
        team {
          ...team
        }
        challenge {
          id
          title
          state
          registrationEndAt
        }
        submittedAt
        createdAt
        __typename
      }
      ... on CompanyChallengeApplication {
        id
        title
        description
        state
        answers {
          id
          text
          questionId
          questionText
        }
        company {
          ...basicInfo
        }
        challenge {
          id
          title
          state
          registrationEndAt
        }
        submittedAt
        createdAt
        __typename
      }
    }
    total
  }
`;

export const TEAM = gql`
  fragment team on Team {
    id
    members {
      teamId
      userId
      user {
        id
        fullName
        image {
          url
        }
      }
      createdAt
      updatedAt
    }
    leaderId
    createdAt
    updatedAt
  }
`;

export const GET_MY_INTRA_CHALLENGE_APPLICATIONS = gql`
  query InternalApplications($limit: Int, $page: Int) {
    me {
      internalApplications(limit: $limit, page: $page) {
        ...challengeApplicationPaginationTotal
      }
    }
  }
  ${MY_CHALLENGE_APPLICATIONS}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
`;

export const GET_MY_OPEN_CHALLENGE_APPLICATIONS = gql`
  query Applications($limit: Int, $page: Int) {
    me {
      applications(limit: $limit, page: $page) {
        ...openChallengeApplicationPaginationTotal
      }
    }
  }
  ${MY_OPEN_CHALLENGE_APPLICATIONS}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
`;

export const GET_MY_INDIVIDUAL_CHALLENGE_APPLICATION_BY_ID = gql`
  query application($id: ID!) {
    me {
      id
      email
      fullName
      image {
        ...file
      }
      application(id: $id) {
        ...challengeApplication
      }
    }
  }
  ${CHALLENGE_APPLICATION}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const GET_APPLICATION = gql`
  query application($id: UUID!) {
    application(id: $id) {
      ...challengeApplication
    }
  }
  ${CHALLENGE_APPLICATION}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const GET_INDIVIDUAL_CHALLENGE_APPLICATION = gql`
  query IndividualApplication($id: ID!) {
    individualApplication(id: $id) {
      id
    }
  }
`;

export const GET_INTRAPRENEUR_CHALLENGES_APPLICATION_FOR_USER = gql`
  query ChallengeApplicationForUser($id: UUID!) {
    challengeApplicationForUser(id: $id) {
      ...challengeApplicationBasic
    }
  }
  ${CHALLENGE_APPLICATION_BASIC}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const CREATE_INDIVIDUAL_CHALLENGE_APPLICATION = gql`
  mutation CreateIndividualChallengeApplication(
    $input: CreateIndividualChallengeApplicationInput
  ) {
    createIndividualChallengeApplication(input: $input) {
      ...individualChallengeApplication
    }
  }
  ${INDIVIDUAL_CHALLENGE_APPLICATION}
  ${USER_BASICS}
  ${FILE}
`;

export const CREATE_TEAM_CHALLENGE_APPLICATION = gql`
  mutation CreateTeamChallengeApplication(
    $input: CreateTeamChallengeApplicationInput
  ) {
    createTeamChallengeApplication(input: $input) {
      ...teamChallengeApplication
    }
  }
  ${TEAM_CHALLENGE_APPLICATION}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
`;

export const PATCH_CHALLENGE_APPLICATION = gql`
  mutation PatchChallengeApplication($input: PatchChallengeApplicationInput) {
    patchChallengeApplication(input: $input) {
      ...challengeApplication
    }
  }
  ${CHALLENGE_APPLICATION}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const SUBMIT_CHALLENGE_APPLICATION = gql`
  mutation SubmitApplication($input: SubmitApplicationInput) {
    submitApplication(input: $input) {
      ...challengeApplication
    }
  }
  ${CHALLENGE_APPLICATION}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_INFO}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const DELETE_CHALLENGE_APPLICATION = gql`
  mutation DeleteChallengeApplication($applicationId: ID!) {
    deleteChallengeApplication(applicationId: $applicationId) {
      applicationId
    }
  }
`;

export const QUALIFY_APPLICATION = gql`
  mutation QualifyApplication($input: QualifyApplicationInput) {
    qualifyApplication(input: $input) {
      ...stageApplication
    }
  }
  ${STAGE_APPLICATION}
  ${CHALLENGE_APPLICATION}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const DISQUALIFY_APPLICATION = gql`
  mutation DisqualifyApplication($input: DisqualifyApplicationInput) {
    disqualifyApplication(input: $input) {
      id
    }
  }
`;

export const CREATE_TEAM = gql`
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      ...team
    }
  }
  ${TEAM}
`;

export const UPDATE_TEAM = gql`
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      ...team
    }
  }
  ${TEAM}
`;

export const UPLOAD_APPLICATION_DOCUMENT = gql`
  mutation UploadApplicationDocument($input: ApplicationDocumentInput) {
    uploadApplicationDocument(input: $input) {
      id
      stage {
        name
        state
        id
        orderNumber
      }
      file {
        id
        storage
        url
        status
        public
        state
        metadata {
          filename
          size
          mime
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_APPLICATION_DOCUMENT = gql`
  mutation DeleteApplicationDocument($documentId: UUID) {
    deleteApplicationDocument(documentId: $documentId)
  }
`;

export const DOWNLOAD_CHALLENGE_APPLICATION = gql`
  query DownloadChallengeApplicationList($challengeId: UUID!) {
    downloadChallengeApplicationList(challengeId: $challengeId)
  }
`;
