import { useLazyQuery } from '@apollo/client';
import {
  Challenge,
  GET_INTRAPRENEUR_CHALLENGE_STAGES,
  GET_OPEN_CHALLENGE_STAGES,
  Stage,
} from '@frontend/data-access';
import { RichEditorSerialized } from '@frontend/shared/ui';
import { Spin, StepProps, Steps, Typography } from 'antd';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

export interface ChallengeTimelineProps {
  challenge: Challenge & { __typename?: string };
}

export const statesMap = {
  UPCOMING: 'wait',
  ACTIVE: 'process',
  ENDED: 'finish',
};

export function ChallengeTimeline({ challenge }: ChallengeTimelineProps) {
  const { t } = useTranslation('admin');
  const [stages, setStages] = useState<Stage[]>([]);
  const [getOpenChallengeStages, resultOpenChallenge] = useLazyQuery<{
    openChallenge: {
      stages: Stage[];
    };
  }>(GET_OPEN_CHALLENGE_STAGES);
  const [getIntraChallengeStages, resultIntraChallenge] = useLazyQuery<{
    internalChallenge: {
      stages: Stage[];
    };
  }>(GET_INTRAPRENEUR_CHALLENGE_STAGES);

  useEffect(() => {
    if (challenge) {
      switch (challenge.__typename) {
        case 'OpenChallenge':
          getOpenChallengeStages({
            variables: {
              challengeId: challenge.id,
            },
          });
          break;
        case 'InternalChallenge':
          getIntraChallengeStages({
            variables: {
              challengeId: challenge.id,
            },
          });
          break;
        default:
          break;
      }
    }
  }, [challenge, getOpenChallengeStages, getIntraChallengeStages]);

  useEffect(() => {
    if (resultOpenChallenge?.data?.openChallenge?.stages) {
      const sortedStages = resultOpenChallenge.data.openChallenge.stages
        .slice(0)
        .sort((a, b) => a.orderNumber - b.orderNumber);

      setStages(sortedStages);
    }

    if (resultIntraChallenge?.data?.internalChallenge?.stages) {
      const sortedStages = resultIntraChallenge.data.internalChallenge.stages
        .slice(0)
        .sort((a, b) => a.orderNumber - b.orderNumber);

      setStages(sortedStages);
    }
  }, [resultOpenChallenge, resultIntraChallenge]);

  if (resultOpenChallenge?.loading || resultIntraChallenge?.loading) {
    return (
      <div className="flex justify-center p-5">
        <Spin />
      </div>
    );
  }

  const items = stages.map((stage) => {
    return {
      title: '',
      status: statesMap[stage.state],
      description: (
        <div className="border border-gray-300 rounded-xl p-4 my-3">
          <div className="text-base font-medium mb-1 text-gray-900">
            {stage.name}
          </div>
          <Typography.Text>
            <RichEditorSerialized value={stage.description} />
          </Typography.Text>
        </div>
      ),
    };
  });

  return (
    <>
      <div className="font-semibold mb-4">
        {t('intra_challenge_timeline_stages')}
      </div>
      <Steps
        progressDot
        status="process"
        direction="vertical"
        items={items as StepProps[]}
      />
    </>
  );
}

export default ChallengeTimeline;
