import { useLazyQuery } from '@apollo/client';
import { GET_CHALLENGE_IDEAS, Idea } from '@frontend/data-access';
import { IdeaCardUser } from '@frontend/feature-idea';
import { Empty, Spin } from 'antd';
import { useEffect } from 'react';

/* eslint-disable-next-line */
export interface ChallengeIdeaProps {
  challengeId: string;
}

export function ChallengeIdea({ challengeId }: ChallengeIdeaProps) {
  const [getData, { data, loading }] = useLazyQuery<{
    challengeIdeas: Idea[];
  }>(GET_CHALLENGE_IDEAS);

  useEffect(() => {
    getData({
      variables: {
        challengeId,
      },
    });
  }, [challengeId, getData]);

  if (loading) {
    return (
      <div className="p-6 text-center">
        <Spin />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="font-semibold mb-4">Ideas</div>
      <div className="space-y-6">
        {data?.challengeIdeas.length ? (
          data?.challengeIdeas.map((idea) => <IdeaCardUser data={idea} />)
        ) : (
          <div>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No idea application at the moment"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ChallengeIdea;
