import { gql } from '@apollo/client';

import { CHALLENGE_ANNOUNCEMENT } from './challenge-announcement';
import {
  CHALLENGE_APPLICATION,
  COMPANY_CHALLENGE_APPLICATION,
  STAGE_APPLICATION,
  TEAM,
} from './challenge-application';
import { COMPANY_BASIC, COMPANY_INFO } from './company';
import { CRITERIA, CRITERIA_RESULT } from './criteria';
import { QUESTION } from './question';
import { REGION } from './region';
import { STAGE } from './stage';
import { TOPIC } from './topic';
import { FILE } from './upload';
import { USER_BASICS } from './user';

export const OPEN_CHALLENGE_BASIC = gql`
  fragment openChallengeBasic on OpenChallenge {
    id
    title
    state
    description
    longDescription
    participationType
    discussionWall
    discussion {
      id
    }
    sponsor
    banner {
      ...file
    }
    regions {
      ...region
    }
    topics {
      ...topic
    }
    prizes
    prizeCashAmount
    registrationStartAt
    registrationEndAt
    registrationEndedAt
    timezone
    createdAt
    updatedAt
    pinned
    publishAt
    createdBy {
      ...userBasics
    }
    videoRequirements
    documentRequirements
  }
`;

export const OPEN_CHALLENGE_STATISTICS = gql`
  fragment openChallengesCount on ChallengesCount {
    active
    ended
    upcoming
    draft
  }
`;

export const GET_OPEN_CHALLENGES = gql`
  query OpenChallenges(
    $page: Int
    $limit: Int
    $sort: InternalChallengeSortOption
    $filterBy: InternalChallengesFilters
  ) {
    openChallenges(
      page: $page
      limit: $limit
      sort: $sort
      filterBy: $filterBy
    ) {
      ...openChallengeBasic
    }
  }
  ${OPEN_CHALLENGE_BASIC}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${USER_BASICS}
`;

export const GET_OPEN_CHALLENGES_AGGREGATE = gql`
  query OpenChallengesAggregate($filterBy: OpenChallengesFilters) {
    openChallengesAggregate(filterBy: $filterBy) {
      totalCount
    }
  }
`;

export const GET_OPEN_CHALLENGES_FOR_USER = gql`
  query OpenChallengesForUser(
    $first: Int
    $after: String
    $filterBy: OpenChallengesForUserFilters
    $forYou: Boolean!
  ) {
    openChallengesForUser(
      first: $first
      after: $after
      filterBy: $filterBy
      forYou: $forYou
    ) {
      edges {
        cursor
        node {
          id
          title
          state
          description
          participationType
          sponsor
          banner {
            ...file
          }
          regions {
            ...region
          }
          topics {
            ...topic
          }
          timezone
          registrationStartAt
          registrationEndAt
          registrationEndedAt
          createdBy {
            ...userBasics
          }
          prizes
          prizeCashAmount
          pinned
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${USER_BASICS}
`;

export const GET_OPEN_CHALLENGE_BUNDLE = gql`
  query getChallengeTags {
    getChallengeTags {
      id
      name
      desc
      brandColor
      backColor
      price
      banner {
        ...file
      }
      modifiedAt
      series
      challenges {
        ...openChallengeBasic
      }
    }
  }
  ${OPEN_CHALLENGE_BASIC}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${USER_BASICS}
`;

export const GET_OPEN_CHALLENGE_STATISTICS = gql`
  query OpenChallengesCount {
    openChallengesCount {
      ...openChallengesCount
    }
  }
  ${OPEN_CHALLENGE_STATISTICS}
`;

export const GET_OPEN_CHALLENGE_PUBLIC = gql`
  query OpenChallenge($challengeId: ID!) {
    openChallenge(challengeId: $challengeId) {
      ...openChallengeBasic
    }
  }
  ${OPEN_CHALLENGE_BASIC}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${USER_BASICS}
`;

export const GET_OPEN_CHALLENGE = gql`
  query OpenChallenge($challengeId: ID!) {
    openChallenge(challengeId: $challengeId) {
      ...openChallengeBasic
      questions {
        ...question
      }
      progress {
        basic
        media
        requirements
        application
        timeline
        questionnaire
        discussionWall
      }
      applications {
        ...challengeApplication
      }
      stages {
        ...stage
      }
      criterias {
        ...criteria
      }
      experts {
        ...userBasics
      }
    }
  }
  ${OPEN_CHALLENGE_BASIC}
  ${QUESTION}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${USER_BASICS}
  ${CHALLENGE_APPLICATION}
  ${COMPANY_BASIC}
  ${TEAM}
  ${COMPANY_INFO}
  ${STAGE}
  ${STAGE_APPLICATION}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const GET_OPEN_CHALLENGE_DETAILS = gql`
  query OpenChallenge($challengeId: ID!) {
    openChallenge(challengeId: $challengeId) {
      ...openChallengeBasic
      questions {
        ...question
      }
      progress {
        basic
        media
        requirements
        application
        timeline
        questionnaire
        discussionWall
      }
    }
  }
  ${OPEN_CHALLENGE_BASIC}
  ${QUESTION}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${USER_BASICS}
`;

export const GET_FEATURED_OPEN_CHALLENGE = gql`
  query GetFeaturedOpenChallenge($spaceTagId: UUID) {
    getFeaturedOpenChallenge(spaceTagId: $spaceTagId) {
      id
      title
      state
      description
      sponsor
      registrationStartAt
      registrationEndedAt
      registrationEndAt
      timezone
      banner {
        ...file
      }
      regions {
        ...region
      }
      topics {
        ...topic
      }
      participationType
    }
  }
  ${FILE}
  ${REGION}
  ${TOPIC}
`;

export const GET_OPEN_CHALLENGE_FOR_MANAGEMENT = gql`
  query OpenChallenge($challengeId: ID!) {
    openChallenge(challengeId: $challengeId) {
      id
      title
      registrationStartAt
      registrationEndAt
      registrationEndedAt
      state
      stages {
        ...stage
      }
      applications {
        ...challengeApplication
      }
    }
  }
  ${CHALLENGE_APPLICATION}
  ${TEAM}
  ${USER_BASICS}
  ${FILE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${STAGE}
  ${STAGE_APPLICATION}
`;

export const GET_OPEN_CHALLENGE_STAGES = gql`
  query OpenChallenge($challengeId: ID!) {
    openChallenge(challengeId: $challengeId) {
      id
      stages {
        ...stage
      }
    }
  }
  ${STAGE}
  ${STAGE_APPLICATION}
  ${CHALLENGE_APPLICATION}
  ${USER_BASICS}
  ${FILE}
  ${TEAM}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const GET_OPEN_CHALLENGE_ANNOUNCEMENT = gql`
  query OpenChallenge($challengeId: ID!) {
    openChallenge(challengeId: $challengeId) {
      id
      announcements {
        ...announcement
      }
    }
  }
  ${CHALLENGE_ANNOUNCEMENT}
`;

export const GET_OPEN_CHALLENGE_BASICS = gql`
  query OpenChallenge($challengeId: ID!) {
    openChallenge(challengeId: $challengeId) {
      id
      title
      state
      description
      longDescription
      participationType
      questions {
        ...question
      }
      videoRequirements
      documentRequirements
    }
  }
  ${QUESTION}
`;

export const GET_OPEN_CHALLENGE_SUGGESTION = gql`
  query OpenChallengeSuggestion($limit: Int) {
    openChallengeSuggestion(limit: $limit) {
      ...openChallengeBasic
    }
  }
  ${OPEN_CHALLENGE_BASIC}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${USER_BASICS}
`;

export const GET_OPEN_CHALLENGES_BASIC = gql`
  query OpenChallenges(
    $page: Int
    $limit: Int
    $sort: InternalChallengeSortOption
    $filterBy: InternalChallengesFilters
  ) {
    openChallenges(
      page: $page
      limit: $limit
      sort: $sort
      filterBy: $filterBy
    ) {
      id
      title
    }
  }
`;

export const GET_OPEN_CHALLENGE_ASSIGNED_APPLICATIONS = gql`
  query AssignedEvaluationApplications(
    $page: Int
    $limit: Int
    $applicationExpertFilter: ApplicationExpertFilter
  ) {
    assignedEvaluationApplications(
      page: $page
      limit: $limit
      applicationExpertFilter: $applicationExpertFilter
    ) {
      items {
        ... on IndividualChallengeApplication {
          id
          title
          description
          video
          state
          currentStage {
            id
            name
          }
          answers {
            id
            text
            questionId
            questionText
          }
          user {
            ...userBasics
          }
          documents {
            id
            file {
              ...file
            }
            createdAt
            updatedAt
            stage {
              id
              name
              state
              orderNumber
            }
          }
          challenge {
            id
            title
          }
          submittedAt
          createdAt
          __typename
        }
        ... on TeamChallengeApplication {
          id
          title
          description
          video
          state
          currentStage {
            id
            name
          }
          answers {
            id
            text
            questionId
            questionText
          }
          user {
            ...userBasics
          }
          team {
            ...team
          }
          documents {
            id
            file {
              ...file
            }
            createdAt
            updatedAt
            stage {
              id
              name
              state
              orderNumber
            }
          }
          challenge {
            id
            title
          }
          submittedAt
          createdAt
          __typename
        }
        ... on CompanyChallengeApplication {
          id
          title
          company {
            id
            logo {
              ...file
            }
            name
          }
          video
          documents {
            file {
              ...file
            }
          }
          challenge {
            id
            title
            criterias {
              ...criteria
            }
          }
          currentStage {
            id
            name
          }
          criteriaResults {
            id
            criteria {
              ...criteria
            }
            scale
            createdBy {
              ...userBasics
            }
            status
            createdAt
          }
          createdAt
          updatedAt
        }
      }
      total
    }
  }
  ${FILE}
  ${CRITERIA}
  ${USER_BASICS}
  ${TEAM}
`;

export const UPDATE_OPEN_CHALLENGE_BUNDLE = gql`
  mutation UpdateChallengeTags($input: CreateChallengeTagsInput) {
    updateChallengeTags(input: $input) {
      id
      name
      desc
      brandColor
      backColor
      price
      banner {
        id
        state
        objectKey
        public
        status
        storage
        url
      }
      modifiedAt
      series
      challenges {
        ...openChallengeBasic
      }
    }
  }
  ${OPEN_CHALLENGE_BASIC}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${USER_BASICS}
`;

export const CREATE_OPEN_CHALLENGE_BUNDLE = gql`
  mutation CreateChallengeTags($input: CreateChallengeTagsInput) {
    createChallengeTags(input: $input) {
      id
      name
      desc
      brandColor
      backColor
      price
      banner {
        id
        state
        objectKey
        public
        status
        storage
        url
      }
      modifiedAt
      series
      challenges {
        ...openChallengeBasic
      }
    }
  }
  ${OPEN_CHALLENGE_BASIC}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${USER_BASICS}
`;

export const DELETE_OPEN_CHALLENGE_BUNDLE = gql`
  mutation DeleteChallengeFromTag($id: UUID) {
    deleteChallengeFromTag(id: $id) {
      id
    }
  }
`;

export const CREATE_OPEN_CHALLENGE = gql`
  mutation CreateOpenChallenge($input: CreateOpenChallengeInput) {
    createOpenChallenge(input: $input) {
      id
      title
      description
      longDescription
      prizes
      prizeCashAmount
      topics {
        id
      }
      regions {
        id
      }
      sponsor
    }
  }
`;

export const PATCH_OPEN_CHALLENGE = gql`
  mutation PatchOpenChallenge($input: PatchOpenChallengeInput) {
    patchOpenChallenge(input: $input) {
      ...openChallengeBasic
      progress {
        basic
        media
        requirements
        application
        timeline
        questionnaire
        discussionWall
      }
    }
  }
  ${OPEN_CHALLENGE_BASIC}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${USER_BASICS}
`;

export const CREATE_COMPANY_CHALLENGE_APPLICATION = gql`
  mutation CreateCompanyChallengeApplication(
    $input: CreateCompanyChallengeApplicationInput
  ) {
    createCompanyChallengeApplication(input: $input) {
      ...companyChallengeApplication
    }
  }
  ${COMPANY_CHALLENGE_APPLICATION}
  ${COMPANY_BASIC}
  ${FILE}
  ${COMPANY_INFO}
`;

export const PIN_OPEN_CHALLENGE = gql`
  mutation PinOpenChallenge($challengeId: ID!) {
    pinOpenChallenge(challengeId: $challengeId) {
      challengeId
      featured
    }
  }
`;

export const UNPIN_OPEN_CHALLENGE = gql`
  mutation UnpinOpenChallenge($challengeId: ID!) {
    unpinOpenChallenge(challengeId: $challengeId)
  }
`;
