import { gql } from '@apollo/client';

export const QUESTION = gql`
  fragment question on Question {
    id
    text
    createdAt
    updatedAt
  }
`;

export const CREATE_QUESTION = gql`
  mutation CreateQuestion($input: CreateQuestionInput) {
    createQuestion(input: $input) {
      ...question
    }
  }
  ${QUESTION}
`;

export const CREATE_QUESTIONS = gql`
  mutation CreateQuestions($inputs: [CreateQuestionInput]) {
    createQuestions(inputs: $inputs) {
      ...question
    }
  }
  ${QUESTION}
`;

export const UPDATE_QUESTIONS = gql`
  mutation UpdateQuestions($challengeId: ID!, $inputs: [UpdateQuestionInput]) {
    updateQuestions(challengeId: $challengeId, inputs: $inputs) {
      ...question
    }
  }
  ${QUESTION}
`;
