import {
  ArrowRightOutlined,
  CalendarOutlined,
  DollarOutlined,
  FlagOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Challenge, GET_ME, Me } from '@frontend/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { StatusTag } from '@frontend/shared/ui';
import { formatDateWithTimezone } from '@frontend/shared/util';
import { Button, Image, Typography } from 'antd';
import getConfig from 'next/config';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

const { Text } = Typography;

export interface ChallengesCardUserProps {
  challenge: Challenge & { __typename?: string };
}

export const participationTypeMap = {
  INDIVIDUAL: 'Individuals',
  TEAM: 'Individuals & team',
  COMPANY: 'Company',
};

export function ChallengeCardUser({ challenge }: ChallengesCardUserProps) {
  const router = useRouter();
  const { publicRuntimeConfig } = getConfig();
  const { data: dataMe } = useQuery<{ me: Me }>(GET_ME);
  const detailUrl = `${challenge?.__typename === 'OpenChallenge' ? '/entrepreneurship' : '/intrapreneurship'}/challenge/${challenge.id}`;

  const renderPrizes = () => {
    return (
      <div className="flex flex-col">
        {Number(challenge.prizeCashAmount) ? (
          <span>
            {Number(challenge.prizeCashAmount).toLocaleString('en-US') + '$'}
          </span>
        ) : (
          ''
        )}
        {challenge.prizes
          ?.filter((prize) => !['CASH', 'CUSTOM'].includes(prize))
          .map((prize, index) => {
            return (
              <span key={index}>
                {prize === 'SCV_PARTNERSHIP'
                  ? 'Opportunity to partner with SC Ventures'
                  : prize}
              </span>
            );
          })}
      </div>
    );
  };

  const details = [
    {
      icon: <CalendarOutlined />,
      text: challenge.registrationEndedAt ? (
        'Registration ended'
      ) : (
        <>
          {formatDateWithTimezone(
            challenge?.registrationEndAt,
            challenge?.timezone,
            'Do MMM YYYY, HH:mm'
          )}
        </>
      ),
    },
    {
      icon: <FlagOutlined />,
      text: challenge?.regions?.map((region) => region.name)?.join(', '),
    },
    {
      icon: <UserOutlined />,
      text: participationTypeMap[challenge.participationType],
    },
  ];

  if (challenge?.__typename === 'OpenChallenge') {
    if (challenge.prizes && challenge.prizes.length) {
      details.splice(details.length - 1, 1, {
        icon: <DollarOutlined />,
        text: renderPrizes(),
      });
    } else {
      details.splice(details.length - 1, 1);
    }
  }

  const checkApplyEnabled = () => {
    return challenge?.state === 'ACTIVE' && !challenge.registrationEndedAt;
  };

  const handleApply = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (challenge?.__typename === 'OpenChallenge') {
      router.push(
        `/entrepreneurship/challenge/${challenge.id}/application/basics`
      );
    } else if (challenge.__typename === 'InternalChallenge') {
      if (
        challenge.id === 'ad322192-6fc6-486b-a0c2-0d36234b62ec' ||
        challenge.id === '2384e838-7586-421c-ad5d-343186160dfe'
      ) {
        router.push(
          `/intrapreneurship/challenge/${challenge.id}/application/basics`
        );
      } else {
        router.push(`/idea/create/scratch?challengeId=${challenge.id}`);
      }
    }
  };

  return (
    <div className="group rounded-2xl bg-gray-200 cursor-pointer hover:shadow-3xl transition duration-700 ease-in-out h-full">
      <Link className="w-full h-full" href={detailUrl}>
        <div className="flex">
          <Image
            className="rounded-2xl group-hover:rounded-b-none w-full aspect-[286/163] ease-in duration-300"
            width="100%"
            preview={false}
            src={challenge?.banner?.url || ''}
            alt={challenge.title}
            fallback="https://dummyimage.com/600x400/F5F5F5/fff.png&text=+"
          />
        </div>
        <div className="px-3 py-6 space-y-4 h-max">
          <div className="flex flex-wrap gap-2 w-full">
            {challenge.state && (
              <StatusTag status={challenge.state} type="challenge" />
            )}
            {challenge.innovationSpaceTag && (
              <div className="rounded-[20px] py-1 px-3 bg-gray-300 truncate">
                <span className="text-gray-900 font-medium text-xs">
                  {challenge.innovationSpaceTag.name}
                </span>
              </div>
            )}
          </div>
          <div className="px-2 space-y-2">
            <div className="text-sm font-light text-gray-900">
              Sponsored by {challenge.sponsor}
            </div>
            <div className="text-gray-900 text-lg font-medium mt-2 flex justify-between">
              <Text className="line-clamp-3">{challenge.title}</Text>
              <span className="text-2xl">
                <Image
                  src={`${publicRuntimeConfig.staticFolder}/svg/arrow-up-right-blue.svg`}
                  preview={false}
                  width={24}
                  height={24}
                />
              </span>
            </div>
            <ul className="text-gray-900 space-y-[10px] mb-0 mt-4">
              {details.map((detail, index) => (
                <li
                  className="text-sm font-light flex items-center gap-x-3"
                  key={index}
                >
                  <span className="text-lg">{detail.icon}</span>
                  {detail.text}
                </li>
              ))}
            </ul>
          </div>
          {(dataMe?.me.role === 'EXTERNAL_USER' || !dataMe?.me) &&
            challenge.__typename === 'OpenChallenge' && (
              <div className="text-right invisible group-hover:visible cursor-pointer stroke-gray-900 transition-opacity ease-in duration-300 opacity-0 group-hover:opacity-100">
                <Button
                  type="primary"
                  shape="round"
                  disabled={!checkApplyEnabled()}
                  onClick={handleApply}
                >
                  <span className="!flex !items-center !gap-2">
                    Apply <ArrowRightOutlined />
                  </span>
                </Button>
              </div>
            )}
          {challenge.__typename === 'InternalChallenge' && (
            <div className="text-right invisible group-hover:visible cursor-pointer stroke-gray-900 transition-opacity ease-in duration-300 opacity-0 group-hover:opacity-100">
              <Button
                type="primary"
                shape="round"
                disabled={!checkApplyEnabled()}
                onClick={handleApply}
              >
                <span className="!flex !items-center !gap-2">
                  Apply <ArrowRightOutlined />
                </span>
              </Button>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

export default ChallengeCardUser;
