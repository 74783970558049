import { Button, Col, Divider, Row, Typography } from 'antd';
import getConfig from 'next/config';
import Image from 'next/image';
import { useRouter } from 'next/router';

declare global {
  interface Window {
    handleSsoSuccess: any;
  }
}

export function Sso({
  type,
  disabled = false,
}: {
  type: string;
  disabled?: boolean;
}) {
  const { publicRuntimeConfig } = getConfig();

  const REST_API_URL = process.env['NEXT_PUBLIC_REST_API_ENDPOINT'];
  const router = useRouter();
  const loginType = router.query['type'];

  const directGoogleSSO = () => {
    const referer = router.query['referrer'];
    let url = '/auth/google';
    if (referer) {
      url = '/auth/google?redirect_to=' + referer;
      localStorage.setItem('referer', referer as string);
    }
    window.location.assign(REST_API_URL + url);
  };

  const directLinkedinSSO = () => {
    const referer = router.query['referrer'];
    let url = '/auth/linkedin';
    if (referer) {
      url = '/auth/linkedin?redirect_to=' + referer;
      localStorage.setItem('referer', referer as string);
    }
    window.location.assign(REST_API_URL + url);
  };

  const directSamlSSO = () => {
    const referer = router.query['referrer'];
    let url = '/auth/cognito';
    if (referer) {
      url = '/auth/cognito?redirect_to=' + referer;
      localStorage.setItem('referer', referer as string);
    }
    window.open(REST_API_URL + url);
  };

  if (typeof window !== 'undefined') {
    window.handleSsoSuccess = function ({ referrer }: { referrer: string }) {
      router.push(referrer);
    };
  }

  return (
    <>
      <div className="text-center mb-2">
        <Typography.Text className="!text-gray-800 text-xs">
          {type} with your registered Standard Chartered account
          {loginType !== 'internal' && 'or social accounts'}
        </Typography.Text>
      </div>
      <Button
        onClick={directSamlSSO}
        className="!flex !justify-center !m-auto !gap-3 !mb-2 !items-center"
        disabled={disabled}
        block
        size="large"
        shape="round"
        type="primary"
        ghost
        icon={
          <Image
            src={`${publicRuntimeConfig.staticFolder}/logo.svg`}
            height="24"
            width="16"
            alt="SCV logo"
          />
        }
      >
        Standard Chartered Employee
      </Button>
      {loginType !== 'internal' && (
        <>
          <Divider className="!my-6">
            <Typography.Text className="text-sm font-normal !text-gray-700">
              or continue with
            </Typography.Text>
          </Divider>
          <Row gutter={8} className="mb-6">
            <Col span={12}>
              <Button
                className="!flex !justify-center !items-center !border-gray-500"
                block
                size="large"
                shape="round"
                disabled={disabled}
                onClick={directLinkedinSSO}
              >
                <Image
                  src={`${publicRuntimeConfig.staticFolder}/svg/linkedin-inline.svg`}
                  height="24"
                  width="16"
                  alt="linkedin"
                />
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="!flex !justify-center !items-center !border-gray-500"
                block
                size="large"
                shape="round"
                disabled={disabled}
                onClick={directGoogleSSO}
              >
                <Image
                  src={`${publicRuntimeConfig.staticFolder}/svg/google-inline.svg`}
                  height="24"
                  width="16"
                  alt="google"
                />
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default Sso;
