import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_EVALUATION,
  Criteria,
  CriteriaResult,
  GET_IDEA,
  GET_INTRAPRENEUR_CHALLENGES_APPLICATION_FOR_USER,
  GET_ME,
  Me,
  PATCH_EVALUATION,
  User,
} from '@frontend/data-access';
import { Button, notification, Progress, Radio, Spin } from 'antd';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export interface EvaluateScoreProps {
  type: 'IDEA' | 'APPLICATION';
  ideaId: string; // Possible application id
}

export const calculateAverageScore = (data: CriteriaResult[]) => {
  const average =
    data.reduce((prev, curr) => prev + curr?.scale, 0) / data.length;
  return isNaN(average) ? undefined : average.toFixed(1);
};

const scales = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export function EvaluateScore({ type, ideaId }: EvaluateScoreProps) {
  const { t: tcommon } = useTranslation('common');
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);
  const [isExpert, setIsExpert] = useState(false);

  const [getData, { loading, refetch: refetchIdea }] = useLazyQuery(
    type === 'IDEA'
      ? GET_IDEA
      : GET_INTRAPRENEUR_CHALLENGES_APPLICATION_FOR_USER,
    {
      onCompleted(data) {
        if (type === 'IDEA') {
          const expertResults =
            data?.idea.criteriaResults.filter(
              (r: CriteriaResult) =>
                r?.criteria?.type === 'CHALLENGE_EXPERT' &&
                r.status === 'DONE' &&
                r.createdBy.id === meData?.me.id
            ) || [];
          setResults(expertResults);

          const expertCriterias =
            data?.idea.criteria.filter(
              (c: Criteria) => c?.type === 'CHALLENGE_EXPERT'
            ) || [];
          setCriteria(expertCriterias);

          setIsExpert(
            data?.idea.challenge?.experts?.some(
              (expert: User) => expert.id === meData?.me.id
            ) || false
          );
        } else if (type === 'APPLICATION') {
          const expertResults =
            data?.challengeApplicationForUser.criteriaResults.filter(
              (r: CriteriaResult) =>
                r?.criteria?.type === 'CHALLENGE_EXPERT' &&
                r.status === 'DONE' &&
                r.createdBy.id === meData?.me.id
            ) || [];
          setResults(expertResults);

          const expertCriterias =
            data?.challengeApplicationForUser.challenge.criterias.filter(
              (c: Criteria) => c?.type === 'CHALLENGE_EXPERT'
            ) || [];
          setCriteria(expertCriterias);

          setIsExpert(
            data?.challengeApplicationForUser.challenge?.experts?.some(
              (expert: User) => expert.id === meData?.me.id
            ) || false
          );
        }
      },
    }
  );

  const [current, setCurrent] = useState<number>(0);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [point, setPoint] = useState<number>();

  const [results, setResults] = useState<CriteriaResult[]>([]);
  const [criteria, setCriteria] = useState<Criteria[]>([]);

  const [patchEvaluation, { loading: patchLoading }] = useMutation(
    PATCH_EVALUATION,
    {
      onCompleted: () => {
        refetchIdea();
        notification.success({
          message: tcommon('evaluation_saved'),
          placement: 'bottomLeft',
        });
      },
    }
  );

  const [createEvaluation, { loading: createLoading }] = useMutation(
    CREATE_EVALUATION,
    {
      onCompleted: () => {
        refetchIdea();
        notification.success({
          message: tcommon('evaluation_saved'),
          placement: 'bottomLeft',
        });
      },
    }
  );

  const handleNext = () => {
    if (current !== criteria?.length - 1) {
      setCurrent(current + 1);
    }
  };
  const handlePrev = () => {
    if (current > 0) {
      setCurrent(current - 1);
    }
  };

  const handleSave = (point: number) => {
    const existingResult = getResult({ criteriaId: criteria[current].id });
    setPoint(point);
    if (existingResult) {
      patchEvaluation({
        variables: {
          input: {
            id: existingResult.id,
            criteriaId: criteria[current].id,
            scale: point,
            status: 'DONE',
          },
        },
      });
    } else {
      createEvaluation({
        variables: {
          input: {
            ideaId,
            criteriaId: criteria[current].id,
            scale: point,
            status: 'DONE',
            evaluationType: type,
          },
        },
      });
    }
  };

  const getResult = ({
    criteriaId,
  }: {
    criteriaId: string;
  }): CriteriaResult | null => {
    const result = results.filter((cr) => cr.criteria.id === criteriaId);
    if (result?.length) {
      return result[0];
    } else return null;
  };

  const handleClickEdit = () => {
    setCurrent(0);
    setIsEditable(!isEditable);
  };

  const renderTitle = () => {
    return (
      <h1 className="font-semibold text-base text-gray-900">
        {tcommon('evaluation_evaluation_this_title')} {type.toLowerCase()}
      </h1>
    );
  };

  useEffect(() => {
    if (criteria.length) {
      const result = getResult({
        criteriaId: criteria[current].id as string,
      });
      if (result) {
        setPoint(result?.scale || undefined);
      } else {
        setPoint(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable, current]);

  useEffect(() => {
    setCurrent(0);
    setIsEditable(false);
  }, [ideaId]);

  useEffect(() => {
    getData({
      variables: {
        id: type === 'APPLICATION' && ideaId,
        ideaId: type === 'IDEA' && ideaId,
      },
    });
  }, [getData, ideaId, type]);

  if (loading) {
    return (
      <div className="p-6 text-center">
        <Spin />
      </div>
    );
  }

  if (!isExpert) {
    return <></>;
  }

  if (!isEditable) {
    return (
      <div className="my-4">
        {renderTitle()}
        <div className="rounded-xl border border-gray-400 p-3 space-y-4">
          <div className="text-gray-900 font-semibold text-sm">
            {tcommon('evaluation_evaluation_title')}
          </div>
          <div className="grid grid-cols-5">
            <div className="space-y-2 col-span-1">
              <div className="text-gray-700 font-medium text-xs">
                {tcommon('evaluation_evaluation_overall')}
              </div>
              <div className="text-gray-900 font-medium text-4xl">
                {results && calculateAverageScore(results)}
              </div>
            </div>
            <div className="col-span-4 grid grid-cols-2 md:grid-cols-5 gap-4">
              {criteria.map((criteria, index) => (
                <div key={index} className="text-center space-y-2">
                  <Progress
                    type="circle"
                    width={80}
                    strokeColor="#595959"
                    percent={
                      (getResult({ criteriaId: criteria.id })
                        ?.scale as number) * 10
                    }
                    format={(value) => (value ? value / 10 : '-')}
                  />
                  <div className="text-gray-700 font-normal text-xs">
                    {criteria.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <Button shape="round" onClick={handleClickEdit}>
              {tcommon('evaluation_edit')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="my-4">
      {renderTitle()}
      <div className="rounded-xl border border-gray-400 p-3 space-y-2">
        <div className="text-gray-900 font-medium text-sm">
          {criteria[current]?.name}
        </div>
        <div className="flex gap-2 justify-between">
          <div className="text-gray-700 font-normal text-sm">
            {criteria[current]?.description}
          </div>
          <div className="text-gray-700 font-normal text-sm">
            {current + 1}/{criteria?.length}
          </div>
        </div>
        <div className="p-4 text-center">
          <Radio.Group
            buttonStyle="solid"
            size="large"
            value={point}
            onChange={(e) => handleSave(e.target.value)}
          >
            {scales.map((point, index) => (
              <Radio.Button key={index} value={point}>
                <span className="md:p-1.5">{point}</span>
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
        <div className="flex justify-between">
          <Button
            shape="round"
            onClick={handleClickEdit}
            disabled={patchLoading || createLoading}
          >
            {tcommon('close')}
          </Button>
          <div className="flex gap-2">
            <Button
              type="default"
              shape="circle"
              disabled={current === 0 || patchLoading || createLoading}
              onClick={handlePrev}
            >
              <LeftOutlined />
            </Button>
            <Button
              type="default"
              shape="circle"
              disabled={
                current === criteria?.length - 1 ||
                patchLoading ||
                createLoading
              }
              onClick={handleNext}
            >
              <RightOutlined />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EvaluateScore;
