import { gql } from '@apollo/client';

export const COUNTRY = gql`
  fragment country on Country {
    id
    name
  }
`;

export const GET_COUNTRIES = gql`
  query Countries {
    countries {
      ...country
    }
  }
  ${COUNTRY}
`;
