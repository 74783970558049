import {
  CheckCircleTwoTone,
  EnvironmentOutlined,
  LeftOutlined,
  LinkedinFilled,
} from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Company,
  GET_COMPANY,
  GET_ME,
  KeyPerson,
  LIST_KEY_PERSON,
  Me,
} from '@frontend/data-access';
import { getLinkLabel } from '@frontend/shared/util';
import { Button, Col, Progress, Row, Spin, Typography } from 'antd';
import Link from 'next/link';
import { NextSeo } from 'next-seo';
import { useEffect, useMemo } from 'react';

import CompanyAvatar from '../company-avatar/company-avatar';
import EditInformationCard, {
  EditInformationCardProps,
} from '../company-profile/components/edit-info-card';
import InformationDeck from '../company-profile/components/information-deck';
import PitchVideo from '../company-profile/components/pitch-video';
import CompanySideBar from '../company-profile/components/sidebar';
import TagItems from '../company-profile/components/tag-item';
import UserAvatar from '../user-avatar/user-avatar';

const { Text } = Typography;

export function MatchmakingProfile({ id }: { id: number }) {
  const [getCompany, { data, loading }] = useLazyQuery<{ company: Company }>(
    GET_COMPANY
  );

  useEffect(() => {
    if (id) {
      console.log('Fetching company ', id);
      getCompany({ variables: { id: id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { data: meData } = useQuery<{ me: Me }>(GET_ME);

  const { data: dataKeyPerson } = useQuery<{
    listKeyPerson: KeyPerson[];
  }>(LIST_KEY_PERSON, {
    variables: {
      companyId: {
        companyId: id,
      },
    },
  });

  const items: EditInformationCardProps[] = useMemo(
    () => [
      {
        title: 'Our partnership goal:',
        content: <Text>{data?.company?.description}</Text>,
      },
      {
        title: 'About:',
        content: <Text>{data?.company?.description}</Text>,
      },
      {
        title: 'Industries',
        content:
          (data?.company.topics && data?.company.topics.length && (
            <TagItems data={data?.company.topics} test="selected-location" />
          )) ||
          null,
      },
      {
        title: 'Key people',
        content:
          (dataKeyPerson?.listKeyPerson.length &&
            dataKeyPerson.listKeyPerson.length > 0 && (
              <Row className="gap-2 mt-1">
                {dataKeyPerson.listKeyPerson.map((item, index) => {
                  return (
                    <Col
                      className="flex flex-row gap-5 items-center bg-gray-200 py-3 pl-6 pr-3 rounded-full text-xs"
                      key={index}
                    >
                      <div>
                        <div className="text-gray-900 font-semibold">
                          {item.name}
                        </div>
                        <span className="text-gray-700 font-normal text-xs">
                          {item.role}
                        </span>
                      </div>
                      {item.linkedinUrl && (
                        <Link href={item.linkedinUrl} target="_blank">
                          <Button shape="circle" icon={<LinkedinFilled />} />
                        </Link>
                      )}
                    </Col>
                  );
                })}
              </Row>
            )) ||
          null,
      },
      {
        title: 'Profile manager',
        content:
          (data?.company.owners && data?.company.owners.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
              {data?.company?.owners.map((item, index) => (
                <div
                  className="flex items-center gap-4 px-4 py-[14px] bg-gray-200 rounded-full w-full"
                  key={index}
                >
                  <UserAvatar
                    size={40}
                    user={item.user}
                    showBadge={meData?.me.role !== 'EXTERNAL_USER'}
                  />
                  <div>
                    <div className="text-gray-900 font-medium text-sm">
                      {item.user.fullName}
                    </div>
                    <div className="text-gray-700 font-normal text-xs">
                      {item.user.role &&
                        getLinkLabel(item.user.role.toLowerCase())}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )) ||
          null,
      },
      {
        title: 'Products',
        content: <Text>{data?.company?.description}</Text>,
      },
      {
        title: 'Information deck',
        content: (
          <InformationDeck
            data={data?.company?.onePagers}
            companyId={data?.company.id}
          />
        ),
      },
      {
        title: 'Pitch Video',
        content:
          data?.company?.pitchVideos || data?.company.isOwner ? (
            <PitchVideo
              data={data?.company?.pitchVideos ?? []}
              companyId={data?.company.id}
            />
          ) : null,
      },
      {
        title: 'What differentiates your solution from others?',
        content: <Text>{data?.company?.description}</Text>,
      },
      {
        title: 'Capital & Funding',
        content: <Text>{data?.company?.description}</Text>,
      },
      {
        title: 'How will you use the funds?',
        content: <Text>{data?.company?.description}</Text>,
      },
      {
        title: 'Current investors',
        content: <Text>{data?.company?.description}</Text>,
      },
      {
        title: 'How do you plan to achieve revenue target?',
        content: <Text>{data?.company?.description}</Text>,
      },
      {
        title: 'Partnerships',
        content: <Text>{data?.company?.description}</Text>,
      },
    ],
    [
      data?.company?.description,
      data?.company.id,
      data?.company.isOwner,
      data?.company?.onePagers,
      data?.company.owners,
      data?.company?.pitchVideos,
      data?.company.topics,
      dataKeyPerson?.listKeyPerson,
      meData?.me.role,
    ]
  );

  if (loading)
    return (
      <div className="flex justify-center min-h-screen">
        <Spin />
      </div>
    );

  return (
    <div>
      {data && (
        <div>
          <NextSeo
            title={data.company.name}
            description={data.company.description}
            openGraph={{
              title: data.company.name,
              description: data.company.description,
              images: [
                {
                  url: data.company?.cover?.url || data.company.logo?.url || '',
                  secureUrl:
                    data.company?.cover?.url || data.company.logo?.url || '',
                },
              ],
            }}
            twitter={{
              handle: '@handle',
              site: '@site',
              cardType: 'summary_large_image',
            }}
          />
          <div className="flex items-center mb-4">
            <Button shape="circle" icon={<LeftOutlined />} />
            <span className="ml-5 text-lg font-semibold">
              Matched companies
            </span>
          </div>
          <div className="bg-white rounded-xl p-8 flex flex-col justify-center container">
            <div className="flex justify-between items-start mb-4">
              <div className="flex items-center space-x-5">
                <CompanyAvatar
                  size={120}
                  company={data.company}
                  shape="square"
                  showBadge={data.company.companyPortfolio?.isScvPortfolio}
                  badgeSize={30}
                  isClickable={false}
                />
                <div>
                  <h2 className="text-xl font-semibold">{data.company.name}</h2>
                  {data.company.topics && (
                    <TagItems
                      data={data.company.topics}
                      test="selected-location"
                    />
                  )}
                  <div className="flex items-center mt-2">
                    <EnvironmentOutlined className="mr-2" />
                    <span>
                      {data?.company.companyInfo?.location
                        ? data?.company.companyInfo?.location
                        : 'No information'}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <Button className="mr-2" size="large">
                  Pass
                </Button>
                <Button type="primary" size="large">
                  Connect
                </Button>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex-1 col-span-2">
                <div className="flex items-center gap-4">
                  <Progress
                    percent={50}
                    showInfo={false}
                    strokeColor="#2CA401"
                  />
                  <span className="text-gray-900 font-medium text-sm">50%</span>
                  <span className="bg-green-700 text-white px-3 py-1 rounded-full text-sm font-semibold whitespace-nowrap">
                    Great match
                  </span>
                </div>
                <div className="flex items-center space-x-5 mt-4 font-medium">
                  <div className="flex items-center gap-2">
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                    <span>Partnership</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                    <span>Location</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                    <span>Business model</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                    <span>Industry</span>
                  </div>
                </div>
                <div className="flex my-4 p-4 bg-green-100 border-2 border-green-500 rounded-lg text-gray-900 font-medium">
                  You & Fauget Group are a great match with same location,
                  matching partnership goals, and business model.
                </div>
              </div>
              <div className="col-span"></div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex-1 col-span-2 space-y-7">
                <Col className="space-y-6">
                  {items.map((item, index) => (
                    <EditInformationCard
                      key={index}
                      title={item.title}
                      uppercase={false}
                      extraAction={
                        data?.company.isOwner || item.hasAccess
                          ? item.extraAction
                          : null
                      }
                      content={item.content}
                    />
                  ))}
                </Col>
              </div>
              <div className="col-span">
                <CompanySideBar />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MatchmakingProfile;
