import { Me, User } from '@frontend/data-access';
import { formatNumber, getLinkLabel } from '@frontend/shared/util';
import { Progress, Typography } from 'antd';
import useTranslation from 'next-translate/useTranslation';

import UserAvatar from '../user-avatar/user-avatar';

const { Text } = Typography;

/* eslint-disable-next-line */
export interface UserHeaderSectionProps {
  data: Me;
  size?: 'small' | 'medium';
}

export function UserHeaderSection({
  data,
  size = 'medium',
}: UserHeaderSectionProps) {
  const { t: tcommon } = useTranslation('common');
  const isShowUserBadge = data.role !== 'EXTERNAL_USER';

  return (
    <div className="w-full">
      <div className="flex gap-5">
        <div>
          <UserAvatar
            size={size === 'small' ? 40 : 70}
            user={data as User}
            badgeSize={size === 'small' ? 20 : 30}
            showBadge={isShowUserBadge}
            isClickable={false}
          />
        </div>
        <div className="items-center justify-between">
          <div className="flex gap-4 items-center">
            <div className="flex flex-col gap-1">
              <Text
                className={`text-gray-900 font-medium line-clamp-1 ${
                  size === 'small' ? 'text-sm' : 'text-xl'
                }`}
              >
                {data.fullName}
              </Text>
              <div
                className={`font-normal ${
                  size === 'small' ? 'text-xs' : 'text-sm'
                }`}
              >
                {getLinkLabel(data.role.toLowerCase())}
              </div>
              {data?.profile?.title && (
                <div data-test="profile-title">
                  {tcommon('user_profile_indicator_title')}:{' '}
                  {data?.profile?.title}
                </div>
              )}
            </div>
          </div>
          {isShowUserBadge && (
            <div>
              <div
                className={`flex justify-between gap-2 w-full font-medium ${
                  size === 'small' ? 'text-xs' : 'text-sm'
                }`}
              >
                <span className="text-primary-600">
                  {tcommon('user_profile_indicator_level')}{' '}
                  {data.rank.badge.level} - {data.rank.badge.name}
                </span>
                <span className="text-gray-600">
                  {`${formatNumber(data?.rank.rank)} / ${formatNumber(
                    data?.rank.badge.endPoint
                  )}`}
                </span>
              </div>
              <Progress
                percent={
                  (100 * (data.rank.rank - data.rank.badge.startPoint)) /
                  (data.rank.badge.endPoint - data.rank.badge.startPoint)
                }
                showInfo={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserHeaderSection;
