import { useQuery } from '@apollo/client';
import {
  ADMIN_PROTECTED_ROUTES,
  AUTH_ROUTES,
  GET_ME,
  Me,
  PLATFORM_OPEN_ROUTES,
  PLATFORM_PROTECTED_ROUTES,
} from '@frontend/data-access';
import { Row, Spin } from 'antd';
import { useRouter } from 'next/router';
import { ReactNode, useCallback, useEffect, useState } from 'react';

export function AuthChecker({
  children,
  type,
}: {
  children: ReactNode;
  type: 'PLATFORM' | 'ADMIN';
}) {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(true);

  const OPEN_ROUTES = type === 'PLATFORM' ? PLATFORM_OPEN_ROUTES : [];
  const isOpenRoute = OPEN_ROUTES.some((r) => r.path === router.pathname);

  const isAuthRoute = AUTH_ROUTES.some((r) => r.path === router.pathname);
  const PROTECTED_ROUTE =
    type === 'PLATFORM' ? PLATFORM_PROTECTED_ROUTES : ADMIN_PROTECTED_ROUTES;

  const setReferrer = useCallback(
    async ({ isInternal = false }: { isInternal?: boolean }) => {
      if (isAuthRoute || isOpenRoute) {
        return;
      }
      const url =
        type === 'ADMIN'
          ? process.env['NEXT_PUBLIC_ADMIN_URL']
          : process.env['NEXT_PUBLIC_PLATFORM_URL'];
      let ref_url = `/login?referrer=${new URL(url || '').origin}${
        window.location.pathname + window.location.search
      }`;
      if (isInternal) {
        ref_url = ref_url + '&type=internal';
      }
      await router.push(ref_url);
      setLoading(false);
    },
    [isAuthRoute, isOpenRoute, router, type]
  );

  const { data, loading: meLoading } = useQuery<{
    me: Me;
  }>(GET_ME, {
    onError: () => {
      if (isAuthRoute) {
        setLoading(false);
      } else if (!isOpenRoute) {
        const isInternal = PROTECTED_ROUTE.some(
          (r) => r.path === router.pathname && r.type === 'internal'
        );
        setReferrer({
          isInternal: isInternal,
        });
      } else {
        setLoading(false);
      }
    },
  });

  const authCheck = useCallback(async () => {
    setLoading(true);
    if (meLoading) {
      return; // Exit early if loading
    }

    const user = data?.me;
    const pathname = router.pathname;

    const isInternal = PROTECTED_ROUTE.some(
      (r) => r.path === pathname && r.type === 'internal'
    );

    if (isAuthRoute && user) {
      const referer = router.query['referrer'];
      if (referer) {
        localStorage.setItem('referer', referer as string);
        const referer_url = localStorage.getItem('referer') as string;
        localStorage.removeItem('referer');
        window.location.href = referer_url;
        return; // Exit early after handling referer
      }
    }

    if (isOpenRoute || (isAuthRoute && router.query['type'] !== 'internal')) {
      if (user && isAuthRoute) {
        await router.push('/');
      }
    } else {
      const isProtectedRoute = PROTECTED_ROUTE.some((r) => r.path === pathname);
      if (user) {
        if (
          type === 'ADMIN' &&
          data.me.role !== 'ADMIN' &&
          data.me.role !== 'CHALLENGE_MANAGER'
        ) {
          const platformUrl = process.env.NEXT_PUBLIC_PLATFORM_URL;
          const basePath = process.env.NEXT_PUBLIC_PLATFORM_BASE_PATH || '';
          if (platformUrl) {
            window.location.href = platformUrl + basePath;
            return;
          }
        }
        const isProtectedRouteWithUserRole = PROTECTED_ROUTE.some(
          (r) => r.path === router.pathname && r.roles.includes(data?.me.role)
        );

        if (
          !isProtectedRouteWithUserRole &&
          isProtectedRoute &&
          pathname !== '/login'
        ) {
          const protectedRoute = PROTECTED_ROUTE.find(
            (r) => r.path === router.pathname && r.roles.includes(data?.me.role)
          );
          if (protectedRoute?.type === 'internal') {
            await router.push({
              pathname: '/login',
              query: { type: 'internal' },
            });
          } else {
            await router.push({
              pathname: '/403',
            });
          }
        }
      } else {
        setReferrer({
          isInternal: isInternal,
        });
      }
    }

    setLoading(false);
  }, [
    PROTECTED_ROUTE,
    data?.me,
    isAuthRoute,
    isOpenRoute,
    meLoading,
    router,
    setReferrer,
    type,
  ]);

  useEffect(() => {
    authCheck();
  }, [authCheck, router.pathname]);

  const loadingContent = (
    <Row className="flex justify-center items-center">
      <Spin />
    </Row>
  );

  if (isOpenRoute) {
    return <div>{children}</div>;
  }

  if (loading || (!isOpenRoute && !data?.me && !isAuthRoute)) {
    return loadingContent;
  }

  return <div>{children}</div>;
}

export default AuthChecker;
