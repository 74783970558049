import { useLazyQuery } from '@apollo/client';
import { GET_TOP_CONTRIBUTIONS, User } from '@frontend/data-access';
import { formatNumber } from '@frontend/shared/util';
import { Empty, Select, Spin, Typography } from 'antd';
import getConfig from 'next/config';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import UserAvatar from '../user-avatar/user-avatar';

const { Text, Title } = Typography;

/* eslint-disable-next-line */
export interface TopContributorsProps {
  limit?: number;
}

type TopFilter = {
  filterBy: string;
  limit: number;
};

type TopContributor = {
  user: User;
  point: number;
};

export function TopContributors({
  limit: contributorsLimit,
}: TopContributorsProps) {
  const { publicRuntimeConfig } = getConfig();
  const [levelUpModalVisible, setLevelUpModalVisible] = useState(false);
  const [limit, setLimit] = useState<number>(contributorsLimit ?? 20);
  const [topParams, setTopParams] = useState<TopFilter>({
    filterBy: 'WEEKLY',
    limit: 20,
  });
  const [getTopUsers, { data, loading }] = useLazyQuery<{
    getTopContributions: TopContributor[];
  }>(GET_TOP_CONTRIBUTIONS);

  const toggleLevelUpModal = () => {
    setLevelUpModalVisible(!levelUpModalVisible);
  };

  const renderImage = (index: number) => {
    if (index > 3) {
      return (
        <div className="relative flex">
          <Image
            src={`${publicRuntimeConfig.staticFolder}/svg/leaderboard/default.svg`}
            height="30"
            width="30"
            alt="badge"
            className="min-w-[30px]"
          />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            {index}
          </div>
        </div>
      );
    } else {
      return (
        <Image
          src={`${publicRuntimeConfig.staticFolder}/svg/leaderboard/place-${index}.svg`}
          height="30"
          width="30"
          alt="badge"
          className="min-w-[30px]"
        />
      );
    }
  };

  const renderList = () => {
    if (loading)
      return (
        <div className="text-center">
          <Spin />
        </div>
      );
    return (
      <ul className="space-y-3.5">
        {data?.getTopContributions.slice(0, limit).map((item, index) => (
          <li key={index} className="flex items-center gap-3">
            {renderImage(index + 1)}
            <div className="w-[40px] min-w-[40px]">
              <UserAvatar size={40} user={item?.user} showBadge />
            </div>
            <div className="flex flex-col w-full">
              <Text className="text-sm text-gray-900 font-medium line-clamp-2">
                {item?.user?.fullName}
              </Text>
              <span className="py-1 text-xs text-gray-700 font-normal whitespace-nowrap">
                {formatNumber(item?.point) + ' Points'}
              </span>
            </div>
          </li>
        ))}
        {data?.getTopContributions?.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No contributors"
          />
        )}
      </ul>
    );
  };

  useEffect(() => {
    getTopUsers({ variables: topParams });
  }, [getTopUsers, topParams]);

  if (loading)
    return (
      <div className="text-center">
        <Spin />
      </div>
    );

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between flex-wrap">
        <Title level={5} className="!mb-0">
          Top contributors
        </Title>
        <Select
          defaultValue="WEEKLY"
          bordered={false}
          style={{ width: 120 }}
          value={topParams.filterBy}
          onChange={(value) =>
            setTopParams({ ...topParams, filterBy: value as string })
          }
        >
          <Select.Option value="ALL">All</Select.Option>
          <Select.Option value="WEEKLY">Weekly</Select.Option>
          <Select.Option value="MONTHLY">Monthly</Select.Option>
        </Select>
      </div>
      {renderList()}
      {/* <Divider /> */}
      {/* <div className="flex justify-between flex-wrap">
        {data?.getTopContributions && data?.getTopContributions?.length > 5 ? (
          <Button
            type="link"
            onClick={() => {
              setLimit(limit === 20 ? 5 : 20);
            }}
          >
            {limit > 5 ? 'View less' : 'View more'}
          </Button>
        ) : (
          <div />
        )}
      </div> */}
    </div>
  );
}

export default TopContributors;
