import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export function QuoteIcon(props: Partial<CustomIconComponentProps>) {
  const QuoteSvg = () => (
    <svg height="20" viewBox="0 0 24 24">
      <path d="M13 6V14H14.88L12.88 18H18.62L21 13.24V6M15 8H19V12.76L17.38 16H16.12L18.12 12H15M3 6V14H4.88L2.88 18H8.62L11 13.24V6M5 8H9V12.76L7.38 16H6.12L8.12 12H5Z" />
    </svg>
  );

  return <Icon height="18px" component={QuoteSvg} {...props} />;
}

export default QuoteIcon;
