import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlayCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Company,
  CompanyIntroduction,
  CREATE_COMPANY_INTRODUCTION_INPUT,
  DELETE_COMPANY_INTRODUCTION_INPUT,
  ErrorResponse,
  GET_COMPANY,
  GET_COMPANY_INTRODUCTION,
  UploadedFile,
} from '@frontend/data-access';
import {
  CompanyAvatar,
  companyType,
  FileUpload,
  supportedVideoFormats,
} from '@frontend/shared/ui';
import { Button, Form, Modal, notification, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';

import ClaimCompanyProfile from './claim';
import EditInformation from './edit-actions/information';

const { Title } = Typography;

const ProfileCover: React.FC<{ data: Company }> = ({ data }) => {
  const [form] = useForm();
  const [openImageUploadModal, setOpenImageUploadModal] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const videos = Form.useWatch('videos', form);
  const [introductionVideo, setIntroductionVideo] = useState<UploadedFile>();
  const [isOpenClaim, setIsOpenClaim] = useState<boolean>(false);

  const [getCompany, { data: dataCompany }] = useLazyQuery<{
    company: Company;
  }>(GET_COMPANY);
  const [getCompanyIntro, { data: dataIntro, refetch }] = useLazyQuery<{
    listCompanyIntroduction: CompanyIntroduction[];
  }>(GET_COMPANY_INTRODUCTION);

  useEffect(() => {
    if (dataIntro?.listCompanyIntroduction?.length) {
      setIntroductionVideo(dataIntro.listCompanyIntroduction[0].fileData);
    } else {
      setIntroductionVideo(undefined);
    }
  }, [dataIntro]);

  const handleIntroCompleted = () => {
    setOpenImageUploadModal(false);
    refetch();
    setIntroductionVideo(undefined);
  };

  useEffect(() => {
    if (data?.id) {
      getCompany({ variables: { id: data.id } });
      getCompanyIntro({ variables: { id: data.id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompany, data?.id]);

  const handleError = (data: ErrorResponse) => {
    notification.error({
      message: 'Error',
      description: data.message,
      icon: (
        <span className="text-red-600">
          <CloseCircleOutlined />
        </span>
      ),
      placement: 'bottomLeft',
    });
  };

  const [updateCompanyIntroductionVideo, { loading: introLoading }] =
    useMutation(CREATE_COMPANY_INTRODUCTION_INPUT, {
      onCompleted: handleIntroCompleted,
      onError: handleError,
    });

  const [deleteCompanyIntroduction, { loading: deleteLoading }] = useMutation(
    DELETE_COMPANY_INTRODUCTION_INPUT,
    {
      onCompleted: handleIntroCompleted,
      onError: handleError,
    }
  );

  const handleUploadIntro = (file: UploadedFile) => {
    updateCompanyIntroductionVideo({
      variables: {
        input: {
          companyId: data?.id,
          fileData: file,
        },
      },
    });
  };

  const handleDeleteIntro = () => {
    deleteCompanyIntroduction({
      variables: {
        input: {
          companyId: data?.id,
          companyIntroductionId: dataIntro?.listCompanyIntroduction[0]?.id,
        },
      },
    });
  };

  const handleUploadModal = () => {
    setOpenImageUploadModal(!openImageUploadModal);
  };

  const getFileByIndex = (index: number) => {
    try {
      if (videos[index]) {
        return [
          {
            uid: videos[index]?.id,
            name: videos[index]?.metadata?.filename,
            url: videos[index]?.url,
          },
        ];
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  const getCompanyType = () => {
    return companyType.find(
      (item) => item.value === dataCompany?.company?.companyType
    )?.label;
  };

  const handleEditModal = () => {
    setEditProfileModal(!editProfileModal);
  };

  const RenderStatus = () => {
    return dataCompany?.company?.approvalStatus === 'denied' ? (
      <div className="space-x-3">
        <span className="py-1 px-3 bg-red-200 rounded-full text-red-800 text-sm">
          Hidden
        </span>
        <span>
          (Reason: {dataCompany?.company?.applications?.[0]?.rejectDesc || '-'})
        </span>
      </div>
    ) : null;
  };

  const getIntroBanner = (file: UploadedFile) => {
    switch (file.state) {
      case 'CLEAN':
        return (
          <div className="flex flex-col items-end">
            <div className="w-full rounded-3xl sm:h-48 md:h-80">
              <video
                className="rounded-3xl object-cover h-full w-full"
                src={introductionVideo?.url}
                controls
              />
            </div>
            {dataCompany?.company?.isOwner && (
              <Button
                danger
                type="link"
                onClick={() => handleDeleteIntro()}
                loading={deleteLoading}
              >
                Remove
              </Button>
            )}
          </div>
        );
      case 'INFECTED':
        if (dataCompany?.company?.isOwner)
          return (
            <div className="flex w-full rounded-3xl bg-gray-200 border-dashed border-gray-500 border overflow-hidden items-center transition-all duration-200 p-5">
              <div className="flex flex-row gap-2 items-center w-full">
                <div className="grow bg-gray-200 rounded-full flex flex-row justify-between items-center p-2 flex-wrap md:flex-nowrap">
                  <div className="flex gap-3 items-center">
                    <PlayCircleOutlined />
                    <div>
                      <div>
                        {file.metadata?.filename?.slice(0, 15) + '...' ??
                          'No file name'}
                      </div>
                      <div>(virus infected)</div>
                    </div>
                  </div>
                </div>
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  shape="circle"
                  onClick={() => handleDeleteIntro()}
                />
              </div>
            </div>
          );
        else return;
      case 'PROCESSING':
        if (dataCompany?.company?.isOwner)
          return (
            <div className="flex w-full rounded-3xl bg-gray-200 border-dashed border-gray-500 border overflow-hidden items-center transition-all duration-200 p-5">
              <div className="flex flex-row gap-2 items-center w-full">
                <div className="grow bg-gray-200 rounded-full flex flex-row justify-between items-center p-2 flex-wrap md:flex-nowrap">
                  <div className="flex gap-3 items-center">
                    <PlayCircleOutlined />
                    <div>
                      <div>Processing:</div>
                      <div>
                        {file.metadata?.filename?.slice(0, 15) + '...' ??
                          'No file name'}
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  shape="circle"
                  onClick={() => handleDeleteIntro()}
                />
              </div>
            </div>
          );
        else return;
      case 'NOT_IN_MIME':
        if (dataCompany?.company?.isOwner)
          return (
            <div className="flex w-full rounded-3xl bg-gray-200 border-dashed border-gray-500 border overflow-hidden items-center transition-all duration-200 p-5">
              <div className="flex flex-row gap-2 items-center w-full">
                <div className="grow bg-gray-200 rounded-full flex flex-row justify-between items-center p-2 flex-wrap md:flex-nowrap">
                  <div className="flex gap-3 items-center">
                    <PlayCircleOutlined />
                    <div>
                      <div>File type not supported:</div>
                      <div>
                        {file.metadata?.filename?.slice(0, 15) + '...' ??
                          'No file name'}
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  shape="circle"
                  onClick={() => handleDeleteIntro()}
                />
              </div>
            </div>
          );
        else return;
      default:
        return;
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row items-center text-center justify-center md:text-start md:justify-start gap-4 md:gap-9">
        {dataCompany?.company && (
          <CompanyAvatar
            size={120}
            company={dataCompany.company}
            shape="square"
            showBadge={dataCompany.company.companyPortfolio?.isScvPortfolio}
            badgeSize={30}
            isClickable={false}
          />
        )}
        <div className="md:flex-auto space-y-2">
          <div className="flex gap-4 items-center">
            <div className="text-4xl font-medium m-auto md:ml-0">
              {dataCompany?.company?.name}
            </div>
            {dataCompany?.company?.isOwner && (
              <Button
                className="mt-1"
                data-test="profile-edit-btn"
                shape="circle"
                onClick={handleEditModal}
              >
                <EditOutlined />
              </Button>
            )}
          </div>
          <RenderStatus />
          <div className="py-1 px-3 bg-gray-200 rounded-full text-gray-800 text-sm inline-block whitespace-nowrap">
            {getCompanyType()}
          </div>
          <div>
            {dataCompany?.company?.isMember && !dataCompany?.company?.isOwner && (
              <>
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => setIsOpenClaim(true)}
                >
                  Claim this profile
                </Button>
                <ClaimCompanyProfile
                  companyId={dataCompany?.company.id}
                  isOpenClaim={isOpenClaim}
                  setIsOpenClaim={setIsOpenClaim}
                />
              </>
            )}
          </div>
        </div>
        <div className="md:w-80 w-full flex-shrink-0">
          {introductionVideo
            ? getIntroBanner(introductionVideo)
            : dataCompany?.company?.isOwner && (
              <div
                className="flex flex-col w-full rounded-3xl bg-gray-200 border-dashed border-gray-500 hover:border-primary-400 border h-80 overflow-hidden justify-center items-center cursor-pointer transition-all duration-200"
                onClick={handleUploadModal}
              >
                <div className="text-white bg-gray-500 rounded-full w-10 h-10 flex items-center justify-center mb-2">
                  <UploadOutlined />
                </div>
                <p>Upload your introduction video</p>
                <Button shape="round" type="primary">
                  Upload video
                </Button>
              </div>
            )}
        </div>
      </div>
      <Modal
        title="Upload video"
        open={openImageUploadModal}
        onOk={() => handleUploadModal()}
        confirmLoading={introLoading}
        onCancel={() => handleUploadModal()}
        okButtonProps={{
          shape: 'round',
        }}
        cancelButtonProps={{
          shape: 'round',
        }}
      >
        <FileUpload
          description={
            <div className="mb-4">Please upload your video here</div>
          }
          maxFileSize={524288000}
          supportedFileTypes={supportedVideoFormats}
          className="w-full overflow-auto"
          onUploadSuccess={(file) => handleUploadIntro(file)}
          onRemove={undefined}
          defaultFiles={getFileByIndex(0)}
        />
      </Modal>
      <Modal
        title="Edit"
        open={editProfileModal}
        onCancel={handleEditModal}
        bodyStyle={{
          padding: 0,
        }}
        footer={false}
      >
        <EditInformation
          companyId={data?.id}
          onFinish={handleEditModal}
          onCancel={handleEditModal}
        />
      </Modal>
    </>
  );
};

export default ProfileCover;
