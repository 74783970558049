import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Company,
  CREATE_PITCH,
  ErrorResponse,
  GET_COMPANY,
  PATCH_COMPANY,
  Pitch,
  UploadedFile,
} from '@frontend/data-access';
import { FileUpload, supportedVideoFormats } from '@frontend/shared/ui';
import { Avatar, Button, Form, notification } from 'antd';
import { useRouter } from 'next/router';
import { useState } from 'react';

interface FormValues {
  pitchVideo: UploadedFile;
}

function EditPitchForm({
  companyId,
  onFinish,
  onCancel,
}: {
  companyId?: number;
  onFinish: () => void;
  onCancel: () => void;
}) {
  const router = useRouter();
  const [isSaved, setIsSaved] = useState(false);
  const [form] = Form.useForm();
  const [isIncomplete, setIsIncomplete] = useState(false);
  const { data } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: companyId },
  });

  const handleCompleted = () => {
    setIsSaved(true);
    notification.success({
      message: 'Successfully saved',
      placement: 'bottomLeft',
    });
  };

  const handleError = (data: ErrorResponse) => {
    notification.error({
      message: 'Error',
      description: data.message,
      icon: (
        <span className="text-red-600">
          <CloseCircleOutlined />
        </span>
      ),
      placement: 'bottomLeft',
    });
  };

  const [patchCompany, { loading: loadingPatch }] = useMutation(PATCH_COMPANY, {
    onCompleted: handleCompleted,
    onError: handleError,
    refetchQueries: [{ query: GET_COMPANY, variables: { id: companyId } }],
  });

  const handleCompletedPitch = ({ createPitch }: { createPitch: Pitch }) => {
    router.push(`/pitch-arena/create/${createPitch.id}/basics`);
  };

  const [createPitch, { loading: createPitchLoading }] = useMutation(
    CREATE_PITCH,
    {
      onCompleted: handleCompletedPitch,
      onError: handleError,
    }
  );

  const handleOnFinish = async (values: FormValues) => {
    await patchCompany({
      variables: {
        input: {
          id: companyId,
          pitchVideos: [
            ...(data?.company?.pitchVideos ?? []),
            values.pitchVideo,
          ],
        },
      },
    });
  };

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsIncomplete(hasErrors);
  };

  const onClose = () => {
    setIsSaved(false);
  };

  const onClickPublish = () => {
    createPitch({
      variables: {
        input: {
          title: `${data?.company.name} Pitch`,
          body: `${data?.company.description}`,
          companyId: data?.company.id,
          topicIds: data?.company.topics?.map((t) => t.id),
          regionIds: data?.company.market?.regionIds,
          video: form.getFieldValue('pitchVideo'),
        },
      },
    });
  };

  const SuccessInfo = () => {
    return (
      <div className="p-6 text-center space-y-4 max-w-md m-auto">
        <Avatar size={68} className="!bg-green-500" icon={<CheckOutlined />} />
        <h3 className="text-gray-900 text-base font-medium">
          Successfully uploaded
        </h3>
        <p className="text-gray-700 text-sm">
          Choose if you want to share your pitch with the community
        </p>
        <div className="grid grid-cols-2 gap-4">
          <Button shape="round" onClick={onClose} block>
            Close
          </Button>
          <Button
            shape="round"
            type="primary"
            onClick={onClickPublish}
            block
            loading={createPitchLoading}
          >
            Publish in Pitches
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      {isSaved ? (
        <SuccessInfo />
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFieldsChange={handleFormChange}
          onFinish={handleOnFinish}
        >
          <div className="p-6">
            <Form.Item name="pitchVideo">
              <FileUpload
                description={
                  <div className="mb-4">
                    Click or drag file to this area to upload
                  </div>
                }
                onUploadSuccess={(file) =>
                  form.setFieldValue('pitchVideo', file)
                }
                compact={false}
                supportedFileTypes={supportedVideoFormats}
                maxFileSize={524288000}
              />
            </Form.Item>
          </div>
          <div className="flex justify-end items-center px-4 py-3 border-t border-solid border-gray-400 gap-2">
            <Button disabled={loadingPatch} shape="round" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              shape="round"
              loading={loadingPatch}
              disabled={isIncomplete}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
}

export default EditPitchForm;
