import {
  BankOutlined,
  CalendarOutlined,
  CheckOutlined,
  CompassOutlined,
  EnvironmentOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  VideoCameraAddOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import {
  Company,
  CompanyIntroduction,
  GET_COMPANY,
  GET_COMPANY_INTRODUCTION,
} from '@frontend/data-access';
import { getLinkLabel } from '@frontend/shared/util';
import { Col, Modal, Row, Space, Spin } from 'antd';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import EditBasicsForm from '../edit-actions/basics';
import EditKeyPersonForm from '../edit-actions/key-person';
import EditRegionForm from '../edit-actions/region';
import EditSocialAccountsForm from '../edit-actions/social-accounts';

type actionTypes = 'REGION' | 'BASIC_INFO' | 'SOCIAL_ACCOUNTS' | 'KEY_PERSON';

interface CheckListType {
  steps: Array<{
    icon: React.ReactNode;
    title: string;
    description?: string;
    actionType?: actionTypes;
    isCompleted: boolean;
  }>;
}

const CompanyProfileComplete = () => {
  const router = useRouter();
  const id = Number(router.query['companyId']);
  const [actionType, setActionType] = useState<actionTypes>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { data, loading } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id },
  });
  const { data: dataIntro } = useQuery<{
    listCompanyIntroduction: CompanyIntroduction[];
  }>(GET_COMPANY_INTRODUCTION, {
    variables: { id },
  });

  const renderTitle = () => {
    return 'Add ' + getLinkLabel(actionType?.toLowerCase() as string);
  };

  const renderAction = () => {
    if (data)
      switch (actionType) {
        case 'BASIC_INFO':
          return (
            <EditBasicsForm
              companyId={data?.company.id}
              onCancel={handleClose}
              onFinish={handleClose}
            />
          );
        case 'REGION':
          return (
            <EditRegionForm
              companyId={data?.company.id}
              onCancel={handleClose}
              onFinish={handleClose}
            />
          );
        case 'KEY_PERSON':
          return (
            <EditKeyPersonForm
              companyId={data?.company.id}
              onCancel={handleClose}
              onFinish={handleClose}
            />
          );
        case 'SOCIAL_ACCOUNTS':
          return (
            <EditSocialAccountsForm
              companyId={data?.company.id}
              onCancel={handleClose}
              onFinish={handleClose}
            />
          );

        default:
          break;
      }
    return <div />;
  };

  const handleModal = (type: actionTypes) => {
    setActionType(type);
    setIsOpenModal(true);
  };

  const handleClose = () => {
    setActionType(undefined);
    setIsOpenModal(false);
  };

  const checklist: CheckListType[] = [
    {
      steps: [
        {
          icon: <CompassOutlined />,
          title: 'Add region',
          description: 'Connect with visionaries – add your social account',
          actionType: 'REGION',
          isCompleted: Boolean(data?.company.market?.regionIds?.length),
        },
        {
          icon: <EnvironmentOutlined />,
          title: 'Add HQ location',
          actionType: 'BASIC_INFO',
          isCompleted: Boolean(data?.company.companyInfo?.location),
        },
        {
          icon: <CalendarOutlined />,
          title: 'Add founded date',
          actionType: 'BASIC_INFO',
          isCompleted: Boolean(data?.company.foundedDate),
        },
      ],
    },
    {
      steps: [
        {
          icon: <TeamOutlined />,
          title: 'Add team size',
          description: 'Add your Institution employee counts',
          actionType: 'BASIC_INFO',
          isCompleted: Boolean(data?.company.companyInfo?.employeeNumber),
        },
        {
          icon: <UserOutlined />,
          title: 'Add key persons',
          description: 'Add your Institution leaderships',
          actionType: 'KEY_PERSON',
          isCompleted: Boolean(data?.company.keyPerson?.length),
        },
        {
          icon: <BankOutlined />,
          title: 'Choose engagement with bank',
          actionType: 'BASIC_INFO',
          isCompleted: Boolean(data?.company.fundingStatus?.bankEngagement),
        },
        {
          icon: <UserOutlined />,
          title: 'Add social accounts',
          description: 'Connect with visionaries – add your social account',
          actionType: 'SOCIAL_ACCOUNTS',
          isCompleted: Boolean(
            data?.company.companyInfo?.facebook ||
              data?.company.companyInfo?.linkedin ||
              data?.company.companyInfo?.twitter
          ),
        },
      ],
    },
    {
      steps: [
        {
          icon: <VideoCameraAddOutlined />,
          title: 'Upload introduction video',
          description: 'Connect with visionaries – add your social account',
          isCompleted: Boolean(dataIntro?.listCompanyIntroduction.length),
        },
        {
          icon: <FileOutlined />,
          title: 'Upload information deck',
          description:
            'Showcase your Institution and solutions to the community',
          isCompleted: Boolean(data?.company?.onePagers?.length),
        },
        {
          icon: <VideoCameraAddOutlined />,
          title: 'Upload pitch video',
          description:
            'Showcase your Institution and solutions to the community',
          isCompleted: Boolean(data?.company.pitchVideos?.length),
        },
      ],
    },
  ];

  useEffect(() => {
    if (data?.company) {
      checklist.forEach((item, index) => {
        if (item.steps.filter((step) => !step.isCompleted).length === 0) {
          setActiveStep(index + 1);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.company]);

  if (loading) {
    return (
      <div className="text-center p-6">
        <Spin />
      </div>
    );
  }

  if (
    checklist.flatMap((item) => item.steps.filter((step) => !step.isCompleted))
      .length === 0 ||
    !data?.company.isOwner
  ) {
    return <div />;
  }

  return (
    <div className="w-full rounded-3xl p-5 bg-gray-200 space-y-3">
      <div className="flex justify-between items-center">
        <div className="text-gray-900 text-sm font-semibold">
          Complete your Profile
        </div>
        <ul className="flex space-x-2 mb-0 items-center">
          {Array.from({ length: checklist.length }, (_, i) => i).map((step) => (
            <li
              key={step}
              className={`h-2 rounded-full cursor-pointer transition-all ease-in-out duration-200 ${
                activeStep === step ? 'w-12 bg-primary-600' : 'w-2 bg-gray-500'
              }`}
              onClick={() => setActiveStep(step)}
            ></li>
          ))}
        </ul>
      </div>

      <Space className="w-full" direction="vertical" size="middle">
        {checklist?.[activeStep]?.steps.map((item, index) => (
          <Row key={index} className="border rounded-xl gap-3">
            <Col>
              <div className="bg-white  py-2 px-2 rounded-lg h-6 w-6 flex justify-center items-center">
                {item.isCompleted ? <CheckOutlined /> : item.icon}
              </div>
            </Col>
            {item.isCompleted ? (
              <Col span={20}>
                <div className="font-medium mb-1 text-sm line-through">
                  {item.title}
                </div>
              </Col>
            ) : (
              <Col span={20}>
                <div className="font-medium mb-1 text-sm">{item.title}</div>
                {item.description && (
                  <div className="text-sm text-gray-700">
                    {item.description}
                  </div>
                )}
                {item.actionType && (
                  <div
                    className="text-primary-600 cursor-pointer"
                    onClick={() => handleModal(item.actionType as actionTypes)}
                  >
                    Add now
                  </div>
                )}
              </Col>
            )}
          </Row>
        ))}
      </Space>

      <Modal
        title={renderTitle()}
        open={isOpenModal}
        onCancel={() => handleClose()}
        width={800}
        bodyStyle={{
          padding: 0,
        }}
        footer={false}
      >
        {renderAction()}
      </Modal>
    </div>
  );
};

export default CompanyProfileComplete;
