import { Challenge } from '@frontend/data-access';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface KanbanState {
  challenge: Challenge;
}

export const initialState: KanbanState = {
  challenge: null,
};

const kanbanSlice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    setChallenge: (state, action: PayloadAction<Challenge>) => {
      state.challenge = action.payload;
    },
  },
});

export const { setChallenge } = kanbanSlice.actions;

export default kanbanSlice.reducer;
