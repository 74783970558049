import { gql } from '@apollo/client';

export const REPLY = gql`
  fragment reply on Reply {
    id
    user {
      id
      fullName
      role
      image {
        url
      }
    }
    likes {
      id
      userId
    }
    text
    isLiked
    likeCount
    createdAt
    updatedAt
  }
`;

export const COMMENT = gql`
  fragment comment on Comment {
    id
    likes {
      id
      userId
    }
    replies {
      ...reply
    }
    user {
      id
      fullName
      role
      image {
        url
      }
    }
    text
    likeCount
    isLiked
    replyCount
    createdAt
    updatedAt
  }
  ${REPLY}
`;

export const COMMENT_LIKE = gql`
  fragment commentLike on CommentLike {
    id
    createdAt
    userId
  }
`;
