import { gql } from '@apollo/client';

export const KEY_TAG = gql`
  fragment keyTag on KeyTag {
    id
    name
  }
`;

export const GET_KEY_TAGS = gql`
  query KeyTags {
    keyTags {
      ...keyTag
    }
  }
  ${KEY_TAG}
`;

export const CREATE_KEY_TAG = gql`
  mutation CreateKeyTag($name: String!) {
    createKeyTag(name: $name) {
      ...keyTag
    }
  }
  ${KEY_TAG}
`;

export const PATCH_KEY_TAG = gql`
  mutation PatchKeyTag($id: ID!, $name: String!) {
    patchKeyTag(id: $id, name: $name) {
      ...keyTag
    }
  }
  ${KEY_TAG}
`;

export const DELETE_KEY_TAG = gql`
  mutation DeleteKeyTag($id: ID!) {
    deleteKeyTag(id: $id)
  }
`;
