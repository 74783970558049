import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_COMPANY_MEMBER,
  Company,
  ErrorResponse,
  GET_ALL_COMPANY,
  GET_MY_COMPANIES,
} from '@frontend/data-access';
import { Button, Form, Image, Modal, notification, Select } from 'antd';
import Link from 'next/link';
import { ReactNode, useState } from 'react';

const { Option } = Select;

/* eslint-disable-next-line */
export interface CompanyAddProps {
  onAfterAdd?: () => void;
  children?: ReactNode;
}

export function CompanyAdd({
  children,
  onAfterAdd = () => undefined,
}: CompanyAddProps) {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleError = (error: ErrorResponse) => {
    notification.error({
      message: 'Error',
      description: error.message,
      icon: (
        <span className="text-red-600">
          <CloseCircleOutlined />
        </span>
      ),
      placement: 'bottomLeft',
    });
  };
  const handleCompleted = () => {
    notification.success({
      message: 'Success',
      description: 'You have successfully added the institution',
      placement: 'bottomLeft',
    });
    setIsModalOpen(false);
    onAfterAdd();
  };

  const { data: companies } = useQuery<{
    allCompanies: Company[];
  }>(GET_ALL_COMPANY);
  // const { data: myCompanies } = useQuery<{
  //   myCompanies: Company[];
  // }>(GET_MY_COMPANIES);
  const [addCompanyMember, { loading: addCompanyMemberLoading }] = useMutation(
    ADD_COMPANY_MEMBER,
    {
      onError: handleError,
      onCompleted: handleCompleted,
      refetchQueries: [{ query: GET_MY_COMPANIES }],
    }
  );

  const handleFinish = async (values: { companyIds: number[] }) => {
    await addCompanyMember({
      variables: {
        companyIds: values.companyIds,
      },
    });
    form.resetFields();
  };

  // useEffect(() => {
  //   form.setFieldValue(
  //     'companyIds',
  //     myCompanies?.myCompanies?.map((company) => company.id) ?? ''
  //   );
  // }, [form, myCompanies]);

  return (
    <div className="">
      <Button
        size="middle"
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        onClick={() => setIsModalOpen(true)}
      >
        {children}
      </Button>
      <Modal
        title="Add Institution"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        okText="Save"
        cancelButtonProps={{
          shape: 'round',
        }}
        okButtonProps={{
          shape: 'round',
          loading: addCompanyMemberLoading,
          onClick: () => {
            form.submit();
          },
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Form.Item
            name="companyIds"
            label="Which institution do you work for?"
            tooltip="Which institution do you work for"
            rules={[{ required: true, message: 'Please select institution' }]}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Search your institution"
              showSearch
              filterOption={(input, option) =>
                ((option?.label as string) ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              notFoundContent={
                <div className="p-2 text-center">
                  Could not find your desired institution? Create a new
                  institution profile
                </div>
              }
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <div className="p-2 text-center">
                    <Link href="/company/onboarding/basics">
                      <Button shape="round">Create new institution</Button>
                    </Link>
                  </div>
                </>
              )}
            >
              {companies?.allCompanies?.map((company) => (
                <Option
                  key={company.id}
                  value={company.id}
                  label={company.name}
                >
                  <div className="flex gap-4 items-center">
                    <Image
                      src={company.logo?.url}
                      height={20}
                      width={20}
                      alt={company.name}
                    />
                    {company.name}
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default CompanyAdd;
