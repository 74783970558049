import { DeleteFilled, LoadingOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  APPROVE_TEAM_INVITE,
  DISCOVER_USERS,
  GET_IDEA,
  GET_ME,
  Idea,
  IdeaCollaboration,
  Me,
  TEAM_INVITE,
  TEAM_INVITE_DELETE,
  User,
} from '@frontend/data-access';
import { Button, Form, Modal, notification, Select, Spin } from 'antd';
import { useState } from 'react';

import StatusTag from '../status-tag/status-tag';
import UserAvatar from '../user-avatar/user-avatar';

export function IdeaDetailTeamInviteButton({ data }: { data: Idea }) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [memberId, setMemberId] = useState<number>();
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);
  const [updateId, setUpdateId] = useState<string>();
  const [deleteId, setDeleteId] = useState<string>();

  const [searchUser, { data: userData, loading: userLoading }] =
    useLazyQuery(DISCOVER_USERS);

  const handleSearch = (value: string) => {
    return searchUser({
      variables: {
        first: 1000,
        filterBy: {
          name: value.trim(),
          roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
        },
      },
    });
  };

  const handleApproveCompleted = () => {
    notification.success({
      message: 'Join request updated.',
      placement: 'bottomLeft',
    });
  };

  const [approve, { loading: loadingApprove }] = useMutation(
    APPROVE_TEAM_INVITE,
    {
      onCompleted: handleApproveCompleted,
      refetchQueries: [{ query: GET_IDEA, variables: { ideaId: data?.id } }],
    }
  );

  const [reject, { loading: loadingReject }] = useMutation(
    APPROVE_TEAM_INVITE,
    {
      onCompleted: handleApproveCompleted,
      refetchQueries: [{ query: GET_IDEA, variables: { ideaId: data?.id } }],
    }
  );

  const handleDeleteCompleted = () => {
    notification.success({
      message: 'Team member deleted.',
      placement: 'bottomLeft',
    });
  };
  const [deleteRequest, { loading: loadingDelete }] = useMutation(
    TEAM_INVITE_DELETE,
    {
      onCompleted: handleDeleteCompleted,
      refetchQueries: [{ query: GET_IDEA, variables: { ideaId: data?.id } }],
    }
  );
  const handleInviteCompleted = () => {
    notification.success({
      message: 'Invite request sent.',
      placement: 'bottomLeft',
    });
  };
  const [invite, { loading: loadingInvite }] = useMutation(TEAM_INVITE, {
    onCompleted: handleInviteCompleted,
    refetchQueries: [{ query: GET_IDEA, variables: { ideaId: data?.id } }],
  });

  const handleInviteClick = () => {
    if (memberId === meData?.me?.id) {
      notification.warning({
        message: 'Unable to invite yourself.',
        placement: 'bottomLeft',
      });
      return;
    }
    invite({
      variables: { ideaId: data?.id, userId: memberId, type: 'INVITE' },
    });
    setMemberId(undefined);
  };

  const InviteItem = ({
    applicant,
    isOwner,
  }: {
    applicant: IdeaCollaboration;
    isOwner?: boolean;
  }) => (
    <div className="grid grid-cols-2">
      <div className="flex items-center space-x-2">
        <UserAvatar user={applicant?.user} />
        <div>
          <div className="font-semibold">{applicant?.user?.fullName}</div>
          <div className="text-gray-700">{applicant?.user?.email}</div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div>
          <StatusTag status={applicant?.status} />
        </div>
        <div className="space-x-2">
          {applicant?.type === 'JOIN' && applicant?.status === 'PENDING' ? (
            <>
              <Button
                size="small"
                type="primary"
                ghost
                loading={loadingApprove && updateId === applicant?.id}
                onClick={() => {
                  setUpdateId(applicant?.id);
                  approve({
                    variables: {
                      id: applicant?.id,
                      status: 'APPROVED',
                    },
                  });
                }}
              >
                Accept
              </Button>
              <Button
                size="small"
                danger
                ghost
                loading={loadingReject && updateId === applicant?.id}
                onClick={() => {
                  setUpdateId(applicant?.id);
                  reject({
                    variables: {
                      id: applicant?.id,
                      status: 'REJECTED',
                    },
                  });
                }}
              >
                Reject
              </Button>
            </>
          ) : (
            !isOwner && (
              <Button
                shape="circle"
                onClick={() => {
                  setDeleteId(applicant?.id);
                  deleteRequest({ variables: { id: applicant?.id } });
                }}
              >
                {loadingDelete && deleteId === applicant?.id ? (
                  <LoadingOutlined />
                ) : (
                  <DeleteFilled />
                )}
              </Button>
            )
          )}
        </div>
      </div>
    </div>
  );

  return meData?.me?.id === data?.createdBy?.id ? (
    <>
      <Button size="small" shape="round" onClick={() => setIsModalOpen(true)}>
        Team management
      </Button>
      <Modal
        title="Teammate invites"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        width={690}
        footer={false}
      >
        <div className="space-y-4">
          {data?.collaborative && (
            <Form layout="inline">
              <Form.Item className="!grow">
                <Select
                  showSearch
                  allowClear
                  placeholder="Search to select"
                  loading={userLoading}
                  notFoundContent={
                    userLoading ? (
                      <div className="text-center">
                        <Spin size="small" />
                      </div>
                    ) : null
                  }
                  filterOption={(input, option) =>
                    ((option?.label as string) ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value: string) => {
                    setMemberId(parseInt(value));
                  }}
                  onSearch={handleSearch}
                >
                  {userData?.discoverUsers?.edges?.map(
                    (item: { node: User }) => (
                      <Select.Option
                        key={item.node.id}
                        value={item.node.id as unknown as string}
                        label={
                          (item.node?.firstName || '') +
                          (item.node?.lastName || '')
                        }
                      >
                        <div className="flex items-center gap-2">
                          <UserAvatar size={24} user={item.node} />
                          {item.node.firstName + ' ' + item.node.lastName}
                        </div>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loadingInvite}
                  disabled={memberId === undefined}
                  size="large"
                  shape="round"
                  onClick={handleInviteClick}
                >
                  Invite
                </Button>
              </Form.Item>
            </Form>
          )}
          {data?.applicants
            .filter(
              (m) =>
                m?.type === 'INVITE' ||
                (m?.type === 'JOIN' && m?.status !== 'PENDING')
            )
            .map((member, index) => (
              <InviteItem
                key={index}
                applicant={member}
                isOwner={data?.createdBy?.id === member?.userId}
              />
            ))}
          {data?.applicants.filter(
            (m) => m?.type === 'JOIN' && m?.status === 'PENDING'
          ).length > 0 && (
            <>
              <div className="font-semibold">Join requests</div>
              {data?.applicants
                .filter((m) => m?.type === 'JOIN' && m?.status === 'PENDING')
                .map((member, index) => (
                  <InviteItem key={index} applicant={member} />
                ))}
            </>
          )}
        </div>
      </Modal>
    </>
  ) : null;
}
