import { useMutation, useQuery } from '@apollo/client';
import {
  Company,
  CompanyType,
  ErrorResponse,
  GET_COMPANY,
  PATCH_COMPANY,
  UploadedFile,
} from '@frontend/data-access';
import { companyType, ImageUpload } from '@frontend/shared/ui';
import {
  Button,
  Form,
  Input,
  notification,
  Select,
  Spin,
  UploadFile,
} from 'antd';
import { useEffect, useState } from 'react';

interface FormValues {
  name: string;
  logo: UploadedFile;
  companyType: CompanyType;
}

function EditInformation({
  companyId,
  onFinish,
  onCancel,
}: {
  companyId: number;
  onFinish: () => void;
  onCancel: () => void;
}) {
  const { data, loading } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: companyId },
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isIncomplete, setIsIncomplete] = useState(false);
  const [form] = Form.useForm();

  const handleCompleted = () => {
    notification.success({
      message: 'Successfully saved',
      placement: 'bottomLeft',
    });
    onFinish();
  };

  const handleError = (data: ErrorResponse) => {
    notification.error({
      message: 'Error',
      description: data.message,
      placement: 'bottomLeft',
    });
  };

  const [patchCompany, { loading: loadingPatch }] = useMutation(PATCH_COMPANY, {
    onCompleted: handleCompleted,
    onError: handleError,
  });

  const handleOnFinish = (values: FormValues) => {
    patchCompany({
      variables: {
        input: {
          id: companyId,
          name: values.name,
          logo: values.logo,
          companyType: values.companyType,
        },
      },
    });
  };

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsIncomplete(hasErrors);
  };

  useEffect(() => {
    if (data?.company) {
      form.setFieldsValue({
        name: data?.company.name,
        logo: data?.company.logo,
        companyType: data?.company.companyType,
      });
      setFileList([
        {
          url: data.company.logo?.url,
          uid: data.company.logo?.id as string,
          name: data.company.logo?.id as string,
        },
      ]);
    }
  }, [data, form]);

  if (loading) {
    return (
      <div className="p-6 text-center">
        <Spin />
      </div>
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleOnFinish}
      onFieldsChange={handleFormChange}
    >
      <div className="px-6 pt-6">
        <Form.Item
          name="logo"
          label="Institution’s logo"
          rules={[{ required: true, message: 'Please input logo' }]}
        >
          <ImageUpload
            data-test="avatar-upload"
            title="Click or drag file to this area to upload"
            description="Recommended image size: 200x200 and less than 5MB."
            crop={{ shape: 'rect' }}
            onUploadSuccess={(v) => form.setFieldValue('logo', v)}
            // onRemove={onRemove}
            maxFileSize={1024 * 1024 * 5} // 5MB
            defaultImages={fileList}
          />
        </Form.Item>
        <Form.Item
          name="name"
          label="Institution name"
          rules={[{ required: true, message: 'Please input name' }]}
        >
          <Input showCount placeholder="Your Institution name" />
        </Form.Item>
        <Form.Item
          name="companyType"
          label="Institution type"
          rules={[{ required: true, message: 'Please input company type' }]}
        >
          <Select placeholder="Select a company type">
            {companyType.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className="flex justify-end items-center px-4 py-3 border-t border-solid border-gray-400 gap-2">
        <Button disabled={loadingPatch} shape="round" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          loading={loadingPatch}
          disabled={isIncomplete}
          shape="round"
        >
          Save
        </Button>
      </div>
    </Form>
  );
}

export default EditInformation;
