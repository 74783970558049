import { gql } from '@apollo/client';

import { COMPANY_BASIC, COMPANY_INFO } from './company';
import { TOPIC } from './topic';
import { FILE } from './upload';
import { USER_BASICS } from './user';

export const MATCHMAKING_PRODUCTS = gql`
  fragment matchmakingInfoProduct on MatchmakingInfoProduct {
    id
    matchmakingInfoId
    name
    description
  }
`;

export const MATCHMAKING_INFO = gql`
  fragment matchmakingInfo on MatchmakingInfo {
    id
    businessModel
    targetRegion
    location
    products {
      ...matchmakingInfoProduct
    }
    user {
      ...userBasics
    }
    createdAt
  }
`;

export const MATCHMAKING_PREFERENCE = gql`
  fragment matchmakingPreference on MatchmakingPreference {
    id
    interestedBusinessStage
    interestedIndustry
    interestedBusinessModel
    interestedCollaborationType
    interestedLocation
    coCreationGoals
    user {
      ...userBasics
    }
    createdAt
  }
`;

export const MATCHMAKING = gql`
  fragment matchmaking on Matchmaking {
    id
    company {
      ...basicInfo
    }
    info {
      ...matchmakingInfo
    }
    preference {
      ...matchmakingPreference
    }
    user {
      ...userBasics
    }
    createdAt
  }
`;

export const GET_MY_MATCHMAKING = gql`
  query getMatchmaking {
    getMatchmaking {
      id
    }
  }
`;

export const CREATE_MATCHMAKING = gql`
  mutation createMatchmaking($input: CreateMatchmakingInput!) {
    createMatchmaking(input: $input) {
      ...matchmaking
    }
  }
  ${MATCHMAKING}
  ${MATCHMAKING_INFO}
  ${MATCHMAKING_PRODUCTS}
  ${MATCHMAKING_PREFERENCE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${FILE}
`;

export const PATCH_MATCHMAKING = gql`
  mutation patchMatchmaking($input: PatchMatchmakingInput!) {
    patchMatchmaking(input: $input) {
      ...matchmaking
    }
  }
  ${MATCHMAKING}
  ${MATCHMAKING_INFO}
  ${MATCHMAKING_PRODUCTS}
  ${MATCHMAKING_PREFERENCE}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${USER_BASICS}
  ${FILE}
`;

export const START_MATCHMAKING = gql`
  mutation startMatchmaking($id: UUID!) {
    startMatchmaking(id: $id)
  }
`;

export const MATCHMAKING_RESULT_KEYWORDS = gql`
  fragment keywords on MatchmakingResultKeywords {
    id
    keyword
    temperature
  }
`;

export const MATCHMAKING_RESULT_DETAILS = gql`
  fragment details on MatchmakingResultDetail {
    id
    type
    reasoning
    score
  }
`;

export const MATCHMAKING_RESULT = gql`
  fragment result on MatchmakingResult {
    id
    partnershipGoals
    hostCompany {
      ...basicInfo
    }
    solutionProvider {
      ...basicInfo
    }
    details {
      ...details
    }
    keywords {
      ...keywords
    }
  }
`;

export const GET_MATCHMAKING_RESULT = gql`
  query FindMatchmakingResults(
    $first: Int
    $after: String
    $matchmakingId: UUID
  ) {
    findMatchmakingResults(
      first: $first
      after: $after
      matchmakingId: $matchmakingId
    ) {
      edges {
        node {
          ...result
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${MATCHMAKING_RESULT}
  ${COMPANY_BASIC}
  ${MATCHMAKING_RESULT_DETAILS}
  ${MATCHMAKING_RESULT_KEYWORDS}
  ${COMPANY_INFO}
  ${FILE}
`;

export const GET_MATCHMAKING_RESULT_BY_ID = gql`
  query matchmakingResultById($id: UUID) {
    matchmakingResultById(id: $id) {
      ...result
    }
  }
  ${MATCHMAKING_RESULT}
  ${COMPANY_BASIC}
  ${MATCHMAKING_RESULT_DETAILS}
  ${MATCHMAKING_RESULT_KEYWORDS}
  ${COMPANY_INFO}
  ${FILE}
`;

export const GET_MATCHMAKING_TOTAL_COUNT = gql`
  query TotalMatches($matchmakingId: UUID) {
    totalMatches(matchmakingId: $matchmakingId)
  }
`;

export const GET_MATCHED_TOTAL_COUNT = gql`
  query CountMatchedCompanies($matchmakingId: UUID) {
    countMatchedCompanies(matchmakingId: $matchmakingId)
  }
`;

export const GET_MATCH_MAKING_LIST = gql`
  query GetMatchmakingList($limit: Int) {
    getMatchmakingList(limit: $limit) {
      id
      company {
        ...basicInfo
        topics {
          ...topic
        }
      }
      preference {
        coCreationGoals
      }
    }
  }
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${FILE}
  ${TOPIC}
`;
