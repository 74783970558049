import { gql } from '@apollo/client';

import { COMPANY_BASIC, COMPANY_INFO, COMPANY_MEMBER } from './company';
import { REGION } from './region';
import { SKILL } from './skill';
import { TOPIC } from './topic';
import { FILE } from './upload';

export const PROFILE = gql`
  fragment profile on Profile {
    id
    linkedin
    location
    facebook
    twitter
    medium
    instagram
    phone
    title
    shortBio
    talkToMeAbout
    profileCategories
    website
    socialHidden
    createdAt
    talkToMeAbout
    updatedAt
    businessAreas
  }
`;

export const EXPERIENCE = gql`
  fragment experience on Experience {
    company {
      id
    }
    companyName
    createdAt
    current
    description
    endDate
    id
    logo {
      ...file
    }
    startDate
    title
    updatedAt
    user {
      id
    }
  }
`;

export const EDUCATION = gql`
  fragment education on Education {
    createdAt
    degree
    deletedAt
    endDate
    id
    logo {
      ...file
    }
    logoData
    school
    startDate
    updatedAt
    user {
      id
    }
    userId
  }
`;

export const USER_BASICS = gql`
  fragment userBasics on User {
    id
    email
    role
    firstName
    lastName
    fullName
    position
    updatedAt
    verified
    imageData
    image {
      ...file
    }
    emailHidden
  }
`;

export const PUBLIC_USER = gql`
  fragment publicUser on PublicUser {
    id
    fullName
    image {
      ...file
    }
  }
`;

export const TOP_CONTRIBUTOR = gql`
  fragment topContributor on TopContributor {
    user {
      id
      fullName
      image {
        ...file
      }
      rank {
        badge {
          name
          startPoint
          endPoint
          level
          id
          createdAt
        }
      }
    }
    point
  }
`;

export const USER_WITH_RANK = gql`
  fragment userWithRank on User {
    id
    email
    firstName
    lastName
    fullName
    position
    updatedAt
    verified
    imageData
    image {
      ...file
    }
    rank {
      rank
      badge {
        name
        startPoint
        endPoint
        level
        id
        createdAt
      }
    }
  }
`;

export const ME = gql`
  fragment me on Me {
    id
    isAdmin
    fullName
    firstName
    lastName
    email
    authToken
    role
    emailHidden
    image {
      url
      id
    }
    profile {
      ...profile
    }
    country
  }
`;

export const ALL_USERS = gql`
  fragment user on PublicUserWrapper {
    founder
    investor
    publicUser {
      userId
      fullName
      verified
      image {
        url
      }
    }
  }
`;

export const RANK = gql`
  fragment rank on RankType {
    rank
    badge {
      id
      name
      startPoint
      endPoint
      level
    }
  }
`;

export const GET_USER_BASICS = gql`
  query User {
    user {
      ...userBasics
      __typename
    }
    me {
      ...me
      __typename
    }
  }
  ${USER_BASICS}
  ${FILE}
  ${ME}
`;

// TODO: ADD
/**
 * skills {
        ...skill
      }
 */
export const GET_ME = gql`
  query Me {
    me {
      ...me
      regions {
        ...region
      }
      topics {
        ...topic
      }
      skills {
        ...skill
      }
      profile {
        ...profile
      }
      notificationSettings {
        emailNotificationCodes
        inAppNotificationCodes
      }
      __typename
    }
  }
  ${ME}
  ${REGION}
  ${TOPIC}
  ${SKILL}
  ${PROFILE}
`;

export const GET_ME_WITH_RANK = gql`
  query Me {
    me {
      ...me
      regions {
        ...region
      }
      topics {
        ...topic
      }
      notificationSettings {
        emailNotificationCodes
        inAppNotificationCodes
      }
      rank {
        rank
        badge {
          name
          startPoint
          endPoint
          level
          id
          createdAt
        }
      }
    }
  }
  ${ME}
  ${PROFILE}
  ${REGION}
  ${TOPIC}
`;

export const GET_USER_RULE_HISTORY = gql`
  query GetUserRuleHistory($limit: Int, $page: Int) {
    me {
      getUserRuleHistory(limit: $limit, page: $page) {
        items {
          id
          userId {
            fullName
          }
          point
          createdAt
          rule
          ruleId
        }
        total
      }
    }
  }
`;

export const GET_ME_INDICATORS = gql`
  query Me {
    me {
      country
      posts(page: 0, limit: 1) {
        items {
          id
          status
        }
      }
      pitches(page: 0, limit: 1) {
        items {
          id
          status
        }
      }
    }
    myCompanies {
      id
    }
  }
`;

export const GET_USER = gql`
  query User {
    user {
      ...userBasics
      currentPositions {
        ...member
      }
      regions {
        ...region
      }
      topics {
        ...topic
      }
      profile {
        ...profile
      }
      experiences {
        ...experience
      }
      educations {
        ...education
      }
      skills {
        ...skill
      }
    }
  }
  ${USER_BASICS}
  ${COMPANY_MEMBER}
  ${REGION}
  ${TOPIC}
  ${PROFILE}
  ${EXPERIENCE}
  ${EDUCATION}
  ${SKILL}
  ${FILE}
`;

export const GET_ALL_USERS = gql`
  query allUsers {
    allUsers {
      ...user
    }
  }
  ${ALL_USERS}
`;

export const GET_ALL_COACHES = gql`
  query PrivateAllUsers {
    privateAllUsers {
      ...userBasics
    }
  }
  ${USER_BASICS}
  ${FILE}
`;

export const GET_USER_BASICS_BY_ID = gql`
  query FindUser($id: ID) {
    findUser(id: $id) {
      ...userBasics
      regions {
        ...region
      }
      topics {
        ...topic
      }
    }
  }
  ${USER_BASICS}
  ${FILE}
  ${REGION}
  ${TOPIC}
`;

export const GET_USER_BY_ID = gql`
  query FindUserWithCompanies($id: ID) {
    findUser(id: $id) {
      ...userBasics
      regions {
        ...region
      }
      topics {
        ...topic
      }
      skills {
        ...skill
      }
      companies {
        ...basicInfo
        companyPortfolio {
          isScvPortfolio
        }
      }
      role
      profile {
        ...profile
      }
      rank {
        rank
        badge {
          name
          startPoint
          endPoint
          level
          id
          createdAt
        }
      }
    }
  }
  ${USER_BASICS}
  ${FILE}
  ${REGION}
  ${TOPIC}
  ${COMPANY_BASIC}
  ${COMPANY_INFO}
  ${PROFILE}
  ${SKILL}
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($input: UpdateProfileInput) {
    updateProfile(input: $input) {
      firstName
      lastName
      emailHidden
      profile {
        ...profile
      }
      image {
        ...file
      }
      __typename
    }
  }
  ${PROFILE}
  ${FILE}
`;

export const GET_ALL_USER_ROLE_STATUS = gql`
  query privateAllUsersCount {
    privateAllUsersCount {
      role
      count
    }
  }
`;

export const GET_ALL_USERS_BY_FILTER_ADMIN = gql`
  query PrivateAllUsers(
    $searchBy: String
    $filterBy: UserFilters
    $sort: UserSortOption
    $page: Int
    $limit: Int
  ) {
    privateAllUsers(
      searchBy: $searchBy
      filterBy: $filterBy
      sort: $sort
      page: $page
      limit: $limit
    ) {
      ...userBasics
      role
      topics {
        ...topic
      }
      regions {
        ...region
      }
      createdAt
      profile {
        title
        talkToMeAbout
        businessAreas
      }
    }
  }
  ${USER_BASICS}
  ${FILE}
  ${TOPIC}
  ${REGION}
`;

export const GET_ALL_USERS_BY_FILTER = gql`
  query PublicUsers(
    $searchBy: String
    $filterBy: UserFilters
    $sort: UserSortOption
    $page: Int
    $limit: Int
  ) {
    publicUsers(
      searchBy: $searchBy
      filterBy: $filterBy
      sort: $sort
      page: $page
      limit: $limit
    ) {
      ...publicUser
    }
  }
  ${PUBLIC_USER}
  ${FILE}
`;

export const DISCOVER_USERS = gql`
  query DiscoverUsers(
    $after: String
    $first: Int
    $filterBy: DiscoverUsersFilter
  ) {
    discoverUsers(after: $after, first: $first, filterBy: $filterBy) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      aggregations {
        location {
          name
          buckets {
            key
            count
          }
        }
      }
      edges {
        cursor
        node {
          id
          firstName
          lastName
          title
          image
          location
          verified
        }
      }
    }
  }
`;

export const GET_USER_RANK = gql`
  query FindUser($id: ID) {
    findUser(id: $id) {
      ...userBasics
      rank {
        rank
        badge {
          level
          name
        }
      }
    }
  }
  ${USER_BASICS}
  ${FILE}
`;

export const CHANGE_USER_NOTIFICATION_SETTINGS = gql`
  mutation changeNotificationSettings(
    $input: ChangeNotificationSettingsInput!
  ) {
    changeNotificationSettings(input: $input) {
      emailNotificationCodes
      inAppNotificationCodes
    }
  }
`;

export const CHANGE_USER_ROLE = gql`
  mutation changeUserRole($userId: ID!, $role: UserRole!) {
    changeUserRole(userId: $userId, role: $role) {
      id
      role
    }
  }
`;

export const GET_ALL_USERS_BY_FILTER_COUNT = gql`
  query privateAllUsersAggregate($searchBy: String, $filterBy: UserFilters) {
    privateAllUsersAggregate(searchBy: $searchBy, filterBy: $filterBy) {
      totalCount
    }
  }
`;

export const DOWNLOAD_ALL_USERS = gql`
  mutation downloadEmailList {
    downloadEmailList
  }
`;
