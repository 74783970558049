import { useMutation, useQuery } from '@apollo/client';
import {
  Company,
  ErrorResponse,
  GET_COMPANY,
  PATCH_COMPANY,
} from '@frontend/data-access';
import { Button, Form, Input, notification, Spin } from 'antd';
import { useEffect, useState } from 'react';

interface FormValues {
  website: string;
  linkedin?: string;
  twitter?: string;
  facebook?: string;
}

function EditSocialAccountsForm({
  companyId,
  onFinish,
  onCancel,
}: {
  companyId: number;
  onFinish: () => void;
  onCancel: () => void;
}) {
  const { data, loading } = useQuery<{ company: Company }>(GET_COMPANY, {
    variables: { id: companyId },
  });
  const [isIncomplete, setIsIncomplete] = useState(false);
  const [form] = Form.useForm();

  const handleCompleted = () => {
    notification.success({
      message: 'Successfully saved',
      placement: 'bottomLeft',
    });
    onFinish();
  };

  const handleError = (data: ErrorResponse) => {
    notification.error({
      message: 'Error',
      description: data.message,
      placement: 'bottomLeft',
    });
  };

  const [patchCompany, { loading: loadingPatch }] = useMutation(PATCH_COMPANY, {
    onCompleted: handleCompleted,
    onError: handleError,
  });

  const handleOnFinish = async (values: FormValues) => {
    patchCompany({
      variables: {
        input: {
          id: companyId,
          companyInfo: {
            ...values,
          },
        },
      },
    });
  };

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsIncomplete(hasErrors);
  };

  useEffect(() => {
    if (data?.company) {
      form.setFieldsValue({
        website: data.company.companyInfo?.website,
        linkedin: data.company.companyInfo?.linkedin,
        twitter: data.company.companyInfo?.twitter,
        facebook: data.company.companyInfo?.facebook,
      });
    }
  }, [data, form]);

  if (loading) {
    return (
      <div className="p-6 text-center">
        <Spin />
      </div>
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleOnFinish}
      onFieldsChange={handleFormChange}
    >
      <div className="px-6 pt-6">
        <Form.Item
          name="website"
          label="Website link"
          rules={[{ required: true, message: 'Please enter website link' }]}
        >
          <Input placeholder="Enter website link" />
        </Form.Item>
        <Form.Item name="linkedin" label="Linkedin">
          <Input placeholder="Enter linkedin link" />
        </Form.Item>
        <Form.Item name="twitter" label="Twitter">
          <Input placeholder="Enter twitter link" />
        </Form.Item>
        <Form.Item name="facebook" label="Facebook">
          <Input placeholder="Enter facebook link" />
        </Form.Item>
      </div>
      <div className="flex justify-end items-center px-4 py-3 border-t border-solid border-gray-400 gap-2">
        <Button disabled={loadingPatch} shape="round" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          loading={loadingPatch}
          disabled={isIncomplete}
          shape="round"
        >
          Save
        </Button>
      </div>
    </Form>
  );
}

export default EditSocialAccountsForm;
