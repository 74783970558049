import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { UploadedFile } from '@frontend/data-access';
import { Button, notification, Spin, Upload, UploadProps } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload';
import { ShowUploadListInterface } from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useEffect, useState } from 'react';

const PRESIGN = gql`
  mutation GetPresign($input: PresignInput!) {
    getPresign(input: $input) {
      url
    }
  }
`;
export type SupportedFileType = {
  type: string;
  mimeType: string;
};
export const supportedVideoFormats = [
  {
    type: 'MP4',
    mimeType: 'video/mp4',
  },
  {
    type: 'WebM',
    mimeType: 'video/webm',
  },
  {
    type: 'Ogg',
    mimeType: 'video/ogg',
  },
  {
    type: 'Ogg',
    mimeType: 'audio/ogg',
  },
];
export const supportedDocumentFormats = [
  {
    type: 'doc',
    mimeType: 'application/msword',
  },
  {
    type: 'docx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  {
    type: 'ppt',
    mimeType: 'application/vnd.ms-powerpoint',
  },
  {
    type: 'pptx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  },
  {
    type: 'xls',
    mimeType: 'application/vnd.ms-excel',
  },
  {
    type: 'xlsx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  {
    type: 'pdf',
    mimeType: 'application/pdf',
  },
];

/* eslint-disable-next-line */
export interface FileUploadProps {
  id?: string;
  description?: JSX.Element;
  compact?: boolean;
  isPrivate?: boolean;
  defaultFiles?: UploadFile[];
  maxFiles?: number;
  maxFileSize?: number; // size in bytes
  showUploadList?: boolean | ShowUploadListInterface;
  supportedFileTypes?: SupportedFileType[];
  onUploadSuccess: (
    params: UploadedFile,
    setFileList: (param: UploadFile[]) => void
  ) => void;
  onRemove?: (file: UploadFile) => void;
  className?: string;
  handleUploadLoading?: (isLoading: boolean) => void;
}

export function FileUpload({
  id,
  description,
  compact = true,
  isPrivate = true,
  defaultFiles = [],
  maxFiles = 1,
  maxFileSize = undefined,
  showUploadList = true,
  supportedFileTypes = [],
  onUploadSuccess = () => undefined,
  onRemove = () => undefined,
  className = undefined,
  handleUploadLoading,
}: FileUploadProps) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [presign] = useMutation(PRESIGN);

  useEffect(() => {
    if (defaultFiles.length) setFileList(defaultFiles);
  }, [defaultFiles]);

  const handleBeforeUpload = (file: UploadFile) => {
    const isSupported = supportedFileTypes.filter(
      (type) => type.mimeType === file.type
    );
    if (isSupported.length === 0) {
      notification.error({
        type: 'warning',
        message: 'Invalid format',
        description: `${file.name} is not a supported file`,
        icon: (
          <span className="text-orange-600">
            <ExclamationCircleOutlined />
          </span>
        ),
        placement: 'bottomLeft',
      });
      return Upload.LIST_IGNORE;
    }
    if (file.size && maxFileSize !== undefined) {
      const isLimit = file.size < maxFileSize; // 10MB;
      if (!isLimit) {
        notification.open({
          message: `File size exceeded maximum limit`,
          icon: (
            <span className="text-red-600">
              <CloseCircleOutlined />
            </span>
          ),
          placement: 'bottomLeft',
        });
        return Upload.LIST_IGNORE;
      }
    }
    return true;
  };

  const prepareParams = (file: RcFile, url: URL): UploadedFile => {
    const key = url.pathname;
    const match = key.match(/\/(cache|store)\/(.+)/) as RegExpMatchArray;
    const id = match[2];
    const storage = match[1];
    return {
      id: id,
      url: url.origin + url.pathname,
      public: !isPrivate,
      status: 'Active',
      storage: storage,
      metadata: {
        size: file.size,
        mime: file.type,
        filename: file.name,
      },
    };
  };
  const handleCustomRequest: UploadProps['customRequest'] = async ({
    file,
    onSuccess = () => undefined,
    onError = () => undefined,
  }: UploadRequestOption) => {
    try {
      setLoading(true);
      const { data } = await presignRequest(file as File);
      const url = data?.getPresign.url;
      await fetch(url, {
        method: 'PUT',
        body: file,
      });
      onUploadSuccess(prepareParams(file as RcFile, new URL(url)), setFileList);
      setLoading(false);
      onSuccess('success');
    } catch (error) {
      let message = 'Upload error';
      setLoading(false);
      if (error instanceof Error) message = error.message;
      onError(
        { name: 'upload.error', message: message },
        'Error uploading image !'
      );
    }
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const presignRequest = (file: File) => {
    const params = {
      storage: 'store',
      isPrivate: isPrivate,
      metadata: {
        size: file.size,
        filename: file.name,
        mime: file.type,
      },
    };
    return presign({ variables: { input: params } });
  };

  const props: UploadProps = {
    name: 'file',
    customRequest: handleCustomRequest,
    onChange: handleChange,
    onRemove: onRemove,
    fileList: fileList,
    maxCount: maxFiles,
    beforeUpload: handleBeforeUpload,
    accept: supportedFileTypes.length
      ? supportedFileTypes.map((t) => t.mimeType).join(',')
      : undefined,
    showUploadList: showUploadList,
  };

  useEffect(() => {
    if (defaultFiles.length > 0) {
      setFileList(defaultFiles);
    }
  }, [defaultFiles]);

  useEffect(() => {
    handleUploadLoading && handleUploadLoading(loading);
  }, [loading]);

  if (!compact) {
    return (
      <div data-test={id}>
        <Upload.Dragger {...props} disabled={loading}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          {loading ? (
            <div className="text-gray-700 space-y-2">
              <p>Uploading</p>
              <Spin />
            </div>
          ) : (
            <div>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              {maxFileSize && (
                <div className="text-gray-700">{`Max file size: ${(
                  maxFileSize / 1000000
                ).toFixed()}MB`}</div>
              )}
              {supportedFileTypes.length && (
                <div className="text-gray-700">
                  {`Supported formats: ${supportedFileTypes
                    .map((item) => item.type)
                    .join(', ')}`}
                </div>
              )}
            </div>
          )}
        </Upload.Dragger>
      </div>
    );
  }

  return (
    <div className={className} data-test={id}>
      {description && <div className="text-gray-700">{description}</div>}
      {maxFileSize && (
        <div className="text-gray-700">{`Max file size: ${(
          maxFileSize / 1000000
        ).toFixed()}MB`}</div>
      )}
      {supportedFileTypes.length && (
        <div className="text-gray-700">
          {`Supported formats: ${supportedFileTypes
            .map((item) => item.type)
            .join(', ')}`}
        </div>
      )}
      <Upload {...props}>
        <Button
          shape="round"
          icon={<UploadOutlined />}
          loading={loading}
          disabled={loading}
        >
          Upload
        </Button>
      </Upload>
    </div>
  );
}

export default FileUpload;
