import useTranslation from 'next-translate/useTranslation';
import { ReactElement } from 'react';

export interface StatusTagProps {
  status: string;
  type?: string;
  children?: ReactElement | null;
}

export function StatusTag({ status, type, children = null }: StatusTagProps) {
  const { t: tcommon } = useTranslation('common');
  let statusClass = '';
  let statusText = '';

  switch (status) {
    case 'ACTIVE':
      statusClass = 'bg-blue-100 text-blue-600';
      statusText = tcommon('Active');
      break;
    case 'COMPLETED':
      statusClass = 'bg-gray-200 text-gray-900';
      statusText = tcommon('Active');
      break;
    case 'UPCOMING':
      statusClass = 'bg-gray-200 text-gray-900';
      statusText = tcommon('Upcoming');
      break;
    case 'DRAFT':
      statusClass = 'bg-orange-100 text-orange-600';
      statusText = tcommon('Draft');
      break;
    case 'QUALIFIED':
      statusClass = 'bg-green-100 text-green-600';
      statusText = tcommon('Qualified');
      break;
    case 'DISQUALIFIED':
      statusClass = 'bg-gray-200 text-gray-900';
      statusText = tcommon('Disqualified');
      break;
    case 'SUBMITTED':
      statusClass = 'bg-green-100 text-green-600';
      statusText = tcommon('Submitted');
      break;
    case 'PUBLISHED':
      statusClass = 'bg-blue-100 text-blue-600';
      statusText = tcommon('Published');
      break;
    case 'PENDING_APPROVAL':
    case 'PENDING':
      statusClass = 'bg-gray-200 text-gray-900';
      statusText = tcommon('Pending approval');
      break;
    case 'APPROVED':
      statusClass = 'bg-green-100 text-green-600';
      statusText = tcommon('Approved');
      break;
    case 'REJECTED':
      statusClass = 'bg-red-100 text-red-600';
      statusText = tcommon('Rejected');
      break;
    case 'DRAFTED':
      statusClass = 'bg-orange-100 text-orange-600';
      statusText = tcommon('Draft');
      break;
    case 'DENIED':
      statusClass = 'bg-red-100 text-red-600';
      statusText = tcommon('Rejected');
      break;
    case 'UNDER_REVIEW':
      statusClass = 'bg-orange-100 text-orange-600';
      statusText = tcommon('Under review');
      break;
    case 'FEEDBACK_PROVIDED':
      statusClass = 'bg-red-100 text-red-600';
      statusText = tcommon('Feedback provided');
      break;
    case 'PROGRESSED':
      statusClass = 'bg-green-100 text-green-600';
      statusText = tcommon('Progressed');
      break;
    case 'POC.NOT_STARTED':
      statusClass = 'bg-orange-100 text-orange-600';
      statusText = tcommon('Not started');
      break;
    case 'POC.IN_PROGRESS':
      statusClass = 'bg-blue-100 text-blue-600';
      statusText = tcommon('In progress');
      break;
    case 'POC.ABANDONED':
      statusClass = 'bg-red-100 text-red-600';
      statusText = tcommon('Abandoned');
      break;
    case 'POC.COMPLETED':
      statusClass = 'bg-green-100 text-green-600';
      statusText = tcommon('Completed');
      break;
    case 'POC.LAUNCHED':
      statusClass = 'bg-green-100 text-green-600';
      statusText = tcommon('Launched');
      break;
    case 'POC.NOT_LAUNCHED':
      statusClass = 'bg-red-100 text-red-600';
      statusText = tcommon('Not launched');
      break;
    case 'REPORTED':
      statusClass = 'bg-gray-200 text-gray-900';
      statusText = tcommon('Reported');
      break;
    case 'HIDDEN':
      statusClass = 'bg-red-100 text-red-600';
      statusText = tcommon('Hidden');
      break;
    case 'EVALUATION.COMPLETED':
      statusClass = 'bg-green-100 text-green-600';
      statusText = tcommon('Completed');
      break;
    case 'EXPIRED':
      statusClass = 'bg-red-100 text-red-600';
      statusText = tcommon('Invitation expired');
      break;
    default:
      statusClass = '';
      statusText = '';
      break;
  }
  if (type === 'company') {
    switch (status) {
      case 'APPROVED':
        statusClass = 'bg-blue-100 text-blue-600';
        statusText = tcommon('Published');
        break;
      case 'DENIED':
        statusClass = 'bg-red-100 text-red-600';
        statusText = tcommon('Hidden');
        break;
      default:
        statusClass = '';
        statusText = '';
        break;
    }
  }
  if (type === 'pitch') {
    switch (status) {
      case 'DRAFT':
        statusClass = 'bg-orange-100 text-orange-600';
        statusText = tcommon('Draft');
        break;
      case 'APPROVED':
        statusClass = 'bg-blue-100 text-blue-600';
        statusText = tcommon('Published');
        break;
      case 'REJECTED':
        statusClass = 'bg-red-100 text-red-600';
        statusText = tcommon('Hidden');
        break;
      default:
        statusClass = '';
        statusText = '';
        break;
    }
  }

  if (type === 'challenge') {
    switch (status) {
      case 'ACTIVE':
        statusClass = 'bg-green-300';
        statusText = tcommon('Registration open');
        break;
      case 'UPCOMING':
        statusClass = 'bg-gold-700';
        statusText = tcommon('Upcoming challenge');
        break;
      case 'ENDED':
        statusClass = 'bg-gray-800';
        statusText = tcommon('Registration closed');
        break;
      case 'DRAFT':
        statusClass = 'bg-gray-600';
        statusText = tcommon('Draft challenge');
        break;
      case 'ARCHIVED':
        statusClass = 'bg-red-600';
        statusText = tcommon('Archived challenge');
        break;
      default:
        statusClass = '';
        statusText = '';
        break;
    }

    return (
      <div className={`truncate rounded-[20px] py-1 px-3 ${statusClass}`}>
        <span className="text-white font-medium text-xs">{statusText}</span>
      </div>
    );
  }

  return (
    <span
      className={`truncate text-xs font-medium rounded-sm py-1 px-3 ${statusClass}`}
    >
      {statusText} {children && children}
    </span>
  );
}

export default StatusTag;
