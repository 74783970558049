import { signIn } from '@frontend/data-access';
import { Alert, AlertMessage } from '@frontend/shared/ui';
import { Button, Form, Input, Spin, Typography } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { getAuthErrorMessages } from '../auth-helper';
import Sso from '../sso/sso';

type FormValues = {
  email: string;
  password: string;
};

export function Login({ type }: { type: string }) {
  const { t } = useTranslation('common');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [form] = Form.useForm();
  const router = useRouter();

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<Alert>();

  const referrer = router.query['referrer'];
  const errors = router.query['errors'] as string;

  if (typeof window !== 'undefined') {
    if (referrer) {
      localStorage.setItem('referrer', referrer as string);
    } else {
      localStorage.removeItem('referrer');
    }
  }

  const handleLogin = async (values: FormValues) => {
    try {
      setAlert(undefined);
      setLoading(true);

      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
      const token = await executeRecaptcha('login');

      const response = await signIn({
        email: values.email,
        password: values.password,
        captcha: token,
      });
      const login = await response.json();
      if (response.status === 200 && login.user) {
        await router.push(referrer ? (referrer as string) : '/');
      } else {
        setAlert({
          messages: getAuthErrorMessages([...login.errors]),
          type: 'error',
        });
      }
    } catch (error) {
      let message = 'Unknown error';
      if (error instanceof Error) message = error.message;
      setAlert({ messages: [message], type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // temporary
  const REST_API_URL = process.env['NEXT_PUBLIC_REST_API_ENDPOINT'];
  const cognitoAutoRedirect =
    process.env['NEXT_PUBLIC_COGNITO_AUTO_REDIRECT'] === 'true';

  useEffect(() => {
    if (errors) {
      setAlert({
        messages: getAuthErrorMessages([errors]),
        type: 'error',
      });
    }
  }, [errors]);

  useEffect(() => {
    if (cognitoAutoRedirect) {
      let url = '/auth/cognito';
      if (referrer) url = '/auth/cognito?redirect_to=' + referrer;
      window.open(REST_API_URL + url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return cognitoAutoRedirect ? (
    <div className="flex justify-center items-center">
      <Spin />
    </div>
  ) : (
    <div className="w-[350px]">
      {type !== 'internal' || type === undefined ? (
        <Typography.Title className="!mb-2 text-center" level={2}>
          {t('login_title')}
        </Typography.Title>
      ) : (
        <Typography.Title className="!mb-2 text-center" level={2}>
          {t('login_internal_title')} <br />
          FinTech Bridge
        </Typography.Title>
      )}
      <Sso type="Login" />
      {(type !== 'internal' || type === undefined) && (
        <>
          <div className="mb-6 text-center">
            <Typography.Text>
              {t('login_join_prefix')}
              <Link href={referrer ? `/join?referrer=${referrer}` : '/join'}>
                {' '}
                {t('login_join_button')}
              </Link>
            </Typography.Text>
          </div>
          {alert && <AlertMessage className="my-6" alert={alert} />}
          <Form layout="vertical" form={form} onFinish={handleLogin}>
            <Form.Item
              label={t('login_input_email')}
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: t('login_input_email_required'),
                },
              ]}
            >
              <Input data-test="email" />
            </Form.Item>
            <Form.Item
              label={t('login_input_password')}
              name="password"
              rules={[
                { required: true, message: t('login_input_password_required') },
              ]}
              extra={
                <Typography>
                  <Link href="forgot-password">
                    {t('login_email_forgot_button')}
                  </Link>
                </Typography>
              }
            >
              <Input.Password data-test="password" />
            </Form.Item>
            <Form.Item>
              <Button
                data-test="submit"
                loading={loading}
                block
                size="large"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                {t('login_button')}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
      <div className="text-center mt-10">
        <Link href="/bridge" className="text-primary-600">
          {t('login_back_button')}
        </Link>
      </div>
    </div>
  );
}

export default Login;
