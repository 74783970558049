/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Challenge,
  GET_INTRAPRENEUR_CHALLENGES,
  GET_OPEN_CHALLENGES,
  PIN_INTRAPRENEUR_CHALLENGE,
  PIN_OPEN_CHALLENGE,
  UNPIN_INTRAPRENEUR_CHALLENGE,
  UNPIN_OPEN_CHALLENGE,
} from '@frontend/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { StatusTag } from '@frontend/shared/ui';
import { formatDate, formatDateWithTimezone } from '@frontend/shared/util';
import { Button, Space, Typography } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

const { Text } = Typography;
export interface ChallengeCardProps {
  challenge: Challenge;
  variables?: any;
}

export interface ChallengePin {
  challengeId: string;
  featured: boolean;
}

export function ChallengeCard({ challenge, variables }: ChallengeCardProps) {
  const { t } = useTranslation('admin');
  const { t: tcommon } = useTranslation('common');
  const { pathname } = useRouter();

  const [pinChallenge, { loading: loadingPin }] = useMutation(
    challenge?.__typename === 'OpenChallenge'
      ? PIN_OPEN_CHALLENGE
      : PIN_INTRAPRENEUR_CHALLENGE,
    {
      refetchQueries: [
        {
          query:
            challenge?.__typename === 'OpenChallenge'
              ? GET_OPEN_CHALLENGES
              : GET_INTRAPRENEUR_CHALLENGES,
          variables,
        },
      ],
    }
  );

  const [unpinChallenge, { loading: loadingUnpin }] = useMutation(
    challenge?.__typename === 'OpenChallenge'
      ? UNPIN_OPEN_CHALLENGE
      : UNPIN_INTRAPRENEUR_CHALLENGE,
    {
      refetchQueries: [
        {
          query:
            challenge?.__typename === 'OpenChallenge'
              ? GET_OPEN_CHALLENGES
              : GET_INTRAPRENEUR_CHALLENGES,
          variables,
        },
      ],
    }
  );

  const renderPrizes = () => {
    return (
      <div className="flex flex-col">
        {Number(challenge.prizeCashAmount) ? (
          <span>
            {Number(challenge.prizeCashAmount).toLocaleString('en-US') + '$'}
          </span>
        ) : (
          ''
        )}
        {challenge.prizes
          ?.filter((prize) => !['CASH', 'CUSTOM'].includes(prize))
          .map((prize, index) => {
            return (
              <span key={index}>
                {prize === 'SCV_PARTNERSHIP'
                  ? 'Opportunity to partner with SC Ventures'
                  : prize}
              </span>
            );
          })}
      </div>
    );
  };

  const handlePinClick = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    await pinChallenge({ variables: { challengeId: challenge.id } });
  };
  const handleUnpinClick = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    await unpinChallenge({ variables: { challengeId: challenge.id } });
  };

  return (
    <Link href={`${pathname}/${challenge.id}`}>
      <div className="border border-gray-400 rounded-2xl p-6 color hover:shadow-sm">
        <Space
          className="text-gray-900"
          direction="vertical"
          size="large"
          style={{ display: 'flex' }}
        >
          <Image
            className="rounded-md w-full"
            width={304}
            height={174}
            src={
              challenge?.banner?.url ||
              'https://dummyimage.com/304x174/F5F5F5/fff.png&text=+'
            }
            alt={challenge.title}
            placeholder="empty"
          />
          <div className="space-y-4">
            <div className="flex gap-2 items-center">
              {challenge.state && (
                <StatusTag status={challenge.state} type="challenge" />
              )}
              {challenge.innovationSpaceTag && (
                <div className="rounded-[20px] py-1 px-3 bg-gray-300">
                  <span className="text-gray-900 font-medium text-xs">
                    {challenge.innovationSpaceTag.name}
                  </span>
                </div>
              )}
            </div>
            <div className="mb-1">
              {t('intra_challenge_card_sponsored_by')} {challenge.sponsor}
            </div>
            <Text className="text-base font-medium">{challenge.title}</Text>
          </div>

          <div>
            <div>
              <span className="text-gray-700">{tcommon('Start date')}: </span>
              {challenge.createdAt &&
                formatDateWithTimezone(
                  challenge?.registrationStartAt,
                  challenge?.timezone,
                  'Do MMM YYYY'
                )}
            </div>
            <div className="mt-2">
              <span className="text-gray-700">{tcommon('End date')}: </span>
              {challenge.createdAt &&
                formatDateWithTimezone(
                  challenge?.registrationEndAt,
                  challenge?.timezone,
                  'Do MMM YYYY'
                )}
            </div>
          </div>

          <div>
            <div className="text-gray-700">{tcommon('Created by')}</div>
            <div>{challenge?.createdBy?.fullName || '-'}</div>
            <div className="text-gray-700 mt-2">{tcommon('Creation date')}</div>
            <div>{formatDate(challenge.createdAt, 'do LLL yyyy')}</div>
            {challenge.prizes && challenge.prizes.length > 0 && (
              <>
                <div className="text-gray-700 mt-2">{tcommon('Prize')}</div>
                <div>{renderPrizes()}</div>
              </>
            )}

            {challenge.panelMembers?.length > 0 && (
              <>
                <div className="text-gray-700 mt-2">
                  {tcommon('Panel members')}
                </div>
                <div>{challenge.panelMembers.join(', ')}</div>
              </>
            )}
          </div>

          {challenge.state !== 'ARCHIVED' && (
            <Space>
              {challenge.pinned ? (
                <Button
                  type="text"
                  shape="round"
                  loading={loadingUnpin}
                  onClick={handleUnpinClick}
                >
                  <PushpinFilled />
                  {tcommon('Pinned')}
                </Button>
              ) : (
                <Button
                  className="!text-gray-900"
                  type="text"
                  shape="round"
                  loading={loadingPin}
                  onClick={handlePinClick}
                >
                  <PushpinOutlined />
                  {tcommon('Pin')}
                </Button>
              )}
            </Space>
          )}
        </Space>
      </div>
    </Link>
  );
}

export default ChallengeCard;
