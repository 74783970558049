import { UploadedFile } from './common';
import { Company } from './company';
import { Discussion } from './discussion';
import { KeyTag } from './key-tag';
import { Region } from './region';
import { Topic } from './topic';
import { User } from './user';

export enum PitchStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface PitchInput {
  id?: string;
  title?: string;
  body?: string;
  topicIds?: string[];
  regionIds?: string[];
  companyId?: number;
  video?: UploadedFile;
  banner?: UploadedFile;
  link?: string;
  document?: UploadedFile;
  keyTagIds?: string[];
}

export interface Pitch {
  id: string;
  status: PitchStatus;
  title: string;
  body: string;
  video: UploadedFile;
  banner: UploadedFile;
  link: string;
  document: UploadedFile;
  company?: Company;
  topics?: Topic[];
  regions?: Region[];
  createdBy?: User;
  createdAt?: string;
  publishedAt?: string;
  approvedBy?: User;
  approvedAt?: string;
  discussion?: Discussion;
  likes: number;
  isLiked: boolean;
  comments: number;
  rejectDesc: string;
  keyTags: KeyTag[];
}

export interface PitchBasic {
  id: string;
  title: string;
  banner?: UploadedFile;
  createdBy?: User;
  publishedAt?: string;
}
export interface CreatePitch {
  createPitch: Pitch;
}
export interface PatchPitch {
  patchPitch: Pitch;
}
export interface PublishPitch {
  publishPitch: Pitch;
}
export interface GetPitch {
  pitch: Pitch;
}

export interface LikePitch {
  likePitch: {
    pitchId: string;
    userId: number;
  };
}

export interface UnlikePitch {
  unlikePitch: boolean;
}
