import { format } from 'date-fns';
import moment from 'moment-timezone';

export function formatDate(date: string | Date, formatting: string): string {
  return format(new Date(date), formatting);
}

export function formatDateWithoutTimezone(
  date: string | Date,
  format: string
): string {
  return moment(date).utcOffset(0, false).format(format);
}

export function formatDateWithTimezone(
  date: string | Date,
  timezone?: string,
  formatting?: string
): string {
  if (timezone) {
    const localOffset = moment().utcOffset();
    const givenTimezone = timezone;
    const givenOffset = moment.tz(givenTimezone).utcOffset();
    const offsetDiff = localOffset - givenOffset;
    return moment.utc(date).utcOffset(offsetDiff).format(formatting);
  }
  return moment(date).format(formatting);
}

export function formatDateWithTimezoneV2(
  date: string | Date,
  timezone?: string,
  formatting?: string
): string {
  return timezone
    ? moment(date).tz(timezone).format(formatting)
    : moment(date).format(formatting);
}

export function calculateDaysBetweenDates(
  startDate: string,
  endDate: string
): number {
  return parseFloat(
    moment
      .duration(moment(endDate)?.diff(moment(startDate)))
      .asDays()
      .toFixed(0)
  );
}

export function timezoneStringToGmtOffsetString(timezone: string) {
  const date = moment.tz(timezone);
  const offset = date.utcOffset() / 60;
  return `GMT${offset >= 0 ? '+' : ''}${offset}:00`;
}
