import { gql } from '@apollo/client';

export const SDG = gql`
  fragment sdg on SDG {
    id
    name
  }
`;

export const GET_SDGS = gql`
  query Sdgs {
    sdgs {
      ...sdg
    }
  }
  ${SDG}
`;
