import {
  ApolloCache,
  NormalizedCacheObject,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  CommentType,
  CREATE_DISCUSSION_WALL_COMMENT,
  Discussion,
  GET_DISCUSSION,
  GET_ME,
  Me,
  REPLY_TO_DISCUSSION_COMMENT,
  User,
} from '@frontend/data-access';
import { Row, Spin } from 'antd';
import { useEffect, useState } from 'react';

import Comment from '../comment/comment';
import CommentAdd from '../comment-add/comment-add';

export interface ChallengeDiscussionWallProps {
  id: string;
  title?: string;
  readonly?: boolean;
}

export interface Thread {
  id: string;
  text: string;
  author: User;
  isEdit?: boolean;
  isDelete?: boolean;
}

export function DiscussionWall({
  id,
  title = 'Discussions',
  readonly = false,
}: ChallengeDiscussionWallProps) {
  const [comments, setComments] = useState<CommentType[]>();
  const [currentThread, setCurrentThread] = useState<Thread | undefined>();

  const { data, loading } = useQuery<{
    discussion: Discussion;
  }>(GET_DISCUSSION, {
    variables: {
      id: id,
    },
  });
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);

  const [createDiscussionWallComment, { loading: commentLoading }] =
    useMutation(CREATE_DISCUSSION_WALL_COMMENT, {
      update: (cache: ApolloCache<NormalizedCacheObject>, { data }) => {
        const {
          createDiscussionComment,
        }: { createDiscussionComment: CommentType } = data;
        if (createDiscussionComment) {
          const cacheData: {
            discussion: Discussion;
          } | null = cache.readQuery({
            query: GET_DISCUSSION,
            variables: {
              id: id,
            },
          });
          if (cacheData) {
            const { discussion } = cacheData;
            if (discussion) {
              cache.writeQuery({
                query: GET_DISCUSSION,
                variables: {
                  id: id,
                },
                data: {
                  discussion: {
                    ...discussion,
                    comments: {
                      ...discussion?.comments,
                      edges: [
                        ...(discussion?.comments.edges || []),
                        {
                          cursor: null,
                          node: createDiscussionComment,
                          __typename: 'CommentEdge',
                        },
                      ],
                    },
                  },
                },
              });
            }
          }
        }
      },
    });

  const [replyToDiscussionComment, { loading: replyLoading }] = useMutation(
    REPLY_TO_DISCUSSION_COMMENT
  );

  useEffect(() => {
    if (data?.discussion?.comments) {
      const comments = data?.discussion?.comments?.edges.map(
        (commentEdge) => commentEdge.node
      );

      setComments(comments);
    }
  }, [data]);

  const createComment = (discussionId: string, text: string) =>
    createDiscussionWallComment({
      variables: { input: { discussionId: discussionId, text } },
    });

  const replyComment = (commentId: string, text: string) =>
    replyToDiscussionComment({
      variables: { input: { commentId: commentId, text } },
    });

  const handleComment = (text: string) => {
    currentThread
      ? replyComment(currentThread?.id, text)
      : data?.discussion?.id && createComment(data?.discussion?.id, text);
  };

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <Spin />
      </div>
    );
  }

  return (
    <div>
      <Row justify="space-between">
        <div className="font-semibold mb-4">{title}</div>
      </Row>
      {comments?.map((comment) => (
        <Comment
          key={comment.id}
          discussionId={id}
          comment={comment}
          currentUser={meData?.me}
          currentThread={currentThread}
          setCurrentThread={setCurrentThread}
        />
      ))}
      {!readonly && (
        <CommentAdd
          currentUser={meData?.me}
          currentThread={currentThread}
          isLoading={commentLoading || replyLoading}
          setCurrentThread={setCurrentThread}
          onComment={handleComment}
        />
      )}
    </div>
  );
}

export default DiscussionWall;
