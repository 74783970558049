import {
  BulbOutlined,
  DotChartOutlined,
  FileOutlined,
  FireOutlined,
  FlagOutlined,
  GlobalOutlined,
  LinkOutlined,
  TagsOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Idea } from '@frontend/data-access';
import { getLinkLabel } from '@frontend/shared/util';
import { Avatar, Col, Row, Space } from 'antd';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

import { handleOpenDocument } from '../documents/documents';
import IconCard from '../icon-card/icon-card';
import { IdeaDetailTeamInviteButton } from '../idea-detail/invite-button';
import UserAvatar from '../user-avatar/user-avatar';

export interface IdeaDetailBasicsProps {
  data: Idea;
}

interface IdeaBasicItemType {
  id: string;
  title: string;
  icon?: React.ReactNode;
  item: React.ReactNode;
}

export const getIdeaBasicItems = (
  idea: Idea,
  isUpdate = false
): IdeaBasicItemType[] => {
  return [
    {
      title: 'idea_detail_basics_applicants',
      icon: <TeamOutlined />,
      item: (
        <div className="flex flex-wrap gap-5 items-center justify-between">
          <div className="flex flex-wrap gap-2">
            <Avatar.Group maxCount={5} className="!space-x-2">
              {idea.anonymous ? (
                <Avatar size={40} icon={<UserOutlined />} />
              ) : (
                <UserAvatar size={40} user={idea.createdBy} showBadge />
              )}
              {idea.applicants
                ?.filter(
                  (i) =>
                    i.status === 'APPROVED' && idea.createdBy.id !== i.user.id
                )
                ?.map((applicant, index) => (
                  <UserAvatar
                    key={index}
                    size={40}
                    user={applicant.user}
                    showBadge
                  />
                ))}
            </Avatar.Group>
          </div>
          <IdeaDetailTeamInviteButton data={idea} />
        </div>
      ),
    },
    isUpdate || idea.video || idea.documents?.length
      ? {
          title: 'idea_detail_basics_attachments',
          icon: <FileOutlined />,
          item: (
            <div className="flex justify-between">
              <div>
                {idea.video && (
                  <div>
                    <span className="text-sm text-gray-900">Video</span>
                    <div className="space-x-1">
                      <span className="text-primary-600">
                        <LinkOutlined />
                      </span>
                      <Link
                        className="text-primary-600 break-all"
                        data-test="idea-video"
                        href={idea.video}
                        target="_blank"
                      >
                        {idea.video}
                      </Link>
                    </div>
                  </div>
                )}
                {idea?.documents && idea?.documents?.length > 0 && (
                  <div>
                    <span className="text-sm text-gray-900">Documents</span>
                    {idea.documents?.map((file, index) => (
                      <div
                        className="space-x-1"
                        key={index}
                        data-test="idea-document"
                      >
                        <span className="text-primary-600">
                          <FileOutlined />
                        </span>
                        <span
                          className="text-primary-600 cursor-pointer"
                          onClick={() => handleOpenDocument(file)}
                        >
                          {file.metadata?.filename}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ),
        }
      : null,
    idea.challenge
      ? {
          title: 'idea_detail_basics_challenge',
          icon: <BulbOutlined />,
          item: (
            <div>
              <Link
                data-test="idea-challenge-name"
                className="text-primary-600"
                href={'/intrapreneurship/challenge/' + idea.challenge?.id || ''}
              >
                {idea.challenge?.title}
              </Link>
            </div>
          ),
        }
      : null,
    idea?.innovationSpaceTag
      ? {
          title: 'idea_detail_basics_innovation_space',
          icon: <FireOutlined />,
          item: (
            <div>
              <Link
                data-test="idea-challenge-name"
                className="text-primary-600"
                href={`/innovation/${idea?.innovationSpaceTag?.id}`}
              >
                {idea?.innovationSpaceTag?.name}
              </Link>
            </div>
          ),
        }
      : null,
    {
      title: 'Region',
      icon: <GlobalOutlined />,
      item: (
        <Row className="gap-2 mt-1">
          {idea.regions?.map((item, index) => {
            return (
              <Col
                className="bg-gray-200 py-1 px-3 rounded-full text-xs"
                key={index}
                data-test="idea-region"
              >
                {item.name}
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      title: 'idea_detail_basics_country',
      icon: <FlagOutlined />,
      item: (
        <Row className="gap-2 mt-1">
          {idea.countries?.map((item, index) => {
            return (
              <Col
                className="bg-gray-200 py-1 px-3 rounded-full text-xs"
                key={index}
                data-test="idea-country"
              >
                {item.name}
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      title: 'idea_detail_basics_topics',
      icon: <TagsOutlined />,
      item: (
        <Row className="gap-2 mt-1">
          {idea.topics?.map((item, index) => {
            return (
              <Col
                className="bg-gray-200 py-1 px-3 rounded-full text-xs"
                key={index}
                data-test="idea-topic"
              >
                {item.name}
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      title: 'idea_detail_basics_business_area',
      icon: <DotChartOutlined />,
      item: (
        <Row className="gap-2 mt-1">
          {idea.businessAreas?.map((item, index) => {
            return (
              <Col key={index} data-test="idea-business-area">
                {item}
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      title: 'idea_detail_basics_idea_status',
      icon: <FireOutlined />,
      item: (
        <span data-test="idea-status">
          {idea.state && getLinkLabel(idea.state.toLowerCase())}
        </span>
      ),
    },
  ].filter((a) => a != null) as IdeaBasicItemType[];
};

export function IdeaDetailBasics({ data }: IdeaDetailBasicsProps) {
  const { t: tcommon } = useTranslation('common');
  return (
    <Space className="w-full" direction="vertical" size="middle">
      {getIdeaBasicItems(data).map((item, index) => (
        <IconCard key={index} title={tcommon(item.title)} icon={item.icon}>
          <div>{item.item}</div>
        </IconCard>
      ))}
    </Space>
  );
}

export default IdeaDetailBasics;
