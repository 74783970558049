import { gql } from '@apollo/client';

import { COUNTRY } from './country';
import { CRITERIA, CRITERIA_RESULT } from './criteria';
import { DISCUSSION } from './discussion';
import { INNOVATION } from './innovation';
import { REGION } from './region';
import { TOPIC } from './topic';
import { FILE } from './upload';
import { RANK, TOP_CONTRIBUTOR, USER_BASICS } from './user';

export const IDEA_REPORTS = gql`
  fragment ideaReports on IdeaReports {
    id
    ideaId
    description
    reportStatus
    createdBy {
      ...userBasics
    }
    updatedAt
    createdAt
  }
`;

export const RULE_WITH_USER_DONE = gql`
  fragment ruleWithUserDone on RuleWithUserDone {
    id
    point
    comment
    interval
    userType
    goal
    done
  }
`;

export const IDEA_CONTRIBUTE = gql`
  fragment contribute on Contribute {
    id
    ideaId
    title
    body
    likes
    isLiked
    photo {
      ...file
    }
    coverPhotoUrl {
      author
      profileUrl
      imageUrl
    }
    createdBy {
      ...userBasics
      rank {
        ...rank
      }
    }
    updatedAt
    createdAt
  }
`;

export const IDEA = gql`
  fragment idea on Idea {
    id
    name
    problem
    coverPhoto {
      ...file
    }
    coverPhotoUrl {
      author
      profileUrl
      imageUrl
    }
    state
    status
    ideaType
    note
    anonymous
    reportedCount
    regContribute
    contributeCount
    pinned
    ideaReports {
      ...ideaReports
    }
    regions {
      ...region
    }
    discussion {
      ...discussion
    }
    internalDiscussion {
      ...discussion
    }
    innovationSpaceTag {
      ...innovation
    }
    likes
    isLiked
    isReported
    comments
    internalComments
    countries {
      ...country
    }
    createdBy {
      ...userBasics
      rank {
        ...rank
      }
    }
    topics {
      ...topic
    }
    documents {
      ...file
    }
    challenge {
      id
      title
      stages {
        id
        name
        ideas {
          id
        }
      }
    }
    currentStage {
      id
      name
    }
    applicants {
      ...ideaCollaboration
    }
    collaborative
    video
    businessAreas
    updatedAt
    createdAt
  }
`;

export const IDEA_BASIC = gql`
  fragment ideaBasic on Idea {
    id
    name
    problem
    coverPhoto {
      ...file
    }
    coverPhotoUrl {
      author
      profileUrl
      imageUrl
    }
    innovationSpaceTag {
      ...innovation
    }
    state
    status
    ideaType
    note
    anonymous
    contributeCount
    pinned
    contributions {
      id
      createdBy {
        ...userBasics
      }
      updatedAt
      createdAt
    }
    regions {
      ...region
    }
    topics {
      ...topic
    }
    documents {
      ...file
    }
    video
    likes
    isLiked
    comments
    createdBy {
      ...userBasics
      rank {
        ...rank
      }
    }
    challenge {
      id
      title
    }
    collaborative
    updatedAt
    createdAt
    criteria {
      ...criteria
    }
    criteriaResults {
      ...result
    }
  }
`;

export const IDEA_COLLABORATION = gql`
  fragment ideaCollaboration on IdeaCollaboration {
    id
    ideaId
    userId
    user {
      ...userBasics
      rank {
        rank
        badge {
          level
          name
        }
      }
    }
    status
    type
  }
`;

export const GET_RULE_BY_GOAL_USER = gql`
  query GetRuleByGoalByUser($goal: Int) {
    getRuleByGoalByUser(goal: $goal) {
      ...ruleWithUserDone
    }
  }
  ${RULE_WITH_USER_DONE}
`;

export const GET_RULE_BY_INTERVAL_USER = gql`
  query GetRuleByIntervalByUser($interval: Int) {
    getRuleByIntervalByUser(interval: $interval) {
      ...ruleWithUserDone
    }
  }
  ${RULE_WITH_USER_DONE}
`;

export const GET_TOP_CONTRIBUTIONS = gql`
  query GetTopContributions($filterBy: TopContributionFilter!, $limit: Int) {
    getTopContributions(filterBy: $filterBy, limit: $limit) {
      ...topContributor
    }
  }
  ${TOP_CONTRIBUTOR}
  ${FILE}
`;

export const GET_IDEA = gql`
  query Idea($ideaId: UUID!) {
    idea(ideaId: $ideaId) {
      ...idea
      criteria {
        ...criteria
      }
      criteriaResults {
        ...result
      }
      challenge {
        experts {
          ...userBasics
        }
      }
    }
  }
  ${IDEA}
  ${INNOVATION}
  ${IDEA_COLLABORATION}
  ${REGION}
  ${TOPIC}
  ${DISCUSSION}
  ${COUNTRY}
  ${IDEA_REPORTS}
  ${FILE}
  ${USER_BASICS}
  ${RANK}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const GET_IDEA_CRITERIA_RESULT = gql`
  query Idea($ideaId: UUID!) {
    idea(ideaId: $ideaId) {
      criteria {
        ...criteria
      }
      criteriaResults {
        ...result
      }
    }
  }
  ${FILE}
  ${USER_BASICS}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const GET_IDEAS_FOR_USER = gql`
  query IdeasForUser($first: Int, $after: String, $filterBy: IdeaFilterType) {
    ideasForUser(first: $first, after: $after, filterBy: $filterBy) {
      edges {
        cursor
        node {
          ...ideaBasic
          __typename
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${IDEA_BASIC}
  ${INNOVATION}
  ${TOPIC}
  ${REGION}
  ${FILE}
  ${USER_BASICS}
  ${RANK}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const GET_IDEA_CONTRIBUTIONS = gql`
  query IdeaContribution($first: Int, $after: String, $ideaId: UUID!) {
    ideaContribution(first: $first, after: $after, ideaId: $ideaId) {
      edges {
        cursor
        node {
          ...contribute
          __typename
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${IDEA_CONTRIBUTE}
  ${RANK}
  ${FILE}
  ${USER_BASICS}
`;

export const GET_MY_IDEAS = gql`
  query Me(
    $filterBy: IdeasFilters
    $sort: IdeaSortOption
    $page: Int
    $limit: Int
  ) {
    me {
      id
      ideas(filterBy: $filterBy, sort: $sort, page: $page, limit: $limit) {
        items {
          ...idea
        }
        total
      }
    }
  }
  ${IDEA}
  ${INNOVATION}
  ${IDEA_COLLABORATION}
  ${REGION}
  ${TOPIC}
  ${DISCUSSION}
  ${COUNTRY}
  ${IDEA_REPORTS}
  ${FILE}
  ${USER_BASICS}
  ${RANK}
`;

export const GET_MY_IDEA_CONTRIBUTIONS = gql`
  query Me(
    $filterBy: IdeasFilters
    $sort: IdeaSortOption
    $page: Int
    $limit: Int
  ) {
    me {
      ideaContributions(
        filterBy: $filterBy
        sort: $sort
        page: $page
        limit: $limit
      ) {
        items {
          ...idea
          contributions {
            ...contribute
          }
        }
        total
      }
    }
  }
  ${IDEA}
  ${INNOVATION}
  ${IDEA_COLLABORATION}
  ${REGION}
  ${TOPIC}
  ${DISCUSSION}
  ${COUNTRY}
  ${IDEA_REPORTS}
  ${IDEA_CONTRIBUTE}
  ${RANK}
  ${FILE}
  ${USER_BASICS}
  ${RANK}
`;

export const GET_IDEA_STATISTICS = gql`
  query IdeaCount {
    ideaCount {
      total
      pinned
      reported
      hidden
    }
  }
`;

export const GET_IDEA_GENERATED = gql`
  query IdeaGenerated($id: UUID!) {
    ideaGenerated(id: $id) {
      code
      message
      requestId
      response {
        detail
      }
    }
  }
`;

export const GET_IDEA_REQUEST_LIMIT = gql`
  query IdeaRequestLimit {
    ideaRequestLimit {
      used
      total
    }
  }
`;

export const GET_IDEAS_FOR_ADMIN = gql`
  query IdeasForAdmin(
    $searchBy: String
    $filterBy: IdeasFilters
    $page: Int
    $limit: Int
  ) {
    ideasForAdmin(
      searchBy: $searchBy
      filterBy: $filterBy
      page: $page
      limit: $limit
    ) {
      items {
        id
        name
        createdAt
        status
        reason
        createdBy {
          fullName
        }
        reason
        pinned
        reportedCount
        ideaReports {
          createdBy {
            fullName
          }
          createdAt
          reportStatus
          description
        }
        innovationSpaceTag {
          ...innovation
        }
      }
      total
    }
  }
  ${INNOVATION}
  ${FILE}
`;

export const GET_ASSIGNED_EVALUATION_IDEAS = gql`
  query AssignedEvaluationIdeas(
    $filterBy: IdeaByEvaluatorFilter
    $page: Int
    $limit: Int
  ) {
    assignedEvaluationIdeas(filterBy: $filterBy, page: $page, limit: $limit) {
      items {
        id
        name
        problem
        video
        documents {
          ...file
        }
        createdBy {
          ...userBasics
        }
        criteriaResults {
          id
          criteria {
            ...criteria
          }
          scale
          createdBy {
            ...userBasics
          }
          status
          createdAt
        }
        challenge {
          id
          title
          stages {
            id
            name
            ideas {
              id
            }
          }
          criterias {
            ...criteria
          }
        }
        currentStage {
          id
          name
        }
        applicants {
          ...ideaCollaboration
        }
        createdAt
      }
      total
    }
  }
  ${CRITERIA}
  ${FILE}
  ${USER_BASICS}
  ${IDEA_COLLABORATION}
`;

export const GET_CHALLENGE_IDEAS = gql`
  query ChallengeIdeas($challengeId: UUID!) {
    challengeIdeas(challengeId: $challengeId) {
      ...ideaBasic
    }
  }
  ${IDEA_BASIC}
  ${INNOVATION}
  ${TOPIC}
  ${REGION}
  ${FILE}
  ${USER_BASICS}
  ${RANK}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const GET_IDEAS_BY_USER_ID = gql`
  query UserIdeas($userId: Int!, $first: Int, $after: String) {
    userIdeas(userId: $userId, first: $first, after: $after) {
      edges {
        cursor
        node {
          ...ideaBasic
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${IDEA_BASIC}
  ${INNOVATION}
  ${TOPIC}
  ${REGION}
  ${FILE}
  ${USER_BASICS}
  ${RANK}
  ${CRITERIA}
  ${CRITERIA_RESULT}
`;

export const CREATE_IDEA = gql`
  mutation CreateIdea($input: CreateIdeaInput!) {
    createIdea(input: $input) {
      ...idea
    }
  }
  ${IDEA}
  ${INNOVATION}
  ${IDEA_COLLABORATION}
  ${REGION}
  ${TOPIC}
  ${DISCUSSION}
  ${COUNTRY}
  ${IDEA_REPORTS}
  ${FILE}
  ${USER_BASICS}
  ${RANK}
`;

export const PATCH_IDEA = gql`
  mutation PatchIdea($input: PatchIdeaInput!) {
    patchIdea(input: $input) {
      ...idea
    }
  }
  ${IDEA}
  ${INNOVATION}
  ${IDEA_COLLABORATION}
  ${REGION}
  ${TOPIC}
  ${DISCUSSION}
  ${COUNTRY}
  ${IDEA_REPORTS}
  ${FILE}
  ${USER_BASICS}
  ${RANK}
`;

export const SUBMIT_IDEA = gql`
  mutation SubmitIdea($input: PatchSubmitIdeaInput!) {
    submitIdea(input: $input) {
      ...idea
    }
  }
  ${IDEA}
  ${INNOVATION}
  ${IDEA_COLLABORATION}
  ${REGION}
  ${TOPIC}
  ${DISCUSSION}
  ${COUNTRY}
  ${IDEA_REPORTS}
  ${FILE}
  ${USER_BASICS}
  ${RANK}
`;

export const IDEA_LIKE = gql`
  mutation LikeIdea($id: UUID!) {
    likeIdea(id: $id) {
      ideaId
      userId
    }
  }
`;

export const IDEA_UNLIKE = gql`
  mutation UnlikeIdea($id: UUID!) {
    unlikeIdea(id: $id)
  }
`;

export const IDEA_HIDE = gql`
  mutation HideIdea($ideaId: UUID!, $reason: String) {
    hideIdea(ideaId: $ideaId, reason: $reason) {
      id
      status
      reason
    }
  }
`;
export const IDEA_UNHIDE = gql`
  mutation UnhideIdea($ideaId: UUID!) {
    unhideIdea(ideaId: $ideaId) {
      id
      status
      reason
    }
  }
`;
export const IDEA_PIN = gql`
  mutation PinIdea($ideaId: UUID!) {
    pinIdea(ideaId: $ideaId) {
      id
      pinned
    }
  }
`;
export const IDEA_UNPIN = gql`
  mutation UnpinIdea($ideaId: UUID!) {
    unpinIdea(ideaId: $ideaId) {
      id
      pinned
    }
  }
`;

export const REGISTER_IDEA_CONTRIBUTE = gql`
  mutation RegisterIdeaContribute($input: RegisterIdeaContributeInput!) {
    registerIdeaContribute(input: $input) {
      id
      contributeCount
      contributions {
        ...contribute
      }
    }
  }
  ${IDEA_CONTRIBUTE}
  ${RANK}
  ${FILE}
  ${USER_BASICS}
`;

export const REPORT_IDEA = gql`
  mutation ReportIdea($input: CreateIdeaReportInput!) {
    reportIdea(input: $input) {
      ...idea
    }
  }
  ${IDEA}
  ${INNOVATION}
  ${IDEA_COLLABORATION}
  ${REGION}
  ${TOPIC}
  ${DISCUSSION}
  ${COUNTRY}
  ${IDEA_REPORTS}
  ${FILE}
  ${USER_BASICS}
  ${RANK}
`;

export const LIKE_IDEA_CONTRIBUTION = gql`
  mutation LikeIdeaContribution($id: UUID!) {
    likeIdeaContribution(id: $id) {
      contributeId
      userId
    }
  }
`;

export const UNLIKE_IDEA_CONTRIBUTION = gql`
  mutation UnlikeIdeaContribution($id: UUID!) {
    unlikeIdeaContribution(id: $id)
  }
`;

export const DELETE_IDEA = gql`
  mutation DeleteIdea($id: UUID!) {
    deleteIdea(id: $id)
  }
`;

export const CREATE_IDEA_AI = gql`
  mutation IdeaCreateAI($input: IdeaCreateAIInput!) {
    ideaCreateAI(input: $input) {
      code
      message
      requestId
      response {
        detail
      }
    }
  }
`;

export const IDEA_CHALLENGE_CHANGE_STAGE = gql`
  mutation PatchIdeaChallengeStage($ideaId: UUID!, $stageId: UUID!) {
    patchIdeaChallengeStage(ideaId: $ideaId, stageId: $stageId)
  }
`;

export const TEAM_INVITE = gql`
  mutation TeamInvite(
    $ideaId: UUID!
    $userId: Int!
    $type: IdeaCollaborationType!
  ) {
    teamInvite(ideaId: $ideaId, userId: $userId, type: $type) {
      ...ideaCollaboration
    }
  }
  ${IDEA_COLLABORATION}
  ${FILE}
  ${USER_BASICS}
`;

export const APPROVE_TEAM_INVITE = gql`
  mutation ApproveTeamInvite($id: UUID!, $status: IdeaCollaborationStatus!) {
    approveTeamInvite(id: $id, status: $status) {
      ...ideaCollaboration
    }
  }
  ${IDEA_COLLABORATION}
  ${FILE}
  ${USER_BASICS}
`;

export const RESPOND_TEAM_INVITE = gql`
  mutation RespondTeamInvite($id: UUID!, $status: IdeaCollaborationStatus!) {
    respondTeamInvite(id: $id, status: $status) {
      ...ideaCollaboration
    }
  }
  ${IDEA_COLLABORATION}
  ${FILE}
  ${USER_BASICS}
`;

export const TEAM_INVITE_DELETE = gql`
  mutation DeleteTeamMember($id: UUID!) {
    deleteTeamMember(id: $id)
  }
`;
export const DOWNLOAD_IDEA = gql`
  query DownloadChallengeIdeaList($challengeId: UUID!) {
    downloadChallengeIdeaList(challengeId: $challengeId)
  }
`;
