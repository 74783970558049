import { migrationConfirm } from '@frontend/data-access';
import { Alert, AlertMessage } from '@frontend/shared/ui';
import { Badge, Button, Col, Form, Input, Row, Typography } from 'antd';
import { useRouter } from 'next/router';
import { useState } from 'react';

import {
  getAuthErrorMessages,
  usePasswordStrengthChecker,
} from '../auth-helper';

type FormValues = {
  email: string;
  password: string;
};

export function AuthUserMigrationConfirm() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<Alert>();
  const router = useRouter();
  const password = Form.useWatch('password', form) || '';

  const {
    hasEnoughCharacters,
    hasNumber,
    hasSpecialCharacters,
    hasUpperCaseCharacter,
  } = usePasswordStrengthChecker(password);

  const token = router.query['migration_token'] as string;

  const handleConfirm = async (values: FormValues) => {
    try {
      setAlert(undefined);
      setLoading(true);
      const response = await migrationConfirm({
        token: token,
        newPassword: values.password,
      });
      if (response.status === 200) {
        router.push('/login');
      } else {
        setAlert({
          messages: getAuthErrorMessages(['error']),
          type: 'error',
        });
      }
    } catch (error) {
      let message = 'Unknown error';
      if (error instanceof Error) message = error.message;
      setAlert({ messages: [message], type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-[450px] flex flex-col items-center justify-center">
      <Typography.Title className="!mb-6 text-center" level={2}>
        Create your password
      </Typography.Title>
      {alert && <AlertMessage className="my-6" alert={alert} />}
      <div className="w-[350px]">
        <Form layout="vertical" form={form} onFinish={handleConfirm}>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter your password' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="password2"
            rules={[
              { required: true, message: 'Please enter your password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item label="Password must contain:">
            <Row>
              <Col span={8} style={{ display: 'flex' }}>
                <Badge
                  status={hasUpperCaseCharacter ? 'success' : 'default'}
                  text="upper-case"
                />
              </Col>
              <Col span={16} style={{ display: 'flex' }}>
                <Badge
                  status={hasEnoughCharacters ? 'success' : 'default'}
                  text="minimum 8 characters"
                />
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ display: 'flex' }}>
                <Badge
                  status={hasNumber ? 'success' : 'default'}
                  text="number"
                />
              </Col>
              <Col span={16} style={{ display: 'flex' }}>
                <Badge
                  status={hasSpecialCharacters ? 'success' : 'default'}
                  text="special character (eg. ~!@#$%&)"
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              disabled={
                !(
                  hasUpperCaseCharacter &&
                  hasEnoughCharacters &&
                  hasNumber &&
                  hasSpecialCharacters
                )
              }
              block
              size="large"
              type="primary"
              shape="round"
              htmlType="submit"
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default AuthUserMigrationConfirm;
