import { useMutation } from '@apollo/client';
import { Idea, REPORT_IDEA } from '@frontend/data-access';
import { Button, Checkbox, Form, Input, notification } from 'antd';
import { useCallback, useState } from 'react';

import styles from './idea-report.module.less';

/* eslint-disable-next-line */
export interface IdeaReportProps {
  data: Idea;
  onCancel(): void;
}

const options = [
  {
    label: 'Harassment or hateful speech',
    value: 'HARASSMENT',
  },
  {
    label: 'Suspicious, spam or fake',
    value: 'NUDITY',
  },
  {
    label: 'Intellectual property infringement or defamation',
    value: 'HATE_SPEECH',
  },
  {
    label: 'Other reasons',
    value: 'OTHER',
  },
];

export function IdeaReport({ data, onCancel }: IdeaReportProps) {
  const [form] = Form.useForm();
  const [isIncomplete, setIsIncomplete] = useState(false);

  const handleCompleted = () => {
    form.resetFields();
    onCancel();
    notification.success({
      message: 'Report successfully submitted',
      placement: 'bottomLeft',
    });
  };

  const [reportIdea, { loading }] = useMutation(REPORT_IDEA, {
    onCompleted: handleCompleted,
  });

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsIncomplete(hasErrors);
  };

  const handleOnFinish = useCallback(
    async (values: any) => {
      try {
        await form.validateFields();
        reportIdea({
          variables: {
            input: {
              ...values,
              ideaId: data?.id,
            },
          },
        });
      } catch (error) {
        //
      }
    },
    [data?.id, form, reportIdea]
  );

  return (
    <div className={styles['container']}>
      <Form
        form={form}
        layout="vertical"
        requiredMark={'optional'}
        onFieldsChange={handleFormChange}
        onFinish={handleOnFinish}
      >
        <div className="p-6">
          <Form.Item
            name="reportStatus"
            label="Problem"
            rules={[{ required: true, message: 'Please select problem' }]}
          >
            <Checkbox.Group>
              {options.map((item) => (
                <div key={item.value}>
                  <Checkbox value={item.value}>{item.label}</Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea rows={4} placeholder="Please input description" />
          </Form.Item>
        </div>
        <div className="flex justify-end items-center px-4 py-3 border-t border-solid border-gray-400 gap-2">
          <Button key="back" shape="round" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            type="primary"
            shape="round"
            loading={loading}
            disabled={isIncomplete}
            htmlType="submit"
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default IdeaReport;
