import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  ItalicOutlined,
  OrderedListOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import {
  FormatAlignJustifyIcon,
  H1Icon,
  H2Icon,
  QuoteIcon,
} from '@frontend/shared/ui';
import { Button, Space } from 'antd';
import isHotkey from 'is-hotkey';
import { ReactElement, useCallback, useState } from 'react';
import {
  BaseEditor,
  createEditor,
  Descendant,
  Editor,
  Element as SlateElement,
  Transforms,
} from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, useSlate, withReact } from 'slate-react';

interface CustomBaseEditor extends BaseEditor {
  text?: string;
  bold?: boolean | null;
}

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomBaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

type CustomText = {
  text: string;
  bold?: boolean | null;
  code?: boolean | null;
  italic?: boolean | null;
  underline?: boolean | null;
};
type CustomElement =
  | { type: 'paragraph'; align: string | undefined; children: CustomText[] }
  | { type: 'code'; align: string | undefined; children: CustomText[] }
  | { type: string; align?: string | undefined };

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};
const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export interface RichEditorProps {
  value?: string;
  placeholder?: string;
  shoudlAutoFocus?: boolean;
  setValue: (value: string) => void;
}

export function RichEditor({
  value,
  placeholder,
  shoudlAutoFocus,
  setValue,
}: RichEditorProps) {
  const initialValue = [
    {
      type: 'paragraph',
      children: [{ text: value && !isJsonString(value) ? value : '' }],
    },
  ] as Descendant[];
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const [editor] = useState(withHistory(withReact(createEditor())));

  editor.children =
    value && isJsonString(value) ? JSON.parse(value) : initialValue;

  function isJsonString(value: string) {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  }

  const handleChange = (newValue: Descendant[]) => {
    setValue(JSON.stringify(newValue));
  };

  return (
    <Slate
      editor={editor}
      value={value && isJsonString(value) ? JSON.parse(value) : initialValue}
      onChange={(newValue) => handleChange(newValue)}
    >
      <Space wrap>
        <MarkButton format="bold" icon={<BoldOutlined />} />
        <MarkButton format="italic" icon={<ItalicOutlined />} />
        <MarkButton format="underline" icon={<UnderlineOutlined />} />
        <BlockButton format="heading-one" icon={<H1Icon />} />
        <BlockButton format="heading-two" icon={<H2Icon />} />
        <BlockButton
          format="block-quote"
          icon={<QuoteIcon className="stroke-gray-100" />}
        />
        <BlockButton format="numbered-list" icon={<OrderedListOutlined />} />
        <BlockButton format="bulleted-list" icon={<UnorderedListOutlined />} />
        <BlockButton format="left" icon={<AlignLeftOutlined />} />
        <BlockButton format="center" icon={<AlignCenterOutlined />} />
        <BlockButton format="right" icon={<AlignRightOutlined />} />
        <BlockButton format="justify" icon={<FormatAlignJustifyIcon />} />
      </Space>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        className="border-solid border border-gray-500 rounded-md px-3 py-2 min-h-[84px]"
        spellCheck
        autoFocus={shoudlAutoFocus}
        placeholder={placeholder}
        renderPlaceholder={({ children, attributes }) => (
          <div {...attributes}>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                textAlign: 'left',
              }}
            >
              {children}
            </p>
          </div>
        )}
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (
              isHotkey(hotkey, event as React.KeyboardEvent<HTMLDivElement>)
            ) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
  );
}

const toggleBlock = (editor: Editor, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<SlateElement>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor: Editor, format: string, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType as keyof CustomElement] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor);
  return marks
    ? marks[format as keyof Omit<CustomText, 'text'>] === true
    : false;
};

const Element = ({
  attributes,
  children,
  element,
}: {
  attributes: any;
  children: ReactElement[];
  element: CustomElement;
}) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote
          className="p-4 my-4 border-l-4 border-gray-500"
          style={style}
          {...attributes}
        >
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <div style={style} {...attributes}>
          {children}
        </div>
      );
  }
};

const Leaf = ({
  attributes,
  children,
  leaf,
}: {
  attributes: any;
  children: ReactElement;
  leaf: CustomText;
}) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({
  format,
  icon,
}: {
  format: string;
  icon: ReactElement;
}) => {
  const editor = useSlate();
  return (
    <Button
      className={`${
        isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
        )
          ? '!bg-gray-300'
          : ''
      } !flex !items-center !justify-center`}
      type="text"
      size="small"
      icon={icon}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    />
  );
};

const MarkButton = ({
  format,
  icon,
}: {
  format: string;
  icon: ReactElement;
}) => {
  const editor = useSlate();
  return (
    <Button
      className={`${
        isMarkActive(editor, format) ? '!bg-gray-300' : ''
      } !flex !items-center !justify-center`}
      type="text"
      size="small"
      icon={icon}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    />
  );
};

export default RichEditor;
