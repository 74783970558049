import { signUp } from '@frontend/data-access';
import { Alert, AlertMessage } from '@frontend/shared/ui';
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from 'antd';
import { Rule } from 'antd/lib/form';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import {
  getAuthErrorMessages,
  usePasswordStrengthChecker,
} from '../auth-helper';
import Sso from '../sso/sso';

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};

export function Join() {
  const { t } = useTranslation('common');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<Alert>();
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const password = Form.useWatch('password', form) || '';
  const {
    hasEnoughCharacters,
    hasNumber,
    hasSpecialCharacters,
    hasUpperCaseCharacter,
  } = usePasswordStrengthChecker(password);

  const Rules: { [key: string]: [Rule] } = {
    firstName: [
      {
        required: true,
        validator: (_, value: string) =>
          value && value.trim().length > 2
            ? Promise.resolve()
            : Promise.reject(t('join_first_name_required')),
      },
    ],
    lastName: [
      {
        required: true,
        validator: (_, value: string) =>
          value && value.trim().length > 2
            ? Promise.resolve()
            : Promise.reject(t('join_last_name_required')),
      },
    ],
  };

  const handleSignUp = async (values: FormValues) => {
    try {
      setAlert(undefined);
      setLoading(true);

      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
      const token = await executeRecaptcha('signup');

      const response = await signUp({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        captcha: token,
      });
      const join = await response.json();
      if (response.status === 200 && join.user) {
        setAlert({
          messages: [t('join_success_message')],
          type: 'success',
        });
      } else {
        setAlert({
          messages: getAuthErrorMessages([...join.errors]),
          type: 'error',
        });
      }
    } catch (error) {
      let message = 'Unknown error';
      if (error instanceof Error) message = error.message;
      setAlert({ messages: [message], type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-[350px]">
      <Typography.Title className="!mb-2 text-center" level={2}>
        {t('join_title')}
      </Typography.Title>
      <div className="mb-6 text-center">
        <Typography.Text>
          {t('join_login_button_prefix')}{' '}
          <Link href="/login">{t('join_login_button')}</Link>
        </Typography.Text>
      </div>
      {alert && <AlertMessage className="my-6" alert={alert} />}
      {alert?.type !== 'success' && (
        <Form form={form} layout="vertical" onFinish={handleSignUp}>
          <Form.Item
            name="firstName"
            label={t('join_input_first_name')}
            rules={Rules.firstName}
          >
            <Input data-test="first-name" maxLength={200} />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={t('join_input_last_name')}
            rules={Rules.lastName}
          >
            <Input data-test="last-name" maxLength={200} />
          </Form.Item>
          <Form.Item
            name="email"
            label={t('join_input_email')}
            rules={[
              {
                required: true,
                type: 'email',
                message: t('join_input_email_required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('join_input_password')}
            name="password"
            rules={[
              { required: true, message: t('join_input_password_required') },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item label={t('join_input_password_contain')}>
            <Row>
              <Col span={8} style={{ display: 'flex' }}>
                <Badge
                  status={hasUpperCaseCharacter ? 'success' : 'default'}
                  text={t('join_input_password_uppercase')}
                />
              </Col>
              <Col span={16} style={{ display: 'flex' }}>
                <Badge
                  status={hasEnoughCharacters ? 'success' : 'default'}
                  text={t('join_input_password_length')}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ display: 'flex' }}>
                <Badge
                  status={hasNumber ? 'success' : 'default'}
                  text={t('join_input_password_number')}
                />
              </Col>
              <Col span={16} style={{ display: 'flex' }}>
                <Badge
                  status={hasSpecialCharacters ? 'success' : 'default'}
                  text={t('join_input_password_character')}
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            <Checkbox onChange={(e) => setPrivacyCheck(e.target.checked)}>
              <div className="text-sm font-normal text-gray-700">
                {t('join_agreement_prefix') + ' '}
                <a
                  className="underline"
                  target="_blank"
                  href="https://scventures.io/privacy-policy"
                  rel="noreferrer"
                >
                  {t('join_agreement_policy')}
                </a>
                ,{' '}
                <a
                  className="underline"
                  target="_blank"
                  href="https://scventures.io/terms-and-conditions"
                  rel="noreferrer"
                >
                  {t('join_agreement_terms')}
                </a>
                , {t('join_agreement_and')}
                <a
                  target="_blank"
                  className="underline"
                  href="https://www.sc.com/en/cookie-policy/"
                  rel="noreferrer"
                >
                  {t('join_agreement_cookie')}
                </a>
                .
              </div>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              disabled={
                !(
                  privacyCheck &&
                  hasUpperCaseCharacter &&
                  hasEnoughCharacters &&
                  hasNumber &&
                  hasSpecialCharacters
                )
              }
              block
              size="large"
              type="primary"
              shape="round"
              htmlType="submit"
            >
              {t('join_button')}
            </Button>
          </Form.Item>
          <Sso type="Sign up" disabled={!privacyCheck} />
        </Form>
      )}
    </div>
  );
}

export default Join;
