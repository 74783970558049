interface ROUTE_TYPE {
  path: string;
  type?: string;
  roles: string[];
}

export const AUTH_ROUTES = [
  { path: '/login' },
  { path: '/join' },
  { path: '/forgot-password' },
  { path: '/reset-password' },
  { path: '/confirm' },
  { path: '/migration' },
  { path: '/migration/confirm' },
];

export const PLATFORM_OPEN_ROUTES = [
  { path: '/' },
  { path: '/bridge' },
  { path: '/community' },
  { path: '/community/[id]' },
  { path: '/entrepreneurship' },
  { path: '/entrepreneurship/challenge/[id]' },
  { path: '/entrepreneurship/challenge/bundle/[id]' },
  { path: '/portfolio' },
  { path: '/pitch-arena' },
  { path: '/pitch-arena/[id]' },
  { path: '/company/[companyId]' },
  { path: '/discover' },
  { path: '/403' },
  { path: '/404' },
  { path: '/kanban' },
];

export const PLATFORM_PROTECTED_ROUTES: ROUTE_TYPE[] = [
  {
    path: '/intrapreneurship',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/application/basics',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/application/[applicationId]',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/application/[applicationId]/basics',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/application/[applicationId]/document',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/application/[applicationId]/questionnaire',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/application/[applicationId]/success',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/application/[applicationId]/team',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/application/[applicationId]/video',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/idea/solution/create',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/proof-of-concepts',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/proof-of-concepts/[id]',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/proof-of-concepts/create/basics',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/proof-of-concepts/create/[id]/basics',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/proof-of-concepts/create/[id]/business-scope',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/proof-of-concepts/create/[id]/geographical-scope',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/proof-of-concepts/create/[id]/key-aspects',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/proof-of-concepts/create/[id]/key-contacts',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/proof-of-concepts/create/[id]/KPIs-and-other-targets',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/proof-of-concepts/create/[id]/status',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/proof-of-concepts/create/[id]/success',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/proof-of-concepts/create/[id]/timeline',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/proof-of-concepts/create/[id]/vendors',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/my-profile/space/challenge-ideas',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/my-profile/space/intra-challenges',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/my-profile/space/open-challenges',
    roles: ['ADMIN', 'EXTERNAL_USER'],
  },
  {
    path: '/my-profile/space/pitch',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER', 'EXTERNAL_USER'],
  },
  {
    path: '/my-profile/space/poc',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/my-profile/space/posts',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER', 'EXTERNAL_USER'],
  },
  {
    path: '/my-profile/space/solution-ideas',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/application/basics',
    type: 'external',
    roles: ['EXTERNAL_USER'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/application/[applicationId]',
    type: 'external',
    roles: ['EXTERNAL_USER'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/application/[applicationId]/basics',
    type: 'external',
    roles: ['EXTERNAL_USER'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/application/[applicationId]/document',
    type: 'external',
    roles: ['EXTERNAL_USER'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/application/[applicationId]/questionnaire',
    type: 'external',
    roles: ['EXTERNAL_USER'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/application/[applicationId]/success',
    type: 'external',
    roles: ['EXTERNAL_USER'],
  },
  {
    path: '/idea',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/idea/[id]',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/innovation',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/innovation-list',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/innovation/[id]',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/innovation/invitation',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/leaderboard',
    type: 'internal',
    roles: ['ADMIN', 'INTRAPRENEUR', 'CHALLENGE_MANAGER'],
  },
];

export const ADMIN_PROTECTED_ROUTES: ROUTE_TYPE[] = [
  {
    path: '/',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/audit-log',
    roles: ['ADMIN'],
  },
  {
    path: '/company-management',
    roles: ['ADMIN'],
  },
  {
    path: '/company-management/[companyId]',
    roles: ['ADMIN'],
  },
  {
    path: '/company-management/showcase',
    roles: ['ADMIN'],
  },
  {
    path: '/content-management',
    roles: ['ADMIN'],
  },
  {
    path: '/content-management/post/[id]',
    roles: ['ADMIN'],
  },
  {
    path: '/entrepreneurship/challenge',
    roles: ['ADMIN'],
  },
  {
    path: '/entrepreneurship/challenge/[id]',
    roles: ['ADMIN'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/basic-info',
    roles: ['ADMIN'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/discussion-',
    roles: ['ADMIN'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/management',
    roles: ['ADMIN'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/media',
    roles: ['ADMIN'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/preview',
    roles: ['ADMIN'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/publish',
    roles: ['ADMIN'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/published',
    roles: ['ADMIN'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/questionnaire',
    roles: ['ADMIN'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/requirements',
    roles: ['ADMIN'],
  },
  {
    path: '/entrepreneurship/challenge/[id]/timeline',
    roles: ['ADMIN'],
  },
  {
    path: '/entrepreneurship/challenge/basic-info',
    roles: ['ADMIN'],
  },
  {
    path: '/entrepreneurship/challenge/bundle',
    roles: ['ADMIN'],
  },
  {
    path: '/idea-management',
    roles: ['ADMIN'],
  },
  {
    path: '/idea-management/[id]',
    roles: ['ADMIN'],
  },
  {
    path: '/intrapreneurship/challenge',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge-ideas',
    roles: ['ADMIN'],
  },
  {
    path: '/intrapreneurship/challenge-ideas/[id]',
    roles: ['ADMIN'],
  },
  {
    path: '/intrapreneurship/challenge-ideas/submission',
    roles: ['ADMIN'],
  },
  {
    path: '/intrapreneurship/challenge/[id]',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/basic-info',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/discussion-',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/management',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/kanban',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/media',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/preview',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/publish',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/published',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/questionnaire',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/[id]/timeline',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/challenge/basic-info',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/intrapreneurship/solution-ideas',
    roles: ['ADMIN'],
  },
  {
    path: '/intrapreneurship/solution-ideas/[id]',
    roles: ['ADMIN'],
  },
  {
    path: '/intrapreneurship/solution-ideas/submission',
    roles: ['ADMIN'],
  },
  {
    path: '/notification',
    roles: ['ADMIN', 'CHALLENGE_MANAGER'],
  },
  {
    path: '/pitch-management',
    roles: ['ADMIN'],
  },
  {
    path: '/pitch-management/pitch/[id]',
    roles: ['ADMIN'],
  },
  {
    path: '/proof-of-concept',
    roles: ['ADMIN'],
  },
  {
    path: '/proof-of-concept/[id]',
    roles: ['ADMIN'],
  },
  {
    path: '/tag-management',
    roles: ['ADMIN'],
  },
  {
    path: '/topic-management',
    roles: ['ADMIN'],
  },
  {
    path: '/user-management',
    roles: ['ADMIN'],
  },
];
