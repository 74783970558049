import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { UploadedFile } from '@frontend/data-access';
import { Modal, notification, Upload, UploadFile } from 'antd';
import type { RcFile, UploadProps } from 'antd/lib/upload';
import ImgCrop from 'antd-img-crop';
import useTranslation from 'next-translate/useTranslation';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useEffect, useState } from 'react';
export const PRESIGN = gql`
  mutation GetPresign($input: PresignInput!) {
    getPresign(input: $input) {
      url
    }
  }
`;

const supportedType = [
  {
    type: 'jpeg, jpg',
    mimeType: 'image/jpeg',
  },
  {
    type: 'png',
    mimeType: 'image/png',
  },
];

type ImageCropProps = {
  quality?: number;
  aspect?: number;
  minZoom?: number;
  maxZoom?: number;
  shape?: 'rect' | 'round' | undefined;
};

type ImageUploadProps = {
  title?: string;
  description?: string;
  compact?: boolean;
  maxImages?: number;
  defaultImages?: UploadFile[];
  crop?: ImageCropProps;
  onUploadSuccess?: (params: UploadedFile) => void;
  onRemove?: () => void;
};

const { Dragger } = Upload;

interface UploadFileListProps extends ImageUploadProps {
  maxFileSize?: number; // size in bytes
}

export function ImageUpload({
  title,
  description,
  compact = true,
  maxImages = 1,
  defaultImages = [],
  crop: {
    quality = 0.8,
    aspect = 1 / 1,
    minZoom = 1,
    maxZoom = 10,
    shape = 'rect',
  } = {},
  onUploadSuccess = () => undefined,
  onRemove = () => undefined,
  maxFileSize = undefined,
  ...props
}: UploadFileListProps) {
  const { t: tcommon } = useTranslation('common');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewTitle, setPreviewTitle] = useState<string>('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [presign] = useMutation(PRESIGN);

  useEffect(() => {
    if (defaultImages.length) setFileList(defaultImages);
  }, [defaultImages]);

  const prepareParams = (file: RcFile, url: URL): UploadedFile => {
    const key = url.pathname;
    const match = key.match(/\/(cache|store)\/(.+)/) as RegExpMatchArray;
    const id = match[2];
    const storage = match[1];
    return {
      id: id,
      url: url.origin + url.pathname,
      public: true,
      status: 'Active',
      storage: storage,
      metadata: {
        size: file.size,
        mime: file.type,
        filename: file.name,
      },
    };
  };

  const handleCustomRequest: UploadProps['customRequest'] = async ({
    file,
    onSuccess = () => undefined,
    onError = () => undefined,
  }: UploadRequestOption) => {
    try {
      const { data } = await presignRequest(file as File);
      const url = data?.getPresign.url;
      await fetch(url, {
        method: 'PUT',
        body: file,
      });
      onUploadSuccess(prepareParams(file as RcFile, new URL(url)));
      onSuccess('success');
    } catch (error) {
      let message = 'Upload error';
      if (error instanceof Error) message = error.message;
      onError(
        { name: 'upload.error', message: message },
        'Error uploading image !'
      );
    }
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const file = newFileList[newFileList.length - 1];
      if (file.size && maxFileSize !== undefined && file.size >= maxFileSize) {
        return;
      }
    }
    setFileList(
      newFileList.length > maxImages
        ? newFileList.slice(newFileList.length - 1)
        : newFileList
    );
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name ||
        String(file.url).substring(String(file.url).lastIndexOf('/') + 1)
    );
  };

  const handleCancel = () => setPreviewOpen(false);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const presignRequest = (file: File) => {
    const params = {
      storage: 'store',
      metadata: {
        size: file.size,
        filename: file.name,
        mime: file.type,
      },
    };
    return presign({ variables: { input: params } });
  };
  const onBeforeCrop = (file: File) => {
    const isSupported = supportedType.filter(
      (type) => type.mimeType === file.type
    );
    if (isSupported.length === 0) {
      notification.error({
        type: 'warning',
        message: 'Invalid format',
        description: `${file.name} is not a supported image file`,
        icon: (
          <span className="text-orange-600">
            <ExclamationCircleOutlined />
          </span>
        ),
        placement: 'bottomLeft',
      });
    }
    if (file.size && maxFileSize !== undefined) {
      const isLimit = file.size < maxFileSize; // 10MB;
      if (!isLimit) {
        notification.open({
          message: `File size exceeded maximum limit`,
          icon: (
            <span className="text-red-600">
              <CloseCircleOutlined />
            </span>
          ),
          placement: 'bottomLeft',
        });
        return false;
      }
    }
    return isSupported.length !== 0;
  };

  if (compact) {
    return (
      <div className="flex">
        <ImgCrop
          quality={quality}
          aspect={aspect}
          minZoom={minZoom}
          maxZoom={maxZoom}
          shape={shape}
          grid
          beforeCrop={onBeforeCrop}
        >
          <Upload
            className="!w-[102px]"
            {...props}
            name="avatar"
            listType="picture-card"
            customRequest={handleCustomRequest}
            fileList={fileList}
            onChange={handleChange}
            onPreview={handlePreview}
            onRemove={onRemove}
            accept={supportedType.map((type) => type.mimeType).join(',')}
          >
            {fileList.length >= maxImages ? null : (
              <PlusOutlined className="text-xl !text-white" />
            )}
          </Upload>
        </ImgCrop>
        <div className="flex flex-col justify-start space-y-1 ml-6">
          {title && <div className="font-medium text-base">{title}</div>}
          {description && (
            <div className="text-gray-700">{`${description} Supported file formats: ${supportedType
              .map((type) => type.type)
              .join(',')}`}</div>
          )}
        </div>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="preview" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }

  return (
    <div className="w-full overflow-auto">
      <ImgCrop
        quality={quality}
        aspect={aspect}
        minZoom={minZoom}
        maxZoom={maxZoom}
        grid
        beforeCrop={onBeforeCrop}
      >
        <Dragger
          {...props}
          customRequest={handleCustomRequest}
          fileList={fileList}
          onChange={handleChange}
          onPreview={handlePreview}
          onRemove={onRemove}
          accept={supportedType.map((type) => type.mimeType).join(',')}
        >
          <div className="p-4">
            <p className="ant-upload-drag-icon ">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              {tcommon('upload_image_default_title', {
                formats: supportedType.map((type) => type.type).join(','),
              })}
            </p>
            <p className="ant-upload-hint">
              {description
                ? description
                : maxImages > 1
                ? tcommon('upload_image_default_support_bulk_description')
                : tcommon('upload_image_default_support_description')}
            </p>
          </div>
        </Dragger>
      </ImgCrop>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
}

export default ImageUpload;
