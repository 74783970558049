import { Layout } from 'antd';
import getConfig from 'next/config';
import Image from 'next/image';
import Link from 'next/link';
import { ReactNode } from 'react';

export interface LayoutProps {
  children: ReactNode;
}

const { Content, Header } = Layout;

export function AuthLayout({ children }: LayoutProps) {
  const { publicRuntimeConfig } = getConfig();

  return (
    <Layout className="!min-h-screen flex flex-col">
      <Content className="flex flex-col py-8 px-6 bg-white">
        <Header className="flex justify-center !bg-white">
          <Link href="/bridge">
            <Image
              src={`${publicRuntimeConfig.staticFolder}/logo-full-transparent.svg`}
              height="48"
              width="200"
              priority
              alt="SCV logo"
            />
          </Link>
        </Header>

        <Layout style={{ maxWidth: '480px', margin: 'auto' }}>
          <Content className="flex flex-col justify-center bg-white">
            {children}
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
}

export default AuthLayout;
