import { IdeaCoverPhotoUrl } from '@frontend/data-access';
import { Image } from 'antd';
const NEXT_PUBLIC_UNSPLASH_PROJECT_NAME =
  process.env['NEXT_PUBLIC_UNSPLASH_PROJECT_NAME'];

/* eslint-disable-next-line */
export interface UnsplashImageProps {
  name: string;
  image: IdeaCoverPhotoUrl;
}

export function UnsplashImage(data: UnsplashImageProps) {
  return (
    <div className="md:min-w-[218px] max-w-[218px] mr-5">
      <div className="flex flex-col">
        <Image
          className="rounded-xl overflow-hidden"
          alt={data?.name}
          width={218}
          height={218}
          src={data?.image?.imageUrl}
          preview={false}
        />
        <span className="my-1 text-[10px] text-center">
          Photo by{' '}
          <a
            href={`${data?.image?.profileUrl}?utm_source=${NEXT_PUBLIC_UNSPLASH_PROJECT_NAME}&utm_medium=referral`}
          >
            {data?.image?.author}
          </a>{' '}
          on{' '}
          <a
            href={`https://unsplash.com/?utm_source=${NEXT_PUBLIC_UNSPLASH_PROJECT_NAME}&utm_medium=referral`}
          >
            Unsplash
          </a>
        </span>
      </div>
    </div>
  );
}

export default UnsplashImage;
