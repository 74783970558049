import { MatchmakingResultKeywords } from '@frontend/data-access';
import styles from './keyword-tag.module.less';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';

/* eslint-disable-next-line */
export interface KeywordTagProps {
  tag: MatchmakingResultKeywords;
}

export function KeywordTag({ tag }: KeywordTagProps) {
  let textColor = '';
  let icon = <></>;
  switch (tag.temperature) {
    case 'POSITIVE':
      textColor = 'text-green-600';
      icon = <CheckCircleOutlined />;
      break;
    case 'NEUTRAL':
      textColor = 'text-yellow-500';
      icon = <MinusCircleOutlined />;
      break;
    case 'NEGATIVE':
    default:
      textColor = 'text-red-500';
      icon = <CloseCircleOutlined />;
      break;
  }

  return (
    <span className="bg-gray-400 py-1 px-3 rounded-full text-xs font-normal gap-1 flex flex-nowrap">
      <div className={textColor}>{icon}</div>
      {tag.keyword}
    </span>
  );
}

export default KeywordTag;
