const REST_API_URL = process.env.NEXT_PUBLIC_REST_API_ENDPOINT;

export const signIn = ({
  email,
  password,
  captcha,
}: {
  email: string;
  password: string;
  captcha: string;
}) => {
  return fetch(`${REST_API_URL}/auth/login`, {
    body: JSON.stringify({
      email,
      password,
      captcha,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    credentials: 'include',
  });
};

export const signOut = () => {
  return fetch(`${REST_API_URL}/auth/logout`, {
    body: null,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    credentials: 'include',
  });
};

export const generateCode = ({
  email,
  captcha,
}: {
  email: string;
  captcha: string;
}) => {
  return fetch(`${REST_API_URL}/auth/password/generate_code`, {
    body: JSON.stringify({
      email: email,
      captcha,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    credentials: 'include',
  });
};

export const passwordConfirm = ({
  newPassword,
  token,
}: {
  newPassword: string;
  token: string;
}) => {
  return fetch(`${REST_API_URL}/auth/password/confirm`, {
    body: JSON.stringify({
      newPassword: newPassword,
      token: token,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    credentials: 'include',
  });
};

export const signUp = ({
  email,
  password,
  firstName,
  lastName,
  captcha,
}: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  captcha: string;
}) => {
  return fetch(`${REST_API_URL}/auth/signup`, {
    body: JSON.stringify({
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      captcha,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    credentials: 'include',
  });
};

export const emailConfirm = ({ token }: { token: string }) => {
  return fetch(`${REST_API_URL}/auth/email/confirm`, {
    body: JSON.stringify({
      token: token,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    credentials: 'include',
  });
};

export const emailGenerateCode = ({
  email,
  captcha,
}: {
  email: string;
  captcha: string;
}) => {
  return fetch(`${REST_API_URL}/auth/email/generate_code`, {
    body: JSON.stringify({
      email: email,
      captcha,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    credentials: 'include',
  });
};

export const passwordChange = ({
  password,
  newPassword,
}: {
  password: string;
  newPassword: string;
}) => {
  return fetch(`${REST_API_URL}/auth/password/change`, {
    headers: {
      accept: '*/*',
      'content-type': 'application/json',
    },
    referrer: `${process.env.NEXT_PUBLIC_PLATFORM_URL}${process.env.NEXT_PUBLIC_PLATFORM_BASE_PATH}`,
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify({ password: password, newPassword: newPassword }),
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
  });
};

export const migrationGenerateCode = ({ email }: { email: string }) => {
  return fetch(`${REST_API_URL}/auth/migration/generate_code`, {
    headers: {
      accept: '*/*',
      'content-type': 'application/json',
    },
    referrer: `${process.env.NEXT_PUBLIC_PLATFORM_URL}${process.env.NEXT_PUBLIC_PLATFORM_BASE_PATH}`,
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify({ email: email }),
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
  });
};

export const migrationConfirm = ({
  token,
  newPassword,
}: {
  token: string;
  newPassword: string;
}) => {
  return fetch(`${REST_API_URL}/auth/migration/confirm`, {
    headers: {
      accept: '*/*',
      'content-type': 'application/json',
    },
    referrer: `${process.env.NEXT_PUBLIC_PLATFORM_URL}${process.env.NEXT_PUBLIC_PLATFORM_BASE_PATH}`,
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify({ token: token, newPassword: newPassword }),
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
  });
};

export const getOrgInfoCrunchBase = ({
  name,
  return_field_ids,
  order = [],
  limit = 50,
}: {
  name: string;
  return_field_ids: string[];
  order?: {
    field_id: string;
    sort: 'asc' | 'desc';
  }[];
  limit?: number;
}) => {
  return fetch(
    `${process.env.NEXT_PUBLIC_BASE_PATH}/crunchbase/api/v4/searches/organizations`,
    {
      method: 'POST',
      headers: {
        accept: '*/*',
        'X-cb-user-key': process.env.NEXT_PUBLIC_CRUNCH_BASE_USER_KEY as string,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        field_ids: return_field_ids,
        order: order,
        query: [
          {
            type: 'predicate',
            field_id: 'identifier',
            operator_id: 'starts',
            values: [name],
          },
        ],
        limit,
      }),
    }
  );
};
