import { Company } from '@frontend/data-access';
import { Avatar, Image, Tooltip } from 'antd';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

/* eslint-disable-next-line */
export interface CompanyAvatarProps {
  company: Company;
  size?: number | 'default' | 'large' | 'small';
  shape?: 'square' | 'circle';
  className?: string;
  showBadge?: boolean;
  badgeSize?: number;
  isClickable?: boolean;
}

const covertName = (name: string) => {
  const splittedStr = name.split(/(\s+)/);
  let str = '';
  splittedStr.forEach((s) => {
    if (s.trim() !== '') {
      str = str.concat(s.trim().charAt(0).toUpperCase());
    }
  });
  return str;
};

export function CompanyAvatar({
  company,
  size = 'default',
  shape = 'square',
  className,
  showBadge = false,
  badgeSize = 20,
  isClickable = true,
}: CompanyAvatarProps) {
  const { publicRuntimeConfig } = getConfig();
  const router = useRouter();

  const handleClick = () => {
    if (company && isClickable) {
      router.push(`/company/${company?.id}`);
    }
  };

  return (
    <div className="relative w-fit">
      <Avatar
        size={size}
        shape={shape}
        src={company?.logo?.url}
        className={`${className} ${isClickable && 'cursor-pointer'}`}
        onClick={handleClick}
      >
        <span>{company?.name && covertName(company.name)}</span>
      </Avatar>
      {showBadge && (
        <Tooltip placement="right" title="SCV Portfolio">
          <div
            className={`absolute cursor-pointer bg-white rounded-full border border-gray-400 p-[2px] ${
              shape === 'square'
                ? 'bottom-[-5px] right-[-5px]'
                : 'bottom-[1px] right-[1px]'
            }`}
            style={{
              width: badgeSize,
              height: badgeSize,
            }}
          >
            <div className="flex justify-center items-center">
              <Image
                alt="scv portfolio"
                src={`${publicRuntimeConfig.staticFolder}/svg/scv-portfolio.svg`}
                preview={false}
              />
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default CompanyAvatar;
