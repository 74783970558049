import { CaretUpOutlined, UserOutlined } from '@ant-design/icons';
import {
  ApolloCache,
  NormalizedCacheObject,
  useMutation,
} from '@apollo/client';
import { GET_IDEA, IDEA_LIKE, IDEA_UNLIKE, Idea } from '@frontend/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserAvatar } from '@frontend/shared/ui';
import { Avatar, Image, Typography } from 'antd';
import { formatDistance } from 'date-fns';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

const { Text } = Typography;

/* eslint-disable-next-line */
export interface IdeaCardUserProps {
  data: Idea;
}

export function IdeaCardUser({ data }: IdeaCardUserProps) {
  const router = useRouter();
  const detailUrl = `/idea/${data?.id}`;
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [likeCount, setLikeCount] = useState<number>(0);

  const handleUpdateCacheLike = (cache: ApolloCache<NormalizedCacheObject>) => {
    const cacheData: { idea: Idea } | null = cache.readQuery({
      query: GET_IDEA,
      variables: {
        ideaId: data?.id,
      },
    });

    if (cacheData?.idea) {
      cache.writeQuery({
        query: GET_IDEA,
        variables: {
          ideaId: data?.id,
        },
        data: {
          idea: {
            ...cacheData?.idea,
            isLiked: true,
            likes: cacheData?.idea.likes + 1,
          },
        },
      });
    }
  };

  const handleUpdateCacheUnLike = (
    cache: ApolloCache<NormalizedCacheObject>
  ) => {
    const cacheData: { idea: Idea } | null = cache.readQuery({
      query: GET_IDEA,
      variables: {
        ideaId: data?.id,
      },
    });

    if (cacheData?.idea) {
      cache.writeQuery({
        query: GET_IDEA,
        variables: {
          ideaId: data?.id,
        },
        data: {
          idea: {
            ...cacheData?.idea,
            isLiked: false,
            likes: cacheData?.idea.likes - 1,
          },
        },
      });
      setIsLiked(true);
      setLikeCount(cacheData?.idea.likes - 1);
    }
  };

  const [likeIdea] = useMutation(IDEA_LIKE, {
    update: handleUpdateCacheLike,
    onCompleted() {
      setIsLiked(true);
      setLikeCount(likeCount + 1);
    },
  });
  const [unlikeIdea] = useMutation(IDEA_UNLIKE, {
    update: handleUpdateCacheUnLike,
    onCompleted() {
      setIsLiked(false);
      setLikeCount(likeCount - 1);
    },
  });

  const handleLike = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (isLiked) {
      unlikeIdea({
        variables: {
          id: data?.id,
        },
      });
    } else {
      likeIdea({
        variables: {
          id: data?.id,
        },
      });
    }
  };

  useEffect(() => {
    if (data) {
      setIsLiked(data.isLiked);
      setLikeCount(data.likes);
    }
  }, [data]);

  const renderAvatar = () => {
    return (
      <div className="flex gap-3 items-center">
        {data?.anonymous ? (
          <Avatar size={40} icon={<UserOutlined />} />
        ) : (
          <UserAvatar size={40} user={data?.createdBy} showBadge />
        )}
        <div className="grid">
          <Text className="text-gray-900 text-base line-clamp-1">
            {data?.anonymous ? 'Anonymous' : data?.createdBy.fullName}
          </Text>
          <span className="text-gray-700 text-sm">
            {formatDistance(new Date(data?.updatedAt), new Date(), {
              addSuffix: true,
            })}
          </span>
        </div>
      </div>
    );
  };

  const renderActions = () => {
    return (
      <div className="flex gap-3 items-center">
        {data?.collaborative && (
          <div className="bg-blue-100 py-0.5 px-3 rounded-full hidden md:block">
            <span className="text-xs text-blue-600 whitespace-nowrap">
              Looking for a teammate
            </span>
          </div>
        )}
        <div
          className={`px-2 py-1 rounded-full border border-gray-400 flex flex-col gap-0 items-center justify-center select-none cursor-pointer ${
            isLiked
              ? 'bg-primary-600 text-white'
              : 'hover:border-primary-600 hover:text-primary-600'
          }`}
          onClick={handleLike}
        >
          <CaretUpOutlined />
          <span className="text-xs">{likeCount}</span>
        </div>
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="flex gap-2">
        {data?.topics.map((tag, index) => {
          if (index < 4)
            return (
              <div
                key={tag.id}
                className="bg-gray-300 py-1 px-4 rounded-full text-sm overflow-hidden truncate"
              >
                {tag.name}
              </div>
            );
          return <div />;
        })}
        {data?.topics.length > 4 && (
          <div className="bg-gray-300 py-1 px-4 rounded-full text-sm break-keep whitespace-nowrap">
            ... {data?.topics.length - 4} more
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="bg-white border border-gray-300 rounded-3xl p-6 space-y-6 cursor-pointer hover:shadow-3xl transition duration-700 ease-in-out"
      onClick={() => router.push(detailUrl)}
    >
      <div className="flex justify-between items-center gap-2 flex-wrap">
        <div>{renderAvatar()}</div>
        <div>{renderActions()}</div>
      </div>
      <div>{renderTags()}</div>
      <div className="flex gap-6">
        <Image
          className="rounded-xl overflow-hidden max-w-[200px] min-w-[150px] md:min-w-[200px] min-h-[150px]"
          alt={data?.name}
          src={data?.coverPhoto?.url || data?.coverPhotoUrl?.imageUrl}
          preview={false}
        />
        <div>
          <Typography.Title level={5} data-test="idea-title">
            {data?.name}
          </Typography.Title>
          <Typography.Paragraph className="text-wrap line-clamp-6 whitespace-break-spaces">
            {data?.problem}
          </Typography.Paragraph>
        </div>
      </div>
      <div className="flex flex-col space-y-3 items-start">
        <div className="w-full flex gap-4 items-center justify-between">
          <div className="flex items-center gap-2">
            {data?.contributeCount > 0 && (
              <Avatar.Group>
                {data?.contributions.slice(0, 3).map((item, index) => (
                  <UserAvatar key={index} user={item.createdBy} />
                ))}
                {data?.contributions.length > 3 && (
                  <Avatar>+ {data?.contributeCount - 3}</Avatar>
                )}
              </Avatar.Group>
            )}
          </div>
        </div>
        {data?.collaborative && (
          <div className="bg-blue-100 py-0.5 px-3 rounded-full block md:hidden">
            <span className="text-xs text-blue-600 whitespace-nowrap">
              Looking for a teammate
            </span>
          </div>
        )}
        {data?.challenge && (
          <div className="space-x-1 overflow-hidden w-full">
            <span className="text-gray-700">Challenge:</span>
            <Text className="text-gray-700 font-semibold line-clamp truncate">
              {data.challenge.title}
            </Text>
          </div>
        )}
        {data?.innovationSpaceTag && (
          <div className="space-x-1 overflow-hidden w-full">
            <span className="text-gray-700">Innovation:</span>
            <Text className="text-gray-700 font-semibold line-clamp truncate">
              {data.innovationSpaceTag.name}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}

export default IdeaCardUser;
